import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataGrid, {
    Column,
    Summary,
    TotalItem,
    FilterRow,
    Export,
    Selection,
    Scrolling,
} from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';
import { usePromiseTracker } from "react-promise-tracker";
import { LinearProgress } from '@material-ui/core';
import { Chart, Series, Label, Size } from 'devextreme-react/chart';
import Moment from 'moment'
import {
    DiscountCellHastaneCommonStyle2,
    DiscountCellHastaneCommonStyle4,
    renderTitleHeader
} from "./devexpressgridspecs/RenderCell"
import { CellPreparedOdeyiciDetayi } from "./devexpressgridspecs/CellPrepared"
import { getOdeyiciDetayiTipi } from "../../../../slices/gunlukCiroRaporu";

const OdeyiciDetayi = () => {
    const dispatch = useDispatch();

    const { gunlukCiroRaporuOdeyiciDetayi } = useSelector((state) => state.gunlukCiroRaporu);
    const jsonGunlukCiroRaporuOdeyiciDetayi = JSON.parse(JSON.stringify(gunlukCiroRaporuOdeyiciDetayi));

    const { gunlukCiroRaporuOdeyiciDetayiGrafik } = useSelector((state) => state.gunlukCiroRaporu);

    const jsonOdeyiDetayiList = [
        { id: "Y", odeyiciDetayi: "Yurtdışı" },
        { id: "Y2", odeyiciDetayi: "Yurtdışı 2" },
        { id: "O", odeyiciDetayi: "Özel Kurum" },
        { id: "S", odeyiciDetayi: "SGK" },
        { id: "U", odeyiciDetayi: "Ücretli" }
    ];

    const [gridBoxValue, setGridBoxValue] = useState(jsonOdeyiDetayiList[0].id);
    const { promiseInProgress } = usePromiseTracker();
    const [reformattedArray, setReformattedArray] = useState([]);
    const [gelisTuruOpen, setGelisTuruOpen] = useState(false);

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    function customizeLabel() {
        return {
            visible: true,
            backgroundColor: '#FF0000',
            customizeText: function (e) {
                return `${numberWithCommas(e.valueText)}`;
            }
        };
    }

    useEffect(() => {
        var lreformattedArray = gunlukCiroRaporuOdeyiciDetayiGrafik.map(obj => {
            var rObj = {};
            rObj.ciro = parseFloat(parseFloat(obj.ciro).toFixed(0));
            var momentObj = Moment(obj.tarih + '');
            var momentString = momentObj.format('DD-MM-YYYY');
            rObj.date = momentString;
            return rObj;
        })

        setReformattedArray(lreformattedArray);

    }, [gunlukCiroRaporuOdeyiciDetayiGrafik])

    const dataGrid_onSelectionChanged = (e) => {
        let lgridBoxValue = e.selectedRowKeys === undefined ? 0 : e.selectedRowKeys[0].id;
        setGridBoxValue(lgridBoxValue);
        dispatch(getOdeyiciDetayiTipi(lgridBoxValue));
        setGelisTuruOpen(false);
    }

    const gridBox_displayExpr = (item) => {
        let displayTxt = item !== undefined ? item.odeyiciDetayi : "";
        return displayTxt;
    }

    const gridColumns = ['odeyiciDetayi'];

    const onGelisTuruOpened = (e) => {
        if (e.name === 'opened') {
            setGelisTuruOpen(e.value)
        }
    }

    const dataGridRender = () => {
        return (
            <DataGrid
                dataSource={jsonOdeyiDetayiList}
                columns={gridColumns}
                hoverStateEnabled={true}
                onSelectionChanged={dataGrid_onSelectionChanged}
                height="100%">
                <Selection mode="single" />
                <Scrolling mode="infinite" />
                <FilterRow visible={false} />
            </DataGrid>
        );
    }

    return (
        <div>
            <div>
                {promiseInProgress && (
                    <LinearProgress />)
                }
            </div>
            <div>
                <DropDownBox
                    value={gridBoxValue}
                    valueExpr="id"
                    deferRendering={false}
                    displayExpr={gridBox_displayExpr}
                    placeholder="Ödeyici Detayı Seçiniz"
                    dataSource={jsonOdeyiDetayiList}
                    contentRender={dataGridRender}
                    opened={gelisTuruOpen}
                    onOptionChanged={onGelisTuruOpened}
                />
                <DataGrid
                    allowColumnResizing={true}
                    columnMinWidth={50}
                    columnAutoWidth={true}
                    dataSource={jsonGunlukCiroRaporuOdeyiciDetayi}
                    showBorders={true}
                    style={{ height: 300 }}
                    onCellPrepared={CellPreparedOdeyiciDetayi}
                >
                    <Export enabled={true} />
                    <FilterRow visible={true} />
                    <Column headerCellRender={renderTitleHeader} dataField="hastane" caption="Hastane" alignment="left" />
                    <Column headerCellRender={renderTitleHeader} dataField="butce" caption="Bütçe" alignment="center"
                        cellRender={DiscountCellHastaneCommonStyle4} />
                    <Column headerCellRender={renderTitleHeader} dataField="ciro" caption="Ciro" alignment="center"
                        cellRender={DiscountCellHastaneCommonStyle4} />
                    <Column headerCellRender={renderTitleHeader} dataField="gidisat" caption="Gidişat" alignment="center"
                        cellRender={DiscountCellHastaneCommonStyle4} />
                    <Column headerCellRender={renderTitleHeader} dataField="endeksdegisimi" caption="Endeks Değişimi" alignment="center"
                        cellRender={DiscountCellHastaneCommonStyle2} />
                    <Column headerCellRender={renderTitleHeader} dataField="butcegerceklesmeorani" caption="Bütçe Gerçekleşme Oranı" alignment="center"
                        cellRender={DiscountCellHastaneCommonStyle2} />
                    <Summary>
                        <TotalItem alignment="right" column="butce" summaryType="sum" valueFormat="fixedPoint" />
                        <TotalItem alignment="right" column="ciro" summaryType="sum" valueFormat="fixedPoint" />
                        <TotalItem alignment="right" column="gidisat" summaryType="sum" valueFormat="fixedPoint" />
                    </Summary>
                </DataGrid>
            </div>
            <div style={{ marginTop: 8 }}>
                <Chart id="chart" dataSource={reformattedArray}
                    customizeLabel={customizeLabel}>
                    <Size
                        height={250}
                    />
                    <Series
                        valueField="ciro"
                        argumentField="date"
                        name="ciro"
                        type="bar"
                        color="#CC0000">
                        <Label visible={true} backgroundColor="#CC0000" />
                    </Series>
                </Chart>
            </div>
        </div>
    );
}

export default OdeyiciDetayi;
