import React, {
  Suspense,
  Fragment,
  lazy
} from 'react';
import {
  Switch,
  Redirect,
  Route
} from 'react-router-dom';
import DashboardLayout3 from 'src/layouts/DashboardLayout3';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes
                    ? renderRoutes(route.routes)
                    : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    path: '/',
    guard: AuthGuard,
    layout: DashboardLayout3,
    component: () => <Redirect to="/ana-sayfa" />
  },
  {
    path: '/ana-sayfa',
    guard: AuthGuard,
    layout: DashboardLayout3,
    routes: [
      {
        exact: true,
        path: '/ana-sayfa',
        component: lazy(() => import('./views/app/ana-sayfa'))
      }
    ]
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout3,
    routes: [
      {
        exact: true,
        path: '/app/sistem/merkezi-bolum-tanim',
        component: lazy(() => import('src/views/app/sistem/MerkeziBolumTanim')),
      },
      {
        exact: true,
        path: '/app/sistem/merkezi-kurum-tanim',
        component: lazy(() => import('src/views/app/sistem/MerkeziKurumTanim')),
      },
      {
        exact: true,
        path: '/app/sistem/merkezi-menu-yetkileri',
        component: lazy(() => import('src/views/app/sistem/MerkeziMenuYetkileri')),
      },
      {
        exact: true,
        path: '/app/sistem/merkezi-personel-tanim',
        component: lazy(() => import('src/views/app/sistem/MerkeziPersonelTanim')),
      },
      {
        exact: true,
        path: '/app/sistem/merkezi-pozisyon-tanim',
        component: lazy(() => import('src/views/app/sistem/MerkeziPozisyonTanim')),
      },
      {
        exact: true,
        path: '/app/sistem/merkezi-yetki-tanim',
        component: lazy(() => import('src/views/app/sistem/MerkeziYetkiTanim')),
      },
      {
        exact: true,
        path: '/app/uluslararasi-hasta/uluslararasi-hasta-paneli',
        component: lazy(() => import('src/views/app/uluslararasi-hasta/UluslararasiHastaPaneli')),
      },
      {
        exact: true,
        path: '/app/uluslararasi-hasta/yurtdisi-hasta-ciro',
        component: lazy(() => import('src/views/app/uluslararasi-hasta/YurtdisiHastaCiro')),
      },
      {
        exact: true,
        path: '/app/uluslararasi-hasta/yurtdisi-ozet',
        component: lazy(() => import('src/views/app/uluslararasi-hasta/YurtdisiOzet')),
      },
      {
        exact: true,
        path: '/app/uluslararasi-hasta/dijital-pazarlama',
        component: lazy(() => import('src/views/app/uluslararasi-hasta/DijitalPazarlama')),
      },
      {
        exact: true,
        path: '/app/bakim-envanter/demirbas-tanimlari',
        component: lazy(() => import('src/views/app/bakim-envanter/DemirbasTanimlari')),
      },
      {
        exact: true,
        path: '/app/bakim-envanter/demirbas-alan-tanimlari',
        component: lazy(() => import('src/views/app/bakim-envanter/DemirbasAlanTanimlari')),
      },
      {
        exact: true,
        path: '/app/bakim-envanter/demirbas-izleme',
        component: lazy(() => import('src/views/app/bakim-envanter/DemirbasIzleme')),
      },
      {
        exact: true,
        path: '/app/bakim-envanter/yer-tanim-izleme',
        component: lazy(() => import('src/views/app/bakim-envanter/YerTanimIzleme')),
      },
      {
        exact: true,
        path: '/app/bakim-envanter/tuketim-takibi',
        component: lazy(() => import('src/views/app/bakim-envanter/TuketimTakibi')),
      },
      {
        exact: true,
        path: '/app/insan-kaynaklari/doktor-kayit',
        component: lazy(() => import('src/views/app/insan-kaynaklari/DoktorKayit')),
      },
      {
        exact: true,
        path: '/app/kalite/dokuman-paneli',
        component: lazy(() => import('src/views/app/kalite/DokumanPaneli')),
      },
      {
        exact: true,
        path: '/app/kalite/olay-bildirimi',
        component: lazy(() => import('src/views/app/kalite/OlayBildirimi')),
      },
      {
        exact: true,
        path: '/app/kalite/oz-degerlendirme',
        component: lazy(() => import('src/views/app/kalite/OzDegerlendirme')),
      },
      {
        exact: true,
        path: '/app/kalite/dof-takibi',
        component: lazy(() => import('src/views/app/kalite/DOFTakibi')),
      },
      {
        exact: true,
        path: '/app/powerbi/:powerBIToken',
        component: lazy(() => import('src/views/app/rapor/PowerBI')),
      },
      {
        exact: true,
        path: '/app/rapor/brans-muayene-ameliyat-sayisi',
        component: lazy(() => import('src/views/app/rapor/BransMuayeneAmeliyatSayisi')),
      },
      {
        exact: true,
        path: '/app/rapor/doktor-karnesi-ozeti',
        component: lazy(() => import('src/views/app/rapor/DoktorKarnesiOzeti')),
      },
      {
        exact: true,
        path: '/app/rapor/doktor-performans-ozeti',
        component: lazy(() => import('src/views/app/rapor/DoktorPerformansOzeti')),
      },
      {
        exact: true,
        path: '/app/rapor/ozellikli-malzeme-raporu',
        component: lazy(() => import('src/views/app/rapor/OzellikliMalzemeRaporu')),
      },
      {
        exact: true,
        path: '/app/rapor/ozellikli-malzeme-raporu',
        component: lazy(() => import('src/views/app/rapor/OzellikliMalzemeRaporu')),
      },
      {
        exact: true,
        path: '/app/rapor/ciro-raporu',
        component: lazy(() => import('src/views/app/rapor/CiroRaporu')),
      },
      {
        exact: true,
        path: '/app/rapor/gunluk-ciro-raporu',
        component: lazy(() => import('src/views/app/rapor/GunlukCiroRaporu')),
      },
      {
        exact: true,
        path: '/app/rapor/gunluk-ciro-raporu/power-bi',
        component: lazy(() => import('src/views/app/rapor/GunlukCiroRaporuPowerBi')),
      },
      {
        exact: true,
        path: '/app/rapor/brans-butce-gerceklesme',
        component: lazy(() => import('src/views/app/rapor/BransButceGerceklesme')),
      },
      {
        exact: true,
        path: '/app/rapor/doktor-karnesi',
        component: lazy(() => import('src/views/app/rapor/DoktorKarnesi')),
      },
      {
        exact: true,
        path: '/app/rapor/ozet-yonetim-raporu',
        component: lazy(() => import('src/views/app/rapor/OzetYonetimRaporu')),
      },
      {
        exact: true,
        path: '/app/rapor/maliyet-analizi',
        component: lazy(() => import('src/views/app/rapor/MaliyetAnalizi')),
      },
      {
        exact: true,
        path: '/app/rapor/ilac-sarf-gidisat-raporu',
        component: lazy(() => import('src/views/app/rapor/IlacSarfGidisatRaporu')),
      },
      {
        exact: true,
        path: '/app/rapor/tani-islem-analizi',
        component: lazy(() => import('src/views/app/rapor/TanıIslemAnalizi')),
      },
      {
        exact: true,
        path: '/app/rapor/ameliyat-maliyet-analizi',
        component: lazy(() => import('src/views/app/rapor/AmeliyatMaliyetAnalizi')),
      },
      {
        exact: true,
        path: '/app/rapor/diger-gider-takip-raporu',
        component: lazy(() => import('src/views/app/rapor/DigerGiderTakipRaporu')),
      },
      {
        exact: true,
        path: '/app/rapor/yatak-doluluk-oranlari',
        component: lazy(() => import('src/views/app/rapor/YatakDolulukOranlari')),
      },
      {
        exact: true,
        path: '/app/rapor/muayene-randevu-analizi',
        component: lazy(() => import('src/views/app/rapor/MuayeneRandevuAnalizi')),
      },
      {
        exact: true,
        path: '/app/rapor/ameliyat-sure-analizi',
        component: lazy(() => import('src/views/app/rapor/AmeliyatSureAnalizi')),
      },
      {
        exact: true,
        path: '/app/rapor/hasta-sayilari',
        component: lazy(() => import('src/views/app/rapor/HastaSayilari')),
      },
      {
        exact: true,
        path: '/app/rapor/hastane-performans-analizi',
        component: lazy(() => import('src/views/app/rapor/HastanePerformansAnalizi')),
      },
      {
        exact: true,
        path: '/app/is-zekasi/rapor-tanimlari/surec-tanimlari',
        component: lazy(() => import('src/views/app/is-zekasi/rapor-tanimlari/SurecTanimlari')),
      },
      {
        exact: true,
        path: '/app/rapor/laboratuvar-sure-analizi',
        component: lazy(() => import('src/views/app/rapor/LaboratuvarSureAnalizi')),
      },
      {
        exact: true,
        path: '/app/rapor/hastane-performans-analizi',
        component: lazy(() => import('src/views/app/rapor/HastanePerformansAnalizi')),
      },
      {
        exact: true,
        path: '/app/rapor/tetkik-sure-analizi',
        component: lazy(() => import('src/views/app/rapor/TetkikSureAnalizi')),
      },
      {
        exact: true,
        path: '/app/rapor/ameliyat-sure-analizi',
        component: lazy(() => import('src/views/app/rapor/AmeliyatSureAnalizi')),
      },
      {
        exact: true,
        path: '/app/rapor/order-sure-analizi',
        component: lazy(() => import('src/views/app/rapor/OrderSureAnalizi')),
      },
      {
        exact: true,
        path: '/app/rapor/hekim-maliyetleri',
        component: lazy(() => import('src/views/app/rapor/HekimMaliyetleri')),
      },
      {
        exact: true,
        path: '/app/rapor/hastalarin-bolge-dagilimi',
        component: lazy(() => import('src/views/app/rapor/HastalarinBolgeDagilimi')),
      },
      {
        exact: true,
        path: '/app/rapor/doktor-karnesi',
        component: lazy(() => import('src/views/app/rapor/DoktorKarnesi')),
      },
      {
        exact: true,
        path: '/app/rapor-paneli',
        component: lazy(() => import('src/views/app/rapor-paneli')),
      },
      {
        exact: true,
        path: '/app/rapor/ozel-rapor',
        component: lazy(() => import('src/views/app/rapor/OzelRapor')),
      },
      {
        exact: true,
        path: '/app/rapor/pivot-objects',
        component: lazy(() => import('src/views/app/relatedobjects4sample/pivotobjects')),
      },  
      {
        exact: true,
        path: '/app/rapor/doktorpanel',
        component: lazy(() => import('src/views/app/doctorpanel')),
      }, 
      {
        exact: true,
        path: '/app/rapor/oneriproje',
        component: lazy(() => import('src/views/app/oneriproje')),
      },  
      {
        exact: true,
        path: '/app/rapor/proje',
        component: lazy(() => import('src/views/app/oneriproje/proje.js')),
      },
      {
        exact: true,
        path: '/app/rapor/gerceklesmisproje',
        component: lazy(() => import('src/views/app/oneriproje/gerceklesmisproje.js')),
      },                
      {
        exact: true,
        path: '/app/PortalGunlukYemekCrud',
        component: lazy(() => import('src/views/app/portal/PortalGunlukYemekCrud.js')),
      },  
      {
        exact: true,
        path: '/app/PortalBaglantilarCrud',
        component: lazy(() => import('src/views/app/portal/PortalBaglantilarCrud.js')),
      },  
      {
        exact: true,
        path: '/app/PortalTelefonRehberiCrud',
        component: lazy(() => import('src/views/app/portal/PortalTelefonRehberiCrud.js')),
      },       
      {
        exact: true,
        path: '/app/PortalDuyurularCrud',
        component: lazy(() => import('src/views/app/portal/PortalDuyurularCrud.js')),
      },         
      {
        exact: true,
        path: '/app/PortalIletiAnaSayfa',
        component: lazy(() => import('src/views/app/portal/PortalIletiMain.js')),
      },                  
      {
        exact: true,
        path: '/app/rapor/doktorpanel-medikal-tanim',
        component: lazy(() => import('src/views/app/doktorpanel-medikal-tanim')),
      }, 
      {
        exact: true,
        path: '/app/hastahizmetleri/hastailiskileri/hastamemnuniyettakiplistesi',
        component: lazy(() => import('src/views/app/hastahizmetleri/hastailiskileri/hastamemnuniyettakiplistesi.js')),
      },         
      {
        exact: true,
        path: '/app/is-zekasi/hekimlikhizmetleri',
        component: lazy(() => import('src/views/app/is-zekasi/hekimlikhizmetleri')),
      },         
      {
        exact: true,
        path: '/app/is-zekasi/hekimlikhizmetleri/kpiolcumtakibi',
        component: lazy(() => import('src/views/app/is-zekasi/hekimlikhizmetleri/kpiolcumtakibi.js')),
      },         
      {
        exact: true,
        path: '/app/is-zekasi/hekimlikhizmetleri/kpiolcumtakibidashboard',
        component: lazy(() => import('src/views/app/is-zekasi/hekimlikhizmetleri/kpiolcumtakibidashboard.js')),
      },         
      {
        exact: true,
        path: '/app/pazarlama/content1',
        component: lazy(() => import('src/views/app/pazarlama/content1.js')),
      },         
      {
        exact: true,
        path: '/app/pazarlama/content2',
        component: lazy(() => import('src/views/app/pazarlama/content2.js')),
      },         
      {
        exact: true,
        path: '/app/pazarlama/content3',
        component: lazy(() => import('src/views/app/pazarlama/content3.js')),
      },         
      {
        exact: true,
        path: '/app/pazarlama/content4',
        component: lazy(() => import('src/views/app/pazarlama/content4.js')),
      },         
      {
        exact: true,
        path: '/app/pazarlama/content5',
        component: lazy(() => import('src/views/app/pazarlama/content5.js')),
      },         
      {
        exact: true,
        path: '/app/pazarlama/content6',
        component: lazy(() => import('src/views/app/pazarlama/content6.js')),
      },          
      {
        exact: true,
        path: '/app/pazarlama/ikcontent1',
        component: lazy(() => import('src/views/app/pazarlama/ikcontent1.js')),
      },         
      {
        exact: true,
        path: '/app/pazarlama/ikcontent2',
        component: lazy(() => import('src/views/app/pazarlama/ikcontent2.js')),
      },         
      {
        exact: true,
        path: '/app/pazarlama/markavekurumsaliletisimcontent',
        component: lazy(() => import('src/views/app/pazarlama/markavekurumsaliletisimcontent.js')),
      },         
      {
        exact: true,
        path: '/app/is-zekasi/projeksiyongunlukciro',
        component: lazy(() => import('src/views/app/is-zekasi/projeksiyongunlukCiro')),
      },         
      {
        exact: true,
        path: '/app/is-zekasi/gelirgiderbutcesi',
        component: lazy(() => import('src/views/app/is-zekasi/gelirgiderbutcesi/listggbutcesifiles')),
      },                            
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
