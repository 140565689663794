import { createSlice } from '@reduxjs/toolkit'
import OzelRaporService from "../services/is-zekasi/rapor/ozelRaporService";
import { trackPromise } from 'react-promise-tracker';

const initialState = { 
    ozelRaporList: []
   }

const ozelRaporSlice = createSlice({
    name: 'ozelRapor',
    initialState,
    reducers: {
        ozelRaporReducer(state, action) {
            const ozelRaporList = action.payload;
            state.ozelRaporList = ozelRaporList;        
        },
    },
})   

export const reducer = ozelRaporSlice.reducer 

const { ozelRaporReducer } = ozelRaporSlice.actions

export const getOzelRaporListAction = (wpc, hCodes, dStart, dEnd) => async dispatch => {

    const ozelraporAction = trackPromise(OzelRaporService.getDinamikRapor(wpc,hCodes,dStart,dEnd));

    ozelraporAction.then((response) =>{
      //console.log('OzelRaporService response', response);
       dispatch(ozelRaporReducer(response.dinamikraporlistList));
    })
    
}