import { TaniIslemAnaliziClient } from "../../../protos/medikal/tedavi/izleme/tani_islem_analizi_grpc_web_pb.js";
import {
  TaniAnalizleriniGetirRequest,
  IslemAnalizleriniGetirRequest,
} from "../../../protos/medikal/tedavi/izleme/tani_islem_analizi_pb.js";
import { Empty } from "google-protobuf/google/protobuf/empty_pb";

class TaniIslemAnaliziService {
  taniAnalizleriniGetir = (
    baslangictarihi,
    bitistarihi,
    bolum,
    hastane,
    gelisTuru,
    taniTipi
  ) => {
    return  new Promise((resolve, reject) => {
        const client = new TaniIslemAnaliziClient(
          process.env.REACT_APP_API_BASE_URL
        );
        const request = new TaniAnalizleriniGetirRequest();
        request.setBaslangictarihi(baslangictarihi);
        request.setBitistarihi(bitistarihi);
        request.setBolum(bolum);
        request.setHastane(hastane);
        request.setGelisturu(gelisTuru);
        request.setTanitipi(taniTipi);
        var token = localStorage.getItem('accessToken');
        client.taniAnalizleriniGetir(request, {"lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
          if (response) {
            resolve(response.toObject());
          } else {
            reject(error);
          }
        });
      });
  };

  islemAnalizleriniGetir = (
    baslangictarihi,
    bitistarihi,
    hastane,
    bolum,
    gelisTuru,
    taniTipi,
    protokolSayisi,
    taniKodu,
    sorumluDoktor
  ) => {
    return new Promise((resolve, reject) => {
        const client = new TaniIslemAnaliziClient(
          process.env.REACT_APP_API_BASE_URL
        );
        const request = new IslemAnalizleriniGetirRequest();
        request.setBaslangictarihi(baslangictarihi);
        request.setBitistarihi(bitistarihi);
        request.setHastane(hastane);
        request.setBolum(bolum);
        request.setGelisturu(gelisTuru);
        request.setTanitipi(taniTipi);
        request.setProtokolsayisi(protokolSayisi);
        request.setTanikodu(taniKodu);
        request.setSorumludoktor(sorumluDoktor);
        var token = localStorage.getItem('accessToken');
        client.islemAnalizleriniGetir(request, {"lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
          if (response) {
            resolve(response.toObject());
          } else {
            reject(error);
          }
        });
      });
  };
  
  merkeziBolumleriGetir = () => {
    return new Promise((resolve, reject) => {
      const client = new TaniIslemAnaliziClient(process.env.REACT_APP_API_BASE_URL);
      const request = new Empty();
      var token = localStorage.getItem('accessToken');

      client.merkeziBolumleriGetir(request, {"lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
        if (response) {
          resolve(response.toObject());
        } else {
          reject(error);
        }
      });
    });
  };

}

const taniIslemAnaliziService = new TaniIslemAnaliziService();

export default taniIslemAnaliziService;
