import jwtDecode from 'jwt-decode';
import { AuthClient } from "../protos/auth_grpc_web_pb.js";
import { LoginWithUsernameAndPasswordRequest, LoginInWithTokenRequest } from "../protos/auth_pb.js";

class AuthService {

    handleAuthentication() {
        const accessToken = this.getAccessToken();

        if (!accessToken) {
            return;
        }

        if (this.isValidToken(accessToken)) {
            this.setSession(accessToken);
        } else {
            this.setSession(null);
        }
    }

    loginWithUsernameAndPassword = (userName, password, institutionId, personelid) => {
        return new Promise((resolve, reject) => {
            const client = new AuthClient(process.env.REACT_APP_API_BASE_URL);
            let loginWithUsernameAndPasswordRequest = new LoginWithUsernameAndPasswordRequest();
            loginWithUsernameAndPasswordRequest.setSifre(password);
            loginWithUsernameAndPasswordRequest.setKullaniciadi(userName);
            loginWithUsernameAndPasswordRequest.setKurumid(institutionId);
            loginWithUsernameAndPasswordRequest.setPersonelid(personelid)
            var token = localStorage.getItem('accessToken');

            localStorage.setItem('lsiid', institutionId);

            client.loginWithUsernameAndPassword(loginWithUsernameAndPasswordRequest, {"lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                if (response) {
                    this.setSession(response.toObject().token)
                    this.setSecretKey(response.toObject().secretkey)

                    resolve(response.toObject());
                } else {
                    reject(error);
                }
            })
        })
    }

    loginInWithToken = () => {
        return new Promise((resolve, reject) => {
            const client = new AuthClient(process.env.REACT_APP_API_BASE_URL);
            let loginInWithTokenRequest = new LoginInWithTokenRequest();
            loginInWithTokenRequest.setToken(this.getAccessToken());
            var token = localStorage.getItem('accessToken');
            client.loginInWithToken(loginInWithTokenRequest, {"lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                if (response) {
                    resolve(response.toObject());
                } else {
                    reject(error);
                }
            })
        })
    }

    logout = () => {
        this.setSession(null);
        this.setSecretKey(null);
    }

    setSession = (accessToken) => {
        if (accessToken) {
            localStorage.setItem('accessToken', accessToken);
        } else {
            localStorage.removeItem('accessToken');
        }
    }

    getAccessToken = () => localStorage.getItem('accessToken');

    setSecretKey = (secretKey) => {
        if(secretKey) {
            localStorage.setItem('secretKey', secretKey);
        } else {
            localStorage.removeItem('secretKey');
        }
    }

    isValidToken = (accessToken) => {
        if (!accessToken) {
            return false;
        }
        
        const decoded = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000;

        return decoded.exp > currentTime;
    }

    isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;
