import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import { reducer as gunlukCiroRaporuReducer } from 'src/slices/gunlukCiroRaporu';
import { reducer as generalContentReducer } from 'src/slices/generalContent';
import { reducer as permissionReducer } from 'src/slices/permission';
import { reducer as raporPaneliReducer } from 'src/slices/raporPaneli';
import { reducer as dashboardReducer } from 'src/slices/dashboard';
import { reducer as yatakDolulukOranlariRaporuReducer } from 'src/slices/yatakDolulukOranlariRaporu';
import { reducer as taniIslemAnaliziRaporuReducer } from 'src/slices/taniİslemAnaliziRaporu';
import { reducer as yetkiTanimlariReducer } from 'src/slices/yetkiTanimlari';
import { reducer as ozelRaporReducer } from 'src/slices/ozelRapor';
//import { reducer as globaldataReducer } from 'src/slices/globaldata';
import { reducer as surecTanimReducer } from 'src/slices/surecTanim';
import { reducer as raporTanimReducer } from 'src/slices/raporTanimlari';
import { reducer as bolumButceTakibiReducer } from 'src/slices/bolumButceTakibi';
import { reducer as ozetYonetimRaporuReducer } from 'src/slices/ozetYonetimRaporu';
import { reducer as doktorKarnesiReducer } from 'src/slices/doktorKarnesi';
import { reducer as doctorpanelReducer } from '../slices/doctorpanel';

const rootReducer = combineReducers({
  form: formReducer,
  permission: permissionReducer,
  gunlukCiroRaporu: gunlukCiroRaporuReducer,
  generalContent: generalContentReducer,
  yetkiTanimlari: yetkiTanimlariReducer,
  raporPaneli: raporPaneliReducer,
  yatakDolulukOranlariRaporu: yatakDolulukOranlariRaporuReducer,
  dashboard: dashboardReducer,
  taniIslemAnaliziRaporu: taniIslemAnaliziRaporuReducer,
  surecTanim: surecTanimReducer,
  raporTanim: raporTanimReducer,
  ozelRapor: ozelRaporReducer,
  bolumButceTakibi: bolumButceTakibiReducer,
  ozetYonetimRaporu: ozetYonetimRaporuReducer,
  doktorKarnesi: doktorKarnesiReducer,
  doctorpanel: doctorpanelReducer
  //globaldata: globaldataReducer
});

export default rootReducer;
