// source: yatak_doluluk_oranlari.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse', null, global);
goog.exportSymbol('proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto', null, global);
goog.exportSymbol('proto.YatakDolulukOranlari.KurumlariGetirResponse', null, global);
goog.exportSymbol('proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto', null, global);
goog.exportSymbol('proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest', null, global);
goog.exportSymbol('proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse', null, global);
goog.exportSymbol('proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.repeatedFields_, null);
};
goog.inherits(proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.displayName = 'proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto.displayName = 'proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.YatakDolulukOranlari.KurumlariGetirResponse.repeatedFields_, null);
};
goog.inherits(proto.YatakDolulukOranlari.KurumlariGetirResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YatakDolulukOranlari.KurumlariGetirResponse.displayName = 'proto.YatakDolulukOranlari.KurumlariGetirResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto.displayName = 'proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest.displayName = 'proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.repeatedFields_, null);
};
goog.inherits(proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.displayName = 'proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.displayName = 'proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    donemtanimlariList: jspb.Message.toObjectList(msg.getDonemtanimlariList(),
    proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse}
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse;
  return proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse}
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto;
      reader.readMessage(value,proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto.deserializeBinaryFromReader);
      msg.addDonemtanimlari(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDonemtanimlariList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto.prototype.toObject = function(opt_includeInstance) {
  return proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    donemadi: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto}
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto;
  return proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto}
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDonemadi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDonemadi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 Id = 1;
 * @return {number}
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto} returns this
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string DonemAdi = 2;
 * @return {string}
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto.prototype.getDonemadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto} returns this
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto.prototype.setDonemadi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated DonemTanimDto DonemTanimlari = 1;
 * @return {!Array<!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto>}
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.prototype.getDonemtanimlariList = function() {
  return /** @type{!Array<!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto, 1));
};


/**
 * @param {!Array<!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto>} value
 * @return {!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse} returns this
*/
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.prototype.setDonemtanimlariList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto}
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.prototype.addDonemtanimlari = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.DonemTanimDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse} returns this
 */
proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.prototype.clearDonemtanimlariList = function() {
  return this.setDonemtanimlariList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.YatakDolulukOranlari.KurumlariGetirResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YatakDolulukOranlari.KurumlariGetirResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    kurumlarList: jspb.Message.toObjectList(msg.getKurumlarList(),
    proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YatakDolulukOranlari.KurumlariGetirResponse}
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YatakDolulukOranlari.KurumlariGetirResponse;
  return proto.YatakDolulukOranlari.KurumlariGetirResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YatakDolulukOranlari.KurumlariGetirResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YatakDolulukOranlari.KurumlariGetirResponse}
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto;
      reader.readMessage(value,proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto.deserializeBinaryFromReader);
      msg.addKurumlar(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YatakDolulukOranlari.KurumlariGetirResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YatakDolulukOranlari.KurumlariGetirResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKurumlarList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto.prototype.toObject = function(opt_includeInstance) {
  return proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    kurumadi: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto}
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto;
  return proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto}
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKurumadi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getKurumadi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 Id = 1;
 * @return {number}
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto} returns this
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string KurumAdi = 2;
 * @return {string}
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto.prototype.getKurumadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto} returns this
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto.prototype.setKurumadi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated KurumDto Kurumlar = 1;
 * @return {!Array<!proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto>}
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.prototype.getKurumlarList = function() {
  return /** @type{!Array<!proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto, 1));
};


/**
 * @param {!Array<!proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto>} value
 * @return {!proto.YatakDolulukOranlari.KurumlariGetirResponse} returns this
*/
proto.YatakDolulukOranlari.KurumlariGetirResponse.prototype.setKurumlarList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto}
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.prototype.addKurumlar = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.YatakDolulukOranlari.KurumlariGetirResponse.KurumDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.YatakDolulukOranlari.KurumlariGetirResponse} returns this
 */
proto.YatakDolulukOranlari.KurumlariGetirResponse.prototype.clearKurumlarList = function() {
  return this.setKurumlarList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    donemid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    kurumid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest;
  return proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDonemid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDonemid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 DonemId = 1;
 * @return {number}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest.prototype.getDonemid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest} returns this
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest.prototype.setDonemid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 KurumId = 2;
 * @return {number}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest} returns this
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    yatakdolulukoranlariList: jspb.Message.toObjectList(msg.getYatakdolulukoranlariList(),
    proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse;
  return proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto;
      reader.readMessage(value,proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.deserializeBinaryFromReader);
      msg.addYatakdolulukoranlari(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYatakdolulukoranlariList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.prototype.toObject = function(opt_includeInstance) {
  return proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    hastane: jspb.Message.getFieldWithDefault(msg, 1, ""),
    servis: jspb.Message.getFieldWithDefault(msg, 2, ""),
    yataktipi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    kadrodolulukorani: jspb.Message.getFieldWithDefault(msg, 4, ""),
    bakimdahilsure: jspb.Message.getFieldWithDefault(msg, 5, ""),
    kadroyataksayisinagoremaksimumsure: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto;
  return proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setServis(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setYataktipi(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKadrodolulukorani(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBakimdahilsure(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setKadroyataksayisinagoremaksimumsure(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getServis();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getYataktipi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKadrodolulukorani();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBakimdahilsure();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getKadroyataksayisinagoremaksimumsure();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string Hastane = 1;
 * @return {string}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto} returns this
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Servis = 2;
 * @return {string}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.prototype.getServis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto} returns this
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.prototype.setServis = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string YatakTipi = 3;
 * @return {string}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.prototype.getYataktipi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto} returns this
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.prototype.setYataktipi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string KadroDolulukOrani = 4;
 * @return {string}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.prototype.getKadrodolulukorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto} returns this
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.prototype.setKadrodolulukorani = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string BakimDahilSure = 5;
 * @return {string}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.prototype.getBakimdahilsure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto} returns this
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.prototype.setBakimdahilsure = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string KadroYatakSayisinaGoreMaksimumSure = 6;
 * @return {string}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.prototype.getKadroyataksayisinagoremaksimumsure = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto} returns this
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto.prototype.setKadroyataksayisinagoremaksimumsure = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated YatakDolulukOranlariDto YatakDolulukOranlari = 1;
 * @return {!Array<!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto>}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.prototype.getYatakdolulukoranlariList = function() {
  return /** @type{!Array<!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto, 1));
};


/**
 * @param {!Array<!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto>} value
 * @return {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse} returns this
*/
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.prototype.setYatakdolulukoranlariList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto}
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.prototype.addYatakdolulukoranlari = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.YatakDolulukOranlariDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse} returns this
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.prototype.clearYatakdolulukoranlariList = function() {
  return this.setYatakdolulukoranlariList([]);
};


goog.object.extend(exports, proto.YatakDolulukOranlari);
