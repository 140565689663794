// source: surec_tanimlari.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.SurecTanimlari.GetSurecAgaciRequest', null, global);
goog.exportSymbol('proto.SurecTanimlari.GetSurecAgaciResponse', null, global);
goog.exportSymbol('proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto', null, global);
goog.exportSymbol('proto.SurecTanimlari.SurecTanimEkleRequest', null, global);
goog.exportSymbol('proto.SurecTanimlari.SurecTanimEkleResponse', null, global);
goog.exportSymbol('proto.SurecTanimlari.SurecTanimGetirRequest', null, global);
goog.exportSymbol('proto.SurecTanimlari.SurecTanimGetirResponse', null, global);
goog.exportSymbol('proto.SurecTanimlari.SurecTanimGuncelleRequest', null, global);
goog.exportSymbol('proto.SurecTanimlari.SurecTanimGuncelleResponse', null, global);
goog.exportSymbol('proto.SurecTanimlari.UpdateSurecAgaciMenuRequest', null, global);
goog.exportSymbol('proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto', null, global);
goog.exportSymbol('proto.SurecTanimlari.UpdateSurecAgaciMenuResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SurecTanimlari.SurecTanimEkleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SurecTanimlari.SurecTanimEkleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SurecTanimlari.SurecTanimEkleResponse.displayName = 'proto.SurecTanimlari.SurecTanimEkleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SurecTanimlari.SurecTanimEkleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SurecTanimlari.SurecTanimEkleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SurecTanimlari.SurecTanimEkleRequest.displayName = 'proto.SurecTanimlari.SurecTanimEkleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SurecTanimlari.GetSurecAgaciRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SurecTanimlari.GetSurecAgaciRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SurecTanimlari.GetSurecAgaciRequest.displayName = 'proto.SurecTanimlari.GetSurecAgaciRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SurecTanimlari.SurecTanimGetirRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SurecTanimlari.SurecTanimGetirRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SurecTanimlari.SurecTanimGetirRequest.displayName = 'proto.SurecTanimlari.SurecTanimGetirRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SurecTanimlari.SurecTanimGetirResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SurecTanimlari.SurecTanimGetirResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SurecTanimlari.SurecTanimGetirResponse.displayName = 'proto.SurecTanimlari.SurecTanimGetirResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SurecTanimlari.GetSurecAgaciResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SurecTanimlari.GetSurecAgaciResponse.repeatedFields_, null);
};
goog.inherits(proto.SurecTanimlari.GetSurecAgaciResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SurecTanimlari.GetSurecAgaciResponse.displayName = 'proto.SurecTanimlari.GetSurecAgaciResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.repeatedFields_, null);
};
goog.inherits(proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.displayName = 'proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SurecTanimlari.SurecTanimGuncelleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SurecTanimlari.SurecTanimGuncelleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SurecTanimlari.SurecTanimGuncelleRequest.displayName = 'proto.SurecTanimlari.SurecTanimGuncelleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SurecTanimlari.SurecTanimGuncelleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SurecTanimlari.SurecTanimGuncelleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SurecTanimlari.SurecTanimGuncelleResponse.displayName = 'proto.SurecTanimlari.SurecTanimGuncelleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.repeatedFields_, null);
};
goog.inherits(proto.SurecTanimlari.UpdateSurecAgaciMenuRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.displayName = 'proto.SurecTanimlari.UpdateSurecAgaciMenuRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.displayName = 'proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SurecTanimlari.UpdateSurecAgaciMenuResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SurecTanimlari.UpdateSurecAgaciMenuResponse.displayName = 'proto.SurecTanimlari.UpdateSurecAgaciMenuResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SurecTanimlari.SurecTanimEkleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SurecTanimlari.SurecTanimEkleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SurecTanimlari.SurecTanimEkleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.SurecTanimEkleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SurecTanimlari.SurecTanimEkleResponse}
 */
proto.SurecTanimlari.SurecTanimEkleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SurecTanimlari.SurecTanimEkleResponse;
  return proto.SurecTanimlari.SurecTanimEkleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SurecTanimlari.SurecTanimEkleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SurecTanimlari.SurecTanimEkleResponse}
 */
proto.SurecTanimlari.SurecTanimEkleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SurecTanimlari.SurecTanimEkleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SurecTanimlari.SurecTanimEkleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SurecTanimlari.SurecTanimEkleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.SurecTanimEkleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 Status = 1;
 * @return {number}
 */
proto.SurecTanimlari.SurecTanimEkleResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SurecTanimlari.SurecTanimEkleResponse} returns this
 */
proto.SurecTanimlari.SurecTanimEkleResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SurecTanimlari.SurecTanimEkleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SurecTanimlari.SurecTanimEkleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SurecTanimlari.SurecTanimEkleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.SurecTanimEkleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    kurumid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    adi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tanimi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    usttanimid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    kayitdurumu: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SurecTanimlari.SurecTanimEkleRequest}
 */
proto.SurecTanimlari.SurecTanimEkleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SurecTanimlari.SurecTanimEkleRequest;
  return proto.SurecTanimlari.SurecTanimEkleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SurecTanimlari.SurecTanimEkleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SurecTanimlari.SurecTanimEkleRequest}
 */
proto.SurecTanimlari.SurecTanimEkleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTanimi(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsttanimid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKayitdurumu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SurecTanimlari.SurecTanimEkleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SurecTanimlari.SurecTanimEkleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SurecTanimlari.SurecTanimEkleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.SurecTanimEkleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTanimi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsttanimid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getKayitdurumu();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 KurumID = 1;
 * @return {number}
 */
proto.SurecTanimlari.SurecTanimEkleRequest.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SurecTanimlari.SurecTanimEkleRequest} returns this
 */
proto.SurecTanimlari.SurecTanimEkleRequest.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Adi = 2;
 * @return {string}
 */
proto.SurecTanimlari.SurecTanimEkleRequest.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SurecTanimlari.SurecTanimEkleRequest} returns this
 */
proto.SurecTanimlari.SurecTanimEkleRequest.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Tanimi = 3;
 * @return {string}
 */
proto.SurecTanimlari.SurecTanimEkleRequest.prototype.getTanimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.SurecTanimlari.SurecTanimEkleRequest} returns this
 */
proto.SurecTanimlari.SurecTanimEkleRequest.prototype.setTanimi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 UstTanimId = 4;
 * @return {number}
 */
proto.SurecTanimlari.SurecTanimEkleRequest.prototype.getUsttanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.SurecTanimlari.SurecTanimEkleRequest} returns this
 */
proto.SurecTanimlari.SurecTanimEkleRequest.prototype.setUsttanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 KayitDurumu = 5;
 * @return {number}
 */
proto.SurecTanimlari.SurecTanimEkleRequest.prototype.getKayitdurumu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.SurecTanimlari.SurecTanimEkleRequest} returns this
 */
proto.SurecTanimlari.SurecTanimEkleRequest.prototype.setKayitdurumu = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SurecTanimlari.GetSurecAgaciRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SurecTanimlari.GetSurecAgaciRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SurecTanimlari.GetSurecAgaciRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.GetSurecAgaciRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    personelid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SurecTanimlari.GetSurecAgaciRequest}
 */
proto.SurecTanimlari.GetSurecAgaciRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SurecTanimlari.GetSurecAgaciRequest;
  return proto.SurecTanimlari.GetSurecAgaciRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SurecTanimlari.GetSurecAgaciRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SurecTanimlari.GetSurecAgaciRequest}
 */
proto.SurecTanimlari.GetSurecAgaciRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPersonelid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SurecTanimlari.GetSurecAgaciRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SurecTanimlari.GetSurecAgaciRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SurecTanimlari.GetSurecAgaciRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.GetSurecAgaciRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonelid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 PersonelId = 1;
 * @return {number}
 */
proto.SurecTanimlari.GetSurecAgaciRequest.prototype.getPersonelid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SurecTanimlari.GetSurecAgaciRequest} returns this
 */
proto.SurecTanimlari.GetSurecAgaciRequest.prototype.setPersonelid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SurecTanimlari.SurecTanimGetirRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SurecTanimlari.SurecTanimGetirRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SurecTanimlari.SurecTanimGetirRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.SurecTanimGetirRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SurecTanimlari.SurecTanimGetirRequest}
 */
proto.SurecTanimlari.SurecTanimGetirRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SurecTanimlari.SurecTanimGetirRequest;
  return proto.SurecTanimlari.SurecTanimGetirRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SurecTanimlari.SurecTanimGetirRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SurecTanimlari.SurecTanimGetirRequest}
 */
proto.SurecTanimlari.SurecTanimGetirRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SurecTanimlari.SurecTanimGetirRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SurecTanimlari.SurecTanimGetirRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SurecTanimlari.SurecTanimGetirRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.SurecTanimGetirRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 ID = 1;
 * @return {number}
 */
proto.SurecTanimlari.SurecTanimGetirRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SurecTanimlari.SurecTanimGetirRequest} returns this
 */
proto.SurecTanimlari.SurecTanimGetirRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SurecTanimlari.SurecTanimGetirResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SurecTanimlari.SurecTanimGetirResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SurecTanimlari.SurecTanimGetirResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.SurecTanimGetirResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    kurumid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    adi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tanimi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    usttanimid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    kayitdurumu: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SurecTanimlari.SurecTanimGetirResponse}
 */
proto.SurecTanimlari.SurecTanimGetirResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SurecTanimlari.SurecTanimGetirResponse;
  return proto.SurecTanimlari.SurecTanimGetirResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SurecTanimlari.SurecTanimGetirResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SurecTanimlari.SurecTanimGetirResponse}
 */
proto.SurecTanimlari.SurecTanimGetirResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTanimi(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsttanimid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKayitdurumu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SurecTanimlari.SurecTanimGetirResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SurecTanimlari.SurecTanimGetirResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SurecTanimlari.SurecTanimGetirResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.SurecTanimGetirResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTanimi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsttanimid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getKayitdurumu();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 KurumID = 1;
 * @return {number}
 */
proto.SurecTanimlari.SurecTanimGetirResponse.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SurecTanimlari.SurecTanimGetirResponse} returns this
 */
proto.SurecTanimlari.SurecTanimGetirResponse.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Adi = 2;
 * @return {string}
 */
proto.SurecTanimlari.SurecTanimGetirResponse.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SurecTanimlari.SurecTanimGetirResponse} returns this
 */
proto.SurecTanimlari.SurecTanimGetirResponse.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Tanimi = 3;
 * @return {string}
 */
proto.SurecTanimlari.SurecTanimGetirResponse.prototype.getTanimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.SurecTanimlari.SurecTanimGetirResponse} returns this
 */
proto.SurecTanimlari.SurecTanimGetirResponse.prototype.setTanimi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 UstTanimId = 4;
 * @return {number}
 */
proto.SurecTanimlari.SurecTanimGetirResponse.prototype.getUsttanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.SurecTanimlari.SurecTanimGetirResponse} returns this
 */
proto.SurecTanimlari.SurecTanimGetirResponse.prototype.setUsttanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 KayitDurumu = 5;
 * @return {number}
 */
proto.SurecTanimlari.SurecTanimGetirResponse.prototype.getKayitdurumu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.SurecTanimlari.SurecTanimGetirResponse} returns this
 */
proto.SurecTanimlari.SurecTanimGetirResponse.prototype.setKayitdurumu = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SurecTanimlari.GetSurecAgaciResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SurecTanimlari.GetSurecAgaciResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SurecTanimlari.GetSurecAgaciResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SurecTanimlari.GetSurecAgaciResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.GetSurecAgaciResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    surecagaciList: jspb.Message.toObjectList(msg.getSurecagaciList(),
    proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SurecTanimlari.GetSurecAgaciResponse}
 */
proto.SurecTanimlari.GetSurecAgaciResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SurecTanimlari.GetSurecAgaciResponse;
  return proto.SurecTanimlari.GetSurecAgaciResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SurecTanimlari.GetSurecAgaciResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SurecTanimlari.GetSurecAgaciResponse}
 */
proto.SurecTanimlari.GetSurecAgaciResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto;
      reader.readMessage(value,proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.deserializeBinaryFromReader);
      msg.addSurecagaci(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SurecTanimlari.GetSurecAgaciResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SurecTanimlari.GetSurecAgaciResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SurecTanimlari.GetSurecAgaciResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.GetSurecAgaciResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSurecagaciList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.prototype.toObject = function(opt_includeInstance) {
  return proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    text: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tipi: jspb.Message.getFieldWithDefault(msg, 4, 0),
    icon: jspb.Message.getFieldWithDefault(msg, 5, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto}
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto;
  return proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto}
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTipi(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 6:
      var value = new proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto;
      reader.readMessage(value,proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTipi();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto} returns this
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto} returns this
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string text = 3;
 * @return {string}
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto} returns this
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 tipi = 4;
 * @return {number}
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.prototype.getTipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto} returns this
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.prototype.setTipi = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string icon = 5;
 * @return {string}
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto} returns this
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated GetSurecAgaciDto items = 6;
 * @return {!Array<!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto>}
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto, 6));
};


/**
 * @param {!Array<!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto>} value
 * @return {!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto} returns this
*/
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto}
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto} returns this
 */
proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * repeated GetSurecAgaciDto surecAgaci = 1;
 * @return {!Array<!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto>}
 */
proto.SurecTanimlari.GetSurecAgaciResponse.prototype.getSurecagaciList = function() {
  return /** @type{!Array<!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto, 1));
};


/**
 * @param {!Array<!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto>} value
 * @return {!proto.SurecTanimlari.GetSurecAgaciResponse} returns this
*/
proto.SurecTanimlari.GetSurecAgaciResponse.prototype.setSurecagaciList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto}
 */
proto.SurecTanimlari.GetSurecAgaciResponse.prototype.addSurecagaci = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SurecTanimlari.GetSurecAgaciResponse.GetSurecAgaciDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SurecTanimlari.GetSurecAgaciResponse} returns this
 */
proto.SurecTanimlari.GetSurecAgaciResponse.prototype.clearSurecagaciList = function() {
  return this.setSurecagaciList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SurecTanimlari.SurecTanimGuncelleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SurecTanimlari.SurecTanimGuncelleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SurecTanimlari.SurecTanimGuncelleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.SurecTanimGuncelleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    adi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tanimi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    usttanimid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    kayitdurumu: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SurecTanimlari.SurecTanimGuncelleRequest}
 */
proto.SurecTanimlari.SurecTanimGuncelleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SurecTanimlari.SurecTanimGuncelleRequest;
  return proto.SurecTanimlari.SurecTanimGuncelleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SurecTanimlari.SurecTanimGuncelleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SurecTanimlari.SurecTanimGuncelleRequest}
 */
proto.SurecTanimlari.SurecTanimGuncelleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTanimi(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsttanimid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKayitdurumu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SurecTanimlari.SurecTanimGuncelleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SurecTanimlari.SurecTanimGuncelleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SurecTanimlari.SurecTanimGuncelleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.SurecTanimGuncelleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTanimi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsttanimid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getKayitdurumu();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 Id = 1;
 * @return {number}
 */
proto.SurecTanimlari.SurecTanimGuncelleRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SurecTanimlari.SurecTanimGuncelleRequest} returns this
 */
proto.SurecTanimlari.SurecTanimGuncelleRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Adi = 2;
 * @return {string}
 */
proto.SurecTanimlari.SurecTanimGuncelleRequest.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SurecTanimlari.SurecTanimGuncelleRequest} returns this
 */
proto.SurecTanimlari.SurecTanimGuncelleRequest.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Tanimi = 3;
 * @return {string}
 */
proto.SurecTanimlari.SurecTanimGuncelleRequest.prototype.getTanimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.SurecTanimlari.SurecTanimGuncelleRequest} returns this
 */
proto.SurecTanimlari.SurecTanimGuncelleRequest.prototype.setTanimi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 UstTanimId = 4;
 * @return {number}
 */
proto.SurecTanimlari.SurecTanimGuncelleRequest.prototype.getUsttanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.SurecTanimlari.SurecTanimGuncelleRequest} returns this
 */
proto.SurecTanimlari.SurecTanimGuncelleRequest.prototype.setUsttanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 KayitDurumu = 5;
 * @return {number}
 */
proto.SurecTanimlari.SurecTanimGuncelleRequest.prototype.getKayitdurumu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.SurecTanimlari.SurecTanimGuncelleRequest} returns this
 */
proto.SurecTanimlari.SurecTanimGuncelleRequest.prototype.setKayitdurumu = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SurecTanimlari.SurecTanimGuncelleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SurecTanimlari.SurecTanimGuncelleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SurecTanimlari.SurecTanimGuncelleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.SurecTanimGuncelleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SurecTanimlari.SurecTanimGuncelleResponse}
 */
proto.SurecTanimlari.SurecTanimGuncelleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SurecTanimlari.SurecTanimGuncelleResponse;
  return proto.SurecTanimlari.SurecTanimGuncelleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SurecTanimlari.SurecTanimGuncelleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SurecTanimlari.SurecTanimGuncelleResponse}
 */
proto.SurecTanimlari.SurecTanimGuncelleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SurecTanimlari.SurecTanimGuncelleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SurecTanimlari.SurecTanimGuncelleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SurecTanimlari.SurecTanimGuncelleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.SurecTanimGuncelleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 Status = 1;
 * @return {number}
 */
proto.SurecTanimlari.SurecTanimGuncelleResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SurecTanimlari.SurecTanimGuncelleResponse} returns this
 */
proto.SurecTanimlari.SurecTanimGuncelleResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    updatesurecagacimenurequestdtoList: jspb.Message.toObjectList(msg.getUpdatesurecagacimenurequestdtoList(),
    proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SurecTanimlari.UpdateSurecAgaciMenuRequest;
  return proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto;
      reader.readMessage(value,proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.deserializeBinaryFromReader);
      msg.addUpdatesurecagacimenurequestdto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUpdatesurecagacimenurequestdtoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.prototype.toObject = function(opt_includeInstance) {
  return proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    adi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 3, ""),
    key: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pid: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto;
  return proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKey(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKey();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 Id = 1;
 * @return {number}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto} returns this
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Adi = 2;
 * @return {string}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto} returns this
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Icon = 3;
 * @return {string}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto} returns this
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 Key = 4;
 * @return {number}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.prototype.getKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto} returns this
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.prototype.setKey = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 Pid = 5;
 * @return {number}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.prototype.getPid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto} returns this
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto.prototype.setPid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated UpdateSurecAgaciMenuRequestDto UpdateSurecAgaciMenuRequestdto = 1;
 * @return {!Array<!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto>}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.prototype.getUpdatesurecagacimenurequestdtoList = function() {
  return /** @type{!Array<!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto, 1));
};


/**
 * @param {!Array<!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto>} value
 * @return {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest} returns this
*/
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.prototype.setUpdatesurecagacimenurequestdtoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.prototype.addUpdatesurecagacimenurequestdto = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.UpdateSurecAgaciMenuRequestDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest} returns this
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuRequest.prototype.clearUpdatesurecagacimenurequestdtoList = function() {
  return this.setUpdatesurecagacimenurequestdtoList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SurecTanimlari.UpdateSurecAgaciMenuResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SurecTanimlari.UpdateSurecAgaciMenuResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pb_return: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SurecTanimlari.UpdateSurecAgaciMenuResponse}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SurecTanimlari.UpdateSurecAgaciMenuResponse;
  return proto.SurecTanimlari.UpdateSurecAgaciMenuResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SurecTanimlari.UpdateSurecAgaciMenuResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SurecTanimlari.UpdateSurecAgaciMenuResponse}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReturn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SurecTanimlari.UpdateSurecAgaciMenuResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SurecTanimlari.UpdateSurecAgaciMenuResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReturn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string return = 1;
 * @return {string}
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuResponse.prototype.getReturn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SurecTanimlari.UpdateSurecAgaciMenuResponse} returns this
 */
proto.SurecTanimlari.UpdateSurecAgaciMenuResponse.prototype.setReturn = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.SurecTanimlari);
