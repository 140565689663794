/* eslint-disable */
//@ts-nocheck

/**
 * @fileoverview gRPC-Web generated client stub for GCFortheUI
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.GCFortheUI = require('./gc_for_the_ui_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.GCFortheUI.GCFortheUIClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.GCFortheUI.GCFortheUIPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.GCFortheUI.GetHospitalListResponseFTUI>}
 */
const methodDescriptor_GCFortheUI_getHospitalListFTUI = new grpc.web.MethodDescriptor(
  '/GCFortheUI.GCFortheUI/getHospitalListFTUI',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.GCFortheUI.GetHospitalListResponseFTUI,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GCFortheUI.GetHospitalListResponseFTUI.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.GCFortheUI.GetHospitalListResponseFTUI>}
 */
const methodInfo_GCFortheUI_getHospitalListFTUI = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GCFortheUI.GetHospitalListResponseFTUI,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GCFortheUI.GetHospitalListResponseFTUI.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GCFortheUI.GetHospitalListResponseFTUI)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GCFortheUI.GetHospitalListResponseFTUI>|undefined}
 *     The XHR Node Readable Stream
 */
proto.GCFortheUI.GCFortheUIClient.prototype.getHospitalListFTUI =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/GCFortheUI.GCFortheUI/getHospitalListFTUI',
      request,
      metadata || {},
      methodDescriptor_GCFortheUI_getHospitalListFTUI,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GCFortheUI.GetHospitalListResponseFTUI>}
 *     Promise that resolves to the response
 */
proto.GCFortheUI.GCFortheUIPromiseClient.prototype.getHospitalListFTUI =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/GCFortheUI.GCFortheUI/getHospitalListFTUI',
      request,
      metadata || {},
      methodDescriptor_GCFortheUI_getHospitalListFTUI);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.GCFortheUI.GetDonemTanimlariResponse>}
 */
const methodDescriptor_GCFortheUI_GetDonemTanimlari = new grpc.web.MethodDescriptor(
  '/GCFortheUI.GCFortheUI/GetDonemTanimlari',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.GCFortheUI.GetDonemTanimlariResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GCFortheUI.GetDonemTanimlariResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.GCFortheUI.GetDonemTanimlariResponse>}
 */
const methodInfo_GCFortheUI_GetDonemTanimlari = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GCFortheUI.GetDonemTanimlariResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GCFortheUI.GetDonemTanimlariResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GCFortheUI.GetDonemTanimlariResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GCFortheUI.GetDonemTanimlariResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.GCFortheUI.GCFortheUIClient.prototype.getDonemTanimlari =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/GCFortheUI.GCFortheUI/GetDonemTanimlari',
      request,
      metadata || {},
      methodDescriptor_GCFortheUI_GetDonemTanimlari,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GCFortheUI.GetDonemTanimlariResponse>}
 *     Promise that resolves to the response
 */
proto.GCFortheUI.GCFortheUIPromiseClient.prototype.getDonemTanimlari =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/GCFortheUI.GCFortheUI/GetDonemTanimlari',
      request,
      metadata || {},
      methodDescriptor_GCFortheUI_GetDonemTanimlari);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.GCFortheUI.GetMerkeziBolumlerResponse>}
 */
const methodDescriptor_GCFortheUI_GetMerkeziBolumler = new grpc.web.MethodDescriptor(
  '/GCFortheUI.GCFortheUI/GetMerkeziBolumler',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.GCFortheUI.GetMerkeziBolumlerResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GCFortheUI.GetMerkeziBolumlerResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.GCFortheUI.GetMerkeziBolumlerResponse>}
 */
const methodInfo_GCFortheUI_GetMerkeziBolumler = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GCFortheUI.GetMerkeziBolumlerResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GCFortheUI.GetMerkeziBolumlerResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GCFortheUI.GetMerkeziBolumlerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GCFortheUI.GetMerkeziBolumlerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.GCFortheUI.GCFortheUIClient.prototype.getMerkeziBolumler =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/GCFortheUI.GCFortheUI/GetMerkeziBolumler',
      request,
      metadata || {},
      methodDescriptor_GCFortheUI_GetMerkeziBolumler,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GCFortheUI.GetMerkeziBolumlerResponse>}
 *     Promise that resolves to the response
 */
proto.GCFortheUI.GCFortheUIPromiseClient.prototype.getMerkeziBolumler =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/GCFortheUI.GCFortheUI/GetMerkeziBolumler',
      request,
      metadata || {},
      methodDescriptor_GCFortheUI_GetMerkeziBolumler);
};


module.exports = proto.GCFortheUI;

