import { GunlukCiraRaporuClient } from "../../../protos/is-zekasi/yonetim-raporlari/gunluk_cira_raporu_grpc_web_pb";
import {
  GetGunlukCiraRaporuByHospitalsRequest,
  GetGunlukCiroRaporuByGroupRequest,
  GetGunlukCiraRaporuByGelisTuruDetayRequest,
  GetGunlukCiroRaporuByHizmetDetayiRequest,
  GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest,
  GetGunlukCiroRaporuByOdeyiciDetayiRequest,
  GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest
} from "../../../protos/is-zekasi/yonetim-raporlari/gunluk_cira_raporu_pb";

class GunlukCiraRaporuService {

  getGunlukCiraRaporuByHospital = (hospitalID, dateVal) => {
    return new Promise((resolve, reject) => {

      const client = new GunlukCiraRaporuClient(process.env.REACT_APP_API_BASE_URL);
      const getGunlukCiraRaporuByHospitalsRequest = new GetGunlukCiraRaporuByHospitalsRequest();

      getGunlukCiraRaporuByHospitalsRequest.setHospitalid(hospitalID);
      getGunlukCiraRaporuByHospitalsRequest.setTarih(dateVal + '');

      var token = localStorage.getItem('accessToken');
      client.getGunlukCiraRaporuByHospitals(getGunlukCiraRaporuByHospitalsRequest,
        { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
          if (response) {
            //console.log('Response->x1->', response);
            resolve(response.toObject());
          } else {
            //console.log('error->x1->', error);
            reject(error);
          }
        });

    });
  }

  getGunlukCiroRaporuByGroup = (date) => {
    return new Promise((resolve, reject) => {

      const client = new GunlukCiraRaporuClient(process.env.REACT_APP_API_BASE_URL);
      const getGunlukCiroRaporuByGroupRequest = new GetGunlukCiroRaporuByGroupRequest();

      getGunlukCiroRaporuByGroupRequest.setTarih(date);

      var token = localStorage.getItem('accessToken');
      client.getGunlukCiroRaporuByGroup(getGunlukCiroRaporuByGroupRequest,
        { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
          if (response) {
            resolve(response.toObject());
          } else {
            reject(error);
          }
        });

    });
  }

  getGunlukCiraRaporuByGelisTuruDetay = (hospitalID, dateVal) => {
    return new Promise((resolve, reject) => {

      const client = new GunlukCiraRaporuClient(process.env.REACT_APP_API_BASE_URL);
      const getGunlukCiraRaporuByGelisTuruDetayRequest = new GetGunlukCiraRaporuByGelisTuruDetayRequest();

      getGunlukCiraRaporuByGelisTuruDetayRequest.setHospitalid(hospitalID);
      getGunlukCiraRaporuByGelisTuruDetayRequest.setTarih(dateVal + '');

      var token = localStorage.getItem('accessToken');
      client.getGunlukCiraRaporuByGelisTuruDetay(getGunlukCiraRaporuByGelisTuruDetayRequest,
        { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
          if (response) {
            //console.log('Response->x1->', response);

            resolve(response.toObject());
          } else {
            //console.log('error->x1->', error);
            reject(error);
          }
        });

    });
  }

  getGunlukCiroRaporuByHizmetDetayi = (kurumId, tarih) => {
    return new Promise((resolve, reject) => {

      const client = new GunlukCiraRaporuClient(process.env.REACT_APP_API_BASE_URL);
      const getGunlukCiroRaporuByHizmetDetayiRequest = new GetGunlukCiroRaporuByHizmetDetayiRequest();

      getGunlukCiroRaporuByHizmetDetayiRequest.setKurumid(kurumId);
      getGunlukCiroRaporuByHizmetDetayiRequest.setTarih(tarih);

      var token = localStorage.getItem('accessToken');
      client.getGunlukCiroRaporuByHizmetDetayi(getGunlukCiroRaporuByHizmetDetayiRequest,
        { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
          if (response) {
            resolve(response.toObject());
          } else {
            reject(error);
          }
        });
    });
  }
  
  getGunlukCiroRaporuButceTakibiEndeksHizmetCirosu = (hospitalID, dateVal, wQuery) => {
    return new Promise((resolve, reject) => {

      const client = new GunlukCiraRaporuClient(process.env.REACT_APP_API_BASE_URL);
      const getGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest = new GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest();

      getGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.setHospitalid(hospitalID);
      getGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.setTarih(dateVal + '');
      getGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.setWquery(wQuery);

      var token = localStorage.getItem('accessToken');
      client.getGunlukCiroRaporuButceTakibiEndeksHizmetCirosu(getGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest,
        { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
          if (response) {
            //console.log('getGunlukCiroRaporuButceTakibiEndeksHizmetCirosu success->x1->', response.toObject());
            resolve(response.toObject());
          } else {
            //console.log(' getGunlukCiroRaporuButceTakibiEndeksHizmetCirosu error->x1->', error);
            reject(error);
          }
        });

    });
  }

  getGunlukCiroRaporuByOdeyiciDetayi = (odeyiciKurumTipi, tarih) => {
    return new Promise((resolve, reject) => {

      const client = new GunlukCiraRaporuClient(process.env.REACT_APP_API_BASE_URL);
      const getGunlukCiroRaporuByOdeyiciDetayiRequest = new GetGunlukCiroRaporuByOdeyiciDetayiRequest();

      getGunlukCiroRaporuByOdeyiciDetayiRequest.setOdeyicikurumtipi(odeyiciKurumTipi);
      getGunlukCiroRaporuByOdeyiciDetayiRequest.setTarih(tarih);

      var token = localStorage.getItem('accessToken');
      client.getGunlukCiroRaporuByOdeyiciDetayi(getGunlukCiroRaporuByOdeyiciDetayiRequest,
        { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
          if (response) {
            resolve(response.toObject());
          } else {
            reject(error);
          }
        });
    });
  }

  getGunlukCiroRaporuByOdeyiciDetayiGrafik = (odeyiciKurumTipi, tarih, kurumId ) => {
    return new Promise((resolve, reject) => {

      const client = new GunlukCiraRaporuClient(process.env.REACT_APP_API_BASE_URL);
      const getGunlukCiroRaporuByOdeyiciDetayiGrafikRequest = new GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest();

      getGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.setOdeyicikurumtipi(odeyiciKurumTipi);
      getGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.setTarih(tarih);
      getGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.setKurumid(kurumId);

      var token = localStorage.getItem('accessToken');
      client.getGunlukCiroRaporuByOdeyiciDetayiGrafik(getGunlukCiroRaporuByOdeyiciDetayiGrafikRequest,
        { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
          if (response) {
            resolve(response.toObject());
          } else {
            reject(error);
          }
        });
    });
  }
}

const gunlukCiraRaporuService = new GunlukCiraRaporuService();

export default gunlukCiraRaporuService;
