/**
 * @fileoverview gRPC-Web generated client stub for BolumButceTakibi
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.BolumButceTakibi = require('./bolum_butce_takibi_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.BolumButceTakibi.BolumButceTakibiClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.BolumButceTakibi.BolumButceTakibiPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest,
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse>}
 */
const methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByGidisat = new grpc.web.MethodDescriptor(
  '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByGidisat',
  grpc.web.MethodType.UNARY,
  proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest,
  proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse,
  /**
   * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest,
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse>}
 */
const methodInfo_BolumButceTakibi_GetBolumButceTakibiByGidisat = new grpc.web.AbstractClientBase.MethodInfo(
  proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse,
  /**
   * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.deserializeBinary
);


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BolumButceTakibi.BolumButceTakibiClient.prototype.getBolumButceTakibiByGidisat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByGidisat',
      request,
      metadata || {},
      methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByGidisat,
      callback);
};


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse>}
 *     Promise that resolves to the response
 */
proto.BolumButceTakibi.BolumButceTakibiPromiseClient.prototype.getBolumButceTakibiByGidisat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByGidisat',
      request,
      metadata || {},
      methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByGidisat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest,
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse>}
 */
const methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByEndeksCiro = new grpc.web.MethodDescriptor(
  '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByEndeksCiro',
  grpc.web.MethodType.UNARY,
  proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest,
  proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse,
  /**
   * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest,
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse>}
 */
const methodInfo_BolumButceTakibi_GetBolumButceTakibiByEndeksCiro = new grpc.web.AbstractClientBase.MethodInfo(
  proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse,
  /**
   * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.deserializeBinary
);


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BolumButceTakibi.BolumButceTakibiClient.prototype.getBolumButceTakibiByEndeksCiro =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByEndeksCiro',
      request,
      metadata || {},
      methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByEndeksCiro,
      callback);
};


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse>}
 *     Promise that resolves to the response
 */
proto.BolumButceTakibi.BolumButceTakibiPromiseClient.prototype.getBolumButceTakibiByEndeksCiro =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByEndeksCiro',
      request,
      metadata || {},
      methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByEndeksCiro);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest,
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse>}
 */
const methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByHizmetDetayi = new grpc.web.MethodDescriptor(
  '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByHizmetDetayi',
  grpc.web.MethodType.UNARY,
  proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest,
  proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse,
  /**
   * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest,
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse>}
 */
const methodInfo_BolumButceTakibi_GetBolumButceTakibiByHizmetDetayi = new grpc.web.AbstractClientBase.MethodInfo(
  proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse,
  /**
   * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.deserializeBinary
);


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BolumButceTakibi.BolumButceTakibiClient.prototype.getBolumButceTakibiByHizmetDetayi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByHizmetDetayi',
      request,
      metadata || {},
      methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByHizmetDetayi,
      callback);
};


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse>}
 *     Promise that resolves to the response
 */
proto.BolumButceTakibi.BolumButceTakibiPromiseClient.prototype.getBolumButceTakibiByHizmetDetayi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByHizmetDetayi',
      request,
      metadata || {},
      methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByHizmetDetayi);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest,
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse>}
 */
const methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByHedefGerceklesmeOranlari = new grpc.web.MethodDescriptor(
  '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByHedefGerceklesmeOranlari',
  grpc.web.MethodType.UNARY,
  proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest,
  proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse,
  /**
   * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest,
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse>}
 */
const methodInfo_BolumButceTakibi_GetBolumButceTakibiByHedefGerceklesmeOranlari = new grpc.web.AbstractClientBase.MethodInfo(
  proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse,
  /**
   * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.deserializeBinary
);


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BolumButceTakibi.BolumButceTakibiClient.prototype.getBolumButceTakibiByHedefGerceklesmeOranlari =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByHedefGerceklesmeOranlari',
      request,
      metadata || {},
      methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByHedefGerceklesmeOranlari,
      callback);
};


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse>}
 *     Promise that resolves to the response
 */
proto.BolumButceTakibi.BolumButceTakibiPromiseClient.prototype.getBolumButceTakibiByHedefGerceklesmeOranlari =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByHedefGerceklesmeOranlari',
      request,
      metadata || {},
      methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByHedefGerceklesmeOranlari);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest,
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse>}
 */
const methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByOdeyiciDetayi = new grpc.web.MethodDescriptor(
  '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByOdeyiciDetayi',
  grpc.web.MethodType.UNARY,
  proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest,
  proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse,
  /**
   * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest,
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse>}
 */
const methodInfo_BolumButceTakibi_GetBolumButceTakibiByOdeyiciDetayi = new grpc.web.AbstractClientBase.MethodInfo(
  proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse,
  /**
   * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.deserializeBinary
);


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BolumButceTakibi.BolumButceTakibiClient.prototype.getBolumButceTakibiByOdeyiciDetayi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByOdeyiciDetayi',
      request,
      metadata || {},
      methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByOdeyiciDetayi,
      callback);
};


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse>}
 *     Promise that resolves to the response
 */
proto.BolumButceTakibi.BolumButceTakibiPromiseClient.prototype.getBolumButceTakibiByOdeyiciDetayi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByOdeyiciDetayi',
      request,
      metadata || {},
      methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByOdeyiciDetayi);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest,
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse>}
 */
const methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByGelisTuruDetay = new grpc.web.MethodDescriptor(
  '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByGelisTuruDetay',
  grpc.web.MethodType.UNARY,
  proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest,
  proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse,
  /**
   * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest,
 *   !proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse>}
 */
const methodInfo_BolumButceTakibi_GetBolumButceTakibiByGelisTuruDetay = new grpc.web.AbstractClientBase.MethodInfo(
  proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse,
  /**
   * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.deserializeBinary
);


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BolumButceTakibi.BolumButceTakibiClient.prototype.getBolumButceTakibiByGelisTuruDetay =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByGelisTuruDetay',
      request,
      metadata || {},
      methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByGelisTuruDetay,
      callback);
};


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse>}
 *     Promise that resolves to the response
 */
proto.BolumButceTakibi.BolumButceTakibiPromiseClient.prototype.getBolumButceTakibiByGelisTuruDetay =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiByGelisTuruDetay',
      request,
      metadata || {},
      methodDescriptor_BolumButceTakibi_GetBolumButceTakibiByGelisTuruDetay);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest,
 *   !proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse>}
 */
const methodDescriptor_BolumButceTakibi_GetBolumButceTakibiCheckup = new grpc.web.MethodDescriptor(
  '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiCheckup',
  grpc.web.MethodType.UNARY,
  proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest,
  proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse,
  /**
   * @param {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest,
 *   !proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse>}
 */
const methodInfo_BolumButceTakibi_GetBolumButceTakibiCheckup = new grpc.web.AbstractClientBase.MethodInfo(
  proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse,
  /**
   * @param {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.deserializeBinary
);


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.BolumButceTakibi.BolumButceTakibiClient.prototype.getBolumButceTakibiCheckup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiCheckup',
      request,
      metadata || {},
      methodDescriptor_BolumButceTakibi_GetBolumButceTakibiCheckup,
      callback);
};


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse>}
 *     Promise that resolves to the response
 */
proto.BolumButceTakibi.BolumButceTakibiPromiseClient.prototype.getBolumButceTakibiCheckup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/BolumButceTakibi.BolumButceTakibi/GetBolumButceTakibiCheckup',
      request,
      metadata || {},
      methodDescriptor_BolumButceTakibi_GetBolumButceTakibiCheckup);
};


module.exports = proto.BolumButceTakibi;

