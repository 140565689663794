import { PermissionClient } from "../protos/permission_grpc_web_pb.js";
import { MenuYetkileriniGetirRequest } from "../protos/permission_pb.js";

class PermissionService {
    menuYetkileriniGetir = (personelId) => {
        return new Promise((resolve, reject) => {
            const client = new PermissionClient(process.env.REACT_APP_API_BASE_URL);
            let menuYetkileriniGetirRequest = new MenuYetkileriniGetirRequest();
            menuYetkileriniGetirRequest.setPersonelid(personelId);
            var token = localStorage.getItem('accessToken');
            client.menuYetkileriniGetir(menuYetkileriniGetirRequest, { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                if (response) {
                    resolve(response.toObject());
                } else {
                    reject(error);
                }
            })
        })
    }
}

const permissionService = new PermissionService();

export default permissionService;
