/* eslint-disable */
//@ts-nocheck

// source: yetki_tanimlari.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.YetkiTanimlari.AddHazirRaporPermissionRequest', null, global);
goog.exportSymbol('proto.YetkiTanimlari.AddHazirRaporPermissionResponse', null, global);
goog.exportSymbol('proto.YetkiTanimlari.AddSurecTanimPermissionRequest', null, global);
goog.exportSymbol('proto.YetkiTanimlari.AddSurecTanimPermissionResponse', null, global);
goog.exportSymbol('proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest', null, global);
goog.exportSymbol('proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse', null, global);
goog.exportSymbol('proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest', null, global);
goog.exportSymbol('proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse', null, global);
goog.exportSymbol('proto.YetkiTanimlari.EditHazirRaporPermissionRequest', null, global);
goog.exportSymbol('proto.YetkiTanimlari.EditHazirRaporPermissionResponse', null, global);
goog.exportSymbol('proto.YetkiTanimlari.EditSurecTanimPermissionRequest', null, global);
goog.exportSymbol('proto.YetkiTanimlari.EditSurecTanimPermissionResponse', null, global);
goog.exportSymbol('proto.YetkiTanimlari.GetAllRolesResponse', null, global);
goog.exportSymbol('proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto', null, global);
goog.exportSymbol('proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest', null, global);
goog.exportSymbol('proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse', null, global);
goog.exportSymbol('proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto', null, global);
goog.exportSymbol('proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest', null, global);
goog.exportSymbol('proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse', null, global);
goog.exportSymbol('proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.GetAllRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.YetkiTanimlari.GetAllRolesResponse.repeatedFields_, null);
};
goog.inherits(proto.YetkiTanimlari.GetAllRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.GetAllRolesResponse.displayName = 'proto.YetkiTanimlari.GetAllRolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.displayName = 'proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest.displayName = 'proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.repeatedFields_, null);
};
goog.inherits(proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.displayName = 'proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.displayName = 'proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.AddSurecTanimPermissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.YetkiTanimlari.AddSurecTanimPermissionRequest.repeatedFields_, null);
};
goog.inherits(proto.YetkiTanimlari.AddSurecTanimPermissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.AddSurecTanimPermissionRequest.displayName = 'proto.YetkiTanimlari.AddSurecTanimPermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.AddSurecTanimPermissionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YetkiTanimlari.AddSurecTanimPermissionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.AddSurecTanimPermissionResponse.displayName = 'proto.YetkiTanimlari.AddSurecTanimPermissionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.EditSurecTanimPermissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YetkiTanimlari.EditSurecTanimPermissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.EditSurecTanimPermissionRequest.displayName = 'proto.YetkiTanimlari.EditSurecTanimPermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.EditSurecTanimPermissionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YetkiTanimlari.EditSurecTanimPermissionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.EditSurecTanimPermissionResponse.displayName = 'proto.YetkiTanimlari.EditSurecTanimPermissionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest.displayName = 'proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse.displayName = 'proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest.displayName = 'proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.repeatedFields_, null);
};
goog.inherits(proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.displayName = 'proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.displayName = 'proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.AddHazirRaporPermissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.YetkiTanimlari.AddHazirRaporPermissionRequest.repeatedFields_, null);
};
goog.inherits(proto.YetkiTanimlari.AddHazirRaporPermissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.AddHazirRaporPermissionRequest.displayName = 'proto.YetkiTanimlari.AddHazirRaporPermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.AddHazirRaporPermissionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YetkiTanimlari.AddHazirRaporPermissionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.AddHazirRaporPermissionResponse.displayName = 'proto.YetkiTanimlari.AddHazirRaporPermissionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YetkiTanimlari.EditHazirRaporPermissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.EditHazirRaporPermissionRequest.displayName = 'proto.YetkiTanimlari.EditHazirRaporPermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.EditHazirRaporPermissionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YetkiTanimlari.EditHazirRaporPermissionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.EditHazirRaporPermissionResponse.displayName = 'proto.YetkiTanimlari.EditHazirRaporPermissionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest.displayName = 'proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse.displayName = 'proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.YetkiTanimlari.GetAllRolesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.GetAllRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.GetAllRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.GetAllRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.GetAllRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rolerowsList: jspb.Message.toObjectList(msg.getRolerowsList(),
    proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.GetAllRolesResponse}
 */
proto.YetkiTanimlari.GetAllRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.GetAllRolesResponse;
  return proto.YetkiTanimlari.GetAllRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.GetAllRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.GetAllRolesResponse}
 */
proto.YetkiTanimlari.GetAllRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto;
      reader.readMessage(value,proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.deserializeBinaryFromReader);
      msg.addRolerows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.GetAllRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.GetAllRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.GetAllRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.GetAllRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRolerowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    motanimi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tipi: jspb.Message.getFieldWithDefault(msg, 3, 0),
    kurumresmiadi: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto}
 */
proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto;
  return proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto}
 */
proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMotanimi(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTipi(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKurumresmiadi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMotanimi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTipi();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getKurumresmiadi();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto} returns this
 */
proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string MOTanimi = 2;
 * @return {string}
 */
proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.prototype.getMotanimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto} returns this
 */
proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.prototype.setMotanimi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 tipi = 3;
 * @return {number}
 */
proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.prototype.getTipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto} returns this
 */
proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.prototype.setTipi = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string kurumResmiAdi = 4;
 * @return {string}
 */
proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.prototype.getKurumresmiadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto} returns this
 */
proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto.prototype.setKurumresmiadi = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated RoleRowDto roleRows = 1;
 * @return {!Array<!proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto>}
 */
proto.YetkiTanimlari.GetAllRolesResponse.prototype.getRolerowsList = function() {
  return /** @type{!Array<!proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto, 1));
};


/**
 * @param {!Array<!proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto>} value
 * @return {!proto.YetkiTanimlari.GetAllRolesResponse} returns this
*/
proto.YetkiTanimlari.GetAllRolesResponse.prototype.setRolerowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto}
 */
proto.YetkiTanimlari.GetAllRolesResponse.prototype.addRolerows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.YetkiTanimlari.GetAllRolesResponse.RoleRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.YetkiTanimlari.GetAllRolesResponse} returns this
 */
proto.YetkiTanimlari.GetAllRolesResponse.prototype.clearRolerowsList = function() {
  return this.setRolerowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    surectanimid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest;
  return proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSurectanimid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSurectanimid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 surecTanimId = 1;
 * @return {number}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest.prototype.getSurectanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest} returns this
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest.prototype.setSurectanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    surectanimpermissionrowsList: jspb.Message.toObjectList(msg.getSurectanimpermissionrowsList(),
    proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse;
  return proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto;
      reader.readMessage(value,proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.deserializeBinaryFromReader);
      msg.addSurectanimpermissionrows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSurectanimpermissionrowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    motanimi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    yetkivarmi: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    kayitdurumu: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto;
  return proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMotanimi(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setYetkivarmi(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKayitdurumu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMotanimi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getYetkivarmi();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getKayitdurumu();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto} returns this
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string MOTanimi = 2;
 * @return {string}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.prototype.getMotanimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto} returns this
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.prototype.setMotanimi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool yetkiVarMi = 3;
 * @return {boolean}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.prototype.getYetkivarmi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto} returns this
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.prototype.setYetkivarmi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 kayitDurumu = 4;
 * @return {number}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.prototype.getKayitdurumu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto} returns this
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto.prototype.setKayitdurumu = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated SurecTanimPermissionRowDto surecTanimPermissionRows = 1;
 * @return {!Array<!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto>}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.prototype.getSurectanimpermissionrowsList = function() {
  return /** @type{!Array<!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto, 1));
};


/**
 * @param {!Array<!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto>} value
 * @return {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse} returns this
*/
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.prototype.setSurectanimpermissionrowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto}
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.prototype.addSurectanimpermissionrows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.SurecTanimPermissionRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse} returns this
 */
proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.prototype.clearSurectanimpermissionrowsList = function() {
  return this.setSurectanimpermissionrowsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.YetkiTanimlari.AddSurecTanimPermissionRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.AddSurecTanimPermissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.AddSurecTanimPermissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.AddSurecTanimPermissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.AddSurecTanimPermissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    goList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    surectanimid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.AddSurecTanimPermissionRequest}
 */
proto.YetkiTanimlari.AddSurecTanimPermissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.AddSurecTanimPermissionRequest;
  return proto.YetkiTanimlari.AddSurecTanimPermissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.AddSurecTanimPermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.AddSurecTanimPermissionRequest}
 */
proto.YetkiTanimlari.AddSurecTanimPermissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setGoList(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSurectanimid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.AddSurecTanimPermissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.AddSurecTanimPermissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.AddSurecTanimPermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.AddSurecTanimPermissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getGoList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getSurectanimid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 userId = 1;
 * @return {number}
 */
proto.YetkiTanimlari.AddSurecTanimPermissionRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.AddSurecTanimPermissionRequest} returns this
 */
proto.YetkiTanimlari.AddSurecTanimPermissionRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int32 GO = 2;
 * @return {!Array<number>}
 */
proto.YetkiTanimlari.AddSurecTanimPermissionRequest.prototype.getGoList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.YetkiTanimlari.AddSurecTanimPermissionRequest} returns this
 */
proto.YetkiTanimlari.AddSurecTanimPermissionRequest.prototype.setGoList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.YetkiTanimlari.AddSurecTanimPermissionRequest} returns this
 */
proto.YetkiTanimlari.AddSurecTanimPermissionRequest.prototype.addGo = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.YetkiTanimlari.AddSurecTanimPermissionRequest} returns this
 */
proto.YetkiTanimlari.AddSurecTanimPermissionRequest.prototype.clearGoList = function() {
  return this.setGoList([]);
};


/**
 * optional int32 surecTanimId = 3;
 * @return {number}
 */
proto.YetkiTanimlari.AddSurecTanimPermissionRequest.prototype.getSurectanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.AddSurecTanimPermissionRequest} returns this
 */
proto.YetkiTanimlari.AddSurecTanimPermissionRequest.prototype.setSurectanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.AddSurecTanimPermissionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.AddSurecTanimPermissionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.AddSurecTanimPermissionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.AddSurecTanimPermissionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.AddSurecTanimPermissionResponse}
 */
proto.YetkiTanimlari.AddSurecTanimPermissionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.AddSurecTanimPermissionResponse;
  return proto.YetkiTanimlari.AddSurecTanimPermissionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.AddSurecTanimPermissionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.AddSurecTanimPermissionResponse}
 */
proto.YetkiTanimlari.AddSurecTanimPermissionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.AddSurecTanimPermissionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.AddSurecTanimPermissionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.AddSurecTanimPermissionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.AddSurecTanimPermissionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.YetkiTanimlari.AddSurecTanimPermissionResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.YetkiTanimlari.AddSurecTanimPermissionResponse} returns this
 */
proto.YetkiTanimlari.AddSurecTanimPermissionResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.EditSurecTanimPermissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.EditSurecTanimPermissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.EditSurecTanimPermissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.EditSurecTanimPermissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    yetkivarmi: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    kayitdurumu: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.EditSurecTanimPermissionRequest}
 */
proto.YetkiTanimlari.EditSurecTanimPermissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.EditSurecTanimPermissionRequest;
  return proto.YetkiTanimlari.EditSurecTanimPermissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.EditSurecTanimPermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.EditSurecTanimPermissionRequest}
 */
proto.YetkiTanimlari.EditSurecTanimPermissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setYetkivarmi(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKayitdurumu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.EditSurecTanimPermissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.EditSurecTanimPermissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.EditSurecTanimPermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.EditSurecTanimPermissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getYetkivarmi();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getKayitdurumu();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.YetkiTanimlari.EditSurecTanimPermissionRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.EditSurecTanimPermissionRequest} returns this
 */
proto.YetkiTanimlari.EditSurecTanimPermissionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 userId = 2;
 * @return {number}
 */
proto.YetkiTanimlari.EditSurecTanimPermissionRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.EditSurecTanimPermissionRequest} returns this
 */
proto.YetkiTanimlari.EditSurecTanimPermissionRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool yetkiVarMi = 3;
 * @return {boolean}
 */
proto.YetkiTanimlari.EditSurecTanimPermissionRequest.prototype.getYetkivarmi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.YetkiTanimlari.EditSurecTanimPermissionRequest} returns this
 */
proto.YetkiTanimlari.EditSurecTanimPermissionRequest.prototype.setYetkivarmi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional int32 kayitDurumu = 4;
 * @return {number}
 */
proto.YetkiTanimlari.EditSurecTanimPermissionRequest.prototype.getKayitdurumu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.EditSurecTanimPermissionRequest} returns this
 */
proto.YetkiTanimlari.EditSurecTanimPermissionRequest.prototype.setKayitdurumu = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.EditSurecTanimPermissionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.EditSurecTanimPermissionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.EditSurecTanimPermissionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.EditSurecTanimPermissionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.EditSurecTanimPermissionResponse}
 */
proto.YetkiTanimlari.EditSurecTanimPermissionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.EditSurecTanimPermissionResponse;
  return proto.YetkiTanimlari.EditSurecTanimPermissionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.EditSurecTanimPermissionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.EditSurecTanimPermissionResponse}
 */
proto.YetkiTanimlari.EditSurecTanimPermissionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.EditSurecTanimPermissionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.EditSurecTanimPermissionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.EditSurecTanimPermissionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.EditSurecTanimPermissionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.YetkiTanimlari.EditSurecTanimPermissionResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.YetkiTanimlari.EditSurecTanimPermissionResponse} returns this
 */
proto.YetkiTanimlari.EditSurecTanimPermissionResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest}
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest;
  return proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest}
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest} returns this
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse}
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse;
  return proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse}
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse} returns this
 */
proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hazirraportanimid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest;
  return proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHazirraportanimid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHazirraportanimid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 hazirRaporTanimId = 1;
 * @return {number}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest.prototype.getHazirraportanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest} returns this
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest.prototype.setHazirraportanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hazirraportanimpermissionrowsList: jspb.Message.toObjectList(msg.getHazirraportanimpermissionrowsList(),
    proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse;
  return proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto;
      reader.readMessage(value,proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.deserializeBinaryFromReader);
      msg.addHazirraportanimpermissionrows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHazirraportanimpermissionrowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    motanimi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    eklemeyetkisivarmi: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    gormeyetkisivarmi: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    gorevyetkisivarmi: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    indirmeyetkisivarmi: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    kayitdurumu: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto;
  return proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMotanimi(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEklemeyetkisivarmi(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGormeyetkisivarmi(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGorevyetkisivarmi(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIndirmeyetkisivarmi(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKayitdurumu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMotanimi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEklemeyetkisivarmi();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getGormeyetkisivarmi();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getGorevyetkisivarmi();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIndirmeyetkisivarmi();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getKayitdurumu();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto} returns this
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string MOTanimi = 2;
 * @return {string}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.prototype.getMotanimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto} returns this
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.prototype.setMotanimi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool eklemeYetkisiVarMi = 3;
 * @return {boolean}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.prototype.getEklemeyetkisivarmi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto} returns this
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.prototype.setEklemeyetkisivarmi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool gormeYetkisiVarMi = 4;
 * @return {boolean}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.prototype.getGormeyetkisivarmi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto} returns this
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.prototype.setGormeyetkisivarmi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool gorevYetkisiVarMi = 5;
 * @return {boolean}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.prototype.getGorevyetkisivarmi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto} returns this
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.prototype.setGorevyetkisivarmi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool indirmeYetkisiVarMi = 6;
 * @return {boolean}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.prototype.getIndirmeyetkisivarmi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto} returns this
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.prototype.setIndirmeyetkisivarmi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int32 kayitDurumu = 7;
 * @return {number}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.prototype.getKayitdurumu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto} returns this
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto.prototype.setKayitdurumu = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated HazirRaporTanimPermissionRowDto hazirRaporTanimPermissionRows = 1;
 * @return {!Array<!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto>}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.prototype.getHazirraportanimpermissionrowsList = function() {
  return /** @type{!Array<!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto, 1));
};


/**
 * @param {!Array<!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto>} value
 * @return {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse} returns this
*/
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.prototype.setHazirraportanimpermissionrowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto}
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.prototype.addHazirraportanimpermissionrows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.HazirRaporTanimPermissionRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse} returns this
 */
proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.prototype.clearHazirraportanimpermissionrowsList = function() {
  return this.setHazirraportanimpermissionrowsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.YetkiTanimlari.AddHazirRaporPermissionRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.AddHazirRaporPermissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.AddHazirRaporPermissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.AddHazirRaporPermissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.AddHazirRaporPermissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    goList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    hazirraportanimid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.AddHazirRaporPermissionRequest}
 */
proto.YetkiTanimlari.AddHazirRaporPermissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.AddHazirRaporPermissionRequest;
  return proto.YetkiTanimlari.AddHazirRaporPermissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.AddHazirRaporPermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.AddHazirRaporPermissionRequest}
 */
proto.YetkiTanimlari.AddHazirRaporPermissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setGoList(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHazirraportanimid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.AddHazirRaporPermissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.AddHazirRaporPermissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.AddHazirRaporPermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.AddHazirRaporPermissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getGoList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getHazirraportanimid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 userId = 1;
 * @return {number}
 */
proto.YetkiTanimlari.AddHazirRaporPermissionRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.AddHazirRaporPermissionRequest} returns this
 */
proto.YetkiTanimlari.AddHazirRaporPermissionRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int32 GO = 2;
 * @return {!Array<number>}
 */
proto.YetkiTanimlari.AddHazirRaporPermissionRequest.prototype.getGoList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.YetkiTanimlari.AddHazirRaporPermissionRequest} returns this
 */
proto.YetkiTanimlari.AddHazirRaporPermissionRequest.prototype.setGoList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.YetkiTanimlari.AddHazirRaporPermissionRequest} returns this
 */
proto.YetkiTanimlari.AddHazirRaporPermissionRequest.prototype.addGo = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.YetkiTanimlari.AddHazirRaporPermissionRequest} returns this
 */
proto.YetkiTanimlari.AddHazirRaporPermissionRequest.prototype.clearGoList = function() {
  return this.setGoList([]);
};


/**
 * optional int32 hazirRaporTanimId = 3;
 * @return {number}
 */
proto.YetkiTanimlari.AddHazirRaporPermissionRequest.prototype.getHazirraportanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.AddHazirRaporPermissionRequest} returns this
 */
proto.YetkiTanimlari.AddHazirRaporPermissionRequest.prototype.setHazirraportanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.AddHazirRaporPermissionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.AddHazirRaporPermissionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.AddHazirRaporPermissionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.AddHazirRaporPermissionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.AddHazirRaporPermissionResponse}
 */
proto.YetkiTanimlari.AddHazirRaporPermissionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.AddHazirRaporPermissionResponse;
  return proto.YetkiTanimlari.AddHazirRaporPermissionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.AddHazirRaporPermissionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.AddHazirRaporPermissionResponse}
 */
proto.YetkiTanimlari.AddHazirRaporPermissionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.AddHazirRaporPermissionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.AddHazirRaporPermissionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.AddHazirRaporPermissionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.AddHazirRaporPermissionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.YetkiTanimlari.AddHazirRaporPermissionResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.YetkiTanimlari.AddHazirRaporPermissionResponse} returns this
 */
proto.YetkiTanimlari.AddHazirRaporPermissionResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.EditHazirRaporPermissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.EditHazirRaporPermissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    eklemeyetkisivarmi: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    gormeyetkisivarmi: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    gorevyetkisivarmi: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    indirmeyetkisivarmi: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    kayitdurumu: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.EditHazirRaporPermissionRequest}
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.EditHazirRaporPermissionRequest;
  return proto.YetkiTanimlari.EditHazirRaporPermissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.EditHazirRaporPermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.EditHazirRaporPermissionRequest}
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEklemeyetkisivarmi(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGormeyetkisivarmi(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGorevyetkisivarmi(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIndirmeyetkisivarmi(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKayitdurumu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.EditHazirRaporPermissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.EditHazirRaporPermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getEklemeyetkisivarmi();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getGormeyetkisivarmi();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getGorevyetkisivarmi();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getIndirmeyetkisivarmi();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getKayitdurumu();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.EditHazirRaporPermissionRequest} returns this
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 userId = 2;
 * @return {number}
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.EditHazirRaporPermissionRequest} returns this
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool eklemeYetkisiVarMi = 3;
 * @return {boolean}
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.prototype.getEklemeyetkisivarmi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.YetkiTanimlari.EditHazirRaporPermissionRequest} returns this
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.prototype.setEklemeyetkisivarmi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool gormeYetkisiVarMi = 4;
 * @return {boolean}
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.prototype.getGormeyetkisivarmi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.YetkiTanimlari.EditHazirRaporPermissionRequest} returns this
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.prototype.setGormeyetkisivarmi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool gorevYetkisiVarMi = 5;
 * @return {boolean}
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.prototype.getGorevyetkisivarmi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.YetkiTanimlari.EditHazirRaporPermissionRequest} returns this
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.prototype.setGorevyetkisivarmi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool indirmeYetkisiVarMi = 6;
 * @return {boolean}
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.prototype.getIndirmeyetkisivarmi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.YetkiTanimlari.EditHazirRaporPermissionRequest} returns this
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.prototype.setIndirmeyetkisivarmi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional int32 kayitDurumu = 7;
 * @return {number}
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.prototype.getKayitdurumu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.EditHazirRaporPermissionRequest} returns this
 */
proto.YetkiTanimlari.EditHazirRaporPermissionRequest.prototype.setKayitdurumu = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.EditHazirRaporPermissionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.EditHazirRaporPermissionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.EditHazirRaporPermissionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.EditHazirRaporPermissionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.EditHazirRaporPermissionResponse}
 */
proto.YetkiTanimlari.EditHazirRaporPermissionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.EditHazirRaporPermissionResponse;
  return proto.YetkiTanimlari.EditHazirRaporPermissionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.EditHazirRaporPermissionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.EditHazirRaporPermissionResponse}
 */
proto.YetkiTanimlari.EditHazirRaporPermissionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.EditHazirRaporPermissionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.EditHazirRaporPermissionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.EditHazirRaporPermissionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.EditHazirRaporPermissionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.YetkiTanimlari.EditHazirRaporPermissionResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.YetkiTanimlari.EditHazirRaporPermissionResponse} returns this
 */
proto.YetkiTanimlari.EditHazirRaporPermissionResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest}
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest;
  return proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest}
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest} returns this
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse}
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse;
  return proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse}
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse} returns this
 */
proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.YetkiTanimlari);
