import { DoktorKarnesiClient } from "../../../protos/is-zekasi/rapor/doktor_karnesi_grpc_web_pb";
import {
    GetDoktorKarnesiRequest,
    GetDoktorKarnesiDetayiRequest,
    GetDoktorKarnesiByBolumRequest,
    GetDoktorKarnesiByHastaneRequest,
} from "../../../protos/is-zekasi/rapor/doktor_karnesi_pb";

class DoktorKarnesiService {

    getDoktorKarnesi = (props) => {
        return new Promise((resolve, reject) => {

            const client = new DoktorKarnesiClient(process.env.REACT_APP_API_BASE_URL);
            const getDoktorKarnesiRequest = new GetDoktorKarnesiRequest();

            getDoktorKarnesiRequest.setDonemtanimid(props.donemTanimId);
            getDoktorKarnesiRequest.setDoktorturuid(props.doktorTuruId);
            getDoktorKarnesiRequest.setKurumid(props.kurumId);
            getDoktorKarnesiRequest.setMerkezibolumid(props.merkeziBolumId);

            var token = localStorage.getItem('accessToken');
            client.getDoktorKarnesi(getDoktorKarnesiRequest,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }

    getDoktorKarnesiDetayi = (props) => {
        return new Promise((resolve, reject) => {

            const client = new DoktorKarnesiClient(process.env.REACT_APP_API_BASE_URL);
            const getDoktorKarnesiDetayiRequest = new GetDoktorKarnesiDetayiRequest();

            getDoktorKarnesiDetayiRequest.setDonemtanimid(props.donemTanimId);
            getDoktorKarnesiDetayiRequest.setKurumid(props.kurumId);
            getDoktorKarnesiDetayiRequest.setDoktoradi(props.doktorAdi);
            getDoktorKarnesiDetayiRequest.setBolumid(props.bolumId);
            getDoktorKarnesiDetayiRequest.setAlanadi(props.alanAdi);

            var token = localStorage.getItem('accessToken');
            client.getDoktorKarnesiDetayi(getDoktorKarnesiDetayiRequest,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }

    getDoktorKarnesiByBolum = (props) => {
        return new Promise((resolve, reject) => {

            const client = new DoktorKarnesiClient(process.env.REACT_APP_API_BASE_URL);
            const getDoktorKarnesiByBolumRequest = new GetDoktorKarnesiByBolumRequest();

            getDoktorKarnesiByBolumRequest.setDonemtanimid(props.donemTanimId);
            getDoktorKarnesiByBolumRequest.setDoktorturuid(props.doktorTuruId);
            getDoktorKarnesiByBolumRequest.setKurumid(props.kurumId);
            getDoktorKarnesiByBolumRequest.setMerkezibolumid(props.merkeziBolumId);

            var token = localStorage.getItem('accessToken');
            client.getDoktorKarnesiByBolum(getDoktorKarnesiByBolumRequest,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }

    getDoktorKarnesiByHastane = (props) => {
        return new Promise((resolve, reject) => {

            const client = new DoktorKarnesiClient(process.env.REACT_APP_API_BASE_URL);
            const getDoktorKarnesiByHastaneRequest = new GetDoktorKarnesiByHastaneRequest();

            getDoktorKarnesiByHastaneRequest.setDonemtanimid(props.donemTanimId);
            getDoktorKarnesiByHastaneRequest.setDoktorturuid(props.doktorTuruId);

            var token = localStorage.getItem('accessToken');
            client.getDoktorKarnesiByHastane(getDoktorKarnesiByHastaneRequest,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }
}

const doktorKarnesiService = new DoktorKarnesiService();

export default doktorKarnesiService;
