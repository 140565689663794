import { GCFortheUIClient } from "../../protos/generalcontent/gc_for_the_ui_grpc_web_pb";
import { Empty } from "google-protobuf/google/protobuf/empty_pb";

class GCFortheUIService {

    getHospitalListFTUI = () => {
        return new Promise((resolve, reject) => {
            const client = new GCFortheUIClient(process.env.REACT_APP_API_BASE_URL);
            var token = localStorage.getItem('accessToken');

            const request = new Empty();
            client.getHospitalListFTUI(request, { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                if (response) {
                    resolve(response.toObject());
                } else {
                    reject(error);
                }
            });
        });
    }

    getDonemTanimlari = () => {
        return new Promise((resolve, reject) => {
            const client = new GCFortheUIClient(process.env.REACT_APP_API_BASE_URL);
            var token = localStorage.getItem('accessToken');

            const request = new Empty();
            client.getDonemTanimlari(request, { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                if (response) {
                    resolve(response.toObject());
                } else {
                    reject(error);
                }
            });
        });
    }

    getMerkeziBolumler = () => {
        return new Promise((resolve, reject) => {
            const client = new GCFortheUIClient(process.env.REACT_APP_API_BASE_URL);
            var token = localStorage.getItem('accessToken');

            const request = new Empty();
            client.getMerkeziBolumler(request, { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                if (response) {
                    resolve(response.toObject());
                } else {
                    reject(error);
                }
            });
        });
    }
}

const gcFortheUIService = new GCFortheUIService();

export default gcFortheUIService;