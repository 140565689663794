import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Topbar from './Topbar';
import Navbar from './Navbar';
import { useDispatch, useSelector } from 'react-redux';
import { getMenuPermissions } from 'src/slices/permission';
import useAuth from 'src/hooks/useAuth';
import { PieChart as PieChartIcon } from 'react-feather';
import './index.css'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    overflow: 'auto',
    width: '100%'
  },
  content: {
    flex: '1 1 auto',
    height: '90%',
    overflow: 'auto',
    paddingLeft: 240
  }
}));

const DashboardLayout3 = props => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { children } = props;

  const { menuPermissions } = useSelector(state => state.permission);
  const { user } = useAuth();

  useEffect(() => {
    dispatch(getMenuPermissions(user.id));
  }, [dispatch, user.id]);

  const sections = [
    {
      id: '1',
      name: 'Rapor Paneli',
      permissions: ['HazirRaporGirisForm'],
      icon: '/static/images/icons/hazir_rapor_16.png',
      items: [
        {
          id: '1_1',
          name: 'Rapor Paneli',
          icon: '/static/images/icons/hazir_rapor_16.png',
          href: '/app/rapor-paneli',
          permissions: ['HazirRaporGirisForm']
        }
      ]
    },
    {
      id: '1_R_2',
      name: 'Birlikte Çalışma',
      icon: '/static/images/icons/klasor_16.png',
      permissions: [
        'MerkeziBirlikteCalisma',
      ],
      items: [
        {
          id: '1_R_2_1',
          name: 'Telefon Rehberi',
          icon: '/static/images/icons/telefon_16.png',
          href: '/app/PortalTelefonRehberiCrud',
          permissions: ['PortalTelefonRehberiForm']
        },
        {
          id: '1_R_2_5',
          name: 'Bağlantılar',
          icon: '/static/images/icons/belge_16.png',
          href: '/app/PortalBaglantilarCrud',
          permissions: ['PortalTelefonRehberiForm']
        },        
        {
          id: '1_R_2_2',
          name: 'Günlük Yemek',
          icon: '/static/images/icons/corba_16.png',
          href: '/app/PortalGunlukYemekCrud',
          permissions: ['PortalGunlukYemekForm']
        },
        {
          id: '1_R_2_3',
          name: 'Iletiler',
          icon: '/static/images/icons/klasor_16.png',
          href: '/app/PortalIletiAnaSayfa',
          permissions: ['PortalAnaSayfa']
        }
        ,
        {
          id: '1_R_2_4',
          name: 'Portal Duyurular',
          icon: '/static/images/icons/klasor_16.png',
          href: '/app/PortalDuyurularCrud',
          permissions: ['DuyuruYayinlamaForm']
        }        
      ]
    },
    {
      id: '1_R_3',
      name : "Hasta Hizmetleri",
      icon: '/static/images/icons/klasor_16.png',
      permissions: [
        'Hasta İlişkileri',
      ],
      items: [
        {
          id: '1_R_3_1',
          name: 'H.Mem.Takip Listesi',
          icon: '/static/images/icons/sikayet_16.png',
          href: '/app/hastahizmetleri/hastailiskileri/hastamemnuniyettakiplistesi',
          permissions: ['SikayetListesi']        
        }
      ]
    },
    {
      id: '2',
      name: 'Kalite',
      icon: '/static/images/icons/klasor_16.png',
      permissions: [
        'DokumanPaneliWeb',
        'OlayBildirimiWeb',
        'OzDegerlendirmeWeb',
        'DOFTakibiWeb'
      ],
      items: [
        {
          id: '2_1',
          name: 'Doküman Paneli',
          icon: '/static/images/icons/klasor_16.png',
          href: '/app/kalite/dokuman-paneli',
          permissions: ['DokumanPaneliWeb']
        },
        {
          id: '2_2',
          name: 'Olay Bildirimi',
          icon: '/static/images/icons/istek_16.png',
          href: '/app/kalite/olay-bildirimi',
          permissions: ['OlayBildirimiWeb']
        },
        {
          id: '2_3',
          name: 'Öz Değerlendirme',
          icon: '/static/images/icons/takvim_16.png',
          href: '/app/kalite/oz-degerlendirme',
          permissions: ['OzDegerlendirmeWeb']
        },
        {
          id: '2_4',
          name: 'DÖF Takibi',
          icon: '/static/images/icons/paket_16.png',
          href: '/app/kalite/dof-takibi',
          permissions: ['DOFTakibiWeb']
        }
      ]
    },
    {
      id: '3',
      name: 'Bakım & Envanter',
      icon: '/static/images/icons/bakim_16.png',
      permissions: [
        'WebDemirbasTanim',
        'WebDemirbasAlanTanim',
        'SeriBakiyeIzlemeWeb',
        'YerTanimIzlemeWeb',
        'TuketimTakibiWeb'
      ],
      items: [
        {
          id: '3_1',
          name: 'Demirbaş Tanımları',
          icon: '/static/images/icons/demirbas_16.png',
          href: '/app/bakim-envanter/demirbas-tanimlari',
          permissions: ['WebDemirbasTanim']
        },
        {
          id: '3_2',
          name: 'Demirbaş Alan Tanımları',
          icon: '/static/images/icons/demirbas_16.png',
          href: '/app/bakim-envanter/demirbas-alan-tanimlari',
          permissions: ['WebDemirbasAlanTanim']
        },
        {
          id: '3_3',
          name: 'Demirbaş İzleme',
          icon: '/static/images/icons/nakit_16.png',
          href: '/app/bakim-envanter/demirbas-izleme',
          permissions: ['SeriBakiyeIzlemeWeb']
        },
        {
          id: '3_4',
          name: 'Yer Tanım İzleme',
          icon: '/static/images/icons/Idari_bolum_16.png',
          href: '/app/bakim-envanter/yer-tanim-izleme',
          permissions: ['YerTanimIzlemeWeb']
        },
        {
          id: '3_5',
          name: 'Tüketim Takibi',
          icon: '/static/images/icons/olcum_16.png',
          href: '/app/bakim-envanter/tuketim-takibi',
          permissions: ['TuketimTakibiWeb']
        }
      ]
    },
    {
      id: '4',
      name: 'İnsan Kaynakları',
      icon: '/static/images/icons/personel_16.png',
      permissions: ['DoktorKayitWeb'],
      items: [
        {
          id: '4_1',
          name: 'Doktor Kayıt',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/insan-kaynaklari/doktor-kayit',
          permissions: ['DoktorKayitWeb']
        }
      ]
    },
    {
      id: '5',
      name: 'Uluslararası Hasta',
      icon: '/static/images/icons/odeyici_kurum_16.png',
      permissions: [
        'YabanciHastaAramaWeb',
        'YurtdisiHastaCiroWeb',
        'YurtdisiOzetWeb',
        'DijitalPazarlamaWeb'
      ],
      items: [
        {
          id: '5_1',
          name: 'Uluslararası Hasta Paneli',
          icon: '/static/images/icons/hasta_16.png',
          href: '/app/uluslararasi-hasta/uluslararasi-hasta-paneli',
          permissions: ['YabanciHastaAramaWeb']
        },
        {
          id: '5_2',
          name: 'Yurtdışı Hasta Ciro',
          icon: '/static/images/icons/hasta_16.png',
          href: '/app/uluslararasi-hasta/yurtdisi-hasta-ciro',
          permissions: ['YurtdisiHastaCiroWeb']
        },
        {
          id: '5_3',
          name: 'Yurtdışı Özet',
          icon: '/static/images/icons/hasta_16.png',
          href: '/app/uluslararasi-hasta/yurtdisi-ozet',
          permissions: ['YurtdisiOzetWeb']
        },
        {
          id: '5_4',
          name: 'Dijital Pazarlama',
          icon: '/static/images/icons/rapor_analiz_16.png',
          href: '/app/uluslararasi-hasta/dijital-pazarlama',
          permissions: ['DijitalPazarlamaWeb']
        }
      ]
    },
    {
      id: '6',
      name: 'İş Zekası',
      icon: '/static/images/icons/rapor_ozel_16.png',
      permissions: [
        'OzetYonetimRaporuWeb',
        'CiroRaporuWebForm',
        'OzellikliMalzemeRaporuWeb',
        'DoktorKarnesiWebForm',
        'DoktorKarnesiOzetiWebForm',
        'DoktorPerformansOzetiWeb',
        'HastaSayisiTakibiWeb',
        'HastaSayisiAnaliziWeb',
        'HastanePerformansAnaliziWeb',
        'SurecTanimForm'
      ],
      items: [
        {
          id: '6_1',
          name: 'Özet Yönetim Raporu',
          icon: '/static/images/icons/proforma_16.png',
          href: '/app/rapor/ozet-yonetim-raporu',
          permissions: ['OzetYonetimRaporuWeb']
        },
        {
          id: '6_2',
          name: 'Ciro Raporu',
          icon: '/static/images/icons/rapor_analiz_16.png',
          href: '/app/rapor/ciro-raporu',
          permissions: ['CiroRaporuWebForm']
        },
        {
          id: '6_3',
          name: 'Özellikli Malzeme Raporu',
          icon: '/static/images/icons/rapor_analiz_16.png',
          href: '/app/rapor/ozellikli-malzeme-raporu',
          permissions: ['OzellikliMalzemeRaporuWeb']
        },
        {
          id: '6_4',
          name: 'Doktor Karnesi',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/rapor/doktor-karnesi',
          permissions: ['DoktorKarnesiWebForm']
        },
        {
          id: '6_5',
          name: 'Doktor Karnesi Özeti',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/rapor/doktor-karnesi-ozeti',
          permissions: ['DoktorKarnesiOzetiWebForm']
        },
        {
          id: '6_6',
          name: 'Doktor Performans Özeti',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/rapor/doktor-performans-ozeti',
          permissions: ['DoktorPerformansOzetiWeb']
        },
        {
          id: '6_7',
          name: 'İşlem Gören Hasta Sayısı',
          icon: '/static/images/icons/hasta_16.png',
          href: '/app/rapor/hasta-sayilari',
          permissions: ['HastaSayisiTakibiWeb']
        },
        {
          id: '6_8',
          name: 'Hastaların Bölge Dağılımı',
          icon: '/static/images/icons/rapor_analiz_16.png',
          href: '/app/rapor/hastalarin-bolge-dagilimi',
          permissions: ['HastaSayisiAnaliziWeb']
        },
        {
          id: '6_9',
          name: 'Hastane Performans Analizi',
          icon: '/static/images/icons/rapor_analiz_16.png',
          href: '/app/rapor/hastane-performans-analizi',
          permissions: ['HastanePerformansAnaliziWeb']
        },
        {
          id: '6_10',
          name: 'Süreç Tanım',
          icon: '/static/images/icons/surec_16.png',
          href: '/app/is-zekasi/rapor-tanimlari/surec-tanimlari',
          permissions: ['SurecTanimForm']
        },
        {
          id: '6_11',
          name: 'Günlük Ciro Projeksiyon',
          icon: '/static/images/icons/rapor_analiz_16.png',
          href: '/app/is-zekasi/projeksiyongunlukciro',
          permissions: ['1200']
        },
        {
          id: '6_12',
          name: 'Gelir Gider Bütçesi',
          icon: '/static/images/icons/rapor_analiz_16.png',
          href: '/app/is-zekasi/gelirgiderbutcesi',
          permissions: ['1203']
        }        
      ]
    },
    {
      id: '7',
      name: 'Sistem',
      icon: '/static/images/icons/tanim_16.png',
      permissions: [
        'MerkeziKurumTanim',
        'MerkeziBolumTanim',
        'MerkeziPozisyonTanim',
        'MerkeziPersonelTanim',
        'MerkeziYetkiTanim',
        'MenuYetkileriForm'
      ],
      items: [
        {
          id: '7_1',
          name: 'Merkezi Kurum Tanım',
          icon: '/static/images/icons/kurum_16.png',
          href: '/app/sistem/merkezi-kurum-tanim',
          permissions: ['MerkeziKurumTanim']
        },
        {
          id: '7_2',
          name: 'Merkezi Bölüm Tanım',
          icon: '/static/images/icons/bolum_16.png',
          href: '/app/sistem/merkezi-bolum-tanim',
          permissions: ['MerkeziBolumTanim']
        },
        {
          id: '7_3',
          name: 'Merkezi Pozisyon Tanım',
          icon: '/static/images/icons/pozisyon_16.png',
          href: '/app/sistem/merkezi-pozisyon-tanim',
          permissions: ['MerkeziPozisyonTanim']
        },
        {
          id: '7_4',
          name: 'Merkezi Personel Tanım',
          icon: '/static/images/icons/personel_16.png',
          href: '/app/sistem/merkezi-personel-tanim',
          permissions: ['MerkeziPersonelTanim']
        },
        {
          id: '7_5',
          name: 'Merkezi Yetki Tanım',
          icon: '/static/images/icons/yetkiler_16.png',
          href: '/app/sistem/merkezi-yetki-tanim',
          permissions: ['MerkeziYetkiTanim']
        },
        {
          id: '7_6',
          name: 'Merkezi Menü Yetkileri',
          icon: '/static/images/icons/yetkiler_16.png',
          href: '/app/sistem/merkezi-menu-yetkileri',
          permissions: ['MenuYetkileriForm']
        }
        // {
        //   id: '7_7',
        //   name: 'Çıkış',
        //   icon: '/static/images/icons/entegrasyon_16.png',
        //   href: '/',
        //   permissions: []
        // }
      ]
    },
    /*    
    {
      id: '8',
      name: 'Medikal',
      permissions: ['DoktorTanimlariForm'],
      icon: '/static/images/icons/doktor_e_16.png',
      items: [
        {
          id: '8_1',
          name: 'Doktor Paneli',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/rapor/doktorpanel',
          permissions: ['DoktorPaneliForm']
        }
      ]
    },*/    
    {
      id: '9',
      name: 'Proje',
      permissions: ['ProjeIzlemeWeb'],
      icon: '/static/images/icons/yetkiler_16.png',
      items: [
        {
          id: '9_1',
          name: 'Projeler',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/rapor/oneriproje',
          permissions: ['ProjeIzlemeWeb']
        },
        {
          id: '9_2',
          name: 'Gerçekleşmiş Projeler',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/rapor/gerceklesmisproje',
          permissions: ['GerceklesmisProjeIzlemeWeb']
        }             
      ]
    },    
    {
      id: '10',
      name: 'Hekimlik Hizmetleri',
      permissions: ['YatisaDonusWeb'],
      icon: '/static/images/icons/yetkiler_16.png',
      items: [
        /*
        {
          id: '10_1',
          name: 'KPI Takip - Eski',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/is-zekasi/hekimlikhizmetleri',
          permissions: ['YatisaDonusWeb']
        },*/
        {
          id: '10_2',
          name: 'KPI Takip',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/is-zekasi/hekimlikhizmetleri/kpiolcumtakibi',
          permissions: ['YatisaDonusWeb']
        },
        {
          id: '10_3',
          name: 'KPI Dashboard',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/is-zekasi/hekimlikhizmetleri/kpiolcumtakibidashboard',
          permissions: ['YatisaDonusWeb']
        }           
      ]
    },    
    {
      id: '11',
      name: 'Pazarlama & İş Geliştirme',
      permissions: ['1196'],
      icon: '/static/images/icons/yetkiler_16.png',
      items: [
        {
          id: '10_1',
          name: 'Raporlar',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/pazarlama/content1',
          permissions: ['1196']
        },
        {
          id: '10_2',
          name: 'Kampanyalar',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/pazarlama/content2',
          permissions: ['1196']
        },
        {
          id: '10_3',
          name: 'Sözleşmeler',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/pazarlama/content3',
          permissions: ['1196']
        },
        {
          id: '10_4',
          name: 'Bilgi Kutuphanesi',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/pazarlama/content4',
          permissions: ['1196']
        },
        {
          id: '10_5',
          name: 'P.&R.&M. Araştırması',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/pazarlama/content5',
          permissions: ['1196']
        },
        {
          id: '10_6',
          name: 'Memnuniyet Anketi',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/pazarlama/content6',
          permissions: ['1196']
        }      

      ]
    },    
    {
      id: '12',
      name: 'İnsan Kaynakları',
      permissions: ['1197'],
      icon: '/static/images/icons/yetkiler_16.png',
      items: [
        /*{
          id: '12_1',
          name: 'Prosedürler',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/pazarlama/ikcontent1',
          permissions: ['1197']
        },*/
        {
          id: '12_2',
          name: 'Organizasyon Şeması',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/pazarlama/ikcontent2',
          permissions: ['1196']
        }      

      ]
    },    
    {
      id: '13',
      name: 'Marka ve Kurumsal İletişim',
      permissions: ['1198'],
      icon: '/static/images/icons/yetkiler_16.png',
      items: [
        {
          id: '13_1',
          name: 'Raporlar',
          icon: '/static/images/icons/doktor_e_16.png',
          href: '/app/pazarlama/markavekurumsaliletisimcontent',
          permissions: ['1198']
        }     
      ]
    }
  ];

  let authorizedSections = [];

  if (menuPermissions.length > 0) {
    sections.forEach(section => {
      let items = [];

      if (section.permissions.length !== 0) {
        section.items.forEach(item => {
          if (item.permissions.length === 0) {
            items.push(item);
          }
          item.permissions.forEach(permission => {
            if (menuPermissions.includes(permission)) {
              items.push(item);
            }
          });
        });
      }

      authorizedSections.push({
        id: section.id,
        name: section.name,
        icon: section.icon,
        items: items
      });
    });
  }

  return (
    <div className={classes.root}>
      <Topbar sections={authorizedSections} />
      <Navbar open={true} variant="temporary" sections={authorizedSections} />
      <div className={classes.content}>{children}</div>
    </div>
  );
};

DashboardLayout3.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout3;
