/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/**
 * @fileoverview gRPC-Web generated client stub for OzetYonetimRaporu
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.OzetYonetimRaporu = require('./ozet_yonetim_raporu_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.OzetYonetimRaporu.OzetYonetimRaporuClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.OzetYonetimRaporu.OzetYonetimRaporuPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.OzetYonetimRaporu.GetCiroAnaliziRequest,
 *   !proto.OzetYonetimRaporu.GetCiroAnaliziResponse>}
 */
const methodDescriptor_OzetYonetimRaporu_GetCiroAnalizi = new grpc.web.MethodDescriptor(
  '/OzetYonetimRaporu.OzetYonetimRaporu/GetCiroAnalizi',
  grpc.web.MethodType.UNARY,
  proto.OzetYonetimRaporu.GetCiroAnaliziRequest,
  proto.OzetYonetimRaporu.GetCiroAnaliziResponse,
  /**
   * @param {!proto.OzetYonetimRaporu.GetCiroAnaliziRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.OzetYonetimRaporu.GetCiroAnaliziResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.OzetYonetimRaporu.GetCiroAnaliziRequest,
 *   !proto.OzetYonetimRaporu.GetCiroAnaliziResponse>}
 */
const methodInfo_OzetYonetimRaporu_GetCiroAnalizi = new grpc.web.AbstractClientBase.MethodInfo(
  proto.OzetYonetimRaporu.GetCiroAnaliziResponse,
  /**
   * @param {!proto.OzetYonetimRaporu.GetCiroAnaliziRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.OzetYonetimRaporu.GetCiroAnaliziResponse.deserializeBinary
);


/**
 * @param {!proto.OzetYonetimRaporu.GetCiroAnaliziRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.OzetYonetimRaporu.GetCiroAnaliziResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.OzetYonetimRaporu.GetCiroAnaliziResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.OzetYonetimRaporu.OzetYonetimRaporuClient.prototype.getCiroAnalizi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/OzetYonetimRaporu.OzetYonetimRaporu/GetCiroAnalizi',
      request,
      metadata || {},
      methodDescriptor_OzetYonetimRaporu_GetCiroAnalizi,
      callback);
};


/**
 * @param {!proto.OzetYonetimRaporu.GetCiroAnaliziRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.OzetYonetimRaporu.GetCiroAnaliziResponse>}
 *     Promise that resolves to the response
 */
proto.OzetYonetimRaporu.OzetYonetimRaporuPromiseClient.prototype.getCiroAnalizi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/OzetYonetimRaporu.OzetYonetimRaporu/GetCiroAnalizi',
      request,
      metadata || {},
      methodDescriptor_OzetYonetimRaporu_GetCiroAnalizi);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest,
 *   !proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse>}
 */
const methodDescriptor_OzetYonetimRaporu_GetTahsilatAnalizi = new grpc.web.MethodDescriptor(
  '/OzetYonetimRaporu.OzetYonetimRaporu/GetTahsilatAnalizi',
  grpc.web.MethodType.UNARY,
  proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest,
  proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse,
  /**
   * @param {!proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest,
 *   !proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse>}
 */
const methodInfo_OzetYonetimRaporu_GetTahsilatAnalizi = new grpc.web.AbstractClientBase.MethodInfo(
  proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse,
  /**
   * @param {!proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.deserializeBinary
);


/**
 * @param {!proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.OzetYonetimRaporu.OzetYonetimRaporuClient.prototype.getTahsilatAnalizi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/OzetYonetimRaporu.OzetYonetimRaporu/GetTahsilatAnalizi',
      request,
      metadata || {},
      methodDescriptor_OzetYonetimRaporu_GetTahsilatAnalizi,
      callback);
};


/**
 * @param {!proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse>}
 *     Promise that resolves to the response
 */
proto.OzetYonetimRaporu.OzetYonetimRaporuPromiseClient.prototype.getTahsilatAnalizi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/OzetYonetimRaporu.OzetYonetimRaporu/GetTahsilatAnalizi',
      request,
      metadata || {},
      methodDescriptor_OzetYonetimRaporu_GetTahsilatAnalizi);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest,
 *   !proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse>}
 */
const methodDescriptor_OzetYonetimRaporu_GetCiroDagilimiVeIslemAnalizi = new grpc.web.MethodDescriptor(
  '/OzetYonetimRaporu.OzetYonetimRaporu/GetCiroDagilimiVeIslemAnalizi',
  grpc.web.MethodType.UNARY,
  proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest,
  proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse,
  /**
   * @param {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest,
 *   !proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse>}
 */
const methodInfo_OzetYonetimRaporu_GetCiroDagilimiVeIslemAnalizi = new grpc.web.AbstractClientBase.MethodInfo(
  proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse,
  /**
   * @param {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.deserializeBinary
);


/**
 * @param {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.OzetYonetimRaporu.OzetYonetimRaporuClient.prototype.getCiroDagilimiVeIslemAnalizi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/OzetYonetimRaporu.OzetYonetimRaporu/GetCiroDagilimiVeIslemAnalizi',
      request,
      metadata || {},
      methodDescriptor_OzetYonetimRaporu_GetCiroDagilimiVeIslemAnalizi,
      callback);
};


/**
 * @param {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse>}
 *     Promise that resolves to the response
 */
proto.OzetYonetimRaporu.OzetYonetimRaporuPromiseClient.prototype.getCiroDagilimiVeIslemAnalizi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/OzetYonetimRaporu.OzetYonetimRaporu/GetCiroDagilimiVeIslemAnalizi',
      request,
      metadata || {},
      methodDescriptor_OzetYonetimRaporu_GetCiroDagilimiVeIslemAnalizi);
};


module.exports = proto.OzetYonetimRaporu;

