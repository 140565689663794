import { createSlice } from '@reduxjs/toolkit'
import { DoktorPaneliService, DoktorPaneliServiceYatarak,
        MedikalTanimlarSablonBilgileri,
        MedikalTanimlarMuayeneSoruBilgileri,
        MedikalTanimlarICDBilgileri,
        MedikalTanimlarRadyolojikIstekBilgileri,
        MedikalTanimlarLaboratuarIstekBilgileri
    } from "src/services/doktorpaneli/doktorPanelService";
import { trackPromise } from 'react-promise-tracker';

const initialState = { 
    doktorPanelAyaktanTedaviList: [],
    doktorPanelYatarakTedaviList: [],
    doctorPanelMedikalTanimSablonlarList: [],
    doctorPanelMedikalTanimMuayeneSoruList: [],
    doctorPanelMedikalTanimICDList: [],
    doctorPanelMedikalTanimRadyolojikIstekList: [],
    doctorPanelMedikalTanimLaboratuarIstekList: [],
   }

const doctorPanelSlice = createSlice({
    name: 'doctorPanel',
    initialState,
    reducers: {
        doctorPanelAyaktaReducer(state, action) {
            const doktorPanelAyaktanTedaviList = action.payload;
            state.doktorPanelAyaktanTedaviList = doktorPanelAyaktanTedaviList;        
        },
        doctorPanelYatarakReducer(state, action) {
            const doktorPanelYatarakTedaviList = action.payload;
            state.doktorPanelYatarakTedaviList = doktorPanelYatarakTedaviList;        
        },
        doctorPanelMedikalTanimSablonlarListReducer(state, action) {
            const doctorPanelMedikalTanimSablonlarList = action.payload;
            state.doctorPanelMedikalTanimSablonlarList = doctorPanelMedikalTanimSablonlarList;        
        },
        doctorPanelMedikalTanimMuayeneSoruListReducer(state, action) {
            const doctorPanelMedikalTanimMuayeneSoruList = action.payload;
            state.doctorPanelMedikalTanimMuayeneSoruList = doctorPanelMedikalTanimMuayeneSoruList;        
        },
        doctorPanelMedikalTanimICDListReducer(state, action) {
            const doctorPanelMedikalTanimICDList = action.payload;
            state.doctorPanelMedikalTanimICDList = doctorPanelMedikalTanimICDList;        
        },
        doctorPanelMedikalTanimRadyolojikIstekListReducer(state, action) {
            const doctorPanelMedikalTanimRadyolojikIstekList = action.payload;
            state.doctorPanelMedikalTanimRadyolojikIstekList = doctorPanelMedikalTanimRadyolojikIstekList;        
        },
        doctorPanelMedikalTanimLaboratuarIstekListReducer(state, action) {
            const doctorPanelMedikalTanimLaboratuarIstekList = action.payload;
            state.doctorPanelMedikalTanimLaboratuarIstekList = doctorPanelMedikalTanimLaboratuarIstekList;        
        },  
    },
})   

export const reducer = doctorPanelSlice.reducer 

const { doctorPanelAyaktaReducer,doctorPanelYatarakReducer,
    doctorPanelMedikalTanimSablonlarListReducer,
    doctorPanelMedikalTanimMuayeneSoruListReducer,
    doctorPanelMedikalTanimICDListReducer,
    doctorPanelMedikalTanimRadyolojikIstekListReducer,
    doctorPanelMedikalTanimLaboratuarIstekListReducer
 } = doctorPanelSlice.actions

export const getDoctorPanelAyaktaAction = (dateStart, dateEnd, name, lastname, patientno, tckimlikno, protocol, allpatient, queryType, userID) => async dispatch => {

    //console.log('DoctorPanelAyaktaAction dateStart', dateStart);
    
    const DoctorPanelAyaktaAction = trackPromise(DoktorPaneliService(dateStart, dateEnd, name, lastname, patientno, tckimlikno, protocol, allpatient, queryType, userID));
    

    DoctorPanelAyaktaAction.then((response) =>{
      //console.log('DoctorPanelAyaktaAction response', response);
      dispatch(doctorPanelAyaktaReducer(response.ayaktantedavilistList));
    })
}

export const getDoctorPanelYatarakAction = (dateStart, dateEnd, name, lastname, patientno, tckimlikno, protocol, allpatient, queryType, userID, ydID) => async dispatch => {

    //console.log('DoctorPanelYatarakAction dateStart', dateStart);
    
    const DoctorPanelYatarakAction = trackPromise(DoktorPaneliServiceYatarak(dateStart, dateEnd, name, lastname, patientno, tckimlikno, protocol, allpatient, queryType, userID, ydID));
    

    DoctorPanelYatarakAction.then((response) =>{
      //console.log('DoctorPanelYatarakAction response', response);
      dispatch(doctorPanelYatarakReducer(response.yataraktedavilistList));
    })
}

export const getDoctorPanelMedikalTanimSablonlariGetirAction = (dateStart, dateEnd, personelId) => async dispatch => {
    
    //console.log("getDoctorPanelMedikalTanimSablonlariGetirAction geldi")
    //console.log("slice personelId",personelId)
    const result = trackPromise(MedikalTanimlarSablonBilgileri(dateStart, dateEnd, personelId));

    result.then((response) =>{
      //console.log('getDoctorPanelMedikalTanimSablonlariGetirAction response', response);
      dispatch(doctorPanelMedikalTanimSablonlarListReducer(response.medikaltanimsablonlarilistList));
    })
}

export const getDoctorPanelMedikalTanimMuayeneSorulariGetirAction = (dateStart, dateEnd, personelId) => async dispatch => {
    
    //console.log("getDoctorPanelMedikalTanimMuayeneSorulariGetirAction geldi")
    const result = trackPromise(MedikalTanimlarMuayeneSoruBilgileri(dateStart, dateEnd, personelId));

    result.then((response) =>{
      //console.log('getDoctorPanelMedikalTanimMuayeneSorulariGetirAction response', response);
      
      dispatch(doctorPanelMedikalTanimMuayeneSoruListReducer(response.medikaltanimmuayenesorularilistList));
    })
}

export const getDoctorPanelMedikalTanimICDGetirAction = (dateStart, dateEnd, personelId) => async dispatch => {
    
    //console.log("getDoctorPanelMedikalTanimICDGetirAction geldi")
    const result = trackPromise(MedikalTanimlarICDBilgileri(dateStart, dateEnd, personelId));

    result.then((response) =>{
      //console.log('getDoctorPanelMedikalTanimICDGetirAction response', response);
      
      dispatch(doctorPanelMedikalTanimICDListReducer(response.medikaltanimicdlistList));
    })
}

export const getDoctorPanelMedikalTanimRadyolojikIstekGetirAction = (dateStart, dateEnd, personelId) => async dispatch => {
    
    //console.log("getDoctorPanelMedikalTanimRadyolojikIstekGetirAction geldi")
    const result = trackPromise(MedikalTanimlarRadyolojikIstekBilgileri(dateStart, dateEnd, personelId));

    result.then((response) =>{
      //console.log('getDoctorPanelMedikalTanimRadyolojikIstekGetirAction response', response);
      dispatch(doctorPanelMedikalTanimRadyolojikIstekListReducer(response.medikaltanimradyolojikisteklistList));
    })
}

export const getDoctorPanelMedikalTanimLaboratuarIstekGetirAction = (dateStart, dateEnd, personelId) => async dispatch => {
    
    //console.log("getDoctorPanelMedikalTanimLaboratuarIstekGetirAction geldi")
    const result = trackPromise(MedikalTanimlarLaboratuarIstekBilgileri(dateStart, dateEnd, personelId));

    result.then((response) =>{
      //console.log('doctorPanelMedikalTanimLaboratuarIstekListReducer response', response);
      dispatch(doctorPanelMedikalTanimLaboratuarIstekListReducer(response.medikaltanimlaboratuaristeklistList));
    })
}

