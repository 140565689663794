// source: bolum_butce_takibi.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse', null, global);
goog.exportSymbol('proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.repeatedFields_, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.repeatedFields_, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.repeatedFields_, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.repeatedFields_, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.repeatedFields_, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.repeatedFields_, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.repeatedFields_, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.displayName = 'proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    getbolumbutcetakibicheckuprowsList: jspb.Message.toObjectList(msg.getGetbolumbutcetakibicheckuprowsList(),
    proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse;
  return proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto;
      reader.readMessage(value,proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.deserializeBinaryFromReader);
      msg.addGetbolumbutcetakibicheckuprows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGetbolumbutcetakibicheckuprowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    hastane: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bolum: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cironosum: jspb.Message.getFieldWithDefault(msg, 3, ""),
    protokolsayisisum: jspb.Message.getFieldWithDefault(msg, 4, ""),
    checkupcironosum: jspb.Message.getFieldWithDefault(msg, 5, ""),
    checkupsayisisum: jspb.Message.getFieldWithDefault(msg, 6, ""),
    toplamcheckupcironosum: jspb.Message.getFieldWithDefault(msg, 7, ""),
    toplamcheckupsayisisum: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto;
  return proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBolum(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCironosum(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtokolsayisisum(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckupcironosum(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCheckupsayisisum(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setToplamcheckupcironosum(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setToplamcheckupsayisisum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBolum();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCironosum();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProtokolsayisisum();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCheckupcironosum();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCheckupsayisisum();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getToplamcheckupcironosum();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getToplamcheckupsayisisum();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string Hastane = 1;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Bolum = 2;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.getBolum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.setBolum = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Cironosum = 3;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.getCironosum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.setCironosum = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string ProtokolSayisisum = 4;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.getProtokolsayisisum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.setProtokolsayisisum = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string CheckupCironosum = 5;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.getCheckupcironosum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.setCheckupcironosum = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string CheckupSayisisum = 6;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.getCheckupsayisisum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.setCheckupsayisisum = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ToplamCheckupCironosum = 7;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.getToplamcheckupcironosum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.setToplamcheckupcironosum = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string ToplamCheckupSayisisum = 8;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.getToplamcheckupsayisisum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto.prototype.setToplamcheckupsayisisum = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated GetBolumButceTakibiCheckupRowDto GetBolumButceTakibiCheckupRows = 1;
 * @return {!Array<!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto>}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.prototype.getGetbolumbutcetakibicheckuprowsList = function() {
  return /** @type{!Array<!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto, 1));
};


/**
 * @param {!Array<!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto>} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse} returns this
*/
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.prototype.setGetbolumbutcetakibicheckuprowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.prototype.addGetbolumbutcetakibicheckuprows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.GetBolumButceTakibiCheckupRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupResponse.prototype.clearGetbolumbutcetakibicheckuprowsList = function() {
  return this.setGetbolumbutcetakibicheckuprowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    input1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    input2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    input3: jspb.Message.getFieldWithDefault(msg, 3, ""),
    input4: jspb.Message.getFieldWithDefault(msg, 4, ""),
    input5: jspb.Message.getFieldWithDefault(msg, 5, ""),
    input6: jspb.Message.getFieldWithDefault(msg, 6, ""),
    input7: jspb.Message.getFieldWithDefault(msg, 7, ""),
    input8: jspb.Message.getFieldWithDefault(msg, 8, ""),
    input9: jspb.Message.getFieldWithDefault(msg, 9, ""),
    input10: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest;
  return proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setInput1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInput2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setInput3(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInput4(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setInput5(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setInput6(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setInput7(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setInput8(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setInput9(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setInput10(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInput1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInput2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getInput3();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInput4();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getInput5();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getInput6();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInput7();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getInput8();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getInput9();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getInput10();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string input1 = 1;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.getInput1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.setInput1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string input2 = 2;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.getInput2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.setInput2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string input3 = 3;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.getInput3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.setInput3 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string input4 = 4;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.getInput4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.setInput4 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string input5 = 5;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.getInput5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.setInput5 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string input6 = 6;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.getInput6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.setInput6 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string input7 = 7;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.getInput7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.setInput7 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string input8 = 8;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.getInput8 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.setInput8 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string input9 = 9;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.getInput9 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.setInput9 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string input10 = 10;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.getInput10 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiCheckupRequest.prototype.setInput10 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    kurumid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tarih: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bolumid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest;
  return proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBolumid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBolumid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 kurumId = 1;
 * @return {number}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string tarih = 2;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 bolumId = 3;
 * @return {number}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest.prototype.getBolumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatRequest.prototype.setBolumid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gidisatrowsList: jspb.Message.toObjectList(msg.getGidisatrowsList(),
    proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse;
  return proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto;
      reader.readMessage(value,proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.deserializeBinaryFromReader);
      msg.addGidisatrows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGidisatrowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    kurumid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hastane: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bolumid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    bolum: jspb.Message.getFieldWithDefault(msg, 4, ""),
    mevcutaygidisat: jspb.Message.getFieldWithDefault(msg, 5, ""),
    mevcutayhariccirogidisat: jspb.Message.getFieldWithDefault(msg, 6, ""),
    oncekiay1: jspb.Message.getFieldWithDefault(msg, 7, ""),
    oncekiay2: jspb.Message.getFieldWithDefault(msg, 8, ""),
    oncekiay3: jspb.Message.getFieldWithDefault(msg, 9, ""),
    oncekiyil1: jspb.Message.getFieldWithDefault(msg, 10, ""),
    oncekiyil2: jspb.Message.getFieldWithDefault(msg, 11, ""),
    butce: jspb.Message.getFieldWithDefault(msg, 12, ""),
    gerceklesme: jspb.Message.getFieldWithDefault(msg, 13, ""),
    sirano: jspb.Message.getFieldWithDefault(msg, 14, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto;
  return proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBolumid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBolum(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMevcutaygidisat(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMevcutayhariccirogidisat(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOncekiay1(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOncekiay2(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOncekiay3(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOncekiyil1(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOncekiyil2(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setButce(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setGerceklesme(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSirano(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBolumid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getBolum();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMevcutaygidisat();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMevcutayhariccirogidisat();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOncekiay1();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOncekiay2();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOncekiay3();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOncekiyil1();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOncekiyil2();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getButce();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getGerceklesme();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSirano();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
};


/**
 * optional int32 kurumId = 1;
 * @return {number}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string hastane = 2;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 bolumId = 3;
 * @return {number}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.getBolumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.setBolumid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string bolum = 4;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.getBolum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.setBolum = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string mevcutAyGidisat = 5;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.getMevcutaygidisat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.setMevcutaygidisat = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string mevcutAyHaricCiroGidisat = 6;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.getMevcutayhariccirogidisat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.setMevcutayhariccirogidisat = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string oncekiAy1 = 7;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.getOncekiay1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.setOncekiay1 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string oncekiAy2 = 8;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.getOncekiay2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.setOncekiay2 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string oncekiAy3 = 9;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.getOncekiay3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.setOncekiay3 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string oncekiYil1 = 10;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.getOncekiyil1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.setOncekiyil1 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string oncekiYil2 = 11;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.getOncekiyil2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.setOncekiyil2 = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string butce = 12;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.getButce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.setButce = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string gerceklesme = 13;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.getGerceklesme = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.setGerceklesme = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 siraNo = 14;
 * @return {number}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.getSirano = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto.prototype.setSirano = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * repeated GidisatRowDto gidisatRows = 1;
 * @return {!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto>}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.prototype.getGidisatrowsList = function() {
  return /** @type{!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto, 1));
};


/**
 * @param {!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto>} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse} returns this
*/
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.prototype.setGidisatrowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.prototype.addGidisatrows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.GidisatRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGidisatResponse.prototype.clearGidisatrowsList = function() {
  return this.setGidisatrowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    kurumid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tarih: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bolumid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest;
  return proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBolumid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBolumid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 kurumId = 1;
 * @return {number}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string tarih = 2;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 bolumId = 3;
 * @return {number}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest.prototype.getBolumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroRequest.prototype.setBolumid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    endekscirorowsList: jspb.Message.toObjectList(msg.getEndekscirorowsList(),
    proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse;
  return proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto;
      reader.readMessage(value,proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.deserializeBinaryFromReader);
      msg.addEndekscirorows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEndekscirorowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    hastane: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bolumid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bolum: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mevcutaygidisat: jspb.Message.getFieldWithDefault(msg, 4, ""),
    oncekiay1: jspb.Message.getFieldWithDefault(msg, 5, ""),
    oncekiay2: jspb.Message.getFieldWithDefault(msg, 6, ""),
    oncekiay3: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto;
  return proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBolumid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBolum(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMevcutaygidisat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOncekiay1(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOncekiay2(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOncekiay3(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBolumid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getBolum();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMevcutaygidisat();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOncekiay1();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOncekiay2();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOncekiay3();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string hastane = 1;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 bolumId = 2;
 * @return {number}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.prototype.getBolumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.prototype.setBolumid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string bolum = 3;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.prototype.getBolum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.prototype.setBolum = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string mevcutAyGidisat = 4;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.prototype.getMevcutaygidisat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.prototype.setMevcutaygidisat = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string oncekiAy1 = 5;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.prototype.getOncekiay1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.prototype.setOncekiay1 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string oncekiAy2 = 6;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.prototype.getOncekiay2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.prototype.setOncekiay2 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string oncekiAy3 = 7;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.prototype.getOncekiay3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto.prototype.setOncekiay3 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated EndeksCiroRowDto endeksCiroRows = 1;
 * @return {!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto>}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.prototype.getEndekscirorowsList = function() {
  return /** @type{!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto, 1));
};


/**
 * @param {!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto>} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse} returns this
*/
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.prototype.setEndekscirorowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.prototype.addEndekscirorows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.EndeksCiroRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByEndeksCiroResponse.prototype.clearEndekscirorowsList = function() {
  return this.setEndekscirorowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tarih: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tipi: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest;
  return proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTipi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTipi();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string tarih = 1;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 tipi = 2;
 * @return {number}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest.prototype.getTipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiRequest.prototype.setTipi = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hizmetdetayirowsList: jspb.Message.toObjectList(msg.getHizmetdetayirowsList(),
    proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse;
  return proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto;
      reader.readMessage(value,proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.deserializeBinaryFromReader);
      msg.addHizmetdetayirows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHizmetdetayirowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    hastane: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sirano: jspb.Message.getFieldWithDefault(msg, 2, 0),
    oncekiay2ciro: jspb.Message.getFieldWithDefault(msg, 3, ""),
    oncekiay2islemsayisi: jspb.Message.getFieldWithDefault(msg, 4, 0),
    oncekiay1ciro: jspb.Message.getFieldWithDefault(msg, 5, ""),
    oncekiay1islemsayisi: jspb.Message.getFieldWithDefault(msg, 6, 0),
    mevcutaygidisat: jspb.Message.getFieldWithDefault(msg, 7, ""),
    mevcutayciro: jspb.Message.getFieldWithDefault(msg, 8, ""),
    mevcutaygidisatislemsayisi: jspb.Message.getFieldWithDefault(msg, 9, 0),
    hedef: jspb.Message.getFieldWithDefault(msg, 10, ""),
    gerceklesmeorani: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto;
  return proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSirano(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOncekiay2ciro(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOncekiay2islemsayisi(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOncekiay1ciro(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOncekiay1islemsayisi(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMevcutaygidisat(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMevcutayciro(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMevcutaygidisatislemsayisi(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setHedef(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setGerceklesmeorani(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSirano();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOncekiay2ciro();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOncekiay2islemsayisi();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOncekiay1ciro();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOncekiay1islemsayisi();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMevcutaygidisat();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMevcutayciro();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getMevcutaygidisatislemsayisi();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getHedef();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getGerceklesmeorani();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string hastane = 1;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 siraNo = 2;
 * @return {number}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.getSirano = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.setSirano = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string oncekiay2Ciro = 3;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.getOncekiay2ciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.setOncekiay2ciro = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 oncekiay2IslemSayisi = 4;
 * @return {number}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.getOncekiay2islemsayisi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.setOncekiay2islemsayisi = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string oncekiay1Ciro = 5;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.getOncekiay1ciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.setOncekiay1ciro = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 oncekiay1IslemSayisi = 6;
 * @return {number}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.getOncekiay1islemsayisi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.setOncekiay1islemsayisi = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string mevcutAyGidisat = 7;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.getMevcutaygidisat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.setMevcutaygidisat = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string mevcutAyCiro = 8;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.getMevcutayciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.setMevcutayciro = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 mevcutAyGidisatIslemSayisi = 9;
 * @return {number}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.getMevcutaygidisatislemsayisi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.setMevcutaygidisatislemsayisi = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string hedef = 10;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.getHedef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.setHedef = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string gerceklesmeOrani = 11;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.getGerceklesmeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto.prototype.setGerceklesmeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * repeated HizmetDetayiRowDto hizmetDetayiRows = 1;
 * @return {!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto>}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.prototype.getHizmetdetayirowsList = function() {
  return /** @type{!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto, 1));
};


/**
 * @param {!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto>} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse} returns this
*/
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.prototype.setHizmetdetayirowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.prototype.addHizmetdetayirows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.HizmetDetayiRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHizmetDetayiResponse.prototype.clearHizmetdetayirowsList = function() {
  return this.setHizmetdetayirowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    kurumid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tarih: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bolumid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest;
  return proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBolumid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBolumid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 kurumId = 1;
 * @return {number}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string tarih = 2;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 bolumId = 3;
 * @return {number}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest.prototype.getBolumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariRequest.prototype.setBolumid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hedefgerceklesmeoranlarirowsList: jspb.Message.toObjectList(msg.getHedefgerceklesmeoranlarirowsList(),
    proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse;
  return proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto;
      reader.readMessage(value,proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.deserializeBinaryFromReader);
      msg.addHedefgerceklesmeoranlarirows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHedefgerceklesmeoranlarirowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    kurumid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    kayitdurumu: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bolum: jspb.Message.getFieldWithDefault(msg, 3, ""),
    butce: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ciro: jspb.Message.getFieldWithDefault(msg, 5, ""),
    gidisat: jspb.Message.getFieldWithDefault(msg, 6, ""),
    endeksgidisat: jspb.Message.getFieldWithDefault(msg, 7, ""),
    gerceklesenaktifgun: jspb.Message.getFieldWithDefault(msg, 8, ""),
    hedefgerceklesmeorani: jspb.Message.getFieldWithDefault(msg, 9, ""),
    endeksciro: jspb.Message.getFieldWithDefault(msg, 10, ""),
    ilacbutce: jspb.Message.getFieldWithDefault(msg, 11, ""),
    kurum: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto;
  return proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKurumid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKayitdurumu(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBolum(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setButce(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCiro(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGidisat(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndeksgidisat(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setGerceklesenaktifgun(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setHedefgerceklesmeorani(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndeksciro(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIlacbutce(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setKurum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKurumid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKayitdurumu();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBolum();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getButce();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCiro();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGidisat();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEndeksgidisat();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getGerceklesenaktifgun();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getHedefgerceklesmeorani();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEndeksciro();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIlacbutce();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getKurum();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string kurumId = 1;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.getKurumid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string kayitDurumu = 2;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.getKayitdurumu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.setKayitdurumu = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bolum = 3;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.getBolum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.setBolum = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string butce = 4;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.getButce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.setButce = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ciro = 5;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.getCiro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.setCiro = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string gidisat = 6;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.getGidisat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.setGidisat = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string endeksGidisat = 7;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.getEndeksgidisat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.setEndeksgidisat = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string gerceklesenAktifGun = 8;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.getGerceklesenaktifgun = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.setGerceklesenaktifgun = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string hedefGerceklesmeOrani = 9;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.getHedefgerceklesmeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.setHedefgerceklesmeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string endeksCiro = 10;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.getEndeksciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.setEndeksciro = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string ilacButce = 11;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.getIlacbutce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.setIlacbutce = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string kurum = 12;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.getKurum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto.prototype.setKurum = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * repeated HedefGerceklesmeOranlariDto hedefGerceklesmeOranlariRows = 1;
 * @return {!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto>}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.prototype.getHedefgerceklesmeoranlarirowsList = function() {
  return /** @type{!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto, 1));
};


/**
 * @param {!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto>} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse} returns this
*/
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.prototype.setHedefgerceklesmeoranlarirowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.prototype.addHedefgerceklesmeoranlarirows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.HedefGerceklesmeOranlariDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByHedefGerceklesmeOranlariResponse.prototype.clearHedefgerceklesmeoranlarirowsList = function() {
  return this.setHedefgerceklesmeoranlarirowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tarih: jspb.Message.getFieldWithDefault(msg, 1, ""),
    odeyicikurumtipi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    kurumid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest;
  return proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOdeyicikurumtipi(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOdeyicikurumtipi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string tarih = 1;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string odeyiciKurumTipi = 2;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest.prototype.getOdeyicikurumtipi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest.prototype.setOdeyicikurumtipi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 kurumId = 3;
 * @return {number}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiRequest.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    odeyicidetayirowsList: jspb.Message.toObjectList(msg.getOdeyicidetayirowsList(),
    proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse;
  return proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto;
      reader.readMessage(value,proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.deserializeBinaryFromReader);
      msg.addOdeyicidetayirows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOdeyicidetayirowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    odeyicidetayi: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tarih: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hastane: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bolum: jspb.Message.getFieldWithDefault(msg, 4, ""),
    butce: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ciro: jspb.Message.getFieldWithDefault(msg, 6, ""),
    gidisat: jspb.Message.getFieldWithDefault(msg, 7, ""),
    endeksdegisimi: jspb.Message.getFieldWithDefault(msg, 8, ""),
    butcegerceklesmeorani: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto;
  return proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOdeyicidetayi(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBolum(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setButce(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCiro(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setGidisat(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndeksdegisimi(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setButcegerceklesmeorani(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOdeyicidetayi();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBolum();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getButce();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCiro();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getGidisat();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getEndeksdegisimi();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getButcegerceklesmeorani();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string odeyiciDetayi = 1;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.getOdeyicidetayi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.setOdeyicidetayi = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tarih = 2;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string hastane = 3;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string bolum = 4;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.getBolum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.setBolum = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string butce = 5;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.getButce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.setButce = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ciro = 6;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.getCiro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.setCiro = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string gidisat = 7;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.getGidisat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.setGidisat = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string endeksDegisimi = 8;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.getEndeksdegisimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.setEndeksdegisimi = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string butceGerceklesmeOrani = 9;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.getButcegerceklesmeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto.prototype.setButcegerceklesmeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated OdeyiciDetayiDto odeyiciDetayiRows = 1;
 * @return {!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto>}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.prototype.getOdeyicidetayirowsList = function() {
  return /** @type{!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto, 1));
};


/**
 * @param {!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto>} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse} returns this
*/
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.prototype.setOdeyicidetayirowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.prototype.addOdeyicidetayirows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.OdeyiciDetayiDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByOdeyiciDetayiResponse.prototype.clearOdeyicidetayirowsList = function() {
  return this.setOdeyicidetayirowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hospitalid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tarih: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest;
  return proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHospitalid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHospitalid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 HospitalId = 1;
 * @return {number}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest.prototype.getHospitalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest.prototype.setHospitalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Tarih = 2;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayRequest.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    getbolumbutcetakibibygelisturudetayrowdtoList: jspb.Message.toObjectList(msg.getGetbolumbutcetakibibygelisturudetayrowdtoList(),
    proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse;
  return proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto;
      reader.readMessage(value,proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.deserializeBinaryFromReader);
      msg.addGetbolumbutcetakibibygelisturudetayrowdto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGetbolumbutcetakibibygelisturudetayrowdtoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    butce: jspb.Message.getFieldWithDefault(msg, 1, ""),
    butcegerceklesmeorani: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ciro: jspb.Message.getFieldWithDefault(msg, 3, ""),
    endeksdegisimi: jspb.Message.getFieldWithDefault(msg, 4, ""),
    gelisturu: jspb.Message.getFieldWithDefault(msg, 5, ""),
    gerceklesme: jspb.Message.getFieldWithDefault(msg, 6, ""),
    gidisat: jspb.Message.getFieldWithDefault(msg, 7, ""),
    hastane: jspb.Message.getFieldWithDefault(msg, 8, ""),
    tarih: jspb.Message.getFieldWithDefault(msg, 9, ""),
    bolum: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto;
  return proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setButce(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setButcegerceklesmeorani(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCiro(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndeksdegisimi(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGelisturu(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGerceklesme(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setGidisat(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBolum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getButce();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getButcegerceklesmeorani();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCiro();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEndeksdegisimi();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGelisturu();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGerceklesme();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getGidisat();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getBolum();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string butce = 1;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.getButce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.setButce = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string butcegerceklesmeorani = 2;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.getButcegerceklesmeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.setButcegerceklesmeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ciro = 3;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.getCiro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.setCiro = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string endeksdegisimi = 4;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.getEndeksdegisimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.setEndeksdegisimi = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string gelisturu = 5;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.getGelisturu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.setGelisturu = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string gerceklesme = 6;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.getGerceklesme = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.setGerceklesme = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string gidisat = 7;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.getGidisat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.setGidisat = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string hastane = 8;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string tarih = 9;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string bolum = 10;
 * @return {string}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.getBolum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto.prototype.setBolum = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated GetBolumButceTakibiByGelisTuruDetayRowDto getBolumButceTakibiByGelisTuruDetayRowDto = 1;
 * @return {!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto>}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.prototype.getGetbolumbutcetakibibygelisturudetayrowdtoList = function() {
  return /** @type{!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto, 1));
};


/**
 * @param {!Array<!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto>} value
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse} returns this
*/
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.prototype.setGetbolumbutcetakibibygelisturudetayrowdtoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto}
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.prototype.addGetbolumbutcetakibibygelisturudetayrowdto = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.GetBolumButceTakibiByGelisTuruDetayRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse} returns this
 */
proto.BolumButceTakibi.GetBolumButceTakibiByGelisTuruDetayResponse.prototype.clearGetbolumbutcetakibibygelisturudetayrowdtoList = function() {
  return this.setGetbolumbutcetakibibygelisturudetayrowdtoList([]);
};


goog.object.extend(exports, proto.BolumButceTakibi);
