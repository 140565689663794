/* eslint-disable */
//@ts-nocheck

// source: tani_islem_analizi.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.TaniIslemAnalizi.BolumGetirResponse', null, global);
goog.exportSymbol('proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto', null, global);
goog.exportSymbol('proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest', null, global);
goog.exportSymbol('proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse', null, global);
goog.exportSymbol('proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto', null, global);
goog.exportSymbol('proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest', null, global);
goog.exportSymbol('proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse', null, global);
goog.exportSymbol('proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.displayName = 'proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.repeatedFields_, null);
};
goog.inherits(proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.displayName = 'proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.displayName = 'proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.displayName = 'proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.repeatedFields_, null);
};
goog.inherits(proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.displayName = 'proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.displayName = 'proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaniIslemAnalizi.BolumGetirResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.TaniIslemAnalizi.BolumGetirResponse.repeatedFields_, null);
};
goog.inherits(proto.TaniIslemAnalizi.BolumGetirResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaniIslemAnalizi.BolumGetirResponse.displayName = 'proto.TaniIslemAnalizi.BolumGetirResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto.displayName = 'proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    baslangictarihi: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bitistarihi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bolum: jspb.Message.getFieldWithDefault(msg, 3, ""),
    hastane: jspb.Message.getFieldWithDefault(msg, 4, 0),
    gelisturu: jspb.Message.getFieldWithDefault(msg, 5, 0),
    tanitipi: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest;
  return proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaslangictarihi(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBitistarihi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBolum(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHastane(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGelisturu(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTanitipi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaslangictarihi();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBitistarihi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBolum();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHastane();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getGelisturu();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTanitipi();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional string BaslangicTarihi = 1;
 * @return {string}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.prototype.getBaslangictarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.prototype.setBaslangictarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string BitisTarihi = 2;
 * @return {string}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.prototype.getBitistarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.prototype.setBitistarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Bolum = 3;
 * @return {string}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.prototype.getBolum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.prototype.setBolum = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 Hastane = 4;
 * @return {number}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.prototype.getHastane = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.prototype.setHastane = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 GelisTuru = 5;
 * @return {number}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.prototype.getGelisturu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.prototype.setGelisturu = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 TaniTipi = 6;
 * @return {number}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.prototype.getTanitipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest.prototype.setTanitipi = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tanianalizleriList: jspb.Message.toObjectList(msg.getTanianalizleriList(),
    proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse;
  return proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto;
      reader.readMessage(value,proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.deserializeBinaryFromReader);
      msg.addTanianalizleri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTanianalizleriList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.toObject = function(opt_includeInstance) {
  return proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    sorumlumerkezbolum: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gelisturuaciklama: jspb.Message.getFieldWithDefault(msg, 2, ""),
    icdadi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    icdkodu: jspb.Message.getFieldWithDefault(msg, 4, ""),
    hastane: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sorumludoktor: jspb.Message.getFieldWithDefault(msg, 6, ""),
    protokolsayisi: jspb.Message.getFieldWithDefault(msg, 7, 0),
    baslangictarihi: jspb.Message.getFieldWithDefault(msg, 8, ""),
    bitistarihi: jspb.Message.getFieldWithDefault(msg, 9, ""),
    gelisturu: jspb.Message.getFieldWithDefault(msg, 10, 0),
    tanitipi: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto;
  return proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSorumlumerkezbolum(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGelisturuaciklama(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcdadi(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcdkodu(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSorumludoktor(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProtokolsayisi(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaslangictarihi(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setBitistarihi(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGelisturu(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTanitipi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSorumlumerkezbolum();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGelisturuaciklama();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIcdadi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIcdkodu();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSorumludoktor();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProtokolsayisi();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getBaslangictarihi();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBitistarihi();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getGelisturu();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getTanitipi();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional string SorumluMerkezBolum = 1;
 * @return {string}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.getSorumlumerkezbolum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.setSorumlumerkezbolum = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string GelisTuruAciklama = 2;
 * @return {string}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.getGelisturuaciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.setGelisturuaciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string IcdAdi = 3;
 * @return {string}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.getIcdadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.setIcdadi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string IcdKodu = 4;
 * @return {string}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.getIcdkodu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.setIcdkodu = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Hastane = 5;
 * @return {string}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string SorumluDoktor = 6;
 * @return {string}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.getSorumludoktor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.setSorumludoktor = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 ProtokolSayisi = 7;
 * @return {number}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.getProtokolsayisi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.setProtokolsayisi = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string BaslangicTarihi = 8;
 * @return {string}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.getBaslangictarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.setBaslangictarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string BitisTarihi = 9;
 * @return {string}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.getBitistarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.setBitistarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 GelisTuru = 10;
 * @return {number}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.getGelisturu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.setGelisturu = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 TaniTipi = 11;
 * @return {number}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.getTanitipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto.prototype.setTanitipi = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated TaniAnaliziDto TaniAnalizleri = 1;
 * @return {!Array<!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto>}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.prototype.getTanianalizleriList = function() {
  return /** @type{!Array<!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto, 1));
};


/**
 * @param {!Array<!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto>} value
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse} returns this
*/
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.prototype.setTanianalizleriList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto}
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.prototype.addTanianalizleri = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.TaniAnaliziDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse} returns this
 */
proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.prototype.clearTanianalizleriList = function() {
  return this.setTanianalizleriList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    baslangictarihi: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bitistarihi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hastane: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bolum: jspb.Message.getFieldWithDefault(msg, 4, ""),
    gelisturu: jspb.Message.getFieldWithDefault(msg, 5, 0),
    tanitipi: jspb.Message.getFieldWithDefault(msg, 6, 0),
    protokolsayisi: jspb.Message.getFieldWithDefault(msg, 7, 0),
    tanikodu: jspb.Message.getFieldWithDefault(msg, 8, ""),
    sorumludoktor: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest;
  return proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaslangictarihi(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBitistarihi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBolum(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGelisturu(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTanitipi(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProtokolsayisi(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTanikodu(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSorumludoktor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBaslangictarihi();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBitistarihi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBolum();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGelisturu();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTanitipi();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getProtokolsayisi();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTanikodu();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSorumludoktor();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string BaslangicTarihi = 1;
 * @return {string}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.getBaslangictarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest} returns this
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.setBaslangictarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string BitisTarihi = 2;
 * @return {string}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.getBitistarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest} returns this
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.setBitistarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Hastane = 3;
 * @return {string}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest} returns this
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Bolum = 4;
 * @return {string}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.getBolum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest} returns this
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.setBolum = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 GelisTuru = 5;
 * @return {number}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.getGelisturu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest} returns this
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.setGelisturu = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 TaniTipi = 6;
 * @return {number}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.getTanitipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest} returns this
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.setTanitipi = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 ProtokolSayisi = 7;
 * @return {number}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.getProtokolsayisi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest} returns this
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.setProtokolsayisi = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string TaniKodu = 8;
 * @return {string}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.getTanikodu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest} returns this
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.setTanikodu = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string SorumluDoktor = 9;
 * @return {string}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.getSorumludoktor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest} returns this
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest.prototype.setSorumludoktor = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    islemanalizleriList: jspb.Message.toObjectList(msg.getIslemanalizleriList(),
    proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse;
  return proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto;
      reader.readMessage(value,proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.deserializeBinaryFromReader);
      msg.addIslemanalizleri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIslemanalizleriList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.prototype.toObject = function(opt_includeInstance) {
  return proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    islemtipi: jspb.Message.getFieldWithDefault(msg, 1, 0),
    islemtipiaciklama: jspb.Message.getFieldWithDefault(msg, 2, ""),
    islemtanim: jspb.Message.getFieldWithDefault(msg, 3, ""),
    islemsayisi: jspb.Message.getFieldWithDefault(msg, 4, 0),
    protokolsayisi: jspb.Message.getFieldWithDefault(msg, 5, 0),
    yuzde: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto;
  return proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIslemtipi(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIslemtipiaciklama(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIslemtanim(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIslemsayisi(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProtokolsayisi(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setYuzde(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIslemtipi();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIslemtipiaciklama();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIslemtanim();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIslemsayisi();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getProtokolsayisi();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getYuzde();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 IslemTipi = 1;
 * @return {number}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.prototype.getIslemtipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto} returns this
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.prototype.setIslemtipi = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string IslemTipiAciklama = 2;
 * @return {string}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.prototype.getIslemtipiaciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto} returns this
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.prototype.setIslemtipiaciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string IslemTanim = 3;
 * @return {string}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.prototype.getIslemtanim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto} returns this
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.prototype.setIslemtanim = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 IslemSayisi = 4;
 * @return {number}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.prototype.getIslemsayisi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto} returns this
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.prototype.setIslemsayisi = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 ProtokolSayisi = 5;
 * @return {number}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.prototype.getProtokolsayisi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto} returns this
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.prototype.setProtokolsayisi = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string Yuzde = 6;
 * @return {string}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.prototype.getYuzde = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto} returns this
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto.prototype.setYuzde = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated IslemAnaliziDto IslemAnalizleri = 1;
 * @return {!Array<!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto>}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.prototype.getIslemanalizleriList = function() {
  return /** @type{!Array<!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto, 1));
};


/**
 * @param {!Array<!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto>} value
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse} returns this
*/
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.prototype.setIslemanalizleriList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto}
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.prototype.addIslemanalizleri = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.IslemAnaliziDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse} returns this
 */
proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.prototype.clearIslemanalizleriList = function() {
  return this.setIslemanalizleriList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.TaniIslemAnalizi.BolumGetirResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaniIslemAnalizi.BolumGetirResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TaniIslemAnalizi.BolumGetirResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaniIslemAnalizi.BolumGetirResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaniIslemAnalizi.BolumGetirResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bolumList: jspb.Message.toObjectList(msg.getBolumList(),
    proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaniIslemAnalizi.BolumGetirResponse}
 */
proto.TaniIslemAnalizi.BolumGetirResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaniIslemAnalizi.BolumGetirResponse;
  return proto.TaniIslemAnalizi.BolumGetirResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaniIslemAnalizi.BolumGetirResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaniIslemAnalizi.BolumGetirResponse}
 */
proto.TaniIslemAnalizi.BolumGetirResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto;
      reader.readMessage(value,proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto.deserializeBinaryFromReader);
      msg.addBolum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaniIslemAnalizi.BolumGetirResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaniIslemAnalizi.BolumGetirResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaniIslemAnalizi.BolumGetirResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaniIslemAnalizi.BolumGetirResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBolumList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto.prototype.toObject = function(opt_includeInstance) {
  return proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    adi: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto}
 */
proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto;
  return proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto}
 */
proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 ID = 1;
 * @return {number}
 */
proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto} returns this
 */
proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Adi = 2;
 * @return {string}
 */
proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto} returns this
 */
proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated BolumDto Bolum = 1;
 * @return {!Array<!proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto>}
 */
proto.TaniIslemAnalizi.BolumGetirResponse.prototype.getBolumList = function() {
  return /** @type{!Array<!proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto, 1));
};


/**
 * @param {!Array<!proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto>} value
 * @return {!proto.TaniIslemAnalizi.BolumGetirResponse} returns this
*/
proto.TaniIslemAnalizi.BolumGetirResponse.prototype.setBolumList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto}
 */
proto.TaniIslemAnalizi.BolumGetirResponse.prototype.addBolum = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.TaniIslemAnalizi.BolumGetirResponse.BolumDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.TaniIslemAnalizi.BolumGetirResponse} returns this
 */
proto.TaniIslemAnalizi.BolumGetirResponse.prototype.clearBolumList = function() {
  return this.setBolumList([]);
};


goog.object.extend(exports, proto.TaniIslemAnalizi);
