import { YetkiTanimlariClient } from "../../protos/generalcontent/yetki_tanimlari_grpc_web_pb.js";
import { Empty } from "google-protobuf/google/protobuf/empty_pb";
import {
    GetSurecTanimPermissionsBySurecTanimIdRequest,
    AddSurecTanimPermissionRequest,
    EditSurecTanimPermissionRequest,
    DeleteSurecTanimPermissionRequest,
    GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest,
    AddHazirRaporPermissionRequest,
    EditHazirRaporPermissionRequest,
    DeleteHazirRaporPermissionRequest
} from "src/protos/generalcontent/yetki_tanimlari_pb.js";

class YetkiTanimlariService {

    getAllRoles = () => {
        return new Promise((resolve, reject) => {
            const client = new YetkiTanimlariClient(process.env.REACT_APP_API_BASE_URL);
            var token = localStorage.getItem('accessToken');

            const request = new Empty();

            client.getAllRoles(request,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });

        });
    }

    getSurecTanimPermissionsBySurecTanimId = (surecTanimId) => {
        return new Promise((resolve, reject) => {
            const client = new YetkiTanimlariClient(process.env.REACT_APP_API_BASE_URL);
            var token = localStorage.getItem('accessToken');

            const request = new GetSurecTanimPermissionsBySurecTanimIdRequest();
            request.setSurectanimid(surecTanimId);

            client.getSurecTanimPermissionsBySurecTanimId(request,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });

        });
    }

    addSurecTanimPermission = (input) => {
        return new Promise((resolve, reject) => {
            const client = new YetkiTanimlariClient(process.env.REACT_APP_API_BASE_URL);
            var token = localStorage.getItem('accessToken');

            const request = new AddSurecTanimPermissionRequest();
            request.setUserid(input.userId);
            request.setGoList(input.goList);
            request.setSurectanimid(input.surecTanimId);

            client.addSurecTanimPermission(request,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }

    editSurecTanimPermission = (userId, input) => {
        return new Promise((resolve, reject) => {
            const client = new YetkiTanimlariClient(process.env.REACT_APP_API_BASE_URL);
            var token = localStorage.getItem('accessToken');

            const request = new EditSurecTanimPermissionRequest();
            request.setId(input.id);
            request.setUserid(userId);
            request.setYetkivarmi(input.yetkivarmi);
            request.setKayitdurumu(input.kayitdurumu);

            client.editSurecTanimPermission(request,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }

    deleteSurecTanimPermission = (surecTanimId) => {
        return new Promise((resolve, reject) => {
            const client = new YetkiTanimlariClient(process.env.REACT_APP_API_BASE_URL);
            var token = localStorage.getItem('accessToken');

            const request = new DeleteSurecTanimPermissionRequest();
            request.setId(surecTanimId);

            client.deleteSurecTanimPermission(request,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }

    getHazirRaporTanimPermissionsByHazirRaporTanimId = (hazirRaporTanimId) => {
        return new Promise((resolve, reject) => {
            const client = new YetkiTanimlariClient(process.env.REACT_APP_API_BASE_URL);
            var token = localStorage.getItem('accessToken');

            const request = new GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest();
            request.setHazirraportanimid(hazirRaporTanimId);

            client.getHazirRaporTanimPermissionsByHazirRaporTanimId(request,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });

        });
    }

    addHazirRaporPermission = (input) => {
        return new Promise((resolve, reject) => {
            const client = new YetkiTanimlariClient(process.env.REACT_APP_API_BASE_URL);
            var token = localStorage.getItem('accessToken');

            const request = new AddHazirRaporPermissionRequest();
            request.setUserid(input.userId);
            request.setGoList(input.goList);
            request.setHazirraportanimid(input.hazirRaporTanimId);

            client.addHazirRaporPermission(request,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }

    editHazirRaporPermission = (userId, input) => {
        return new Promise((resolve, reject) => {
            const client = new YetkiTanimlariClient(process.env.REACT_APP_API_BASE_URL);
            var token = localStorage.getItem('accessToken');

            const request = new EditHazirRaporPermissionRequest();
            request.setId(input.id);
            request.setUserid(userId);
            request.setEklemeyetkisivarmi(input.eklemeyetkisivarmi);
            request.setGormeyetkisivarmi(input.gormeyetkisivarmi);
            request.setGorevyetkisivarmi(input.gorevyetkisivarmi);
            request.setIndirmeyetkisivarmi(input.indirmeyetkisivarmi);
            request.setKayitdurumu(input.kayitdurumu);

            client.editHazirRaporPermission(request,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }

    deleteHazirRaporPermission = (hazirRaporTanimId) => {
        return new Promise((resolve, reject) => {
            const client = new YetkiTanimlariClient(process.env.REACT_APP_API_BASE_URL);
            var token = localStorage.getItem('accessToken');

            const request = new DeleteHazirRaporPermissionRequest();
            request.setId(hazirRaporTanimId);

            client.deleteHazirRaporPermission(request,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }
}

const yetkiTanimlariService = new YetkiTanimlariService();

export default yetkiTanimlariService;