/**
 * @fileoverview gRPC-Web generated client stub for YatakDolulukOranlari
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.YatakDolulukOranlari = require('./yatak_doluluk_oranlari_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse>}
 */
const methodDescriptor_YatakDolulukOranlari_DonemTanimlariniGetir = new grpc.web.MethodDescriptor(
  '/YatakDolulukOranlari.YatakDolulukOranlari/DonemTanimlariniGetir',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse>}
 */
const methodInfo_YatakDolulukOranlari_DonemTanimlariniGetir = new grpc.web.AbstractClientBase.MethodInfo(
  proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariClient.prototype.donemTanimlariniGetir =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/YatakDolulukOranlari.YatakDolulukOranlari/DonemTanimlariniGetir',
      request,
      metadata || {},
      methodDescriptor_YatakDolulukOranlari_DonemTanimlariniGetir,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.YatakDolulukOranlari.DonemTanimlariniGetirResponse>}
 *     Promise that resolves to the response
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariPromiseClient.prototype.donemTanimlariniGetir =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/YatakDolulukOranlari.YatakDolulukOranlari/DonemTanimlariniGetir',
      request,
      metadata || {},
      methodDescriptor_YatakDolulukOranlari_DonemTanimlariniGetir);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.YatakDolulukOranlari.KurumlariGetirResponse>}
 */
const methodDescriptor_YatakDolulukOranlari_KurumlariGetir = new grpc.web.MethodDescriptor(
  '/YatakDolulukOranlari.YatakDolulukOranlari/KurumlariGetir',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.YatakDolulukOranlari.KurumlariGetirResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YatakDolulukOranlari.KurumlariGetirResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.YatakDolulukOranlari.KurumlariGetirResponse>}
 */
const methodInfo_YatakDolulukOranlari_KurumlariGetir = new grpc.web.AbstractClientBase.MethodInfo(
  proto.YatakDolulukOranlari.KurumlariGetirResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YatakDolulukOranlari.KurumlariGetirResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.YatakDolulukOranlari.KurumlariGetirResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.YatakDolulukOranlari.KurumlariGetirResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariClient.prototype.kurumlariGetir =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/YatakDolulukOranlari.YatakDolulukOranlari/KurumlariGetir',
      request,
      metadata || {},
      methodDescriptor_YatakDolulukOranlari_KurumlariGetir,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.YatakDolulukOranlari.KurumlariGetirResponse>}
 *     Promise that resolves to the response
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariPromiseClient.prototype.kurumlariGetir =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/YatakDolulukOranlari.YatakDolulukOranlari/KurumlariGetir',
      request,
      metadata || {},
      methodDescriptor_YatakDolulukOranlari_KurumlariGetir);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest,
 *   !proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse>}
 */
const methodDescriptor_YatakDolulukOranlari_YatakDolulukOranlariniGetir = new grpc.web.MethodDescriptor(
  '/YatakDolulukOranlari.YatakDolulukOranlari/YatakDolulukOranlariniGetir',
  grpc.web.MethodType.UNARY,
  proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest,
  proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse,
  /**
   * @param {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest,
 *   !proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse>}
 */
const methodInfo_YatakDolulukOranlari_YatakDolulukOranlariniGetir = new grpc.web.AbstractClientBase.MethodInfo(
  proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse,
  /**
   * @param {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse.deserializeBinary
);


/**
 * @param {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariClient.prototype.yatakDolulukOranlariniGetir =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/YatakDolulukOranlari.YatakDolulukOranlari/YatakDolulukOranlariniGetir',
      request,
      metadata || {},
      methodDescriptor_YatakDolulukOranlari_YatakDolulukOranlariniGetir,
      callback);
};


/**
 * @param {!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.YatakDolulukOranlari.YatakDolulukOranlariniGetirResponse>}
 *     Promise that resolves to the response
 */
proto.YatakDolulukOranlari.YatakDolulukOranlariPromiseClient.prototype.yatakDolulukOranlariniGetir =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/YatakDolulukOranlari.YatakDolulukOranlari/YatakDolulukOranlariniGetir',
      request,
      metadata || {},
      methodDescriptor_YatakDolulukOranlari_YatakDolulukOranlariniGetir);
};


module.exports = proto.YatakDolulukOranlari;

