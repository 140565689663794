/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.id;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem href={item.href} icon={item.icon} key={key} title={item.name} />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  desktopDrawer: {
    /*width: 256,*/
    top: 50,
    height: 'calc(100% - 64px)'
  },
  listSubHeaderRoot: {
    lineHeight: "34px",
    fontSize:"14px"
  }
}));

const NavBar = ({ sections }) => {
  const classes = useStyles();
  const { user } = useAuth();

  const [isDark, setIsDark] = useState(true);

  useEffect(() => {
    setIsDark((JSON.parse(localStorage.getItem("settings")).theme==='UNICORN'));
  },[]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
          <div style={{margin:"30px 0px 30px 45px", height:"19px"}}>
          <div style={{borderRadius: "15px",backgroundColor:(isDark)?"#1C2531":"#EFEFEF", width:"190px", height:"70px", position:"absolute", margin:"-17px"}}></div>
            <div style={{float:"left"}}>
            <Avatar alt="User" className={classes.avatar} src={"/static/doctor_icon.png"} />
            </div>
            <div style={{verticalAlign:"middle", height:"0px", position:"absolute", margin: "10px 0 0 60px", fontSize:"14px"}}>
              {user.adi + ' ' + user.soyadi}
            </div>
          </div>
        <div style={{clear:"both"}}>&nbsp;</div>
        <Divider />
        <Box p={2}>
          {sections.map(
            section =>
              section.items.length > 0 && (
                <List 
                  key={section.id}
                  subheader={
                    <ListSubheader disableGutters disableSticky classes={{root:classes.listSubHeaderRoot}}>
                      <img src={"/static/menu_icon.png"} width="14px"></img><span style={{color:(isDark)?"#FFF":"Black"}}> {section.name}</span>
                    </ListSubheader>
                  }
                >
                  {renderNavItems({
                    items: section.items
                    //pathname: location.pathname
                  })}
                </List>
              )
          )}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.desktopDrawer }}
      open
      variant="persistent"
    >
      {content}
    </Drawer>
  );
};

NavBar.propTypes = {};

export default NavBar;
