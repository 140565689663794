import React from 'react';
import { commonStyles } from 'src/config';
import {
  numberFormat,
  numberFormatForFloor
} from './../../../../../utils/numberFormat';
import { THEMES } from './../../../../../constants';

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

function gridCellData(gridData) {
  //console.log('gridCellData(cellData).value1',  gridData.data[gridData.column.caption.toLowerCase()])
  return gridData.data[gridData.column.dataField.toLowerCase()];
}

function gridCellDataPercent(gridData) {
  //console.log('gridCellData(cellData).value1',  gridData.data[gridData.column.caption.toLowerCase()])
  return parseFloat(gridData.data[gridData.column.dataField.toLowerCase()]) ? "%" + (parseFloat(gridData.data[gridData.column.dataField.toLowerCase()]) * 100).toFixed(0) : '';
}

function gridCellDataPercentV2(gridData) {
  //console.log('gridCellData(cellData).value1',  gridData.data[gridData.column.caption.toLowerCase()])
  return parseFloat(gridData.data[gridData.column.dataField.toLowerCase()]) ? "%" + (parseFloat(gridData.data[gridData.column.dataField.toLowerCase()]) * 100).toFixed(1) : '';
}

function DiscountCellHastaneCommonStyle1(cellData) {
  //console.log('gridCellData(cellData).value2', cellData)
  return (
    <div style={{ "textAlign": 'right', fontSize: THEMES.FONT_SIZE }}>{gridCellData(cellData)}</div>
  )
}

function DiscountCellHastaneCommonStyle2(cellData) {
  //console.log('gridCellData(cellData).value2', cellData)
  return (
    <div style={{ "textAlign": 'center', fontSize: THEMES.FONT_SIZE, }}>
      {numberWithCommas(gridCellDataPercent(cellData))}
    </div>
  )
}

function DiscountCellHastaneCommonStyle3(cellData) {
  //console.log('gridCellData(cellData).value2', cellData)
  return (
    <div style={{ "textAlign": 'center', fontSize: THEMES.FONT_SIZE, }}>{gridCellDataPercentV2(cellData)}</div>
  )
}

function DiscountCellHastaneCommonStyle4(cellData) {
  //console.log('gridCellData(cellData).value2', cellData)
  return (
    <div style={{ "textAlign": 'right', fontSize: THEMES.FONT_SIZE, }}>
      {gridCellData(cellData) !== ''
        ? numberWithCommas(parseFloat(gridCellData(cellData)).toFixed(0))
        : ''}</div>
  )
}

function RenderCellGerceklesmeButce(cellData) {
  return (
    <div style={{
      "textAlign": 'right',
      "color": '#7FAEFF',
      fontSize: THEMES.FONT_SIZE
    }}>{numberFormat(gridCellData(cellData))}</div>
  )
}

function RenderCellGerceklesmeGidisat(cellData) {
  return (
    <div style={{
      "textAlign": 'right',
      "color": '#F14693',
      fontSize: THEMES.FONT_SIZE
    }}>{numberFormat(gridCellData(cellData))}</div>
  )
}

function RenderCellGerceklesmeCiro(cellData) {
  return (
    <div style={{
      "textAlign": 'right',
      "color": '#85B627',
      fontSize: THEMES.FONT_SIZE
    }}>{numberWithCommas(parseFloat(gridCellData(cellData)).toFixed(0))}</div>
  )
}



function DiscountCellHastaneGunlukDegisim(cellData) {

  let imgRtrn = "";
  if (parseFloat(cellData.data.gunlukdegisim) > 0.00) {
    imgRtrn = <img src={require('./imgs/ok_yukari_16.png')} alt="ok_yukari_16"></img>;
  }
  else if (parseFloat(cellData.data.gunlukdegisim) < 0.00) {
    imgRtrn = <img src={require('./imgs/ok_asagi_16.png')} alt="ok_asagi_16"></img>;
  }
  else {
    imgRtrn = "";
  }

  return (
    <div style={{ "textAlign": 'center', fontSize: THEMES.FONT_SIZE, }}>
      {imgRtrn}{parseFloat(cellData.data.gunlukdegisim) ? "%" + (parseFloat(cellData.data.gunlukdegisim) * 100).toFixed(0) : ""}
    </div>
  );
}

// function customizeTooltip(data) {
//   return {
//     text: `${parseInt(data.value)}%`
//   };
// }

function DiscountCellGrup(cellData) {

  return (
    <div>test123{cellData.value}-</div>
  );
}

//Genel bir fonksiyon yapılmalı
function DiscountCellGelisTuruEndekDegisimi(cellData) {

  let imgRtrn = "";
  return (
    <div style={{ "textAlign": 'center', fontSize: THEMES.FONT_SIZE, }}>
      {imgRtrn}{parseFloat(cellData.data.endeksdegisimi) ? "%" + (parseFloat(cellData.data.endeksdegisimi) * 100).toFixed(0) : ""}
    </div>
  );
}

//Genel bir fonksiyon yapılmalı
function DiscountCellGelisTuruButceGerceklesmeOrani(cellData) {

  let imgRtrn = "";
  return (
    <div style={{ "textAlign": 'center', fontSize: THEMES.FONT_SIZE, }}>
      {imgRtrn}{parseFloat(cellData.data.butcegerceklesmeorani) ? "%" + (parseFloat(cellData.data.butcegerceklesmeorani) * 100).toFixed(0) : ""}
    </div>
  );
}

function DiscountCellGelisTuruGelisTuru(cellData) {

  let imgRtrn = "";

  if (cellData.data.gelisturu == "Ayaktan") {
    imgRtrn = <div style={{ "textAlign": "left", "fontSize": THEMES.TITLE_FONT_SIZE}}>
      <img src={'/static/images/icons/baseline_nordic_walking_black_18dp.png'} alt="Geliş Türü (Ayaktan)" title="Geliş Türü (Ayaktan)"></img>
                  &nbsp; {cellData.data.gelisturu}
    </div>
  } else {
    imgRtrn = <div style={{ "textAlign": "left", "fontSize": THEMES.TITLE_FONT_SIZE }}>
      <img src={'/static/images/icons/baseline_hotel_black_18dp.png'} alt="Geliş Türü (Yatan)" title="Geliş Türü (Yatan)"></img>
                &nbsp; {cellData.data.gelisturu}
    </div>
  }
  return imgRtrn;
}


function renderTitleHeader(data) {
  return <div style={{ "fontSize": THEMES.TITLE_FONT_SIZE }}>{data.column.caption}</div>;
}

export {
  DiscountCellHastaneCommonStyle4,
  DiscountCellHastaneCommonStyle3,
  DiscountCellHastaneCommonStyle2,
  DiscountCellHastaneCommonStyle1,
  DiscountCellHastaneGunlukDegisim,
  DiscountCellGrup,
  DiscountCellGelisTuruEndekDegisimi,
  DiscountCellGelisTuruButceGerceklesmeOrani,
  DiscountCellGelisTuruGelisTuru,
  renderTitleHeader,
  RenderCellGerceklesmeCiro,
  RenderCellGerceklesmeButce,
  RenderCellGerceklesmeGidisat
};