import { createSlice } from '@reduxjs/toolkit'
import GCFortheUIService from "../services/generalcontent/GCFortheUIService";

export const GET_HOSPITAL_LIST = "@general-content/hospitallist";

const initialState = {
  hospitalList: [],
  donemTanimlari: [],
  merkeziBolumler: []
}

const generalContentSlice = createSlice({
  name: 'generalContent',
  initialState,
  reducers: {
    generalContentReducer(state, action) {
      const hospitalList = action.payload;
      state.hospitalList = hospitalList;
    },
    getDonemTanimlari(state, action) {
      const donemTanimlari = action.payload;
      state.donemTanimlari = donemTanimlari;
    },
    getMerkeziBolumler(state, action) {
      const merkeziBolumler = action.payload;
      state.merkeziBolumler = merkeziBolumler;
    }
  },
})

export const reducer = generalContentSlice.reducer

const { generalContentReducer } = generalContentSlice.actions

export const getHospitalListAction = () => async dispatch => {
  GCFortheUIService.getHospitalListFTUI().then((response) => {
    dispatch(generalContentReducer(response.hospitallistftuiList));
  })
}

export const getDonemTanimlari = () => async dispatch => {
  GCFortheUIService.getDonemTanimlari().then((response) => {
    dispatch(generalContentSlice.actions.getDonemTanimlari(response.donemtanimlariList));
  })
}

export const getMerkeziBolumler = () => async dispatch => {
  GCFortheUIService.getMerkeziBolumler().then((response) => {
    dispatch(generalContentSlice.actions.getMerkeziBolumler(response.merkezibolumlerList));
  })
}