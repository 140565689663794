import { createSlice } from '@reduxjs/toolkit'
import surecTanimService from "../services/is-zekasi/rapor-tanimlari/surec-tanimlari/surecTanimService";
import { trackPromise } from 'react-promise-tracker';
var _ = require('lodash');

const initialState = {
  surecTanimlari: [],
  status: false,
  // adi : null,
  // tanimi : null,
  // kayitdurumu : null,
  // kurumid : null,
  // ustSurec : null,
  surecTanim : {}
}

const surecTanimSlice = createSlice({
  name: 'surecTanim',
  initialState,
  reducers: {
    surecTanimGetirReducer(state, action) {
        const surecTanimlari = action.payload;
        state.surecTanimlari = surecTanimlari;
    },
    setSurecTanimEkleReducer(state, action) {
      const status = action.payload;
      state.status = status;
    },
    setSurecTanimGuncelleReducer(state, action) {
      const surecTanim = action.payload;
      state.surecTanim = surecTanim;
    },
    getSurecTanimGetirReducer(state, action) {

      state.surecTanim =  Object.assign({}, action.payload);
    },
  },
})

export const reducer = surecTanimSlice.reducer

const { surecTanimGetirReducer,
        setSurecTanimEkleReducer,
        setSurecTanimGuncelleReducer,
        getSurecTanimGetirReducer
      } = surecTanimSlice.actions


export const getSurecTanimlariGetir = (personelID)  => async dispatch =>  {
  const response = await  trackPromise(surecTanimService.getSurecAgaci(personelID));
  dispatch(surecTanimGetirReducer(response.surecagaciList));
}

export const setSurecTanimEkle = (KurumID, Adi, Tanimi, KayitDurumu, UstTanimId)  => async dispatch =>  {
  const response = await  trackPromise(surecTanimService.setSurecTanimEkle(KurumID, Adi, Tanimi, KayitDurumu, UstTanimId));
  dispatch(setSurecTanimEkleReducer(response.status));
}

export const setSurecTanimGuncelle = (Id, Adi, Tanimi, KayitDurumu, UstTanimId)  => async dispatch =>  {
  const response = await  trackPromise(surecTanimService.setSurecTanimGuncelle(Id, Adi, Tanimi, KayitDurumu, UstTanimId));
  dispatch(setSurecTanimGuncelleReducer(response.status));
}

export const getSurecTanimGetir = (ID)  => async dispatch =>  {
  const response = await  trackPromise(surecTanimService.getSurecTanimGetir(ID));
  dispatch(getSurecTanimGetirReducer(response));
}