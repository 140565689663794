/* eslint-disable */
//@ts-nocheck

/**
 * @fileoverview gRPC-Web generated client stub for GunlukCiraRaporu
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.GunlukCiraRaporu = require('./gunluk_cira_raporu_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.GunlukCiraRaporu.GetHospitalListResponse>}
 */
const methodDescriptor_GunlukCiraRaporu_GetHospitalList = new grpc.web.MethodDescriptor(
  '/GunlukCiraRaporu.GunlukCiraRaporu/GetHospitalList',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.GunlukCiraRaporu.GetHospitalListResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GunlukCiraRaporu.GetHospitalListResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.GunlukCiraRaporu.GetHospitalListResponse>}
 */
const methodInfo_GunlukCiraRaporu_GetHospitalList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GunlukCiraRaporu.GetHospitalListResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GunlukCiraRaporu.GetHospitalListResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GunlukCiraRaporu.GetHospitalListResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GunlukCiraRaporu.GetHospitalListResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuClient.prototype.getHospitalList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/GunlukCiraRaporu.GunlukCiraRaporu/GetHospitalList',
      request,
      metadata || {},
      methodDescriptor_GunlukCiraRaporu_GetHospitalList,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GunlukCiraRaporu.GetHospitalListResponse>}
 *     Promise that resolves to the response
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuPromiseClient.prototype.getHospitalList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/GunlukCiraRaporu.GunlukCiraRaporu/GetHospitalList',
      request,
      metadata || {},
      methodDescriptor_GunlukCiraRaporu_GetHospitalList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest,
 *   !proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse>}
 */
const methodDescriptor_GunlukCiraRaporu_GetGunlukCiraRaporuByHospitals = new grpc.web.MethodDescriptor(
  '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiraRaporuByHospitals',
  grpc.web.MethodType.UNARY,
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest,
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse,
  /**
   * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest,
 *   !proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse>}
 */
const methodInfo_GunlukCiraRaporu_GetGunlukCiraRaporuByHospitals = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse,
  /**
   * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.deserializeBinary
);


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuClient.prototype.getGunlukCiraRaporuByHospitals =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiraRaporuByHospitals',
      request,
      metadata || {},
      methodDescriptor_GunlukCiraRaporu_GetGunlukCiraRaporuByHospitals,
      callback);
};


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse>}
 *     Promise that resolves to the response
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuPromiseClient.prototype.getGunlukCiraRaporuByHospitals =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiraRaporuByHospitals',
      request,
      metadata || {},
      methodDescriptor_GunlukCiraRaporu_GetGunlukCiraRaporuByHospitals);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest,
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse>}
 */
const methodDescriptor_GunlukCiraRaporu_GetGunlukCiroRaporuByGroup = new grpc.web.MethodDescriptor(
  '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiroRaporuByGroup',
  grpc.web.MethodType.UNARY,
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest,
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse,
  /**
   * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest,
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse>}
 */
const methodInfo_GunlukCiraRaporu_GetGunlukCiroRaporuByGroup = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse,
  /**
   * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.deserializeBinary
);


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuClient.prototype.getGunlukCiroRaporuByGroup =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiroRaporuByGroup',
      request,
      metadata || {},
      methodDescriptor_GunlukCiraRaporu_GetGunlukCiroRaporuByGroup,
      callback);
};


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse>}
 *     Promise that resolves to the response
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuPromiseClient.prototype.getGunlukCiroRaporuByGroup =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiroRaporuByGroup',
      request,
      metadata || {},
      methodDescriptor_GunlukCiraRaporu_GetGunlukCiroRaporuByGroup);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest,
 *   !proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse>}
 */
const methodDescriptor_GunlukCiraRaporu_GetGunlukCiraRaporuByGelisTuruDetay = new grpc.web.MethodDescriptor(
  '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiraRaporuByGelisTuruDetay',
  grpc.web.MethodType.UNARY,
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest,
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse,
  /**
   * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest,
 *   !proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse>}
 */
const methodInfo_GunlukCiraRaporu_GetGunlukCiraRaporuByGelisTuruDetay = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse,
  /**
   * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.deserializeBinary
);


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuClient.prototype.getGunlukCiraRaporuByGelisTuruDetay =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiraRaporuByGelisTuruDetay',
      request,
      metadata || {},
      methodDescriptor_GunlukCiraRaporu_GetGunlukCiraRaporuByGelisTuruDetay,
      callback);
};


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse>}
 *     Promise that resolves to the response
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuPromiseClient.prototype.getGunlukCiraRaporuByGelisTuruDetay =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiraRaporuByGelisTuruDetay',
      request,
      metadata || {},
      methodDescriptor_GunlukCiraRaporu_GetGunlukCiraRaporuByGelisTuruDetay);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest,
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse>}
 */
const methodDescriptor_GunlukCiraRaporu_GetGunlukCiroRaporuByHizmetDetayi = new grpc.web.MethodDescriptor(
  '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiroRaporuByHizmetDetayi',
  grpc.web.MethodType.UNARY,
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest,
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse,
  /**
   * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest,
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse>}
 */
const methodInfo_GunlukCiraRaporu_GetGunlukCiroRaporuByHizmetDetayi = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse,
  /**
   * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.deserializeBinary
);


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuClient.prototype.getGunlukCiroRaporuByHizmetDetayi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiroRaporuByHizmetDetayi',
      request,
      metadata || {},
      methodDescriptor_GunlukCiraRaporu_GetGunlukCiroRaporuByHizmetDetayi,
      callback);
};


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse>}
 *     Promise that resolves to the response
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuPromiseClient.prototype.getGunlukCiroRaporuByHizmetDetayi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiroRaporuByHizmetDetayi',
      request,
      metadata || {},
      methodDescriptor_GunlukCiraRaporu_GetGunlukCiroRaporuByHizmetDetayi);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest,
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse>}
 */
const methodDescriptor_GunlukCiraRaporu_GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosu = new grpc.web.MethodDescriptor(
  '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosu',
  grpc.web.MethodType.UNARY,
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest,
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse,
  /**
   * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest,
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse>}
 */
const methodInfo_GunlukCiraRaporu_GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosu = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse,
  /**
   * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.deserializeBinary
);


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuClient.prototype.getGunlukCiroRaporuButceTakibiEndeksHizmetCirosu =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosu',
      request,
      metadata || {},
      methodDescriptor_GunlukCiraRaporu_GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosu,
      callback);
};


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse>}
 *     Promise that resolves to the response
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuPromiseClient.prototype.getGunlukCiroRaporuButceTakibiEndeksHizmetCirosu =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosu',
      request,
      metadata || {},
      methodDescriptor_GunlukCiraRaporu_GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosu);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest,
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse>}
 */
const methodDescriptor_GunlukCiraRaporu_GetGunlukCiroRaporuByOdeyiciDetayi = new grpc.web.MethodDescriptor(
  '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiroRaporuByOdeyiciDetayi',
  grpc.web.MethodType.UNARY,
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest,
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse,
  /**
   * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest,
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse>}
 */
const methodInfo_GunlukCiraRaporu_GetGunlukCiroRaporuByOdeyiciDetayi = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse,
  /**
   * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.deserializeBinary
);


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuClient.prototype.getGunlukCiroRaporuByOdeyiciDetayi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiroRaporuByOdeyiciDetayi',
      request,
      metadata || {},
      methodDescriptor_GunlukCiraRaporu_GetGunlukCiroRaporuByOdeyiciDetayi,
      callback);
};


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse>}
 *     Promise that resolves to the response
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuPromiseClient.prototype.getGunlukCiroRaporuByOdeyiciDetayi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiroRaporuByOdeyiciDetayi',
      request,
      metadata || {},
      methodDescriptor_GunlukCiraRaporu_GetGunlukCiroRaporuByOdeyiciDetayi);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest,
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse>}
 */
const methodDescriptor_GunlukCiraRaporu_GetGunlukCiroRaporuByOdeyiciDetayiGrafik = new grpc.web.MethodDescriptor(
  '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiroRaporuByOdeyiciDetayiGrafik',
  grpc.web.MethodType.UNARY,
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest,
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse,
  /**
   * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest,
 *   !proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse>}
 */
const methodInfo_GunlukCiraRaporu_GetGunlukCiroRaporuByOdeyiciDetayiGrafik = new grpc.web.AbstractClientBase.MethodInfo(
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse,
  /**
   * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.deserializeBinary
);


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuClient.prototype.getGunlukCiroRaporuByOdeyiciDetayiGrafik =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiroRaporuByOdeyiciDetayiGrafik',
      request,
      metadata || {},
      methodDescriptor_GunlukCiraRaporu_GetGunlukCiroRaporuByOdeyiciDetayiGrafik,
      callback);
};


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse>}
 *     Promise that resolves to the response
 */
proto.GunlukCiraRaporu.GunlukCiraRaporuPromiseClient.prototype.getGunlukCiroRaporuByOdeyiciDetayiGrafik =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/GunlukCiraRaporu.GunlukCiraRaporu/GetGunlukCiroRaporuByOdeyiciDetayiGrafik',
      request,
      metadata || {},
      methodDescriptor_GunlukCiraRaporu_GetGunlukCiroRaporuByOdeyiciDetayiGrafik);
};


module.exports = proto.GunlukCiraRaporu;

