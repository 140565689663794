/* eslint-disable */
//@ts-nocheck

/**
 * @fileoverview gRPC-Web generated client stub for YetkiTanimlari
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.YetkiTanimlari = require('./yetki_tanimlari_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.YetkiTanimlari.YetkiTanimlariClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.YetkiTanimlari.YetkiTanimlariPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.YetkiTanimlari.GetAllRolesResponse>}
 */
const methodDescriptor_YetkiTanimlari_GetAllRoles = new grpc.web.MethodDescriptor(
  '/YetkiTanimlari.YetkiTanimlari/GetAllRoles',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.YetkiTanimlari.GetAllRolesResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.GetAllRolesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.YetkiTanimlari.GetAllRolesResponse>}
 */
const methodInfo_YetkiTanimlari_GetAllRoles = new grpc.web.AbstractClientBase.MethodInfo(
  proto.YetkiTanimlari.GetAllRolesResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.GetAllRolesResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.YetkiTanimlari.GetAllRolesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.YetkiTanimlari.GetAllRolesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.YetkiTanimlari.YetkiTanimlariClient.prototype.getAllRoles =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/GetAllRoles',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_GetAllRoles,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.YetkiTanimlari.GetAllRolesResponse>}
 *     Promise that resolves to the response
 */
proto.YetkiTanimlari.YetkiTanimlariPromiseClient.prototype.getAllRoles =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/GetAllRoles',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_GetAllRoles);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest,
 *   !proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse>}
 */
const methodDescriptor_YetkiTanimlari_GetSurecTanimPermissionsBySurecTanimId = new grpc.web.MethodDescriptor(
  '/YetkiTanimlari.YetkiTanimlari/GetSurecTanimPermissionsBySurecTanimId',
  grpc.web.MethodType.UNARY,
  proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest,
  proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse,
  /**
   * @param {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest,
 *   !proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse>}
 */
const methodInfo_YetkiTanimlari_GetSurecTanimPermissionsBySurecTanimId = new grpc.web.AbstractClientBase.MethodInfo(
  proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse,
  /**
   * @param {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse.deserializeBinary
);


/**
 * @param {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.YetkiTanimlari.YetkiTanimlariClient.prototype.getSurecTanimPermissionsBySurecTanimId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/GetSurecTanimPermissionsBySurecTanimId',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_GetSurecTanimPermissionsBySurecTanimId,
      callback);
};


/**
 * @param {!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.YetkiTanimlari.GetSurecTanimPermissionsBySurecTanimIdResponse>}
 *     Promise that resolves to the response
 */
proto.YetkiTanimlari.YetkiTanimlariPromiseClient.prototype.getSurecTanimPermissionsBySurecTanimId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/GetSurecTanimPermissionsBySurecTanimId',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_GetSurecTanimPermissionsBySurecTanimId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.YetkiTanimlari.AddSurecTanimPermissionRequest,
 *   !proto.YetkiTanimlari.AddSurecTanimPermissionResponse>}
 */
const methodDescriptor_YetkiTanimlari_AddSurecTanimPermission = new grpc.web.MethodDescriptor(
  '/YetkiTanimlari.YetkiTanimlari/AddSurecTanimPermission',
  grpc.web.MethodType.UNARY,
  proto.YetkiTanimlari.AddSurecTanimPermissionRequest,
  proto.YetkiTanimlari.AddSurecTanimPermissionResponse,
  /**
   * @param {!proto.YetkiTanimlari.AddSurecTanimPermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.AddSurecTanimPermissionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.YetkiTanimlari.AddSurecTanimPermissionRequest,
 *   !proto.YetkiTanimlari.AddSurecTanimPermissionResponse>}
 */
const methodInfo_YetkiTanimlari_AddSurecTanimPermission = new grpc.web.AbstractClientBase.MethodInfo(
  proto.YetkiTanimlari.AddSurecTanimPermissionResponse,
  /**
   * @param {!proto.YetkiTanimlari.AddSurecTanimPermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.AddSurecTanimPermissionResponse.deserializeBinary
);


/**
 * @param {!proto.YetkiTanimlari.AddSurecTanimPermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.YetkiTanimlari.AddSurecTanimPermissionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.YetkiTanimlari.AddSurecTanimPermissionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.YetkiTanimlari.YetkiTanimlariClient.prototype.addSurecTanimPermission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/AddSurecTanimPermission',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_AddSurecTanimPermission,
      callback);
};


/**
 * @param {!proto.YetkiTanimlari.AddSurecTanimPermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.YetkiTanimlari.AddSurecTanimPermissionResponse>}
 *     Promise that resolves to the response
 */
proto.YetkiTanimlari.YetkiTanimlariPromiseClient.prototype.addSurecTanimPermission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/AddSurecTanimPermission',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_AddSurecTanimPermission);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.YetkiTanimlari.EditSurecTanimPermissionRequest,
 *   !proto.YetkiTanimlari.EditSurecTanimPermissionResponse>}
 */
const methodDescriptor_YetkiTanimlari_EditSurecTanimPermission = new grpc.web.MethodDescriptor(
  '/YetkiTanimlari.YetkiTanimlari/EditSurecTanimPermission',
  grpc.web.MethodType.UNARY,
  proto.YetkiTanimlari.EditSurecTanimPermissionRequest,
  proto.YetkiTanimlari.EditSurecTanimPermissionResponse,
  /**
   * @param {!proto.YetkiTanimlari.EditSurecTanimPermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.EditSurecTanimPermissionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.YetkiTanimlari.EditSurecTanimPermissionRequest,
 *   !proto.YetkiTanimlari.EditSurecTanimPermissionResponse>}
 */
const methodInfo_YetkiTanimlari_EditSurecTanimPermission = new grpc.web.AbstractClientBase.MethodInfo(
  proto.YetkiTanimlari.EditSurecTanimPermissionResponse,
  /**
   * @param {!proto.YetkiTanimlari.EditSurecTanimPermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.EditSurecTanimPermissionResponse.deserializeBinary
);


/**
 * @param {!proto.YetkiTanimlari.EditSurecTanimPermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.YetkiTanimlari.EditSurecTanimPermissionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.YetkiTanimlari.EditSurecTanimPermissionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.YetkiTanimlari.YetkiTanimlariClient.prototype.editSurecTanimPermission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/EditSurecTanimPermission',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_EditSurecTanimPermission,
      callback);
};


/**
 * @param {!proto.YetkiTanimlari.EditSurecTanimPermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.YetkiTanimlari.EditSurecTanimPermissionResponse>}
 *     Promise that resolves to the response
 */
proto.YetkiTanimlari.YetkiTanimlariPromiseClient.prototype.editSurecTanimPermission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/EditSurecTanimPermission',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_EditSurecTanimPermission);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest,
 *   !proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse>}
 */
const methodDescriptor_YetkiTanimlari_DeleteSurecTanimPermission = new grpc.web.MethodDescriptor(
  '/YetkiTanimlari.YetkiTanimlari/DeleteSurecTanimPermission',
  grpc.web.MethodType.UNARY,
  proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest,
  proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse,
  /**
   * @param {!proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest,
 *   !proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse>}
 */
const methodInfo_YetkiTanimlari_DeleteSurecTanimPermission = new grpc.web.AbstractClientBase.MethodInfo(
  proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse,
  /**
   * @param {!proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse.deserializeBinary
);


/**
 * @param {!proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.YetkiTanimlari.YetkiTanimlariClient.prototype.deleteSurecTanimPermission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/DeleteSurecTanimPermission',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_DeleteSurecTanimPermission,
      callback);
};


/**
 * @param {!proto.YetkiTanimlari.DeleteSurecTanimPermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.YetkiTanimlari.DeleteSurecTanimPermissionResponse>}
 *     Promise that resolves to the response
 */
proto.YetkiTanimlari.YetkiTanimlariPromiseClient.prototype.deleteSurecTanimPermission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/DeleteSurecTanimPermission',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_DeleteSurecTanimPermission);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest,
 *   !proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse>}
 */
const methodDescriptor_YetkiTanimlari_GetHazirRaporTanimPermissionsByHazirRaporTanimId = new grpc.web.MethodDescriptor(
  '/YetkiTanimlari.YetkiTanimlari/GetHazirRaporTanimPermissionsByHazirRaporTanimId',
  grpc.web.MethodType.UNARY,
  proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest,
  proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse,
  /**
   * @param {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest,
 *   !proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse>}
 */
const methodInfo_YetkiTanimlari_GetHazirRaporTanimPermissionsByHazirRaporTanimId = new grpc.web.AbstractClientBase.MethodInfo(
  proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse,
  /**
   * @param {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse.deserializeBinary
);


/**
 * @param {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.YetkiTanimlari.YetkiTanimlariClient.prototype.getHazirRaporTanimPermissionsByHazirRaporTanimId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/GetHazirRaporTanimPermissionsByHazirRaporTanimId',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_GetHazirRaporTanimPermissionsByHazirRaporTanimId,
      callback);
};


/**
 * @param {!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.YetkiTanimlari.GetHazirRaporTanimPermissionsByHazirRaporTanimIdResponse>}
 *     Promise that resolves to the response
 */
proto.YetkiTanimlari.YetkiTanimlariPromiseClient.prototype.getHazirRaporTanimPermissionsByHazirRaporTanimId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/GetHazirRaporTanimPermissionsByHazirRaporTanimId',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_GetHazirRaporTanimPermissionsByHazirRaporTanimId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.YetkiTanimlari.AddHazirRaporPermissionRequest,
 *   !proto.YetkiTanimlari.AddHazirRaporPermissionResponse>}
 */
const methodDescriptor_YetkiTanimlari_AddHazirRaporPermission = new grpc.web.MethodDescriptor(
  '/YetkiTanimlari.YetkiTanimlari/AddHazirRaporPermission',
  grpc.web.MethodType.UNARY,
  proto.YetkiTanimlari.AddHazirRaporPermissionRequest,
  proto.YetkiTanimlari.AddHazirRaporPermissionResponse,
  /**
   * @param {!proto.YetkiTanimlari.AddHazirRaporPermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.AddHazirRaporPermissionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.YetkiTanimlari.AddHazirRaporPermissionRequest,
 *   !proto.YetkiTanimlari.AddHazirRaporPermissionResponse>}
 */
const methodInfo_YetkiTanimlari_AddHazirRaporPermission = new grpc.web.AbstractClientBase.MethodInfo(
  proto.YetkiTanimlari.AddHazirRaporPermissionResponse,
  /**
   * @param {!proto.YetkiTanimlari.AddHazirRaporPermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.AddHazirRaporPermissionResponse.deserializeBinary
);


/**
 * @param {!proto.YetkiTanimlari.AddHazirRaporPermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.YetkiTanimlari.AddHazirRaporPermissionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.YetkiTanimlari.AddHazirRaporPermissionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.YetkiTanimlari.YetkiTanimlariClient.prototype.addHazirRaporPermission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/AddHazirRaporPermission',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_AddHazirRaporPermission,
      callback);
};


/**
 * @param {!proto.YetkiTanimlari.AddHazirRaporPermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.YetkiTanimlari.AddHazirRaporPermissionResponse>}
 *     Promise that resolves to the response
 */
proto.YetkiTanimlari.YetkiTanimlariPromiseClient.prototype.addHazirRaporPermission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/AddHazirRaporPermission',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_AddHazirRaporPermission);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.YetkiTanimlari.EditHazirRaporPermissionRequest,
 *   !proto.YetkiTanimlari.EditHazirRaporPermissionResponse>}
 */
const methodDescriptor_YetkiTanimlari_EditHazirRaporPermission = new grpc.web.MethodDescriptor(
  '/YetkiTanimlari.YetkiTanimlari/EditHazirRaporPermission',
  grpc.web.MethodType.UNARY,
  proto.YetkiTanimlari.EditHazirRaporPermissionRequest,
  proto.YetkiTanimlari.EditHazirRaporPermissionResponse,
  /**
   * @param {!proto.YetkiTanimlari.EditHazirRaporPermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.EditHazirRaporPermissionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.YetkiTanimlari.EditHazirRaporPermissionRequest,
 *   !proto.YetkiTanimlari.EditHazirRaporPermissionResponse>}
 */
const methodInfo_YetkiTanimlari_EditHazirRaporPermission = new grpc.web.AbstractClientBase.MethodInfo(
  proto.YetkiTanimlari.EditHazirRaporPermissionResponse,
  /**
   * @param {!proto.YetkiTanimlari.EditHazirRaporPermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.EditHazirRaporPermissionResponse.deserializeBinary
);


/**
 * @param {!proto.YetkiTanimlari.EditHazirRaporPermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.YetkiTanimlari.EditHazirRaporPermissionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.YetkiTanimlari.EditHazirRaporPermissionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.YetkiTanimlari.YetkiTanimlariClient.prototype.editHazirRaporPermission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/EditHazirRaporPermission',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_EditHazirRaporPermission,
      callback);
};


/**
 * @param {!proto.YetkiTanimlari.EditHazirRaporPermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.YetkiTanimlari.EditHazirRaporPermissionResponse>}
 *     Promise that resolves to the response
 */
proto.YetkiTanimlari.YetkiTanimlariPromiseClient.prototype.editHazirRaporPermission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/EditHazirRaporPermission',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_EditHazirRaporPermission);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest,
 *   !proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse>}
 */
const methodDescriptor_YetkiTanimlari_DeleteHazirRaporPermission = new grpc.web.MethodDescriptor(
  '/YetkiTanimlari.YetkiTanimlari/DeleteHazirRaporPermission',
  grpc.web.MethodType.UNARY,
  proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest,
  proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse,
  /**
   * @param {!proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest,
 *   !proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse>}
 */
const methodInfo_YetkiTanimlari_DeleteHazirRaporPermission = new grpc.web.AbstractClientBase.MethodInfo(
  proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse,
  /**
   * @param {!proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse.deserializeBinary
);


/**
 * @param {!proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.YetkiTanimlari.YetkiTanimlariClient.prototype.deleteHazirRaporPermission =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/DeleteHazirRaporPermission',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_DeleteHazirRaporPermission,
      callback);
};


/**
 * @param {!proto.YetkiTanimlari.DeleteHazirRaporPermissionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.YetkiTanimlari.DeleteHazirRaporPermissionResponse>}
 *     Promise that resolves to the response
 */
proto.YetkiTanimlari.YetkiTanimlariPromiseClient.prototype.deleteHazirRaporPermission =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/YetkiTanimlari.YetkiTanimlari/DeleteHazirRaporPermission',
      request,
      metadata || {},
      methodDescriptor_YetkiTanimlari_DeleteHazirRaporPermission);
};


module.exports = proto.YetkiTanimlari;

