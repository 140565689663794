/* eslint-disable */
//@ts-nocheck

/**
 * @fileoverview gRPC-Web generated client stub for RaporTanimlari
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.RaporTanimlari = require('./rapor_tanimlari_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.RaporTanimlari.RaporTanimlariClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.RaporTanimlari.RaporTanimlariPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest,
 *   !proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse>}
 */
const methodDescriptor_RaporTanimlari_GetHazirRaporTanimListBySurecTanimID = new grpc.web.MethodDescriptor(
  '/RaporTanimlari.RaporTanimlari/GetHazirRaporTanimListBySurecTanimID',
  grpc.web.MethodType.UNARY,
  proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest,
  proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse,
  /**
   * @param {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest,
 *   !proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse>}
 */
const methodInfo_RaporTanimlari_GetHazirRaporTanimListBySurecTanimID = new grpc.web.AbstractClientBase.MethodInfo(
  proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse,
  /**
   * @param {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.deserializeBinary
);


/**
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.RaporTanimlari.RaporTanimlariClient.prototype.getHazirRaporTanimListBySurecTanimID =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/RaporTanimlari.RaporTanimlari/GetHazirRaporTanimListBySurecTanimID',
      request,
      metadata || {},
      methodDescriptor_RaporTanimlari_GetHazirRaporTanimListBySurecTanimID,
      callback);
};


/**
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse>}
 *     Promise that resolves to the response
 */
proto.RaporTanimlari.RaporTanimlariPromiseClient.prototype.getHazirRaporTanimListBySurecTanimID =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/RaporTanimlari.RaporTanimlari/GetHazirRaporTanimListBySurecTanimID',
      request,
      metadata || {},
      methodDescriptor_RaporTanimlari_GetHazirRaporTanimListBySurecTanimID);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.RaporTanimlari.GetHazirRaporTanimByIdRequest,
 *   !proto.RaporTanimlari.GetHazirRaporTanimByIdResponse>}
 */
const methodDescriptor_RaporTanimlari_GetHazirRaporTanimById = new grpc.web.MethodDescriptor(
  '/RaporTanimlari.RaporTanimlari/GetHazirRaporTanimById',
  grpc.web.MethodType.UNARY,
  proto.RaporTanimlari.GetHazirRaporTanimByIdRequest,
  proto.RaporTanimlari.GetHazirRaporTanimByIdResponse,
  /**
   * @param {!proto.RaporTanimlari.GetHazirRaporTanimByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.RaporTanimlari.GetHazirRaporTanimByIdRequest,
 *   !proto.RaporTanimlari.GetHazirRaporTanimByIdResponse>}
 */
const methodInfo_RaporTanimlari_GetHazirRaporTanimById = new grpc.web.AbstractClientBase.MethodInfo(
  proto.RaporTanimlari.GetHazirRaporTanimByIdResponse,
  /**
   * @param {!proto.RaporTanimlari.GetHazirRaporTanimByIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.deserializeBinary
);


/**
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.RaporTanimlari.GetHazirRaporTanimByIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.RaporTanimlari.GetHazirRaporTanimByIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.RaporTanimlari.RaporTanimlariClient.prototype.getHazirRaporTanimById =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/RaporTanimlari.RaporTanimlari/GetHazirRaporTanimById',
      request,
      metadata || {},
      methodDescriptor_RaporTanimlari_GetHazirRaporTanimById,
      callback);
};


/**
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimByIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.RaporTanimlari.GetHazirRaporTanimByIdResponse>}
 *     Promise that resolves to the response
 */
proto.RaporTanimlari.RaporTanimlariPromiseClient.prototype.getHazirRaporTanimById =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/RaporTanimlari.RaporTanimlari/GetHazirRaporTanimById',
      request,
      metadata || {},
      methodDescriptor_RaporTanimlari_GetHazirRaporTanimById);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.RaporTanimlari.HazirRaporTanimEkleRequest,
 *   !proto.RaporTanimlari.HazirRaporTanimEkleResponse>}
 */
const methodDescriptor_RaporTanimlari_HazirRaporTanimEkle = new grpc.web.MethodDescriptor(
  '/RaporTanimlari.RaporTanimlari/HazirRaporTanimEkle',
  grpc.web.MethodType.UNARY,
  proto.RaporTanimlari.HazirRaporTanimEkleRequest,
  proto.RaporTanimlari.HazirRaporTanimEkleResponse,
  /**
   * @param {!proto.RaporTanimlari.HazirRaporTanimEkleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.RaporTanimlari.HazirRaporTanimEkleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.RaporTanimlari.HazirRaporTanimEkleRequest,
 *   !proto.RaporTanimlari.HazirRaporTanimEkleResponse>}
 */
const methodInfo_RaporTanimlari_HazirRaporTanimEkle = new grpc.web.AbstractClientBase.MethodInfo(
  proto.RaporTanimlari.HazirRaporTanimEkleResponse,
  /**
   * @param {!proto.RaporTanimlari.HazirRaporTanimEkleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.RaporTanimlari.HazirRaporTanimEkleResponse.deserializeBinary
);


/**
 * @param {!proto.RaporTanimlari.HazirRaporTanimEkleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.RaporTanimlari.HazirRaporTanimEkleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.RaporTanimlari.HazirRaporTanimEkleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.RaporTanimlari.RaporTanimlariClient.prototype.hazirRaporTanimEkle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/RaporTanimlari.RaporTanimlari/HazirRaporTanimEkle',
      request,
      metadata || {},
      methodDescriptor_RaporTanimlari_HazirRaporTanimEkle,
      callback);
};


/**
 * @param {!proto.RaporTanimlari.HazirRaporTanimEkleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.RaporTanimlari.HazirRaporTanimEkleResponse>}
 *     Promise that resolves to the response
 */
proto.RaporTanimlari.RaporTanimlariPromiseClient.prototype.hazirRaporTanimEkle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/RaporTanimlari.RaporTanimlari/HazirRaporTanimEkle',
      request,
      metadata || {},
      methodDescriptor_RaporTanimlari_HazirRaporTanimEkle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.RaporTanimlari.HazirRaporTanimGuncelleRequest,
 *   !proto.RaporTanimlari.HazirRaporTanimGuncelleResponse>}
 */
const methodDescriptor_RaporTanimlari_HazirRaporTanimGuncelle = new grpc.web.MethodDescriptor(
  '/RaporTanimlari.RaporTanimlari/HazirRaporTanimGuncelle',
  grpc.web.MethodType.UNARY,
  proto.RaporTanimlari.HazirRaporTanimGuncelleRequest,
  proto.RaporTanimlari.HazirRaporTanimGuncelleResponse,
  /**
   * @param {!proto.RaporTanimlari.HazirRaporTanimGuncelleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.RaporTanimlari.HazirRaporTanimGuncelleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.RaporTanimlari.HazirRaporTanimGuncelleRequest,
 *   !proto.RaporTanimlari.HazirRaporTanimGuncelleResponse>}
 */
const methodInfo_RaporTanimlari_HazirRaporTanimGuncelle = new grpc.web.AbstractClientBase.MethodInfo(
  proto.RaporTanimlari.HazirRaporTanimGuncelleResponse,
  /**
   * @param {!proto.RaporTanimlari.HazirRaporTanimGuncelleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.RaporTanimlari.HazirRaporTanimGuncelleResponse.deserializeBinary
);


/**
 * @param {!proto.RaporTanimlari.HazirRaporTanimGuncelleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.RaporTanimlari.HazirRaporTanimGuncelleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.RaporTanimlari.HazirRaporTanimGuncelleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.RaporTanimlari.RaporTanimlariClient.prototype.hazirRaporTanimGuncelle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/RaporTanimlari.RaporTanimlari/HazirRaporTanimGuncelle',
      request,
      metadata || {},
      methodDescriptor_RaporTanimlari_HazirRaporTanimGuncelle,
      callback);
};


/**
 * @param {!proto.RaporTanimlari.HazirRaporTanimGuncelleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.RaporTanimlari.HazirRaporTanimGuncelleResponse>}
 *     Promise that resolves to the response
 */
proto.RaporTanimlari.RaporTanimlariPromiseClient.prototype.hazirRaporTanimGuncelle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/RaporTanimlari.RaporTanimlari/HazirRaporTanimGuncelle',
      request,
      metadata || {},
      methodDescriptor_RaporTanimlari_HazirRaporTanimGuncelle);
};


module.exports = proto.RaporTanimlari;

