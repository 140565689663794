/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

// source: ozet_yonetim_raporu.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.OzetYonetimRaporu.GetCiroAnaliziRequest', null, global);
goog.exportSymbol('proto.OzetYonetimRaporu.GetCiroAnaliziResponse', null, global);
goog.exportSymbol('proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto', null, global);
goog.exportSymbol('proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest', null, global);
goog.exportSymbol('proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse', null, global);
goog.exportSymbol('proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto', null, global);
goog.exportSymbol('proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest', null, global);
goog.exportSymbol('proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse', null, global);
goog.exportSymbol('proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OzetYonetimRaporu.GetCiroAnaliziRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OzetYonetimRaporu.GetCiroAnaliziRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OzetYonetimRaporu.GetCiroAnaliziRequest.displayName = 'proto.OzetYonetimRaporu.GetCiroAnaliziRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.OzetYonetimRaporu.GetCiroAnaliziResponse.repeatedFields_, null);
};
goog.inherits(proto.OzetYonetimRaporu.GetCiroAnaliziResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OzetYonetimRaporu.GetCiroAnaliziResponse.displayName = 'proto.OzetYonetimRaporu.GetCiroAnaliziResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.displayName = 'proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest.displayName = 'proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.repeatedFields_, null);
};
goog.inherits(proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.displayName = 'proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.displayName = 'proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.displayName = 'proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.repeatedFields_, null);
};
goog.inherits(proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.displayName = 'proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.displayName = 'proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.OzetYonetimRaporu.GetCiroAnaliziRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OzetYonetimRaporu.GetCiroAnaliziRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetCiroAnaliziRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    kurumid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    baslangictarihi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bitistarihi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    kdvdahilmi: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziRequest}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OzetYonetimRaporu.GetCiroAnaliziRequest;
  return proto.OzetYonetimRaporu.GetCiroAnaliziRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OzetYonetimRaporu.GetCiroAnaliziRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziRequest}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaslangictarihi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBitistarihi(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKdvdahilmi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OzetYonetimRaporu.GetCiroAnaliziRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OzetYonetimRaporu.GetCiroAnaliziRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetCiroAnaliziRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBaslangictarihi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBitistarihi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKdvdahilmi();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 kurumId = 1;
 * @return {number}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziRequest.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziRequest} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziRequest.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string baslangicTarihi = 2;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziRequest.prototype.getBaslangictarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziRequest} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziRequest.prototype.setBaslangictarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bitisTarihi = 3;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziRequest.prototype.getBitistarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziRequest} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziRequest.prototype.setBitistarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool kdvDahilMi = 4;
 * @return {boolean}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziRequest.prototype.getKdvdahilmi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziRequest} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziRequest.prototype.setKdvdahilmi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.OzetYonetimRaporu.GetCiroAnaliziResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    ciroanalizirowsList: jspb.Message.toObjectList(msg.getCiroanalizirowsList(),
    proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OzetYonetimRaporu.GetCiroAnaliziResponse;
  return proto.OzetYonetimRaporu.GetCiroAnaliziResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto;
      reader.readMessage(value,proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.deserializeBinaryFromReader);
      msg.addCiroanalizirows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OzetYonetimRaporu.GetCiroAnaliziResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCiroanalizirowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    hastane: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gelisturu: jspb.Message.getFieldWithDefault(msg, 2, 0),
    gelisturuaciklama: jspb.Message.getFieldWithDefault(msg, 3, ""),
    anaodeyicikurumtipi: jspb.Message.getFieldWithDefault(msg, 4, 0),
    anaodeyicikurumtipiaciklama: jspb.Message.getFieldWithDefault(msg, 5, ""),
    protokolkurumu: jspb.Message.getFieldWithDefault(msg, 6, 0),
    protokolkurumuaciklama: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sorumlubolum: jspb.Message.getFieldWithDefault(msg, 8, ""),
    sorumlupersonel: jspb.Message.getFieldWithDefault(msg, 9, ""),
    odenecektutar: jspb.Message.getFieldWithDefault(msg, 10, ""),
    sgkodenecektutar: jspb.Message.getFieldWithDefault(msg, 11, ""),
    ozelkurumodenecektutar: jspb.Message.getFieldWithDefault(msg, 12, ""),
    hastaodenecektutar: jspb.Message.getFieldWithDefault(msg, 13, ""),
    maliyetmalzeme: jspb.Message.getFieldWithDefault(msg, 14, ""),
    malzemeciro: jspb.Message.getFieldWithDefault(msg, 15, ""),
    malzemeharicciro: jspb.Message.getFieldWithDefault(msg, 16, ""),
    ozelliklimalzemeciro: jspb.Message.getFieldWithDefault(msg, 17, ""),
    ozelliklimalzemeharicciro: jspb.Message.getFieldWithDefault(msg, 18, ""),
    muayenesayisi: jspb.Message.getFieldWithDefault(msg, 19, 0),
    ameliyatsayisi: jspb.Message.getFieldWithDefault(msg, 20, 0),
    protokolsayisi: jspb.Message.getFieldWithDefault(msg, 21, 0),
    laboratuvarciro: jspb.Message.getFieldWithDefault(msg, 22, ""),
    radyolojiciro: jspb.Message.getFieldWithDefault(msg, 23, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto;
  return proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGelisturu(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGelisturuaciklama(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAnaodeyicikurumtipi(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnaodeyicikurumtipiaciklama(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProtokolkurumu(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtokolkurumuaciklama(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSorumlubolum(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSorumlupersonel(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOdenecektutar(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSgkodenecektutar(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOzelkurumodenecektutar(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastaodenecektutar(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setMaliyetmalzeme(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setMalzemeciro(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setMalzemeharicciro(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setOzelliklimalzemeciro(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setOzelliklimalzemeharicciro(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMuayenesayisi(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAmeliyatsayisi(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProtokolsayisi(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setLaboratuvarciro(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setRadyolojiciro(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGelisturu();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getGelisturuaciklama();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAnaodeyicikurumtipi();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAnaodeyicikurumtipiaciklama();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProtokolkurumu();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getProtokolkurumuaciklama();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSorumlubolum();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSorumlupersonel();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOdenecektutar();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSgkodenecektutar();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOzelkurumodenecektutar();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getHastaodenecektutar();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getMaliyetmalzeme();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getMalzemeciro();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getMalzemeharicciro();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getOzelliklimalzemeciro();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getOzelliklimalzemeharicciro();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getMuayenesayisi();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getAmeliyatsayisi();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getProtokolsayisi();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getLaboratuvarciro();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getRadyolojiciro();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
};


/**
 * optional string hastane = 1;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 gelisTuru = 2;
 * @return {number}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getGelisturu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setGelisturu = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string gelisTuruAciklama = 3;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getGelisturuaciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setGelisturuaciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 anaOdeyiciKurumTipi = 4;
 * @return {number}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getAnaodeyicikurumtipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setAnaodeyicikurumtipi = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string anaOdeyiciKurumTipiAciklama = 5;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getAnaodeyicikurumtipiaciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setAnaodeyicikurumtipiaciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 protokolKurumu = 6;
 * @return {number}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getProtokolkurumu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setProtokolkurumu = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string protokolKurumuAciklama = 7;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getProtokolkurumuaciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setProtokolkurumuaciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string sorumluBolum = 8;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getSorumlubolum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setSorumlubolum = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string sorumluPersonel = 9;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getSorumlupersonel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setSorumlupersonel = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string odenecekTutar = 10;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getOdenecektutar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setOdenecektutar = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string sgkOdenecekTutar = 11;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getSgkodenecektutar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setSgkodenecektutar = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string ozelKurumOdenecekTutar = 12;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getOzelkurumodenecektutar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setOzelkurumodenecektutar = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string hastaOdenecekTutar = 13;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getHastaodenecektutar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setHastaodenecektutar = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string maliyetMalzeme = 14;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getMaliyetmalzeme = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setMaliyetmalzeme = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string malzemeCiro = 15;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getMalzemeciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setMalzemeciro = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string malzemeHaricCiro = 16;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getMalzemeharicciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setMalzemeharicciro = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string ozellikliMalzemeCiro = 17;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getOzelliklimalzemeciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setOzelliklimalzemeciro = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string ozellikliMalzemeHaricCiro = 18;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getOzelliklimalzemeharicciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setOzelliklimalzemeharicciro = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional int32 muayeneSayisi = 19;
 * @return {number}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getMuayenesayisi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setMuayenesayisi = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 ameliyatSayisi = 20;
 * @return {number}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getAmeliyatsayisi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setAmeliyatsayisi = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 protokolSayisi = 21;
 * @return {number}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getProtokolsayisi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setProtokolsayisi = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional string laboratuvarCiro = 22;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getLaboratuvarciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setLaboratuvarciro = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string radyolojiCiro = 23;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.getRadyolojiciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto.prototype.setRadyolojiciro = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * repeated CiroAnaliziRowDto ciroAnaliziRows = 1;
 * @return {!Array<!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto>}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.prototype.getCiroanalizirowsList = function() {
  return /** @type{!Array<!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto, 1));
};


/**
 * @param {!Array<!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto>} value
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse} returns this
*/
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.prototype.setCiroanalizirowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto}
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.prototype.addCiroanalizirows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OzetYonetimRaporu.GetCiroAnaliziResponse.CiroAnaliziRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.OzetYonetimRaporu.GetCiroAnaliziResponse} returns this
 */
proto.OzetYonetimRaporu.GetCiroAnaliziResponse.prototype.clearCiroanalizirowsList = function() {
  return this.setCiroanalizirowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    kurumid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    baslangictarihi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bitistarihi: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest;
  return proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaslangictarihi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBitistarihi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBaslangictarihi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBitistarihi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 kurumId = 1;
 * @return {number}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest} returns this
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string baslangicTarihi = 2;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest.prototype.getBaslangictarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest} returns this
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest.prototype.setBaslangictarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bitisTarihi = 3;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest.prototype.getBitistarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest} returns this
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziRequest.prototype.setBitistarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tahsilatanalizirowsList: jspb.Message.toObjectList(msg.getTahsilatanalizirowsList(),
    proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse;
  return proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto;
      reader.readMessage(value,proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.deserializeBinaryFromReader);
      msg.addTahsilatanalizirows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTahsilatanalizirowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    odemesekli: jspb.Message.getFieldWithDefault(msg, 1, 0),
    odemesekliaciklama: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hesaphareketitipi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    hesaphareketitipiaciklama: jspb.Message.getFieldWithDefault(msg, 4, ""),
    odeyicikurumtipi: jspb.Message.getFieldWithDefault(msg, 5, 0),
    odeyicikurumtipiaciklama: jspb.Message.getFieldWithDefault(msg, 6, ""),
    tutar: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto;
  return proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOdemesekli(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOdemesekliaciklama(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHesaphareketitipi(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHesaphareketitipiaciklama(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOdeyicikurumtipi(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOdeyicikurumtipiaciklama(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTutar(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOdemesekli();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOdemesekliaciklama();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHesaphareketitipi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHesaphareketitipiaciklama();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOdeyicikurumtipi();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOdeyicikurumtipiaciklama();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTutar();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int32 odemeSekli = 1;
 * @return {number}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.prototype.getOdemesekli = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.prototype.setOdemesekli = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string odemeSekliAciklama = 2;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.prototype.getOdemesekliaciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.prototype.setOdemesekliaciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string hesapHareketiTipi = 3;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.prototype.getHesaphareketitipi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.prototype.setHesaphareketitipi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string hesapHareketiTipiAciklama = 4;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.prototype.getHesaphareketitipiaciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.prototype.setHesaphareketitipiaciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 odeyiciKurumTipi = 5;
 * @return {number}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.prototype.getOdeyicikurumtipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.prototype.setOdeyicikurumtipi = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string odeyiciKurumTipiAciklama = 6;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.prototype.getOdeyicikurumtipiaciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.prototype.setOdeyicikurumtipiaciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string tutar = 7;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.prototype.getTutar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto.prototype.setTutar = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated TahsilatAnaliziRowDto tahsilatAnaliziRows = 1;
 * @return {!Array<!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto>}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.prototype.getTahsilatanalizirowsList = function() {
  return /** @type{!Array<!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto, 1));
};


/**
 * @param {!Array<!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto>} value
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse} returns this
*/
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.prototype.setTahsilatanalizirowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto}
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.prototype.addTahsilatanalizirows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.TahsilatAnaliziRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse} returns this
 */
proto.OzetYonetimRaporu.GetTahsilatAnaliziResponse.prototype.clearTahsilatanalizirowsList = function() {
  return this.setTahsilatanalizirowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    kurumid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    baslangictarihi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    bitistarihi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    kdvdahilmi: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest;
  return proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaslangictarihi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBitistarihi(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setKdvdahilmi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBaslangictarihi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBitistarihi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKdvdahilmi();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 kurumId = 1;
 * @return {number}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest} returns this
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string baslangicTarihi = 2;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.prototype.getBaslangictarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest} returns this
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.prototype.setBaslangictarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string bitisTarihi = 3;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.prototype.getBitistarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest} returns this
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.prototype.setBitistarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool kdvDahilMi = 4;
 * @return {boolean}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.prototype.getKdvdahilmi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest} returns this
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziRequest.prototype.setKdvdahilmi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cirodagilimiveislemanalizirowsList: jspb.Message.toObjectList(msg.getCirodagilimiveislemanalizirowsList(),
    proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse;
  return proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto;
      reader.readMessage(value,proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.deserializeBinaryFromReader);
      msg.addCirodagilimiveislemanalizirows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCirodagilimiveislemanalizirowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    odeyicikurumtipi: jspb.Message.getFieldWithDefault(msg, 1, 0),
    odeyicikurumtipiaciklama: jspb.Message.getFieldWithDefault(msg, 2, ""),
    merkeziozelkod1: jspb.Message.getFieldWithDefault(msg, 3, ""),
    merkeziozelkod2: jspb.Message.getFieldWithDefault(msg, 4, ""),
    parametreislemsayisi: jspb.Message.getFieldWithDefault(msg, 5, ""),
    odenecektutar: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto;
  return proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOdeyicikurumtipi(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOdeyicikurumtipiaciklama(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerkeziozelkod1(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMerkeziozelkod2(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setParametreislemsayisi(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOdenecektutar(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOdeyicikurumtipi();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOdeyicikurumtipiaciklama();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMerkeziozelkod1();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMerkeziozelkod2();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getParametreislemsayisi();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOdenecektutar();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 odeyiciKurumTipi = 1;
 * @return {number}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.prototype.getOdeyicikurumtipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.prototype.setOdeyicikurumtipi = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string odeyiciKurumTipiAciklama = 2;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.prototype.getOdeyicikurumtipiaciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.prototype.setOdeyicikurumtipiaciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string merkeziOzelKod1 = 3;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.prototype.getMerkeziozelkod1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.prototype.setMerkeziozelkod1 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string merkeziOzelKod2 = 4;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.prototype.getMerkeziozelkod2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.prototype.setMerkeziozelkod2 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string parametreIslemSayisi = 5;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.prototype.getParametreislemsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.prototype.setParametreislemsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string odenecekTutar = 6;
 * @return {string}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.prototype.getOdenecektutar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto} returns this
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto.prototype.setOdenecektutar = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated CiroDagilimiVeIslemAnaliziRowDto ciroDagilimiVeIslemAnaliziRows = 1;
 * @return {!Array<!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto>}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.prototype.getCirodagilimiveislemanalizirowsList = function() {
  return /** @type{!Array<!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto, 1));
};


/**
 * @param {!Array<!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto>} value
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse} returns this
*/
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.prototype.setCirodagilimiveislemanalizirowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto}
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.prototype.addCirodagilimiveislemanalizirows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.CiroDagilimiVeIslemAnaliziRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse} returns this
 */
proto.OzetYonetimRaporu.GetCiroDagilimiVeIslemAnaliziResponse.prototype.clearCirodagilimiveislemanalizirowsList = function() {
  return this.setCirodagilimiveislemanalizirowsList([]);
};


goog.object.extend(exports, proto.OzetYonetimRaporu);
