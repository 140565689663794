
const color = { red: 'red', yellow: '#ffff00', green: '#008000', green2: '#85B627', white: '#efefef', black: 'black', custom: '#881188', navy: '#7FAEFF', custom2: '#F14693' }

const columnNameToFilter = {
  gerceklesme: 'gerceklesme',
  gidisaticindekioran: 'gidisaticindekioran',
  hedefgerceklesmeorani: 'hedefgerceklesmeorani',
  butce: 'butce',
  ciro: 'ciro',
  gidisat: 'gidisat',
  butcedenKalan: 'butcedenkalan',
  oncekiGunGidisati: 'oncekigungidisati',
  oncekiGunCiro: 'oncekigunciro',
  gidisatHizmetButcesiFarki: 'gidisathizmetbutcesifarki',
  gerceklesmesiGereken: 'gerceklesmesigereken',
  islemsayisi: 'islemsayisi',
  gidisatislemsayisi: 'gidisatislemsayisi',
  gidisatciro: 'gidisatciro',
  oncekiayislemsayisi: 'oncekiayislemsayisi',
  oncekiayciro: 'oncekiayciro'
}

function CellPreparedHastane(e) {

  if (e.rowType === 'data') {

    if (e.column.dataField === columnNameToFilter.butce) {
      e.cellElement.style.color = color.navy;
    }

    if (e.column.dataField === columnNameToFilter.ciro || e.column.dataField === columnNameToFilter.gerceklesmesigereken) {
      e.cellElement.style.color = color.green2;
    }

    if (e.column.dataField === columnNameToFilter.gidisat) {
      e.cellElement.style.color = color.custom2;
    }

    if (e.data.sirano === 3 || e.data.sirano === 6 || e.data.sirano === 13 || e.data.sirano === 14 || e.data.sirano === 15) {
      e.cellElement.style.fontWeight = "bold";
    }

    if (parseFloat(e.value) === 0.0) {
      e.cellElement.style.color = color.white;
    }

    if (e.column.dataField === columnNameToFilter.gerceklesme) {
      if (parseFloat(e.value) >= 0.95) {
        e.cellElement.style.backgroundColor = color.green;
        e.cellElement.style.color = color.white;
      }
      else if (parseFloat(e.value) >= 0.85 && parseFloat(e.value) < 0.95) {
        e.cellElement.style.backgroundColor = color.yellow;
        e.cellElement.style.color = color.black;
      }
      else if (parseFloat(e.value) > 0.00 && parseFloat(e.value) < 0.85) {
        e.cellElement.style.backgroundColor = color.red;
        e.cellElement.style.color = color.white;
      }
      else if (parseFloat(e.value) === 0.00) {

      }
    }

    if (e.column.dataField === columnNameToFilter.hedefgerceklesmeorani) {
      if (parseFloat(e.value) > 0 && parseFloat(e.value) <= 0.9) {
        e.cellElement.style.backgroundColor = color.red;
        e.cellElement.style.color = color.white;
      }
      else if (parseFloat(e.value) > 0.9 && parseFloat(e.value) < 0.95) {
        e.cellElement.style.backgroundColor = color.yellow;
        e.cellElement.style.color = color.black;
      }
      else if (parseFloat(e.value) >= 0.95) {
        e.cellElement.style.backgroundColor = color.green;
        e.cellElement.style.color = color.white;
      }
    }

    if (e.column.caption === "Gidişat İçindeki Oran") {
      if (parseFloat(e.data.gidisathizmetbutcesifarki) >= 1.15) {
        e.cellElement.style.backgroundColor = color.red;
        e.cellElement.style.color = color.white;
      }
      else if (parseFloat(e.data.gidisathizmetbutcesifarki) >= 1.05 && parseFloat(e.data.gidisathizmetbutcesifarki) < 1.15) {
        e.cellElement.style.backgroundColor = color.yellow;
        e.cellElement.style.color = color.black;
      }
      else if (parseFloat(e.data.gidisathizmetbutcesifarki) < 1.05 && parseFloat(e.data.gidisathizmetbutcesifarki) !== 0.00) {
        e.cellElement.style.backgroundColor = color.green;
        e.cellElement.style.color = color.white;
      }
    }
  }

}

function CellPreparedHizmetDetayi(e) {
  if (e.rowType === 'data') {
    if (e.column.dataField === columnNameToFilter.islemsayisi || e.column.dataField === columnNameToFilter.ciro) {
      e.cellElement.style.color = color.navy;
    }
    if (e.column.dataField === columnNameToFilter.gidisatislemsayisi || e.column.dataField === columnNameToFilter.gidisatciro) {
      e.cellElement.style.color = color.green2;
    }
    if (e.column.dataField === columnNameToFilter.oncekiayislemsayisi || e.column.dataField === columnNameToFilter.oncekiayciro) {
      e.cellElement.style.color = color.custom2;
    }
  }
}

function CellPreparedGrup(e) {

  if (e.rowType === 'data') {

    if (e.column.dataField === columnNameToFilter.butce) {
      e.cellElement.style.color = color.navy;
    }

    if (e.column.dataField === columnNameToFilter.ciro || e.column.dataField === columnNameToFilter.butcedenKalan) {
      e.cellElement.style.color = color.green2;
    }

    if (e.column.dataField === columnNameToFilter.gidisat) {
      e.cellElement.style.color = color.custom2;
    }

    if (e.data.tipi === 3 || e.data.tipi === 6 || e.data.tipi === 13 || e.data.tipi === 14 || e.data.tipi === 15) {
      e.cellElement.style.fontWeight = "bold";
    }

    if (parseFloat(e.value) === 0.0) {
      e.cellElement.style.color = color.white;
    }

    if (e.column.dataField === columnNameToFilter.hedefgerceklesmeorani) {
      if (parseFloat(e.value) > 0 && parseFloat(e.value) < 0.9) {
        e.cellElement.style.backgroundColor = color.red;
        e.cellElement.style.color = color.white;
      }
      else if (parseFloat(e.value) >= 0.9 && parseFloat(e.value) < 0.95) {
        e.cellElement.style.backgroundColor = color.yellow;
        e.cellElement.style.color = color.black;
      }
      else if (parseFloat(e.value) >= 0.95) {
        e.cellElement.style.backgroundColor = color.green;
        e.cellElement.style.color = color.white;
      }
    }

    if (e.data.tipi === 7 || e.data.tipi === 8 || e.data.tipi === 9 || e.data.tipi === 10 || e.data.tipi === 11 || e.data.tipi === 12 || e.data.tpi === 13) {
      if (e.column.caption === "Gerçekleşme") {
        if (parseFloat(e.value) >= 1.176) {
          e.cellElement.style.backgroundColor = color.red;
          e.cellElement.style.color = color.White;
        }
        else if (parseFloat(e.value) >= 1.053 && parseFloat(e.value) < 1.176) {
          e.cellElement.style.backgroundColor = color.yellow;
          e.cellElement.style.color = color.black;
        }
        else if (parseFloat(e.value) > 0.00 && parseFloat(e.value) < 1.053) {
          e.cellElement.style.backgroundColor = color.green;
          e.cellElement.style.color = color.white;
        }
        else if (parseFloat(e.value) === 0.00) {

        }
      }
    }
    else {
      if (e.column.caption === "Gerçekleşme") {
        if (parseFloat(e.value) >= 0.95) {
          e.cellElement.style.backgroundColor = color.green
          e.cellElement.style.color = color.white
        }
        else if (parseFloat(e.value) >= 0.85 && parseFloat(e.value) < 0.95) {
          e.cellElement.style.backgroundColor = color.yellow
          e.cellElement.style.color = color.black
        }
        else if (parseFloat(e.value) > 0.00 && parseFloat(e.value) < 0.85) {
          e.cellElement.style.backgroundColor = color.red;
          e.cellElement.style.color = color.white;
        }
        else if (parseFloat(e.value) === 0.00) {

        }
      }
    }
  }
}

function CellPreparedGelisTuruDetay(e) {

  if (e.rowType === 'data') {

    if (e.column.dataField === columnNameToFilter.butce) {
      e.cellElement.style.color = color.navy;
    }

    if (e.column.dataField === columnNameToFilter.ciro || e.column.dataField === columnNameToFilter.gerceklesmesigereken) {
      e.cellElement.style.color = color.green;
    }

    if (e.column.dataField === columnNameToFilter.gidisat) {
      e.cellElement.style.color = color.custom2;
    }

    if (e.data.sirano === 3 || e.data.sirano === 6 || e.data.sirano === 13 || e.data.sirano === 14 || e.data.sirano === 15) {
      e.cellElement.style.fontWeight = "bold";
    }

    if (parseFloat(e.value) === 0.0) {
      e.cellElement.style.color = color.white;
    }

    if (e.column.dataField === columnNameToFilter.gerceklesme) {
      if (parseFloat(e.value) >= 0.95) {
        e.cellElement.style.backgroundColor = color.green;
        e.cellElement.style.color = color.white;
      }
      else if (parseFloat(e.value) >= 0.85 && parseFloat(e.value) < 0.95) {
        e.cellElement.style.backgroundColor = color.yellow;
      }
      else if (parseFloat(e.value) > 0.00 && parseFloat(e.value) < 0.85) {
        e.cellElement.style.backgroundColor = color.red;
        e.cellElement.style.color = color.white;
      }
      else if (parseFloat(e.value) === 0.00) {

      }
    }

    if (e.column.dataField === columnNameToFilter.hedefgerceklesmeorani) {
      if (parseFloat(e.value) > 0 && parseFloat(e.value) < 0.9) {
        e.cellElement.style.backgroundColor = color.red;
        e.cellElement.style.color = color.white;
      }
      else if (parseFloat(e.value) >= 0.9 && parseFloat(e.value) < 0.95) {
        e.cellElement.style.backgroundColor = color.yellow;
        e.cellElement.style.color = color.black;
      }
      else if (parseFloat(e.value) >= 0.95) {
        e.cellElement.style.backgroundColor = color.green;
        e.cellElement.style.color = color.white;
      }
    }

    if (e.column.caption === "Gidişat İçindeki Oran") {
      if (parseFloat(e.data.gidisathizmetbutcesifarki) >= 1.15) {
        e.cellElement.style.backgroundColor = color.red;
        e.cellElement.style.color = color.white;
      }
      else if (parseFloat(e.data.gidisathizmetbutcesifarki) >= 1.05 && parseFloat(e.data.gidisathizmetbutcesifarki) < 1.15) {
        e.cellElement.style.backgroundColor = color.yellow;
        e.cellElement.style.color = color.black;
      }
      else if (parseFloat(e.data.gidisathizmetbutcesifarki) < 1.05 && parseFloat(e.data.gidisathizmetbutcesifarki) !== 0.00) {
        e.cellElement.style.backgroundColor = color.green;
        e.cellElement.style.color = color.white;
      }
    }
  }


}

function CellPreparedOdeyiciDetayi(e) {
  if (e.rowType === 'data') {
    if (e.column.dataField === columnNameToFilter.butce) {
      e.cellElement.style.color = color.navy;
    }

    if (e.column.dataField === columnNameToFilter.ciro) {
      e.cellElement.style.color = color.green;
    }

    if (e.column.dataField === columnNameToFilter.gidisat) {
      e.cellElement.style.color = color.custom2;
    }
  }
}

export { CellPreparedHastane, CellPreparedGrup, CellPreparedHizmetDetayi, CellPreparedGelisTuruDetay, CellPreparedOdeyiciDetayi };