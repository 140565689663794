/* eslint-disable */
//@ts-nocheck

// source: rapor_paneli.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.RaporPaneli.RaporBilgisiniGetirRequest', null, global);
goog.exportSymbol('proto.RaporPaneli.RaporBilgisiniGetirResponse', null, global);
goog.exportSymbol('proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto', null, global);
goog.exportSymbol('proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest', null, global);
goog.exportSymbol('proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse', null, global);
goog.exportSymbol('proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto', null, global);
goog.exportSymbol('proto.RaporPaneli.SaveReportReadingHistoryRequest', null, global);
goog.exportSymbol('proto.RaporPaneli.SaveReportReadingHistoryResponse', null, global);
goog.exportSymbol('proto.RaporPaneli.SurecVeRaporAgaciRequest', null, global);
goog.exportSymbol('proto.RaporPaneli.SurecVeRaporAgaciResponse', null, global);
goog.exportSymbol('proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporPaneli.SurecVeRaporAgaciRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaporPaneli.SurecVeRaporAgaciRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporPaneli.SurecVeRaporAgaciRequest.displayName = 'proto.RaporPaneli.SurecVeRaporAgaciRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.RaporPaneli.SurecVeRaporAgaciResponse.repeatedFields_, null);
};
goog.inherits(proto.RaporPaneli.SurecVeRaporAgaciResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporPaneli.SurecVeRaporAgaciResponse.displayName = 'proto.RaporPaneli.SurecVeRaporAgaciResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.repeatedFields_, null);
};
goog.inherits(proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.displayName = 'proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest.displayName = 'proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.repeatedFields_, null);
};
goog.inherits(proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.displayName = 'proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.displayName = 'proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporPaneli.RaporBilgisiniGetirRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaporPaneli.RaporBilgisiniGetirRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporPaneli.RaporBilgisiniGetirRequest.displayName = 'proto.RaporPaneli.RaporBilgisiniGetirRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaporPaneli.RaporBilgisiniGetirResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporPaneli.RaporBilgisiniGetirResponse.displayName = 'proto.RaporPaneli.RaporBilgisiniGetirResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.displayName = 'proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporPaneli.SaveReportReadingHistoryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaporPaneli.SaveReportReadingHistoryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporPaneli.SaveReportReadingHistoryRequest.displayName = 'proto.RaporPaneli.SaveReportReadingHistoryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporPaneli.SaveReportReadingHistoryResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaporPaneli.SaveReportReadingHistoryResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporPaneli.SaveReportReadingHistoryResponse.displayName = 'proto.RaporPaneli.SaveReportReadingHistoryResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporPaneli.SurecVeRaporAgaciRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporPaneli.SurecVeRaporAgaciRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporPaneli.SurecVeRaporAgaciRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.SurecVeRaporAgaciRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    personelid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciRequest}
 */
proto.RaporPaneli.SurecVeRaporAgaciRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporPaneli.SurecVeRaporAgaciRequest;
  return proto.RaporPaneli.SurecVeRaporAgaciRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporPaneli.SurecVeRaporAgaciRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciRequest}
 */
proto.RaporPaneli.SurecVeRaporAgaciRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPersonelid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporPaneli.SurecVeRaporAgaciRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporPaneli.SurecVeRaporAgaciRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporPaneli.SurecVeRaporAgaciRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.SurecVeRaporAgaciRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonelid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 PersonelId = 1;
 * @return {number}
 */
proto.RaporPaneli.SurecVeRaporAgaciRequest.prototype.getPersonelid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciRequest} returns this
 */
proto.RaporPaneli.SurecVeRaporAgaciRequest.prototype.setPersonelid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporPaneli.SurecVeRaporAgaciResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporPaneli.SurecVeRaporAgaciResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    surecveraporagaciList: jspb.Message.toObjectList(msg.getSurecveraporagaciList(),
    proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciResponse}
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporPaneli.SurecVeRaporAgaciResponse;
  return proto.RaporPaneli.SurecVeRaporAgaciResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporPaneli.SurecVeRaporAgaciResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciResponse}
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto;
      reader.readMessage(value,proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.deserializeBinaryFromReader);
      msg.addSurecveraporagaci(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporPaneli.SurecVeRaporAgaciResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporPaneli.SurecVeRaporAgaciResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSurecveraporagaciList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    key: jspb.Message.getFieldWithDefault(msg, 2, ""),
    text: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tipi: jspb.Message.getFieldWithDefault(msg, 4, 0),
    icon: jspb.Message.getFieldWithDefault(msg, 5, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto}
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto;
  return proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto}
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKey(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTipi(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 6:
      var value = new proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto;
      reader.readMessage(value,proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKey();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTipi();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto} returns this
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string key = 2;
 * @return {string}
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.prototype.getKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto} returns this
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.prototype.setKey = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string text = 3;
 * @return {string}
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto} returns this
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 tipi = 4;
 * @return {number}
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.prototype.getTipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto} returns this
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.prototype.setTipi = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string icon = 5;
 * @return {string}
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto} returns this
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated SurecVeRaporAgaciDto items = 6;
 * @return {!Array<!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto>}
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto, 6));
};


/**
 * @param {!Array<!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto>} value
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto} returns this
*/
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto}
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto} returns this
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * repeated SurecVeRaporAgaciDto surecVeRaporAgaci = 1;
 * @return {!Array<!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto>}
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.prototype.getSurecveraporagaciList = function() {
  return /** @type{!Array<!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto, 1));
};


/**
 * @param {!Array<!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto>} value
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciResponse} returns this
*/
proto.RaporPaneli.SurecVeRaporAgaciResponse.prototype.setSurecveraporagaciList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto}
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.prototype.addSurecveraporagaci = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.RaporPaneli.SurecVeRaporAgaciResponse.SurecVeRaporAgaciDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.RaporPaneli.SurecVeRaporAgaciResponse} returns this
 */
proto.RaporPaneli.SurecVeRaporAgaciResponse.prototype.clearSurecveraporagaciList = function() {
  return this.setSurecveraporagaciList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hazirraportanimid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest;
  return proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHazirraportanimid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHazirraportanimid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 HazirRaporTanimId = 1;
 * @return {number}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest.prototype.getHazirraportanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest} returns this
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest.prototype.setHazirraportanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    raporokumagecmisiList: jspb.Message.toObjectList(msg.getRaporokumagecmisiList(),
    proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse;
  return proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto;
      reader.readMessage(value,proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.deserializeBinaryFromReader);
      msg.addRaporokumagecmisi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRaporokumagecmisiList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    okuyan: jspb.Message.getFieldWithDefault(msg, 1, ""),
    okumazamani: jspb.Message.getFieldWithDefault(msg, 2, ""),
    gorevdurumu: jspb.Message.getFieldWithDefault(msg, 3, ""),
    okundumu: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto;
  return proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOkuyan(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOkumazamani(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGorevdurumu(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOkundumu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOkuyan();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOkumazamani();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGorevdurumu();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOkundumu();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string Okuyan = 1;
 * @return {string}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.prototype.getOkuyan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto} returns this
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.prototype.setOkuyan = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string OkumaZamani = 2;
 * @return {string}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.prototype.getOkumazamani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto} returns this
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.prototype.setOkumazamani = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string GorevDurumu = 3;
 * @return {string}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.prototype.getGorevdurumu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto} returns this
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.prototype.setGorevdurumu = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool OkunduMu = 4;
 * @return {boolean}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.prototype.getOkundumu = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto} returns this
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto.prototype.setOkundumu = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * repeated RaporOkumaGecmisiDto RaporOkumaGecmisi = 1;
 * @return {!Array<!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto>}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.prototype.getRaporokumagecmisiList = function() {
  return /** @type{!Array<!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto, 1));
};


/**
 * @param {!Array<!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto>} value
 * @return {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse} returns this
*/
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.prototype.setRaporokumagecmisiList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto}
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.prototype.addRaporokumagecmisi = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.RaporOkumaGecmisiDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse} returns this
 */
proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.prototype.clearRaporokumagecmisiList = function() {
  return this.setRaporokumagecmisiList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporPaneli.RaporBilgisiniGetirRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporPaneli.RaporBilgisiniGetirRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporPaneli.RaporBilgisiniGetirRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.RaporBilgisiniGetirRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    personelid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hazirraportanimid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirRequest}
 */
proto.RaporPaneli.RaporBilgisiniGetirRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporPaneli.RaporBilgisiniGetirRequest;
  return proto.RaporPaneli.RaporBilgisiniGetirRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporPaneli.RaporBilgisiniGetirRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirRequest}
 */
proto.RaporPaneli.RaporBilgisiniGetirRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPersonelid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHazirraportanimid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporPaneli.RaporBilgisiniGetirRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporPaneli.RaporBilgisiniGetirRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporPaneli.RaporBilgisiniGetirRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.RaporBilgisiniGetirRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonelid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHazirraportanimid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 PersonelId = 1;
 * @return {number}
 */
proto.RaporPaneli.RaporBilgisiniGetirRequest.prototype.getPersonelid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirRequest} returns this
 */
proto.RaporPaneli.RaporBilgisiniGetirRequest.prototype.setPersonelid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 HazirRaporTanimId = 2;
 * @return {number}
 */
proto.RaporPaneli.RaporBilgisiniGetirRequest.prototype.getHazirraportanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirRequest} returns this
 */
proto.RaporPaneli.RaporBilgisiniGetirRequest.prototype.setHazirraportanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporPaneli.RaporBilgisiniGetirResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporPaneli.RaporBilgisiniGetirResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    raporbilgisi: (f = msg.getRaporbilgisi()) && proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirResponse}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporPaneli.RaporBilgisiniGetirResponse;
  return proto.RaporPaneli.RaporBilgisiniGetirResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporPaneli.RaporBilgisiniGetirResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirResponse}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto;
      reader.readMessage(value,proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.deserializeBinaryFromReader);
      msg.setRaporbilgisi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporPaneli.RaporBilgisiniGetirResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporPaneli.RaporBilgisiniGetirResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRaporbilgisi();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    raporid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tipi: jspb.Message.getFieldWithDefault(msg, 2, 0),
    adi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sonokunmatarihi: jspb.Message.getFieldWithDefault(msg, 4, ""),
    gorevtanimi: jspb.Message.getFieldWithDefault(msg, 5, ""),
    gorevtamamlanmadurumu: jspb.Message.getFieldWithDefault(msg, 6, ""),
    webraporlink: jspb.Message.getFieldWithDefault(msg, 7, ""),
    raporkodu: jspb.Message.getFieldWithDefault(msg, 8, ""),
    webraporlink2: jspb.Message.getFieldWithDefault(msg, 9, ""),
    webraporlink3: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto;
  return proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRaporid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTipi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSonokunmatarihi(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGorevtanimi(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGorevtamamlanmadurumu(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebraporlink(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRaporkodu(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebraporlink2(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebraporlink3(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRaporid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTipi();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSonokunmatarihi();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGorevtanimi();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGorevtamamlanmadurumu();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getWebraporlink();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRaporkodu();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getWebraporlink2();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getWebraporlink3();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional int32 raporId = 1;
 * @return {number}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.getRaporid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto} returns this
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.setRaporid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 tipi = 2;
 * @return {number}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.getTipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto} returns this
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.setTipi = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string adi = 3;
 * @return {string}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto} returns this
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sonOkunmaTarihi = 4;
 * @return {string}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.getSonokunmatarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto} returns this
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.setSonokunmatarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string gorevTanimi = 5;
 * @return {string}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.getGorevtanimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto} returns this
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.setGorevtanimi = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string gorevTamamlanmaDurumu = 6;
 * @return {string}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.getGorevtamamlanmadurumu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto} returns this
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.setGorevtamamlanmadurumu = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string webRaporLink = 7;
 * @return {string}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.getWebraporlink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto} returns this
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.setWebraporlink = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string raporKodu = 8;
 * @return {string}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.getRaporkodu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto} returns this
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.setRaporkodu = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string webRaporLink2 = 9;
 * @return {string}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.getWebraporlink2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto} returns this
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.setWebraporlink2 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string webRaporLink3 = 10;
 * @return {string}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.getWebraporlink3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto} returns this
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto.prototype.setWebraporlink3 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional RaporBilgisiDto raporBilgisi = 1;
 * @return {?proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.prototype.getRaporbilgisi = function() {
  return /** @type{?proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto} */ (
    jspb.Message.getWrapperField(this, proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto, 1));
};


/**
 * @param {?proto.RaporPaneli.RaporBilgisiniGetirResponse.RaporBilgisiDto|undefined} value
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirResponse} returns this
*/
proto.RaporPaneli.RaporBilgisiniGetirResponse.prototype.setRaporbilgisi = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RaporPaneli.RaporBilgisiniGetirResponse} returns this
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.prototype.clearRaporbilgisi = function() {
  return this.setRaporbilgisi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RaporPaneli.RaporBilgisiniGetirResponse.prototype.hasRaporbilgisi = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporPaneli.SaveReportReadingHistoryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporPaneli.SaveReportReadingHistoryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporPaneli.SaveReportReadingHistoryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.SaveReportReadingHistoryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    personelid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hazirraportanimid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporPaneli.SaveReportReadingHistoryRequest}
 */
proto.RaporPaneli.SaveReportReadingHistoryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporPaneli.SaveReportReadingHistoryRequest;
  return proto.RaporPaneli.SaveReportReadingHistoryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporPaneli.SaveReportReadingHistoryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporPaneli.SaveReportReadingHistoryRequest}
 */
proto.RaporPaneli.SaveReportReadingHistoryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPersonelid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHazirraportanimid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporPaneli.SaveReportReadingHistoryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporPaneli.SaveReportReadingHistoryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporPaneli.SaveReportReadingHistoryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.SaveReportReadingHistoryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonelid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHazirraportanimid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 personelId = 1;
 * @return {number}
 */
proto.RaporPaneli.SaveReportReadingHistoryRequest.prototype.getPersonelid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporPaneli.SaveReportReadingHistoryRequest} returns this
 */
proto.RaporPaneli.SaveReportReadingHistoryRequest.prototype.setPersonelid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 hazirRaporTanimId = 2;
 * @return {number}
 */
proto.RaporPaneli.SaveReportReadingHistoryRequest.prototype.getHazirraportanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporPaneli.SaveReportReadingHistoryRequest} returns this
 */
proto.RaporPaneli.SaveReportReadingHistoryRequest.prototype.setHazirraportanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporPaneli.SaveReportReadingHistoryResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporPaneli.SaveReportReadingHistoryResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporPaneli.SaveReportReadingHistoryResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.SaveReportReadingHistoryResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporPaneli.SaveReportReadingHistoryResponse}
 */
proto.RaporPaneli.SaveReportReadingHistoryResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporPaneli.SaveReportReadingHistoryResponse;
  return proto.RaporPaneli.SaveReportReadingHistoryResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporPaneli.SaveReportReadingHistoryResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporPaneli.SaveReportReadingHistoryResponse}
 */
proto.RaporPaneli.SaveReportReadingHistoryResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporPaneli.SaveReportReadingHistoryResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporPaneli.SaveReportReadingHistoryResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporPaneli.SaveReportReadingHistoryResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporPaneli.SaveReportReadingHistoryResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool status = 1;
 * @return {boolean}
 */
proto.RaporPaneli.SaveReportReadingHistoryResponse.prototype.getStatus = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.RaporPaneli.SaveReportReadingHistoryResponse} returns this
 */
proto.RaporPaneli.SaveReportReadingHistoryResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


goog.object.extend(exports, proto.RaporPaneli);
