import { createSlice } from '@reduxjs/toolkit'
import gunlukCiroRaporuService from "../services/is-zekasi/yonetim-raporlari/gunlukCiroRaporuService";
import { trackPromise } from 'react-promise-tracker';
import OdeyiciDetayi from 'src/views/app/rapor/GunlukCiroRaporu/OdeyiciDetayi';

const initialState = {
  gunlukCiro: [],
  gunlukCiroRaporuGroup: [],
  gunlukCiraRaporuGelisTuruDetay: [],
  gunlukCiroRaporuHizmetDetayi: [],
  gunlukCiroRaporuButceTakibiEndeksHizmetCirosu: [],
  gunlukCiroRaporuButceTakibiEndeksHizmetCirosuOran: [],
  gunlukCiroRaporuOdeyiciDetayi: [],
  gunlukCiroRaporuOdeyiciDetayiGrafik: [],
  odeyiciDetayiTipi: 'Y'
}

const gunlukCiroRaporuSlice = createSlice({
  name: 'gunlukCiroRaporu',
  initialState,
  reducers: {
    gunlukCiraRaporuReducer(state, action) {
      const gunlukCiro = action.payload;
      state.gunlukCiro = gunlukCiro;
    },
    getGunlukCiroRaporuByGroup(state, action) {
      const gunlukCiroRaporuGroup = action.payload;
      state.gunlukCiroRaporuGroup = gunlukCiroRaporuGroup;
    },
    gunlukCiraRaporuGelisTuruDetayReducer(state, action) {
      const gunlukCiraRaporuGelisTuruDetay = action.payload;
      state.gunlukCiraRaporuGelisTuruDetay = gunlukCiraRaporuGelisTuruDetay;
    },
    gunlukCiroRaporuByHizmetDetayi(state, action) {
      const gunlukCiroRaporuHizmetDetayi = action.payload;
      state.gunlukCiroRaporuHizmetDetayi = gunlukCiroRaporuHizmetDetayi;
    },
    gunlukCiroRaporuButceTakibiEndeksHizmetCirosuReducer(state, action) {
      const gunlukCiroRaporuButceTakibiEndeksHizmetCirosu = action.payload;
      state.gunlukCiroRaporuButceTakibiEndeksHizmetCirosu = gunlukCiroRaporuButceTakibiEndeksHizmetCirosu;
    },
    gunlukCiroRaporuButceTakibiEndeksHizmetCirosuOranReducer(state, action) {
      const gunlukCiroRaporuButceTakibiEndeksHizmetCirosuOran = action.payload;
      state.gunlukCiroRaporuButceTakibiEndeksHizmetCirosuOran = gunlukCiroRaporuButceTakibiEndeksHizmetCirosuOran;
    },
    getGunlukCiroRaporuByOdeyiciDetayi(state, action) {
      const gunlukCiroRaporuOdeyiciDetayi = action.payload;
      state.gunlukCiroRaporuOdeyiciDetayi = gunlukCiroRaporuOdeyiciDetayi;
    },
    getGunlukCiroRaporuByOdeyiciDetayiGrafik(state, action) {
      const gunlukCiroRaporuOdeyiciDetayiGrafik = action.payload;
      state.gunlukCiroRaporuOdeyiciDetayiGrafik = gunlukCiroRaporuOdeyiciDetayiGrafik;
    },
    getOdeyiciDetayiTipi(state, action) {
      const odeyiciDetayiTipi = action.payload;
      state.odeyiciDetayiTipi = odeyiciDetayiTipi;
    }
  },
})

export const reducer = gunlukCiroRaporuSlice.reducer

const { gunlukCiraRaporuReducer, gunlukCiraRaporuGelisTuruDetayReducer, gunlukCiroRaporuButceTakibiEndeksHizmetCirosuReducer, gunlukCiroRaporuButceTakibiEndeksHizmetCirosuOranReducer } = gunlukCiroRaporuSlice.actions

export const gunlukCiraRaporuByHospitalAction = (hospitalId, dateVal) => async dispatch => {

  const gunlukCiraRaporuByHospital = trackPromise(gunlukCiroRaporuService.getGunlukCiraRaporuByHospital(hospitalId, dateVal));

  gunlukCiraRaporuByHospital.then((response) => {
    dispatch(gunlukCiraRaporuReducer(response.gunlukciroraporurowsList));
  })
}

export const getGunlukCiroRaporuByGroup = (date) => async dispatch => {
  const gunlukCiroRaporuByGroup = await trackPromise(gunlukCiroRaporuService.getGunlukCiroRaporuByGroup(date));

  dispatch(gunlukCiroRaporuSlice.actions.getGunlukCiroRaporuByGroup(gunlukCiroRaporuByGroup.gunlukciroraporurowsList));
}

export const gunlukCiraRaporuByGelisTuruDetayAction = (hospitalId, dateVal) => async dispatch => {

  const gunlukCiraRaporuByGelisTuruDetay = gunlukCiroRaporuService.getGunlukCiraRaporuByGelisTuruDetay(hospitalId, dateVal);

  gunlukCiraRaporuByGelisTuruDetay.then((response) => {
    dispatch(gunlukCiraRaporuGelisTuruDetayReducer(response.gunlukciraraporugelisturudetayrowdtoList));
  })
}

export const gunlukCiroRaporuButceTakibiEndeksHizmetCirosuAction = (hospitalId, dateVal, wQuery) => async dispatch => {

  if (wQuery === "-1") {
    const gunlukCiroRaporuButceTakibiEndeksHizmetCirosu = trackPromise(gunlukCiroRaporuService.getGunlukCiroRaporuButceTakibiEndeksHizmetCirosu(hospitalId, dateVal, wQuery));
    gunlukCiroRaporuButceTakibiEndeksHizmetCirosu.then((response) => {
      dispatch(gunlukCiroRaporuButceTakibiEndeksHizmetCirosuReducer(response.gunlukciroraporubutcetakibiendekshizmetcirosurowsList));
      //console.log('--->1',response)
    })
  } else {
    const gunlukCiroRaporuButceTakibiEndeksHizmetCirosu = trackPromise(gunlukCiroRaporuService.getGunlukCiroRaporuButceTakibiEndeksHizmetCirosu(hospitalId, dateVal, wQuery));
    gunlukCiroRaporuButceTakibiEndeksHizmetCirosu.then((response) => {
      dispatch(gunlukCiroRaporuButceTakibiEndeksHizmetCirosuOranReducer(response.gunlukciroraporubutcetakibiendekshizmetcirosurowsList));
      //console.log('--->2',response)
    })
  }

}

export const getGunlukCiroRaporuByHizmetDetayi = (kurumId, tarih) => async dispatch => {
  const gunlukCiroRaporuByHizmetDetayi = await trackPromise(gunlukCiroRaporuService.getGunlukCiroRaporuByHizmetDetayi(kurumId, tarih));

  dispatch(gunlukCiroRaporuSlice.actions.gunlukCiroRaporuByHizmetDetayi(gunlukCiroRaporuByHizmetDetayi.gunlukciroraporurowsList));
}

export const getGunlukCiroRaporuByOdeyiciDetayi = (odeyiciKurumTipi, tarih) => async dispatch => {
  const gunlukCiroRaporuByOdeyiciDetayi = await trackPromise(gunlukCiroRaporuService.getGunlukCiroRaporuByOdeyiciDetayi(odeyiciKurumTipi, tarih));

  dispatch(gunlukCiroRaporuSlice.actions.getGunlukCiroRaporuByOdeyiciDetayi(gunlukCiroRaporuByOdeyiciDetayi.gunlukciroraporurowsList));
}

export const getGunlukCiroRaporuByOdeyiciDetayiGrafik = (odeyiciKurumTipi, tarih, kurumId) => async dispatch => {
  const gunlukCiroRaporuByOdeyiciDetayiGrafik = await trackPromise(gunlukCiroRaporuService.getGunlukCiroRaporuByOdeyiciDetayiGrafik(odeyiciKurumTipi, tarih, kurumId));

  dispatch(gunlukCiroRaporuSlice.actions.getGunlukCiroRaporuByOdeyiciDetayiGrafik(gunlukCiroRaporuByOdeyiciDetayiGrafik.gunlukciroraporurowsList));
}

export const getOdeyiciDetayiTipi = (odeyiciDetayiTipi) => async dispatch => {
  dispatch(gunlukCiroRaporuSlice.actions.getOdeyiciDetayiTipi(odeyiciDetayiTipi));
}
