import { OzetYonetimRaporuClient } from "../../../protos/is-zekasi/rapor/ozet_yonetim_raporu_grpc_web_pb";
import {
    GetCiroAnaliziRequest,
    GetTahsilatAnaliziRequest,
    GetCiroDagilimiVeIslemAnaliziRequest,
} from "../../../protos/is-zekasi/rapor/ozet_yonetim_raporu_pb";

class OzetYonetimRaporuService {

    getCiroAnalizi = (kurumId, baslangicTarihi, bitisTarihi, kdvDahilMi) => {
        return new Promise((resolve, reject) => {

            const client = new OzetYonetimRaporuClient(process.env.REACT_APP_API_BASE_URL);
            const getCiroAnaliziRequest = new GetCiroAnaliziRequest();

            getCiroAnaliziRequest.setKurumid(kurumId);
            getCiroAnaliziRequest.setBaslangictarihi(baslangicTarihi);
            getCiroAnaliziRequest.setBitistarihi(bitisTarihi);
            getCiroAnaliziRequest.setKdvdahilmi(kdvDahilMi);

            var token = localStorage.getItem('accessToken');
            client.getCiroAnalizi(getCiroAnaliziRequest,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }

    getTahsilatAnalizi = (kurumId, baslangicTarihi, bitisTarihi) => {
        return new Promise((resolve, reject) => {

            const client = new OzetYonetimRaporuClient(process.env.REACT_APP_API_BASE_URL);
            const getTahsilatAnaliziRequest = new GetTahsilatAnaliziRequest();

            getTahsilatAnaliziRequest.setKurumid(kurumId);
            getTahsilatAnaliziRequest.setBaslangictarihi(baslangicTarihi);
            getTahsilatAnaliziRequest.setBitistarihi(bitisTarihi);

            var token = localStorage.getItem('accessToken');
            client.getTahsilatAnalizi(getTahsilatAnaliziRequest,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }

    getCiroDagilimiVeIslemAnalizi = (kurumId, baslangicTarihi, bitisTarihi, kdvDahilMi) => {
        return new Promise((resolve, reject) => {

            const client = new OzetYonetimRaporuClient(process.env.REACT_APP_API_BASE_URL);
            const getCiroDagilimiVeIslemAnaliziRequest = new GetCiroDagilimiVeIslemAnaliziRequest();

            getCiroDagilimiVeIslemAnaliziRequest.setKurumid(kurumId);
            getCiroDagilimiVeIslemAnaliziRequest.setBaslangictarihi(baslangicTarihi);
            getCiroDagilimiVeIslemAnaliziRequest.setBitistarihi(bitisTarihi);
            getCiroDagilimiVeIslemAnaliziRequest.setKdvdahilmi(kdvDahilMi);

            var token = localStorage.getItem('accessToken');
            client.getCiroDagilimiVeIslemAnalizi(getCiroDagilimiVeIslemAnaliziRequest,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }
}

const ozetYonetimRaporuService = new OzetYonetimRaporuService();

export default ozetYonetimRaporuService;
