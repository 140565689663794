import { createSlice } from '@reduxjs/toolkit'
import yatakDolulukOranlariService from "../services/hasta-hizmetleri/yatis-ve-taburcu/izleme/yatakDolulukOranlariService";
import { trackPromise } from 'react-promise-tracker';

const initialState = {
    donemTanimlari: [],
    kurumlar: [],
    yatakDolulukOranlari: []
  };

const yatakDolulukOranlariSlice = createSlice({
    name: 'yatakDolulukOranlariRaporu',
    initialState,  
    reducers: {
        DONEM_TANIMLARINI_GETIR_Reducer(state, action){
            const donemTanimlari = action.payload;
            state.donemTanimlari = donemTanimlari;
        },
        KURUMLARI_GETIR_Reducer(state, action){
            const kurumlar = action.payload;
            state.kurumlar = kurumlar;
        },   
        YATAK_DOLULUK_ORANLARINI_GETIR_Reducer(state, action){
            const yatakDolulukOranlari = action.payload;
            state.yatakDolulukOranlari = yatakDolulukOranlari;
        },               

    }
})

export const reducer = yatakDolulukOranlariSlice.reducer

const { DONEM_TANIMLARINI_GETIR_Reducer, KURUMLARI_GETIR_Reducer, YATAK_DOLULUK_ORANLARINI_GETIR_Reducer } = yatakDolulukOranlariSlice.actions

export const DONEM_TANIMLARINI_GETIR_Action = () => async dispatch => {

    const donemTanimlari = yatakDolulukOranlariService.donemTanimlariniGetir();
 
    donemTanimlari.then((response) => {
      dispatch(DONEM_TANIMLARINI_GETIR_Reducer(response.donemtanimlariList));
    })
  }

export const KURUMLARI_GETIR_Action = () => async dispatch => {

    const donemTanimlari = yatakDolulukOranlariService.kurumlariGetir();

    donemTanimlari.then((response) => {
        dispatch(KURUMLARI_GETIR_Reducer(response.kurumlarList));
    })
}  

export const YATAK_DOLULUK_ORANLARINI_GETIR_Action = (donemId, kurumlar) => async dispatch => {

    const yatakDolulukOranlari = trackPromise(yatakDolulukOranlariService.yatakDolulukOranlariniGetir(donemId, kurumlar));

    yatakDolulukOranlari.then((response) => {
        dispatch(YATAK_DOLULUK_ORANLARINI_GETIR_Reducer(response.yatakdolulukoranlariList));
    })
}  

