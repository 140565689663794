/**
 * @fileoverview gRPC-Web generated client stub for SurecTanimlari
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.SurecTanimlari = require('./surec_tanimlari_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.SurecTanimlari.SurecTanimlariClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.SurecTanimlari.SurecTanimlariPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.SurecTanimlari.SurecTanimEkleRequest,
 *   !proto.SurecTanimlari.SurecTanimEkleResponse>}
 */
const methodDescriptor_SurecTanimlari_SurecTanimEkle = new grpc.web.MethodDescriptor(
  '/SurecTanimlari.SurecTanimlari/SurecTanimEkle',
  grpc.web.MethodType.UNARY,
  proto.SurecTanimlari.SurecTanimEkleRequest,
  proto.SurecTanimlari.SurecTanimEkleResponse,
  /**
   * @param {!proto.SurecTanimlari.SurecTanimEkleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.SurecTanimlari.SurecTanimEkleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.SurecTanimlari.SurecTanimEkleRequest,
 *   !proto.SurecTanimlari.SurecTanimEkleResponse>}
 */
const methodInfo_SurecTanimlari_SurecTanimEkle = new grpc.web.AbstractClientBase.MethodInfo(
  proto.SurecTanimlari.SurecTanimEkleResponse,
  /**
   * @param {!proto.SurecTanimlari.SurecTanimEkleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.SurecTanimlari.SurecTanimEkleResponse.deserializeBinary
);


/**
 * @param {!proto.SurecTanimlari.SurecTanimEkleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.SurecTanimlari.SurecTanimEkleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.SurecTanimlari.SurecTanimEkleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.SurecTanimlari.SurecTanimlariClient.prototype.surecTanimEkle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/SurecTanimlari.SurecTanimlari/SurecTanimEkle',
      request,
      metadata || {},
      methodDescriptor_SurecTanimlari_SurecTanimEkle,
      callback);
};


/**
 * @param {!proto.SurecTanimlari.SurecTanimEkleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.SurecTanimlari.SurecTanimEkleResponse>}
 *     Promise that resolves to the response
 */
proto.SurecTanimlari.SurecTanimlariPromiseClient.prototype.surecTanimEkle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/SurecTanimlari.SurecTanimlari/SurecTanimEkle',
      request,
      metadata || {},
      methodDescriptor_SurecTanimlari_SurecTanimEkle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.SurecTanimlari.SurecTanimGuncelleRequest,
 *   !proto.SurecTanimlari.SurecTanimGuncelleResponse>}
 */
const methodDescriptor_SurecTanimlari_SurecTanimGuncelle = new grpc.web.MethodDescriptor(
  '/SurecTanimlari.SurecTanimlari/SurecTanimGuncelle',
  grpc.web.MethodType.UNARY,
  proto.SurecTanimlari.SurecTanimGuncelleRequest,
  proto.SurecTanimlari.SurecTanimGuncelleResponse,
  /**
   * @param {!proto.SurecTanimlari.SurecTanimGuncelleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.SurecTanimlari.SurecTanimGuncelleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.SurecTanimlari.SurecTanimGuncelleRequest,
 *   !proto.SurecTanimlari.SurecTanimGuncelleResponse>}
 */
const methodInfo_SurecTanimlari_SurecTanimGuncelle = new grpc.web.AbstractClientBase.MethodInfo(
  proto.SurecTanimlari.SurecTanimGuncelleResponse,
  /**
   * @param {!proto.SurecTanimlari.SurecTanimGuncelleRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.SurecTanimlari.SurecTanimGuncelleResponse.deserializeBinary
);


/**
 * @param {!proto.SurecTanimlari.SurecTanimGuncelleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.SurecTanimlari.SurecTanimGuncelleResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.SurecTanimlari.SurecTanimGuncelleResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.SurecTanimlari.SurecTanimlariClient.prototype.surecTanimGuncelle =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/SurecTanimlari.SurecTanimlari/SurecTanimGuncelle',
      request,
      metadata || {},
      methodDescriptor_SurecTanimlari_SurecTanimGuncelle,
      callback);
};


/**
 * @param {!proto.SurecTanimlari.SurecTanimGuncelleRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.SurecTanimlari.SurecTanimGuncelleResponse>}
 *     Promise that resolves to the response
 */
proto.SurecTanimlari.SurecTanimlariPromiseClient.prototype.surecTanimGuncelle =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/SurecTanimlari.SurecTanimlari/SurecTanimGuncelle',
      request,
      metadata || {},
      methodDescriptor_SurecTanimlari_SurecTanimGuncelle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.SurecTanimlari.SurecTanimGetirRequest,
 *   !proto.SurecTanimlari.SurecTanimGetirResponse>}
 */
const methodDescriptor_SurecTanimlari_GetSurecTanimGetir = new grpc.web.MethodDescriptor(
  '/SurecTanimlari.SurecTanimlari/GetSurecTanimGetir',
  grpc.web.MethodType.UNARY,
  proto.SurecTanimlari.SurecTanimGetirRequest,
  proto.SurecTanimlari.SurecTanimGetirResponse,
  /**
   * @param {!proto.SurecTanimlari.SurecTanimGetirRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.SurecTanimlari.SurecTanimGetirResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.SurecTanimlari.SurecTanimGetirRequest,
 *   !proto.SurecTanimlari.SurecTanimGetirResponse>}
 */
const methodInfo_SurecTanimlari_GetSurecTanimGetir = new grpc.web.AbstractClientBase.MethodInfo(
  proto.SurecTanimlari.SurecTanimGetirResponse,
  /**
   * @param {!proto.SurecTanimlari.SurecTanimGetirRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.SurecTanimlari.SurecTanimGetirResponse.deserializeBinary
);


/**
 * @param {!proto.SurecTanimlari.SurecTanimGetirRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.SurecTanimlari.SurecTanimGetirResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.SurecTanimlari.SurecTanimGetirResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.SurecTanimlari.SurecTanimlariClient.prototype.getSurecTanimGetir =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/SurecTanimlari.SurecTanimlari/GetSurecTanimGetir',
      request,
      metadata || {},
      methodDescriptor_SurecTanimlari_GetSurecTanimGetir,
      callback);
};


/**
 * @param {!proto.SurecTanimlari.SurecTanimGetirRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.SurecTanimlari.SurecTanimGetirResponse>}
 *     Promise that resolves to the response
 */
proto.SurecTanimlari.SurecTanimlariPromiseClient.prototype.getSurecTanimGetir =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/SurecTanimlari.SurecTanimlari/GetSurecTanimGetir',
      request,
      metadata || {},
      methodDescriptor_SurecTanimlari_GetSurecTanimGetir);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.SurecTanimlari.GetSurecAgaciRequest,
 *   !proto.SurecTanimlari.GetSurecAgaciResponse>}
 */
const methodDescriptor_SurecTanimlari_GetSurecAgaci = new grpc.web.MethodDescriptor(
  '/SurecTanimlari.SurecTanimlari/GetSurecAgaci',
  grpc.web.MethodType.UNARY,
  proto.SurecTanimlari.GetSurecAgaciRequest,
  proto.SurecTanimlari.GetSurecAgaciResponse,
  /**
   * @param {!proto.SurecTanimlari.GetSurecAgaciRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.SurecTanimlari.GetSurecAgaciResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.SurecTanimlari.GetSurecAgaciRequest,
 *   !proto.SurecTanimlari.GetSurecAgaciResponse>}
 */
const methodInfo_SurecTanimlari_GetSurecAgaci = new grpc.web.AbstractClientBase.MethodInfo(
  proto.SurecTanimlari.GetSurecAgaciResponse,
  /**
   * @param {!proto.SurecTanimlari.GetSurecAgaciRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.SurecTanimlari.GetSurecAgaciResponse.deserializeBinary
);


/**
 * @param {!proto.SurecTanimlari.GetSurecAgaciRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.SurecTanimlari.GetSurecAgaciResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.SurecTanimlari.GetSurecAgaciResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.SurecTanimlari.SurecTanimlariClient.prototype.getSurecAgaci =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/SurecTanimlari.SurecTanimlari/GetSurecAgaci',
      request,
      metadata || {},
      methodDescriptor_SurecTanimlari_GetSurecAgaci,
      callback);
};


/**
 * @param {!proto.SurecTanimlari.GetSurecAgaciRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.SurecTanimlari.GetSurecAgaciResponse>}
 *     Promise that resolves to the response
 */
proto.SurecTanimlari.SurecTanimlariPromiseClient.prototype.getSurecAgaci =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/SurecTanimlari.SurecTanimlari/GetSurecAgaci',
      request,
      metadata || {},
      methodDescriptor_SurecTanimlari_GetSurecAgaci);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.SurecTanimlari.UpdateSurecAgaciMenuRequest,
 *   !proto.SurecTanimlari.UpdateSurecAgaciMenuResponse>}
 */
const methodDescriptor_SurecTanimlari_UpdateSurecAgaciMenu = new grpc.web.MethodDescriptor(
  '/SurecTanimlari.SurecTanimlari/UpdateSurecAgaciMenu',
  grpc.web.MethodType.UNARY,
  proto.SurecTanimlari.UpdateSurecAgaciMenuRequest,
  proto.SurecTanimlari.UpdateSurecAgaciMenuResponse,
  /**
   * @param {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.SurecTanimlari.UpdateSurecAgaciMenuResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.SurecTanimlari.UpdateSurecAgaciMenuRequest,
 *   !proto.SurecTanimlari.UpdateSurecAgaciMenuResponse>}
 */
const methodInfo_SurecTanimlari_UpdateSurecAgaciMenu = new grpc.web.AbstractClientBase.MethodInfo(
  proto.SurecTanimlari.UpdateSurecAgaciMenuResponse,
  /**
   * @param {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.SurecTanimlari.UpdateSurecAgaciMenuResponse.deserializeBinary
);


/**
 * @param {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.SurecTanimlari.UpdateSurecAgaciMenuResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.SurecTanimlari.UpdateSurecAgaciMenuResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.SurecTanimlari.SurecTanimlariClient.prototype.updateSurecAgaciMenu =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/SurecTanimlari.SurecTanimlari/UpdateSurecAgaciMenu',
      request,
      metadata || {},
      methodDescriptor_SurecTanimlari_UpdateSurecAgaciMenu,
      callback);
};


/**
 * @param {!proto.SurecTanimlari.UpdateSurecAgaciMenuRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.SurecTanimlari.UpdateSurecAgaciMenuResponse>}
 *     Promise that resolves to the response
 */
proto.SurecTanimlari.SurecTanimlariPromiseClient.prototype.updateSurecAgaciMenu =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/SurecTanimlari.SurecTanimlari/UpdateSurecAgaciMenu',
      request,
      metadata || {},
      methodDescriptor_SurecTanimlari_UpdateSurecAgaciMenu);
};


module.exports = proto.SurecTanimlari;

