import { YatakDolulukOranlariClient } from "src/protos/hasta-hizmetleri/yatis-taburcu/izleme/yatak_doluluk_oranlari_grpc_web_pb.js";
import { YatakDolulukOranlariniGetirRequest } from "src/protos/hasta-hizmetleri/yatis-taburcu/izleme/yatak_doluluk_oranlari_pb.js";

import { Empty } from "google-protobuf/google/protobuf/empty_pb";

class YatakDolulukOranlariService {
  donemTanimlariniGetir = () => {
    return new Promise((resolve, reject) => {
      const client = new YatakDolulukOranlariClient(
        process.env.REACT_APP_API_BASE_URL
      );
      const request = new Empty();
      var token = localStorage.getItem('accessToken');
      client.donemTanimlariniGetir(request, {"lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
        if (response) {
          resolve(response.toObject());
        } else {
          reject(error);
        }
      });
    });
  };

  kurumlariGetir = () => {
    return new Promise((resolve, reject) => {
      const client = new YatakDolulukOranlariClient(
        process.env.REACT_APP_API_BASE_URL
      );
      const request = new Empty();
      var token = localStorage.getItem('accessToken');
      client.kurumlariGetir(request, {"lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
        if (response) {
          resolve(response.toObject());
        } else {
          reject(error);
        }
      });
    });
  };

  yatakDolulukOranlariniGetir = (donemId, kurumId) => {
    return new Promise((resolve, reject) => {
      const client = new YatakDolulukOranlariClient(
        process.env.REACT_APP_API_BASE_URL
      );
      const request = new YatakDolulukOranlariniGetirRequest();
      request.setDonemid(donemId);
      request.setKurumid(kurumId);
      var token = localStorage.getItem('accessToken');
      client.yatakDolulukOranlariniGetir(request, {"lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
        if (response) {
          resolve(response.toObject());
        } else {
          reject(error);
        }
      });
    });
  };
}

const yatakDolulukOranlariService = new YatakDolulukOranlariService();

export default yatakDolulukOranlariService;
