import { createSlice } from '@reduxjs/toolkit'
import { trackPromise } from 'react-promise-tracker';
import raporTanimService from "../services/is-zekasi/rapor-tanimlari/surec-tanimlari/raporTanimService";

const initialState = {
    raporTanimlari: [],
    raporTanim: {},
  }

const raporTanimSlice = createSlice({
    name: 'raporTanim',
    initialState,
    reducers: {
    raporTanimlariGetirReducer(state, action) {
        const raporTanimlari = action.payload;
        state.raporTanimlari = raporTanimlari;
    },
    raporTanimEkleReducer(state, action) {
      const status = action.payload;
      state.status = status;
    },
    raporTanimGetirReducer(state, action) {
      state.raporTanim =  Object.assign({}, action.payload);
    },
    raporTanimGuncelleReducer(state, action) {
      const raporTanim = action.payload;
      state.raporTanim = raporTanim;
    },
},
})  

export const reducer = raporTanimSlice.reducer

const { raporTanimlariGetirReducer, 
        raporTanimEkleReducer,
        raporTanimGetirReducer,
        raporTanimGuncelleReducer
      } = raporTanimSlice.actions

export const getRaporTanimlariGetir = (personelID)  => async dispatch =>  {
    const response = await  trackPromise(raporTanimService.getRaporTanimList(personelID));
    dispatch(raporTanimlariGetirReducer(response.hazirraportanimlistbysurectanimidList));
  }

export const setHazirRaporTanimEkle = (Tipi, Adi, Tanimi, SonOkumaTipi, SonOkuma, KayitDurumu)  => async dispatch =>  {
  const response = await  trackPromise(raporTanimService.setHazirRaporTanimEkle(Tipi, Adi, Tanimi, SonOkumaTipi, SonOkuma, KayitDurumu));
  dispatch(raporTanimEkleReducer(response.status));
}

export const getRaporTanimGetir = (id)  => async dispatch =>  {
  const response = await  trackPromise(raporTanimService.getRaporTanimGetir(id)); 
  dispatch(raporTanimGetirReducer(response));
}

export const getRaporTanimGuncelle = (Id, Tipi, Adi, Tanimi, SonOkumaTipi, SonOkuma, KayitDurumu)  => async dispatch =>  {
  const response = await  trackPromise(raporTanimService.setRaporTanimGuncelle(Id, Tipi, Adi, Tanimi, SonOkumaTipi, SonOkuma, KayitDurumu)); 
  dispatch(raporTanimGuncelleReducer(response.status));
}


