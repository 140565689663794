/* eslint-disable */
//@ts-nocheck

/**
 * @fileoverview gRPC-Web generated client stub for TaniIslemAnalizi
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.TaniIslemAnalizi = require('./tani_islem_analizi_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.TaniIslemAnalizi.TaniIslemAnaliziClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.TaniIslemAnalizi.TaniIslemAnaliziPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest,
 *   !proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse>}
 */
const methodDescriptor_TaniIslemAnalizi_TaniAnalizleriniGetir = new grpc.web.MethodDescriptor(
  '/TaniIslemAnalizi.TaniIslemAnalizi/TaniAnalizleriniGetir',
  grpc.web.MethodType.UNARY,
  proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest,
  proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse,
  /**
   * @param {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest,
 *   !proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse>}
 */
const methodInfo_TaniIslemAnalizi_TaniAnalizleriniGetir = new grpc.web.AbstractClientBase.MethodInfo(
  proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse,
  /**
   * @param {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse.deserializeBinary
);


/**
 * @param {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TaniIslemAnalizi.TaniIslemAnaliziClient.prototype.taniAnalizleriniGetir =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TaniIslemAnalizi.TaniIslemAnalizi/TaniAnalizleriniGetir',
      request,
      metadata || {},
      methodDescriptor_TaniIslemAnalizi_TaniAnalizleriniGetir,
      callback);
};


/**
 * @param {!proto.TaniIslemAnalizi.TaniAnalizleriniGetirRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TaniIslemAnalizi.TaniAnalizleriniGetirResponse>}
 *     Promise that resolves to the response
 */
proto.TaniIslemAnalizi.TaniIslemAnaliziPromiseClient.prototype.taniAnalizleriniGetir =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TaniIslemAnalizi.TaniIslemAnalizi/TaniAnalizleriniGetir',
      request,
      metadata || {},
      methodDescriptor_TaniIslemAnalizi_TaniAnalizleriniGetir);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest,
 *   !proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse>}
 */
const methodDescriptor_TaniIslemAnalizi_IslemAnalizleriniGetir = new grpc.web.MethodDescriptor(
  '/TaniIslemAnalizi.TaniIslemAnalizi/IslemAnalizleriniGetir',
  grpc.web.MethodType.UNARY,
  proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest,
  proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse,
  /**
   * @param {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest,
 *   !proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse>}
 */
const methodInfo_TaniIslemAnalizi_IslemAnalizleriniGetir = new grpc.web.AbstractClientBase.MethodInfo(
  proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse,
  /**
   * @param {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse.deserializeBinary
);


/**
 * @param {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TaniIslemAnalizi.TaniIslemAnaliziClient.prototype.islemAnalizleriniGetir =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TaniIslemAnalizi.TaniIslemAnalizi/IslemAnalizleriniGetir',
      request,
      metadata || {},
      methodDescriptor_TaniIslemAnalizi_IslemAnalizleriniGetir,
      callback);
};


/**
 * @param {!proto.TaniIslemAnalizi.IslemAnalizleriniGetirRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TaniIslemAnalizi.IslemAnalizleriniGetirResponse>}
 *     Promise that resolves to the response
 */
proto.TaniIslemAnalizi.TaniIslemAnaliziPromiseClient.prototype.islemAnalizleriniGetir =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TaniIslemAnalizi.TaniIslemAnalizi/IslemAnalizleriniGetir',
      request,
      metadata || {},
      methodDescriptor_TaniIslemAnalizi_IslemAnalizleriniGetir);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.TaniIslemAnalizi.BolumGetirResponse>}
 */
const methodDescriptor_TaniIslemAnalizi_MerkeziBolumleriGetir = new grpc.web.MethodDescriptor(
  '/TaniIslemAnalizi.TaniIslemAnalizi/MerkeziBolumleriGetir',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.TaniIslemAnalizi.BolumGetirResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TaniIslemAnalizi.BolumGetirResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.TaniIslemAnalizi.BolumGetirResponse>}
 */
const methodInfo_TaniIslemAnalizi_MerkeziBolumleriGetir = new grpc.web.AbstractClientBase.MethodInfo(
  proto.TaniIslemAnalizi.BolumGetirResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.TaniIslemAnalizi.BolumGetirResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.TaniIslemAnalizi.BolumGetirResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.TaniIslemAnalizi.BolumGetirResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.TaniIslemAnalizi.TaniIslemAnaliziClient.prototype.merkeziBolumleriGetir =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/TaniIslemAnalizi.TaniIslemAnalizi/MerkeziBolumleriGetir',
      request,
      metadata || {},
      methodDescriptor_TaniIslemAnalizi_MerkeziBolumleriGetir,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.TaniIslemAnalizi.BolumGetirResponse>}
 *     Promise that resolves to the response
 */
proto.TaniIslemAnalizi.TaniIslemAnaliziPromiseClient.prototype.merkeziBolumleriGetir =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/TaniIslemAnalizi.TaniIslemAnalizi/MerkeziBolumleriGetir',
      request,
      metadata || {},
      methodDescriptor_TaniIslemAnalizi_MerkeziBolumleriGetir);
};


module.exports = proto.TaniIslemAnalizi;

