import { RaporPaneliClient } from "src/protos/is-zekasi/rapor_paneli_grpc_web_pb.js";
import { SurecVeRaporAgaciRequest } from "src/protos/is-zekasi/rapor_paneli_pb.js";
import { RaporBilgisiniGetirRequest } from "src/protos/is-zekasi/rapor_paneli_pb.js";
import { RaporOkumaGecmisiniGetirRequest } from "src/protos/is-zekasi/rapor_paneli_pb";
import { SaveReportReadingHistoryRequest } from "src/protos/is-zekasi/rapor_paneli_pb";

class RaporPaneliService {
    getSurecVeRaporAgaci = (personelId) => {
        return new Promise((resolve, reject) => {
            const client = new RaporPaneliClient(process.env.REACT_APP_API_BASE_URL);
            const surecVeRaporAgaciRequest = new SurecVeRaporAgaciRequest();
            surecVeRaporAgaciRequest.setPersonelid(personelId);
            var token = localStorage.getItem('accessToken');
            client.surecVeRaporAgaciniGetir(surecVeRaporAgaciRequest, { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                if (response) {
                    setTimeout(() => {
                        resolve(response.toObject());
                    }, 1000);
                } else {
                    reject(error);
                }
            })
        })
    }

    getRaporBilgisi = (personelId, hazirRaporTanimId) => {
        return new Promise((resolve, reject) => {
            const client = new RaporPaneliClient(process.env.REACT_APP_API_BASE_URL);
            const raporBilgisiniGetirRequest = new RaporBilgisiniGetirRequest();
            raporBilgisiniGetirRequest.setPersonelid(personelId);
            raporBilgisiniGetirRequest.setHazirraportanimid(hazirRaporTanimId);
            var token = localStorage.getItem('accessToken');
            client.raporBilgisiniGetir(raporBilgisiniGetirRequest, { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                if (response) {
                    setTimeout(() => {
                        resolve(response.toObject());
                    }, 1000);
                } else {
                    reject(error);
                }
            })
        })
    }

    getRaporOkumaGecmisi = (hazirRaporTanimId) => {
        return new Promise((resolve, reject) => {
            const client = new RaporPaneliClient(process.env.REACT_APP_API_BASE_URL);
            const raporOkumaGecmisiniGetirRequest = new RaporOkumaGecmisiniGetirRequest();
            raporOkumaGecmisiniGetirRequest.setHazirraportanimid(hazirRaporTanimId);
            var token = localStorage.getItem('accessToken');
            client.raporOkumaGecmisiniGetir(raporOkumaGecmisiniGetirRequest, { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                if (response) {
                    setTimeout(() => {
                        resolve(response.toObject());
                    }, 1000);
                } else {
                    reject(error);
                }
            })
        })
    }

    saveReportReadingHistory = (personelId, hazirRaporTanimId) => {
        return new Promise((resolve, reject) => {
            const client = new RaporPaneliClient(process.env.REACT_APP_API_BASE_URL);
            const saveReportReadingHistoryRequest = new SaveReportReadingHistoryRequest();
            saveReportReadingHistoryRequest.setPersonelid(personelId);
            saveReportReadingHistoryRequest.setHazirraportanimid(hazirRaporTanimId);
            var token = localStorage.getItem('accessToken');
            client.saveReportReadingHistory(saveReportReadingHistoryRequest, { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                if (response) {
                    setTimeout(() => {
                        resolve(response.toObject());
                    }, 1000);
                } else {
                    reject(error);
                }
            })
        });
    }
}

const raporPaneliService = new RaporPaneliService();

export default raporPaneliService;