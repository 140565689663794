import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Toolbar, Hidden, Button, Box } from '@material-ui/core';
import Menu from 'devextreme-react/menu';
import { useHistory } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height: 240,
    flexGrow: 1,
    maxWidth: 400
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  flexGrow: {
    flexGrow: 1
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  toolbar: {
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    backgroundColor:
      JSON.parse(localStorage.getItem('settings')) === undefined ||
        JSON.parse(localStorage.getItem('settings')) === null
        ? '#293142'
        : JSON.parse(localStorage.getItem('settings')).theme === 'UNICORN'
          ? '#293142'
          : '#fff'
  },
  listItem: {
    cursor: 'pointer',
    paddingTop: 0,
    paddingBottom: 0
  },
  listItemText: {
    flex: '0 0 auto',
    whiteSpace: 'nowrap',
    textDecoration: 'none'
  },
  listItemButton: {
    whiteSpace: 'nowrap'
  },
  iconButton: {
    padding: 0,
    '&:hover': {
      background: 'transparent'
    }
  },
  logoContainer: {
    width: 100,
    height: 28,
    [theme.breakpoints.up('md')]: {
      width: 120,
      height: 32,
      marginLeft: '3%',
      marginRight: '4.2%'
    }
  },
  logoImage: {
    width: '100%',
    height: '100%'
  }
}));

const Topbar = props => {
  //const { sections } = props;

  const classes = useStyles();
  const history = useHistory();
  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleLogout = async () => {
    try {
      logout();
      history.push('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error'
      });
    }
  };

  // const itemClick = e => {
  //   if (e.itemData.href !== undefined) {
  //     if (e.itemData.name == 'Çıkış') {
  //       handleLogout();
  //     } else {
  //       history.push(e.itemData.href);
  //     }
  //   }
  // };

  const theme = createMuiTheme({
    overrides: {
      MuiToolbar: {
        regular: {
          height: '48px',
          minHeight: '48px',
          '@media(min-width:600px)': {
            minHeight: '48px'
          }
        }
      }
    }
  });

  return (
    <MuiThemeProvider theme={theme}>
      <Toolbar disableGutters className={classes.toolbar}>
        <div style={{ marginLeft: 16 }}>
          <a
            href="/"
            title="medicana"
            style={{
              color:
                JSON.parse(localStorage.getItem('settings')) === undefined ||
                  JSON.parse(localStorage.getItem('settings')) === null
                  ? '#fff'
                  : JSON.parse(localStorage.getItem('settings')).theme ===
                    'UNICORN'
                    ? '#fff'
                    : '#161E27',
              textDecoration: 'none',
              textTransform: 'uppercase',
              fontSize: '19px',
              textShadow: '1px 1px 1px rgba(0,0,0,.5), 2px 2px 10px'
            }}
          >
            <img src="/static/medicana-saglik-grubu-logo.png"></img> &nbsp;&nbsp;&nbsp;&nbsp;
          </a>
        </div>
        {/* <Hidden smDown>
          <div style={{ marginLeft: 16 }}>
            <Menu
              dataSource={sections}
              displayExpr="name"
              onItemClick={itemClick}
            />
          </div>
        </Hidden> */}
        <Box
          ml={2}
          flexGrow={1}
        />
        <Button variant="outlined"  onClick={handleLogout}>
          <img src={'/static/images/icons/round_exit_to_app_black_18dp.png'} alt="Geliş Türü (Yatan)" title="Geliş Türü (Yatan)"></img> &nbsp; Çıkış
        </Button>
      </Toolbar>
    </MuiThemeProvider>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  sections: PropTypes.array.isRequired
};

export default Topbar;
