import { createSlice } from '@reduxjs/toolkit'
import yetkiTanimlariService from "../services/generalcontent/yetkiTanimlariService";
import { trackPromise } from 'react-promise-tracker';

export const GET_HOSPITAL_LIST = "@general-content/hospitallist";

const initialState = {
    roles: [],
    surecTanimPermissions: [],
    hazirRaporTanimPermissions: [],
    status: false
}

const yetkiTanimlariSlice = createSlice({
    name: 'yetkiTanimlari',
    initialState,
    reducers: {
        getAllRoles(state, action) {
            const roles = action.payload;
            state.roles = roles;
        },
        getSurecTanimPermissionsBySurecTanimId(state, action) {
            const surecTanimPermissions = action.payload;
            state.surecTanimPermissions = surecTanimPermissions;
        },
        addSurecTanimPermission(state, action) {
            const status = action.payload;
            state.status = status;
        },
        editSurecTanimPermission(state, action) {
            const status = action.payload;
            state.status = status;
        },
        deleteSurecTanimPermission(state, action) {
            const status = action.payload;
            state.status = status;
        },
        getHazirRaporTanimPermissionsByHazirRaporTanimId(state, action) {
            const hazirRaporTanimPermissions = action.payload;
            state.hazirRaporTanimPermissions = hazirRaporTanimPermissions;
        },
        addHazirRaporPermission(state, action) {
            const status = action.payload;
            state.status = status;
        },
        editHazirRaporPermission(state, action) {
            const status = action.payload;
            state.status = status;
        },
        deleteHazirRaporPermission(state, action) {
            const status = action.payload;
            state.status = status;
        },
    },
})

export const reducer = yetkiTanimlariSlice.reducer

export const getAllRoles = () => async dispatch => {
    const roles = await trackPromise(yetkiTanimlariService.getAllRoles());
    dispatch(yetkiTanimlariSlice.actions.getAllRoles(roles.rolerowsList));
}

export const getSurecTanimPermissionsBySurecTanimId = (surecTanimId) => async dispatch => {
    const surecTanimPermissions = await trackPromise(yetkiTanimlariService.getSurecTanimPermissionsBySurecTanimId(surecTanimId));
    dispatch(yetkiTanimlariSlice.actions.getSurecTanimPermissionsBySurecTanimId(surecTanimPermissions.surectanimpermissionrowsList));
}

export const addSurecTanimPermission = (input) => async dispatch => {
    const result = await trackPromise(yetkiTanimlariService.addSurecTanimPermission(input));
    dispatch(yetkiTanimlariSlice.actions.addSurecTanimPermission(result.status));
}

export const editSurecTanimPermission = (userId, input) => async dispatch => {
    const result = await trackPromise(yetkiTanimlariService.editSurecTanimPermission(userId, input));
    dispatch(yetkiTanimlariSlice.actions.editSurecTanimPermission(result.status));
}

export const deleteSurecTanimPermission = (surecTanimId) => async dispatch => {
    const result = await trackPromise(yetkiTanimlariService.deleteSurecTanimPermission(surecTanimId));
    dispatch(yetkiTanimlariSlice.actions.deleteSurecTanimPermission(result.status));
}

export const getHazirRaporTanimPermissionsByHazirRaporTanimId = (hazirRaporTanimId) => async dispatch => {
    const hazirRaporTanimPermissions = await trackPromise(yetkiTanimlariService.getHazirRaporTanimPermissionsByHazirRaporTanimId(hazirRaporTanimId));
    dispatch(yetkiTanimlariSlice.actions.getHazirRaporTanimPermissionsByHazirRaporTanimId(hazirRaporTanimPermissions.hazirraportanimpermissionrowsList));
}

export const addHazirRaporPermission = (input) => async dispatch => {
    const result = await trackPromise(yetkiTanimlariService.addHazirRaporPermission(input));
    dispatch(yetkiTanimlariSlice.actions.addHazirRaporPermission(result.status));
}

export const editHazirRaporPermission = (userId, input) => async dispatch => {
    const result = await trackPromise(yetkiTanimlariService.editHazirRaporPermission(userId, input));
    dispatch(yetkiTanimlariSlice.actions.editHazirRaporPermission(result.status));
}

export const deleteHazirRaporPermission = (hazirRaporTanimId) => async dispatch => {
    const result = await trackPromise(yetkiTanimlariService.deleteHazirRaporPermission(hazirRaporTanimId));
    dispatch(yetkiTanimlariSlice.actions.deleteHazirRaporPermission(result.status));
}