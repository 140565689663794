import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedShortcut: ''
};

const slice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        getSelectedShortcut(state, action) {
            const selectedShortcut = action.payload;
            state.selectedShortcut = selectedShortcut;
        }
    }
});

export const reducer = slice.reducer;

export const getSelectedShortcut = (shortcut) => async (dispatch) => {
    dispatch(slice.actions.getSelectedShortcut(shortcut));
};

export default slice;
