import { createSlice } from '@reduxjs/toolkit'
import ozetYonetimRaporuService from "../services/is-zekasi/rapor/ozetYonetimRaporuService";
import { trackPromise } from 'react-promise-tracker';

var date = new Date();
date.setDate(date.getDate() - 1);

const initialState = {
    ciroAnalizi: [],
    tahsilatAnalizi: [],
    ciroDagilimiVeIslemAnalizi: []
}

const ozetYonetimRaporuSlice = createSlice({
    name: 'ozetYonetimRaporu',
    initialState,
    reducers: {
        getCiroAnalizi(state, action) {
            const ciroAnalizi = action.payload;
            state.ciroAnalizi = ciroAnalizi;
        },
        getTahsilatAnalizi(state, action) {
            const tahsilatAnalizi = action.payload;
            state.tahsilatAnalizi = tahsilatAnalizi;
        },
        getCiroDagilimiVeIslemAnalizi(state, action) {
            const ciroDagilimiVeIslemAnalizi = action.payload;
            state.ciroDagilimiVeIslemAnalizi = ciroDagilimiVeIslemAnalizi;
        },
    },
})

export const reducer = ozetYonetimRaporuSlice.reducer

export const getCiroAnalizi = (kurumId, baslangicTarihi, bitisTarihi, kdvDahilMi) => async dispatch => {

    const ciroAnalizi = trackPromise(ozetYonetimRaporuService.getCiroAnalizi(kurumId, baslangicTarihi, bitisTarihi, kdvDahilMi));

    ciroAnalizi.then((response) => {

        //console.log("response", response);
        

        dispatch(ozetYonetimRaporuSlice.actions.getCiroAnalizi(response.ciroanalizirowsList));
    })
}

export const getTahsilatAnalizi = (kurumId, baslangicTarihi, bitisTarihi) => async dispatch => {

    const tahsilatAnalizi = trackPromise(ozetYonetimRaporuService.getTahsilatAnalizi(kurumId, baslangicTarihi, bitisTarihi));

    tahsilatAnalizi.then((response) => {
        dispatch(ozetYonetimRaporuSlice.actions.getTahsilatAnalizi(response.tahsilatanalizirowsList));
    })
}

export const getCiroDagilimiVeIslemAnalizi = (kurumId, baslangicTarihi, bitisTarihi, kdvDahilMi) => async dispatch => {

    const ciroDagilimiVeIslemAnalizi = trackPromise(ozetYonetimRaporuService.getCiroDagilimiVeIslemAnalizi(kurumId, baslangicTarihi, bitisTarihi, kdvDahilMi));

    ciroDagilimiVeIslemAnalizi.then((response) => {
        dispatch(ozetYonetimRaporuSlice.actions.getCiroDagilimiVeIslemAnalizi(response.cirodagilimiveislemanalizirowsList));
    })
}

