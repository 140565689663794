import { OzelRaporClient } from "../../../protos/is-zekasi/rapor/ozel_rapor_grpc_web_pb";
import { GetDinamikRaporRequest } from "../../../protos/is-zekasi/rapor/ozel_rapor_pb";

class OzelRaporService{

    getDinamikRapor = (wpc, hospitalIDs, dateStart, dateEnd) => {
        return new Promise((resolve, reject) => {
    
          const client = new OzelRaporClient(process.env.REACT_APP_API_BASE_URL);
          const getDinamikRaporRequest = new GetDinamikRaporRequest();

          let issid=localStorage.getItem('lsiid');
          getDinamikRaporRequest.setMyhospitalid(issid);
          getDinamikRaporRequest.setDatestart(dateStart);
          getDinamikRaporRequest.setDateend(dateEnd);
          getDinamikRaporRequest.setWpc(wpc);

          for(let hrow in hospitalIDs){
            getDinamikRaporRequest.addHospitalids(hospitalIDs[hrow].id);
          }
          
          console.log('hospitalIDs service',getDinamikRaporRequest.getHospitalidsList());

          //getDinamikRaporRequest.addHospitalids(123);
          //getDinamikRaporRequest.addHospitalids(456);
        
          var token = localStorage.getItem('accessToken');
          client.getDinamikRapor(getDinamikRaporRequest,
            { "lsiid": issid, "Authorization": token }, (error, response) => {
              if (response) {
                //console.log('Response->x1->', response);
                resolve(response.toObject());
              } else {
                //console.log('error->x1->', error);
                reject(error);
              }
            });
    
        });
      }

}

const ozelRaporService = new OzelRaporService();

export default ozelRaporService;
