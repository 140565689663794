/**
 * @fileoverview gRPC-Web generated client stub for doktorpanelipr
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.doktorpanelipr = require('./doktorpanelipr_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.doktorpanelipr.DoktorPaneliPRClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.AyaktanTedaviRequestGrpc,
 *   !proto.doktorpanelipr.AyaktanTedaviResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_AyaktanTedavi = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/AyaktanTedavi',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.AyaktanTedaviRequestGrpc,
  proto.doktorpanelipr.AyaktanTedaviResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.AyaktanTedaviResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.AyaktanTedaviRequestGrpc,
 *   !proto.doktorpanelipr.AyaktanTedaviResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_AyaktanTedavi = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.AyaktanTedaviResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.AyaktanTedaviResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.AyaktanTedaviResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.AyaktanTedaviResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.ayaktanTedavi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/AyaktanTedavi',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_AyaktanTedavi,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.AyaktanTedaviResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.ayaktanTedavi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/AyaktanTedavi',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_AyaktanTedavi);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.YatarakTedaviRequestGrpc,
 *   !proto.doktorpanelipr.YatarakTedaviResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_YatarakTedavi = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/YatarakTedavi',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.YatarakTedaviRequestGrpc,
  proto.doktorpanelipr.YatarakTedaviResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.YatarakTedaviResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.YatarakTedaviRequestGrpc,
 *   !proto.doktorpanelipr.YatarakTedaviResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_YatarakTedavi = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.YatarakTedaviResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.YatarakTedaviResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.YatarakTedaviResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.YatarakTedaviResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.yatarakTedavi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/YatarakTedavi',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_YatarakTedavi,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.YatarakTedaviResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.yatarakTedavi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/YatarakTedavi',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_YatarakTedavi);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.IstekQORequestGrpc,
 *   !proto.doktorpanelipr.IstekQOResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_IstekQO = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/IstekQO',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.IstekQORequestGrpc,
  proto.doktorpanelipr.IstekQOResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.IstekQORequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.IstekQOResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.IstekQORequestGrpc,
 *   !proto.doktorpanelipr.IstekQOResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_IstekQO = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.IstekQOResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.IstekQORequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.IstekQOResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.IstekQORequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.IstekQOResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.IstekQOResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.istekQO =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/IstekQO',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_IstekQO,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.IstekQORequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.IstekQOResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.istekQO =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/IstekQO',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_IstekQO);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.GenelMuayeneFormRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneFormResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_GenelMuayeneForm = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneForm',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.GenelMuayeneFormRequestGrpc,
  proto.doktorpanelipr.GenelMuayeneFormResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneFormRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.GenelMuayeneFormRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneFormResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_GenelMuayeneForm = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.GenelMuayeneFormResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneFormRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.GenelMuayeneFormResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.genelMuayeneForm =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneForm',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneForm,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.genelMuayeneForm =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneForm',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneForm);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_GenelMuayeneFormTumTanilarByPersonel = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneFormTumTanilarByPersonel',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc,
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_GenelMuayeneFormTumTanilarByPersonel = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.genelMuayeneFormTumTanilarByPersonel =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneFormTumTanilarByPersonel',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneFormTumTanilarByPersonel,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.genelMuayeneFormTumTanilarByPersonel =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneFormTumTanilarByPersonel',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneFormTumTanilarByPersonel);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_GenelMuayeneFormTumTanilarByIstek = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneFormTumTanilarByIstek',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc,
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_GenelMuayeneFormTumTanilarByIstek = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.genelMuayeneFormTumTanilarByIstek =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneFormTumTanilarByIstek',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneFormTumTanilarByIstek,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.genelMuayeneFormTumTanilarByIstek =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneFormTumTanilarByIstek',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneFormTumTanilarByIstek);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_GenelMuayeneFormRecete = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneFormRecete',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc,
  proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_GenelMuayeneFormRecete = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.genelMuayeneFormRecete =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneFormRecete',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneFormRecete,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.genelMuayeneFormRecete =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneFormRecete',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneFormRecete);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_GenelMuayeneFormForDoctor = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneFormForDoctor',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc,
  proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_GenelMuayeneFormForDoctor = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.genelMuayeneFormForDoctor =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneFormForDoctor',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneFormForDoctor,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.genelMuayeneFormForDoctor =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneFormForDoctor',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneFormForDoctor);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_GenelMuayeneFormTedaviKontrol = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneFormTedaviKontrol',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc,
  proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_GenelMuayeneFormTedaviKontrol = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.genelMuayeneFormTedaviKontrol =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneFormTedaviKontrol',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneFormTedaviKontrol,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.genelMuayeneFormTedaviKontrol =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneFormTedaviKontrol',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneFormTedaviKontrol);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_GenelMuayeneTeknikTest = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneTeknikTest',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc,
  proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_GenelMuayeneTeknikTest = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.genelMuayeneTeknikTest =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneTeknikTest',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneTeknikTest,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.genelMuayeneTeknikTest =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneTeknikTest',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneTeknikTest);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_GenelMuayeneTetkikler = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneTetkikler',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc,
  proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_GenelMuayeneTetkikler = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.genelMuayeneTetkikler =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneTetkikler',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneTetkikler,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.genelMuayeneTetkikler =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneTetkikler',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneTetkikler);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_GenelMuayeneTetkikDisKurumRaporlari = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneTetkikDisKurumRaporlari',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc,
  proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_GenelMuayeneTetkikDisKurumRaporlari = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.genelMuayeneTetkikDisKurumRaporlari =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneTetkikDisKurumRaporlari',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneTetkikDisKurumRaporlari,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.genelMuayeneTetkikDisKurumRaporlari =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayeneTetkikDisKurumRaporlari',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayeneTetkikDisKurumRaporlari);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_GenelMuayenePlanliHasta = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/GenelMuayenePlanliHasta',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc,
  proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_GenelMuayenePlanliHasta = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.genelMuayenePlanliHasta =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayenePlanliHasta',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayenePlanliHasta,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.genelMuayenePlanliHasta =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayenePlanliHasta',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayenePlanliHasta);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_GenelMuayenePlanliHastaDetay = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/GenelMuayenePlanliHastaDetay',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc,
  proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc,
 *   !proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_GenelMuayenePlanliHastaDetay = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.genelMuayenePlanliHastaDetay =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayenePlanliHastaDetay',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayenePlanliHastaDetay,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.genelMuayenePlanliHastaDetay =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/GenelMuayenePlanliHastaDetay',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_GenelMuayenePlanliHastaDetay);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc,
 *   !proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_MedikalTanimSablonlariGetir = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/MedikalTanimSablonlariGetir',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc,
  proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc,
 *   !proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_MedikalTanimSablonlariGetir = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.medikalTanimSablonlariGetir =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/MedikalTanimSablonlariGetir',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_MedikalTanimSablonlariGetir,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.medikalTanimSablonlariGetir =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/MedikalTanimSablonlariGetir',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_MedikalTanimSablonlariGetir);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc,
 *   !proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_MedikalTanimSablonlariGetirCrud = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/MedikalTanimSablonlariGetirCrud',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc,
  proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc,
 *   !proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_MedikalTanimSablonlariGetirCrud = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.medikalTanimSablonlariGetirCrud =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/MedikalTanimSablonlariGetirCrud',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_MedikalTanimSablonlariGetirCrud,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.medikalTanimSablonlariGetirCrud =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/MedikalTanimSablonlariGetirCrud',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_MedikalTanimSablonlariGetirCrud);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc,
 *   !proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_MedikalTanimMuayeneSorulariGetir = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/MedikalTanimMuayeneSorulariGetir',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc,
  proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc,
 *   !proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_MedikalTanimMuayeneSorulariGetir = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.medikalTanimMuayeneSorulariGetir =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/MedikalTanimMuayeneSorulariGetir',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_MedikalTanimMuayeneSorulariGetir,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.medikalTanimMuayeneSorulariGetir =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/MedikalTanimMuayeneSorulariGetir',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_MedikalTanimMuayeneSorulariGetir);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc,
 *   !proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_MedikalTanimICDGetir = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/MedikalTanimICDGetir',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc,
  proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc,
 *   !proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_MedikalTanimICDGetir = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.medikalTanimICDGetir =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/MedikalTanimICDGetir',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_MedikalTanimICDGetir,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.medikalTanimICDGetir =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/MedikalTanimICDGetir',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_MedikalTanimICDGetir);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc,
 *   !proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_MedikalTanimRadyolojikIstekleriGetir = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/MedikalTanimRadyolojikIstekleriGetir',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc,
  proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc,
 *   !proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_MedikalTanimRadyolojikIstekleriGetir = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.medikalTanimRadyolojikIstekleriGetir =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/MedikalTanimRadyolojikIstekleriGetir',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_MedikalTanimRadyolojikIstekleriGetir,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.medikalTanimRadyolojikIstekleriGetir =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/MedikalTanimRadyolojikIstekleriGetir',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_MedikalTanimRadyolojikIstekleriGetir);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc,
 *   !proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_MedikalTanimRadyolojikIstekleriGetirCrud = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/MedikalTanimRadyolojikIstekleriGetirCrud',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc,
  proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc,
 *   !proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_MedikalTanimRadyolojikIstekleriGetirCrud = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.medikalTanimRadyolojikIstekleriGetirCrud =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/MedikalTanimRadyolojikIstekleriGetirCrud',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_MedikalTanimRadyolojikIstekleriGetirCrud,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.medikalTanimRadyolojikIstekleriGetirCrud =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/MedikalTanimRadyolojikIstekleriGetirCrud',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_MedikalTanimRadyolojikIstekleriGetirCrud);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc,
 *   !proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_MedikalTanimLaboratuarkIstekleriGetir = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/MedikalTanimLaboratuarkIstekleriGetir',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc,
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc,
 *   !proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_MedikalTanimLaboratuarkIstekleriGetir = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.medikalTanimLaboratuarkIstekleriGetir =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/MedikalTanimLaboratuarkIstekleriGetir',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_MedikalTanimLaboratuarkIstekleriGetir,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.medikalTanimLaboratuarkIstekleriGetir =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/MedikalTanimLaboratuarkIstekleriGetir',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_MedikalTanimLaboratuarkIstekleriGetir);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc,
 *   !proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_MedikalTanimLaboratuarkIstekleriGetirCrud = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/MedikalTanimLaboratuarkIstekleriGetirCrud',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc,
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc,
 *   !proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_MedikalTanimLaboratuarkIstekleriGetirCrud = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.medikalTanimLaboratuarkIstekleriGetirCrud =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/MedikalTanimLaboratuarkIstekleriGetirCrud',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_MedikalTanimLaboratuarkIstekleriGetirCrud,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.medikalTanimLaboratuarkIstekleriGetirCrud =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/MedikalTanimLaboratuarkIstekleriGetirCrud',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_MedikalTanimLaboratuarkIstekleriGetirCrud);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_DoctorPanelMuayeneCevapCrud = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/DoctorPanelMuayeneCevapCrud',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc,
  proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_DoctorPanelMuayeneCevapCrud = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.doctorPanelMuayeneCevapCrud =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPanelMuayeneCevapCrud',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPanelMuayeneCevapCrud,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.doctorPanelMuayeneCevapCrud =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPanelMuayeneCevapCrud',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPanelMuayeneCevapCrud);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_DoctorPanelLaboratuarIstekleriPopupTestleriGetir = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/DoctorPanelLaboratuarIstekleriPopupTestleriGetir',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc,
  proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_DoctorPanelLaboratuarIstekleriPopupTestleriGetir = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.doctorPanelLaboratuarIstekleriPopupTestleriGetir =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPanelLaboratuarIstekleriPopupTestleriGetir',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPanelLaboratuarIstekleriPopupTestleriGetir,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.doctorPanelLaboratuarIstekleriPopupTestleriGetir =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPanelLaboratuarIstekleriPopupTestleriGetir',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPanelLaboratuarIstekleriPopupTestleriGetir);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse>}
 */
const methodDescriptor_DoktorPaneliPR_DoctorPanelIslemGrubuAgaci = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/DoctorPanelIslemGrubuAgaci',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc,
  proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse,
  /**
   * @param {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse>}
 */
const methodInfo_DoktorPaneliPR_DoctorPanelIslemGrubuAgaci = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse,
  /**
   * @param {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.doctorPanelIslemGrubuAgaci =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPanelIslemGrubuAgaci',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPanelIslemGrubuAgaci,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.doctorPanelIslemGrubuAgaci =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPanelIslemGrubuAgaci',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPanelIslemGrubuAgaci);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_DoctorPaneliIslemTanimList = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/DoctorPaneliIslemTanimList',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc,
  proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_DoctorPaneliIslemTanimList = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.doctorPaneliIslemTanimList =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPaneliIslemTanimList',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPaneliIslemTanimList,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.doctorPaneliIslemTanimList =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPaneliIslemTanimList',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPaneliIslemTanimList);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_DoctorPanelIcdAgaci = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/DoctorPanelIcdAgaci',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc,
  proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_DoctorPanelIcdAgaci = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.doctorPanelIcdAgaci =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPanelIcdAgaci',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPanelIcdAgaci,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.doctorPanelIcdAgaci =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPanelIcdAgaci',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPanelIcdAgaci);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_DoctorPanelTibbiGecmis = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/DoctorPanelTibbiGecmis',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc,
  proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_DoctorPanelTibbiGecmis = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.doctorPanelTibbiGecmis =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPanelTibbiGecmis',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPanelTibbiGecmis,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.doctorPanelTibbiGecmis =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPanelTibbiGecmis',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPanelTibbiGecmis);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_DoctorPanelMerkeziTibbiGecmisGenericService = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/DoctorPanelMerkeziTibbiGecmisGenericService',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc,
  proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_DoctorPanelMerkeziTibbiGecmisGenericService = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.doctorPanelMerkeziTibbiGecmisGenericService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPanelMerkeziTibbiGecmisGenericService',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPanelMerkeziTibbiGecmisGenericService,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.doctorPanelMerkeziTibbiGecmisGenericService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPanelMerkeziTibbiGecmisGenericService',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPanelMerkeziTibbiGecmisGenericService);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_DoctorPanelFiyatlandirmaIstegiGenericService = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/DoctorPanelFiyatlandirmaIstegiGenericService',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc,
  proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_DoctorPanelFiyatlandirmaIstegiGenericService = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.doctorPanelFiyatlandirmaIstegiGenericService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPanelFiyatlandirmaIstegiGenericService',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPanelFiyatlandirmaIstegiGenericService,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.doctorPanelFiyatlandirmaIstegiGenericService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPanelFiyatlandirmaIstegiGenericService',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPanelFiyatlandirmaIstegiGenericService);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc>}
 */
const methodDescriptor_DoktorPaneliPR_DoctorPanelDusmeRiskiDegerlendirmeGenericService = new grpc.web.MethodDescriptor(
  '/doktorpanelipr.DoktorPaneliPR/DoctorPanelDusmeRiskiDegerlendirmeGenericService',
  grpc.web.MethodType.UNARY,
  proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc,
  proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc,
 *   !proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc>}
 */
const methodInfo_DoktorPaneliPR_DoctorPanelDusmeRiskiDegerlendirmeGenericService = new grpc.web.AbstractClientBase.MethodInfo(
  proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc,
  /**
   * @param {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.deserializeBinary
);


/**
 * @param {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc>|undefined}
 *     The XHR Node Readable Stream
 */
proto.doktorpanelipr.DoktorPaneliPRClient.prototype.doctorPanelDusmeRiskiDegerlendirmeGenericService =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPanelDusmeRiskiDegerlendirmeGenericService',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPanelDusmeRiskiDegerlendirmeGenericService,
      callback);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc>}
 *     Promise that resolves to the response
 */
proto.doktorpanelipr.DoktorPaneliPRPromiseClient.prototype.doctorPanelDusmeRiskiDegerlendirmeGenericService =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/doktorpanelipr.DoktorPaneliPR/DoctorPanelDusmeRiskiDegerlendirmeGenericService',
      request,
      metadata || {},
      methodDescriptor_DoktorPaneliPR_DoctorPanelDusmeRiskiDegerlendirmeGenericService);
};


module.exports = proto.doktorpanelipr;

