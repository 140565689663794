import { createSlice } from '@reduxjs/toolkit'
import taniIslemAnaliziService from "../services/medikal/tedavi/taniIslemAnaliziService";
import { trackPromise } from 'react-promise-tracker';

const initialState = {
    taniIslemAnalizi: [],
    islemAnalizleri: [],
    kurumlar: [],
    bolumler: [],
}

const taniIslemAnaliziRaporuSlice = createSlice({
  name: 'taniIslemAnaliziRaporu',
  initialState,
  reducers: {
    taniAnalizleriniGetirReducer(state, action) {
        const taniIslemAnalizi = action.payload;
        state.taniIslemAnalizi = taniIslemAnalizi;
    },
    islemAnalizleriniGetirReducer(state, action) {
        const islemAnalizleri = action.payload;
        state.islemAnalizleri = islemAnalizleri;
    },
    bolumleriGetirReducer(state, action) {
        const bolumler = action.payload;
        state.bolumler = bolumler;
    },
  },
})

export const reducer = taniIslemAnaliziRaporuSlice.reducer

const { taniAnalizleriniGetirReducer, 
        islemAnalizleriniGetirReducer,
        kurumlariGetirReducer,
        bolumleriGetirReducer
} = taniIslemAnaliziRaporuSlice.actions


export const taniAnalizleriniGetir = (
    baslangicTarihi,
    bitisTarihi,
    bolum,
    hastane,
    gelisTuru,
    taniTipi
  ) => async dispatch => {
    const response =  await trackPromise(taniIslemAnaliziService.taniAnalizleriniGetir(
      baslangicTarihi,
      bitisTarihi,
      bolum,
      hastane,
      gelisTuru,
      taniTipi
    ));
  
    dispatch(taniAnalizleriniGetirReducer(response.tanianalizleriList));
  }

  export const islemAnalizleriniGetir = (
    baslangicTarihi,
    bitisTarihi,
    hastane,
    bolum,
    gelisTuru,
    taniTipi,
    protokolSayisi,
    taniKodu,
    sorumluDoktor
  )  => async dispatch =>  {
    const response = await  trackPromise(taniIslemAnaliziService.islemAnalizleriniGetir(
      baslangicTarihi,
      bitisTarihi,
      hastane,
      bolum,
      gelisTuru,
      taniTipi,
      protokolSayisi, 
      taniKodu,
      sorumluDoktor
    ));
  
    dispatch(islemAnalizleriniGetirReducer(response.islemanalizleriList));
    
  }

  export const merkeziBolumleriGetir = ()  => async dispatch =>  {
    const response = await  trackPromise(taniIslemAnaliziService.merkeziBolumleriGetir());
    
    dispatch(bolumleriGetirReducer(response.bolumList));
  }