import {RaporTanimlariClient} from "../../../../protos/is-zekasi/rapor-tanimlari/surec-tanimlari/rapor_tanimlari_grpc_web_pb";
import {GetHazirRaporTanimListBySurecTanimIDRequest,
        HazirRaporTanimEkleRequest,
        GetHazirRaporTanimByIdRequest,
        HazirRaporTanimGuncelleRequest
} from "../../../../protos/is-zekasi/rapor-tanimlari/surec-tanimlari/rapor_tanimlari_pb";

class RaporTanimService {
    
    getRaporTanimList = (surectanimid) => {
        return new Promise((resolve, reject) => {
    
          const client = new RaporTanimlariClient(process.env.REACT_APP_API_BASE_URL);
          const getHazirRaporTanimListBySurecTanimIDRequest = new GetHazirRaporTanimListBySurecTanimIDRequest();
          
          getHazirRaporTanimListBySurecTanimIDRequest.setSurectanimid(surectanimid);
          
          //console.log("surectanimid",surectanimid);
          
          var token = localStorage.getItem('accessToken');
          client.getHazirRaporTanimListBySurecTanimID(getHazirRaporTanimListBySurecTanimIDRequest,
            { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
              if (response) {
                //console.log('Response->x1->', response);
                resolve(response.toObject());
              } else {
                //console.log('error->x1->', error);
                reject(error);
              }
            });
    
        });
      }

     setHazirRaporTanimEkle = (Tipi, Adi, Tanimi, SonOkumaTipi, SonOkuma, KayitDurumu) => {
      return new Promise((resolve, reject) => {
  
        const client = new RaporTanimlariClient(process.env.REACT_APP_API_BASE_URL);
        const setHazirRaporTanimEkleRequest = new HazirRaporTanimEkleRequest();
        
        //KayitDurumu enum a göre
        const kayitDurumu = (KayitDurumu == 0) ? 2 : KayitDurumu;
  
        setHazirRaporTanimEkleRequest.setTipi(Tipi);
        setHazirRaporTanimEkleRequest.setAdi(Adi);
        setHazirRaporTanimEkleRequest.setTanimi(Tanimi);
        setHazirRaporTanimEkleRequest.setSonokumatipi(SonOkumaTipi);
        setHazirRaporTanimEkleRequest.setSonokuma(SonOkuma);
        setHazirRaporTanimEkleRequest.setAktifmi(KayitDurumu);
        
        
        var token = localStorage.getItem('accessToken');
        client.hazirRaporTanimEkle(setHazirRaporTanimEkleRequest,
          { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
            if (response) {
              //console.log('Response->x1->', response);
              resolve(response.toObject());
            } else {
              //console.log('error->x1->', error);
              reject(error);
            }
          });
  
      });
    }

    getRaporTanimGetir = (id) => {
      return new Promise((resolve, reject) => {
  
        const client = new RaporTanimlariClient(process.env.REACT_APP_API_BASE_URL);
        const getHazirRaporTanimByIdRequest = new GetHazirRaporTanimByIdRequest();
          
        getHazirRaporTanimByIdRequest.setId(id);
        
        var token = localStorage.getItem('accessToken');
        client.getHazirRaporTanimById(getHazirRaporTanimByIdRequest,
          { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
            if (response) {
              //console.log('Response->x1->', response);
              resolve(response.toObject());
            } else {
              //console.log('error->x1->', error);
              reject(error);
            }
          });
  
      });
    }

    setRaporTanimGuncelle = (Id, Tipi, Adi, Tanimi, SonOkumaTipi, SonOkuma, KayitDurumu) => {
      return new Promise((resolve, reject) => {
  
        const client = new RaporTanimlariClient(process.env.REACT_APP_API_BASE_URL);
        const setHazirRaporTanimGuncelleRequest = new HazirRaporTanimGuncelleRequest();
        
        //KayitDurumu enum a göre
        const kayitDurumu = (KayitDurumu == 0) ? 2 : KayitDurumu;
  
        setHazirRaporTanimGuncelleRequest.setId(Id);
        setHazirRaporTanimGuncelleRequest.setTipi(Tipi);
        setHazirRaporTanimGuncelleRequest.setAdi(Adi);
        setHazirRaporTanimGuncelleRequest.setTanimi(Tanimi);
        setHazirRaporTanimGuncelleRequest.setSonokumatipi(SonOkumaTipi);
        setHazirRaporTanimGuncelleRequest.setSonokuma(SonOkuma);
        setHazirRaporTanimGuncelleRequest.setAktifmi(KayitDurumu);
        
        
        var token = localStorage.getItem('accessToken');
        client.hazirRaporTanimGuncelle(setHazirRaporTanimGuncelleRequest,
          { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
            if (response) {
              //console.log('Response->x1->', response);
              resolve(response.toObject());
            } else {
              //console.log('error->x1->', error);
              reject(error);
            }
          });
  
      });
    }

}

const raporTanimService = new RaporTanimService();

export default raporTanimService;
