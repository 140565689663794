import { BolumButceTakibiClient } from "../../../protos/is-zekasi/rapor/bolum_butce_takibi_grpc_web_pb";
import {
    GetBolumButceTakibiByGidisatRequest,
    GetBolumButceTakibiByEndeksCiroRequest,
    GetBolumButceTakibiByHizmetDetayiRequest,
    GetBolumButceTakibiByHedefGerceklesmeOranlariRequest,
    GetBolumButceTakibiByOdeyiciDetayiRequest,
    GetBolumButceTakibiByGelisTuruDetayRequest,
    GetBolumButceTakibiCheckupRequest
}
    from "../../../protos/is-zekasi/rapor/bolum_butce_takibi_pb";

class BolumButceTakibiService {

    getBolumButceTakibiCheckup = (kurumId, tarih1, tarih2) => {

        const client = new BolumButceTakibiClient(process.env.REACT_APP_API_BASE_URL);
	    const nRequest = new GetBolumButceTakibiCheckupRequest();
        nRequest.setInput1(kurumId);
        nRequest.setInput2(tarih1);
        nRequest.setInput3(tarih2);
        //console.log('nRequest', nRequest);	
        var token = localStorage.getItem('accessToken');        

        return new Promise((resolve, reject) => {
            client.getBolumButceTakibiCheckup(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
            if (response) {
                resolve(response.toObject());
              } else {
                reject(error);
              }
            })
        });        
    }

    getBolumButceTakibiByGidisat = (kurumId, tarih, bolumId) => {
        return new Promise((resolve, reject) => {

            const client = new BolumButceTakibiClient(process.env.REACT_APP_API_BASE_URL);
            const getBolumButceTakibiByGidisatRequest = new GetBolumButceTakibiByGidisatRequest();

            getBolumButceTakibiByGidisatRequest.setKurumid(kurumId);
            getBolumButceTakibiByGidisatRequest.setTarih(tarih);
            getBolumButceTakibiByGidisatRequest.setBolumid(bolumId);

            var token = localStorage.getItem('accessToken');
            client.getBolumButceTakibiByGidisat(getBolumButceTakibiByGidisatRequest,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }

    getBolumButceTakibiByEndeksCiro = (kurumId, tarih, bolumId) => {
        return new Promise((resolve, reject) => {

            const client = new BolumButceTakibiClient(process.env.REACT_APP_API_BASE_URL);
            const getBolumButceTakibiByEndeksCiroRequest = new GetBolumButceTakibiByEndeksCiroRequest();

            getBolumButceTakibiByEndeksCiroRequest.setKurumid(kurumId);
            getBolumButceTakibiByEndeksCiroRequest.setTarih(tarih);
            getBolumButceTakibiByEndeksCiroRequest.setBolumid(bolumId);

            var token = localStorage.getItem('accessToken');
            client.getBolumButceTakibiByEndeksCiro(getBolumButceTakibiByEndeksCiroRequest,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }

    getBolumButceTakibiByHizmetDetayi = (tarih, tipi) => {
        return new Promise((resolve, reject) => {

            const client = new BolumButceTakibiClient(process.env.REACT_APP_API_BASE_URL);
            const getBolumButceTakibiByHizmetDetayiRequest = new GetBolumButceTakibiByHizmetDetayiRequest();

            getBolumButceTakibiByHizmetDetayiRequest.setTarih(tarih);
            getBolumButceTakibiByHizmetDetayiRequest.setTipi(tipi);

            var token = localStorage.getItem('accessToken');
            client.getBolumButceTakibiByHizmetDetayi(getBolumButceTakibiByHizmetDetayiRequest,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }
    
    getBolumButceTakibiByHedefGerceklesmeOranlari = (kurumId, tarih, bolumId) => {
        return new Promise((resolve, reject) => {

            const client = new BolumButceTakibiClient(process.env.REACT_APP_API_BASE_URL);
            const getBolumButceTakibiByHedefGerceklesmeOranlariRequest = new GetBolumButceTakibiByHedefGerceklesmeOranlariRequest();

            getBolumButceTakibiByHedefGerceklesmeOranlariRequest.setKurumid(kurumId);   
            getBolumButceTakibiByHedefGerceklesmeOranlariRequest.setTarih(tarih);
            getBolumButceTakibiByHedefGerceklesmeOranlariRequest.setBolumid(bolumId);         

            var token = localStorage.getItem('accessToken');
            client.getBolumButceTakibiByHedefGerceklesmeOranlari(getBolumButceTakibiByHedefGerceklesmeOranlariRequest,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }

    getBolumButceTakibiByOdeyiciDetayi = (odeyiciKurumTipi, tarih, kurumId) => {        
        return new Promise((resolve, reject) => {

            const client = new BolumButceTakibiClient(process.env.REACT_APP_API_BASE_URL);
            const getBolumButceTakibiByOdeyiciDetayiRequest = new GetBolumButceTakibiByOdeyiciDetayiRequest();

            getBolumButceTakibiByOdeyiciDetayiRequest.setOdeyicikurumtipi(odeyiciKurumTipi);
            getBolumButceTakibiByOdeyiciDetayiRequest.setTarih(tarih);
            getBolumButceTakibiByOdeyiciDetayiRequest.setKurumid(kurumId);

            var token = localStorage.getItem('accessToken');
            client.getBolumButceTakibiByOdeyiciDetayi(getBolumButceTakibiByOdeyiciDetayiRequest,
                { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
                    if (response) {
                        resolve(response.toObject());
                    } else {
                        reject(error);
                    }
                });
        });
    }

    getBolumButceTakibiByGelisTuruDetay = (hospitalID, dateVal) => {
        return new Promise((resolve, reject) => {
    
          const client = new BolumButceTakibiClient(process.env.REACT_APP_API_BASE_URL);
          const getBolumButceTakibiByGelisTuruDetayRequest = new GetBolumButceTakibiByGelisTuruDetayRequest();
    
          getBolumButceTakibiByGelisTuruDetayRequest.setHospitalid(hospitalID);
          getBolumButceTakibiByGelisTuruDetayRequest.setTarih(dateVal);
    
          var token = localStorage.getItem('accessToken');
          client.getBolumButceTakibiByGelisTuruDetay(getBolumButceTakibiByGelisTuruDetayRequest,
            { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
              if (response) {
                resolve(response.toObject());
              } else {
                reject(error);
              }
            });
        });
    }

}

const bolumButceTakibiService = new BolumButceTakibiService();

export default bolumButceTakibiService;
