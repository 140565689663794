import { createSlice } from '@reduxjs/toolkit'
import bolumButceTakibiService from "../services/is-zekasi/rapor/bolumButceTakibiService";
import { trackPromise } from 'react-promise-tracker';

var date = new Date();
date.setDate(date.getDate() - 1);

const initialState = {
  tarih: date,
  kurumId: -1,
  bolumButceTakibiGidisat: [],
  bolumButceTakibiGidisatTumHastaneler: [],
  bolumButceTakibiEndeksCiro: [],
  bolumButceTakibiEndeksCiroTumHastaneler: [],
  bolumButceTakibiHizmetDetayi: [],
  bolumButceTakibiByHedefGerceklesmeOranlari: [],
  bolumButceTakibiByOdeyiciDetayi:[],
  bolumButceTakibiByGelisTuruDetayi:[]
}

const bolumButceTakibiSlice = createSlice({
  name: 'bolumButceTakibi',
  initialState,
  reducers: {
    setTarih(state, action) {
      const tarih = action.payload;
      state.tarih = tarih;
    },
    getTarih(state, action) {
      const tarih = action.payload;
      state.tarih = tarih;
    },
    setKurumId(state, action) {
      const kurumId = action.payload;
      state.kurumId = kurumId;
    },
    getKurumId(state, action) {
      const kurumId = action.payload;
      state.kurumId = kurumId;
    },
    getBolumButceTakibiByGidisat(state, action) {
      const bolumButceTakibiGidisat = action.payload;
      state.bolumButceTakibiGidisat = bolumButceTakibiGidisat;
    },
    getBolumButceTakibiByGidisatTumHastaneler(state, action) {
      const bolumButceTakibiGidisatTumHastaneler = action.payload;
      state.bolumButceTakibiGidisatTumHastaneler = bolumButceTakibiGidisatTumHastaneler;
    },
    getBolumButceTakibiByEndeksCiro(state, action) {
      const bolumButceTakibiEndeksCiro = action.payload;
      state.bolumButceTakibiEndeksCiro = bolumButceTakibiEndeksCiro;
    },
    getBolumButceTakibiByEndeksCiroTumHastanaler(state, action) {
      const bolumButceTakibiEndeksCiroTumHastaneler = action.payload;
      state.bolumButceTakibiEndeksCiroTumHastaneler = bolumButceTakibiEndeksCiroTumHastaneler;
    },
    getBolumButceTakibiByHizmetDetayi(state, action) {
      const bolumButceTakibiHizmetDetayi = action.payload;
      state.bolumButceTakibiHizmetDetayi = bolumButceTakibiHizmetDetayi;
    },
    getBolumButceTakibiByHedefGerceklesmeOranlari(state, action) {
      const bolumButceTakibiByHedefGerceklesmeOranlari = action.payload;
      state.bolumButceTakibiByHedefGerceklesmeOranlari = bolumButceTakibiByHedefGerceklesmeOranlari;
    },
    getBolumButceTakibiByOdeyiciDetayi(state, action) {
      const bolumButceTakibiByOdeyiciDetayi = action.payload;
      state.bolumButceTakibiByOdeyiciDetayi = bolumButceTakibiByOdeyiciDetayi;
    },
    getBolumButceTakibiByGelisTuruDetayi(state, action) {
      const bolumButceTakibiByGelisTuruDetayi = action.payload;
      state.bolumButceTakibiByGelisTuruDetayi = bolumButceTakibiByGelisTuruDetayi;
    },
  },
})

export const reducer = bolumButceTakibiSlice.reducer

export const setTarih = (tarih) => async dispatch => {
  dispatch(bolumButceTakibiSlice.actions.setTarih(tarih));
}

export const getTarih = () => async dispatch => {
  dispatch(bolumButceTakibiSlice.actions.getTarih());
}

export const setKurumId = (kurumId) => async dispatch => {
  dispatch(bolumButceTakibiSlice.actions.setKurumId(kurumId));
}

export const getKurumId = () => async dispatch => {
  dispatch(bolumButceTakibiSlice.actions.getKurumId());
}

export const getBolumButceTakibiByGidisat = (kurumId, tarih, bolumId) => async dispatch => {

  const bolumButceTakibiByGidisat = trackPromise(bolumButceTakibiService.getBolumButceTakibiByGidisat(kurumId, tarih, bolumId));

  bolumButceTakibiByGidisat.then((response) => {
    dispatch(bolumButceTakibiSlice.actions.getBolumButceTakibiByGidisat(response.gidisatrowsList));
  })
}

export const getBolumButceTakibiByGidisatTumHastaneler = (kurumId, tarih, bolumId) => async dispatch => {

  const bolumButceTakibiByGidisatTumHastaneler = trackPromise(bolumButceTakibiService.getBolumButceTakibiByGidisat(kurumId, tarih, bolumId));

  bolumButceTakibiByGidisatTumHastaneler.then((response) => {
    dispatch(bolumButceTakibiSlice.actions.getBolumButceTakibiByGidisatTumHastaneler(response.gidisatrowsList));
  })
}

export const getBolumButceTakibiByEndeksCiro = (kurumId, tarih, bolumId) => async dispatch => {

  const bolumButceTakibiByEndeksCiro = trackPromise(bolumButceTakibiService.getBolumButceTakibiByEndeksCiro(kurumId, tarih, bolumId));

  bolumButceTakibiByEndeksCiro.then((response) => {
    dispatch(bolumButceTakibiSlice.actions.getBolumButceTakibiByEndeksCiro(response.endekscirorowsList));
  })
}

export const getBolumButceTakibiByEndeksCiroTumHastaneler = (kurumId, tarih, bolumId) => async dispatch => {

  const bolumButceTakibiByEndeksCiroTumHastaneler = trackPromise(bolumButceTakibiService.getBolumButceTakibiByEndeksCiro(kurumId, tarih, bolumId));

  bolumButceTakibiByEndeksCiroTumHastaneler.then((response) => {
    dispatch(bolumButceTakibiSlice.actions.getBolumButceTakibiByEndeksCiroTumHastanaler(response.endekscirorowsList));
  })
}

export const getBolumButceTakibiByHizmetDetayi = (tarih, tipi) => async dispatch => {

  const bolumButceTakibiByHizmetDetayi = trackPromise(bolumButceTakibiService.getBolumButceTakibiByHizmetDetayi(tarih, tipi));

  bolumButceTakibiByHizmetDetayi.then((response) => {
    dispatch(bolumButceTakibiSlice.actions.getBolumButceTakibiByHizmetDetayi(response.hizmetdetayirowsList));
  })
}

export const getBolumButceTakibiByHedefGerceklesmeOranlari = (kurumId, tarih, bolumId) => async dispatch => {

  const bolumButceTakibiByHedefGerceklesmeOranlari = trackPromise(bolumButceTakibiService.getBolumButceTakibiByHedefGerceklesmeOranlari(kurumId, tarih, bolumId));
  
  bolumButceTakibiByHedefGerceklesmeOranlari.then((response) => {
    dispatch(bolumButceTakibiSlice.actions.getBolumButceTakibiByHedefGerceklesmeOranlari(response.hedefgerceklesmeoranlarirowsList));
  })
}

export const getBolumButceTakibiByOdeyiciDetayi = (odeyiciKurumTipi, tarih, kurumId) => async dispatch => {

  const bolumButceTakibiByOdeyiciDetayi = trackPromise(bolumButceTakibiService.getBolumButceTakibiByOdeyiciDetayi(odeyiciKurumTipi, tarih, kurumId));
  
  bolumButceTakibiByOdeyiciDetayi.then((response) => {
    dispatch(bolumButceTakibiSlice.actions.getBolumButceTakibiByOdeyiciDetayi(response.odeyicidetayirowsList));
  })
}

export const getBolumButceTakibiByGelisTuruDetayi = (hospitalId, dateVal) => async dispatch => {

  const getBolumButceTakibiByGelisTuruDetay = trackPromise(bolumButceTakibiService.getBolumButceTakibiByGelisTuruDetay(hospitalId, dateVal));
  
  getBolumButceTakibiByGelisTuruDetay.then((response) => {
    dispatch(bolumButceTakibiSlice.actions.getBolumButceTakibiByGelisTuruDetayi(response.getbolumbutcetakibibygelisturudetayrowdtoList));
  })
}



