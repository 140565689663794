import { createSlice } from '@reduxjs/toolkit'
import doktorKarnesiService from "../services/is-zekasi/rapor/doktorKarnesiService";
import { trackPromise } from 'react-promise-tracker';

const initialState = {
    doktorKarnesi: [],
    doktorKarnesiDetayi: [],
    doktorKarnesiByBolum: [],
    doktorKarnesiByHastane: [],
}

const doktorKarnesiSlice = createSlice({
    name: 'doktorKarnesi',
    initialState,
    reducers: {
        getDoktorKarnesi(state, action) {
            const doktorKarnesi = action.payload;
            state.doktorKarnesi = doktorKarnesi;
        },
        getDoktorKarnesiDetayi(state, action) {
            const doktorKarnesiDetayi = action.payload;
            state.doktorKarnesiDetayi = doktorKarnesiDetayi;
        },
        getDoktorKarnesiByBolum(state, action) {
            const doktorKarnesiByBolum = action.payload;
            state.doktorKarnesiByBolum = doktorKarnesiByBolum;
        },
        getDoktorKarnesiByHastane(state, action) {
            const doktorKarnesiByHastane = action.payload;
            state.doktorKarnesiByHastane = doktorKarnesiByHastane;
        },
    },
})

export const reducer = doktorKarnesiSlice.reducer

export const getDoktorKarnesi = (props) => async dispatch => {

    const doktorKarnesi = trackPromise(doktorKarnesiService.getDoktorKarnesi(props));

    doktorKarnesi.then((response) => {
        dispatch(doktorKarnesiSlice.actions.getDoktorKarnesi(response.doktorkarnesirowsList));
    })
}

export const getDoktorKarnesiDetayi = (props) => async dispatch => {

    const doktorKarnesiDetayi = trackPromise(doktorKarnesiService.getDoktorKarnesiDetayi(props));

    doktorKarnesiDetayi.then((response) => {
        dispatch(doktorKarnesiSlice.actions.getDoktorKarnesiDetayi(response.doktorkarnesidetayirowsList));
    })
}

export const getDoktorKarnesiByBolum = (props) => async dispatch => {

    const doktorKarnesiByBolum = trackPromise(doktorKarnesiService.getDoktorKarnesiByBolum(props));

    doktorKarnesiByBolum.then((response) => {
        dispatch(doktorKarnesiSlice.actions.getDoktorKarnesiByBolum(response.doktorkarnesibybolumrowsList));
    })
}

export const getDoktorKarnesiByHastane = (props) => async dispatch => {

    const doktorKarnesiByHastane = trackPromise(doktorKarnesiService.getDoktorKarnesiByHastane(props));

    doktorKarnesiByHastane.then((response) => {
        dispatch(doktorKarnesiSlice.actions.getDoktorKarnesiByHastane(response.doktorkarnesibyhastanerowsList));
    })
}

