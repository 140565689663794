/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/**
 * @fileoverview gRPC-Web generated client stub for DoktorKarnesi
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.DoktorKarnesi = require('./doktor_karnesi_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.DoktorKarnesi.DoktorKarnesiClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.DoktorKarnesi.DoktorKarnesiPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.DoktorKarnesi.GetDoktorKarnesiRequest,
 *   !proto.DoktorKarnesi.GetDoktorKarnesiResponse>}
 */
const methodDescriptor_DoktorKarnesi_GetDoktorKarnesi = new grpc.web.MethodDescriptor(
  '/DoktorKarnesi.DoktorKarnesi/GetDoktorKarnesi',
  grpc.web.MethodType.UNARY,
  proto.DoktorKarnesi.GetDoktorKarnesiRequest,
  proto.DoktorKarnesi.GetDoktorKarnesiResponse,
  /**
   * @param {!proto.DoktorKarnesi.GetDoktorKarnesiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.DoktorKarnesi.GetDoktorKarnesiResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.DoktorKarnesi.GetDoktorKarnesiRequest,
 *   !proto.DoktorKarnesi.GetDoktorKarnesiResponse>}
 */
const methodInfo_DoktorKarnesi_GetDoktorKarnesi = new grpc.web.AbstractClientBase.MethodInfo(
  proto.DoktorKarnesi.GetDoktorKarnesiResponse,
  /**
   * @param {!proto.DoktorKarnesi.GetDoktorKarnesiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.DoktorKarnesi.GetDoktorKarnesiResponse.deserializeBinary
);


/**
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.DoktorKarnesi.GetDoktorKarnesiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.DoktorKarnesi.GetDoktorKarnesiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.DoktorKarnesi.DoktorKarnesiClient.prototype.getDoktorKarnesi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/DoktorKarnesi.DoktorKarnesi/GetDoktorKarnesi',
      request,
      metadata || {},
      methodDescriptor_DoktorKarnesi_GetDoktorKarnesi,
      callback);
};


/**
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.DoktorKarnesi.GetDoktorKarnesiResponse>}
 *     Promise that resolves to the response
 */
proto.DoktorKarnesi.DoktorKarnesiPromiseClient.prototype.getDoktorKarnesi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/DoktorKarnesi.DoktorKarnesi/GetDoktorKarnesi',
      request,
      metadata || {},
      methodDescriptor_DoktorKarnesi_GetDoktorKarnesi);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest,
 *   !proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse>}
 */
const methodDescriptor_DoktorKarnesi_GetDoktorKarnesiDetayi = new grpc.web.MethodDescriptor(
  '/DoktorKarnesi.DoktorKarnesi/GetDoktorKarnesiDetayi',
  grpc.web.MethodType.UNARY,
  proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest,
  proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse,
  /**
   * @param {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest,
 *   !proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse>}
 */
const methodInfo_DoktorKarnesi_GetDoktorKarnesiDetayi = new grpc.web.AbstractClientBase.MethodInfo(
  proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse,
  /**
   * @param {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.deserializeBinary
);


/**
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.DoktorKarnesi.DoktorKarnesiClient.prototype.getDoktorKarnesiDetayi =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/DoktorKarnesi.DoktorKarnesi/GetDoktorKarnesiDetayi',
      request,
      metadata || {},
      methodDescriptor_DoktorKarnesi_GetDoktorKarnesiDetayi,
      callback);
};


/**
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse>}
 *     Promise that resolves to the response
 */
proto.DoktorKarnesi.DoktorKarnesiPromiseClient.prototype.getDoktorKarnesiDetayi =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/DoktorKarnesi.DoktorKarnesi/GetDoktorKarnesiDetayi',
      request,
      metadata || {},
      methodDescriptor_DoktorKarnesi_GetDoktorKarnesiDetayi);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest,
 *   !proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse>}
 */
const methodDescriptor_DoktorKarnesi_GetDoktorKarnesiByBolum = new grpc.web.MethodDescriptor(
  '/DoktorKarnesi.DoktorKarnesi/GetDoktorKarnesiByBolum',
  grpc.web.MethodType.UNARY,
  proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest,
  proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse,
  /**
   * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest,
 *   !proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse>}
 */
const methodInfo_DoktorKarnesi_GetDoktorKarnesiByBolum = new grpc.web.AbstractClientBase.MethodInfo(
  proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse,
  /**
   * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.deserializeBinary
);


/**
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.DoktorKarnesi.DoktorKarnesiClient.prototype.getDoktorKarnesiByBolum =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/DoktorKarnesi.DoktorKarnesi/GetDoktorKarnesiByBolum',
      request,
      metadata || {},
      methodDescriptor_DoktorKarnesi_GetDoktorKarnesiByBolum,
      callback);
};


/**
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse>}
 *     Promise that resolves to the response
 */
proto.DoktorKarnesi.DoktorKarnesiPromiseClient.prototype.getDoktorKarnesiByBolum =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/DoktorKarnesi.DoktorKarnesi/GetDoktorKarnesiByBolum',
      request,
      metadata || {},
      methodDescriptor_DoktorKarnesi_GetDoktorKarnesiByBolum);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest,
 *   !proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse>}
 */
const methodDescriptor_DoktorKarnesi_GetDoktorKarnesiByHastane = new grpc.web.MethodDescriptor(
  '/DoktorKarnesi.DoktorKarnesi/GetDoktorKarnesiByHastane',
  grpc.web.MethodType.UNARY,
  proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest,
  proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse,
  /**
   * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest,
 *   !proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse>}
 */
const methodInfo_DoktorKarnesi_GetDoktorKarnesiByHastane = new grpc.web.AbstractClientBase.MethodInfo(
  proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse,
  /**
   * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.deserializeBinary
);


/**
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.DoktorKarnesi.DoktorKarnesiClient.prototype.getDoktorKarnesiByHastane =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/DoktorKarnesi.DoktorKarnesi/GetDoktorKarnesiByHastane',
      request,
      metadata || {},
      methodDescriptor_DoktorKarnesi_GetDoktorKarnesiByHastane,
      callback);
};


/**
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse>}
 *     Promise that resolves to the response
 */
proto.DoktorKarnesi.DoktorKarnesiPromiseClient.prototype.getDoktorKarnesiByHastane =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/DoktorKarnesi.DoktorKarnesi/GetDoktorKarnesiByHastane',
      request,
      metadata || {},
      methodDescriptor_DoktorKarnesi_GetDoktorKarnesiByHastane);
};


module.exports = proto.DoktorKarnesi;

