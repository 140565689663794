// source: doktorpanelipr.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.doktorpanelipr.AyaktanTedaviRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.AyaktanTedaviResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.IstekQORequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.IstekQOResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.YatarakTedaviRequestGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.YatarakTedaviResponseGrpc', null, global);
goog.exportSymbol('proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.displayName = 'proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.displayName = 'proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.displayName = 'proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.displayName = 'proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.displayName = 'proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.displayName = 'proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.displayName = 'proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.displayName = 'proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.displayName = 'proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc.displayName = 'proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.displayName = 'proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.displayName = 'proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc.displayName = 'proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.displayName = 'proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.displayName = 'proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc.displayName = 'proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.displayName = 'proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.displayName = 'proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc.displayName = 'proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.displayName = 'proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.displayName = 'proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc.displayName = 'proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.displayName = 'proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.displayName = 'proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.displayName = 'proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.displayName = 'proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.displayName = 'proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc.displayName = 'proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.displayName = 'proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.displayName = 'proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc.displayName = 'proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.displayName = 'proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.displayName = 'proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormRequestGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.displayName = 'proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.AyaktanTedaviRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.AyaktanTedaviRequestGrpc.displayName = 'proto.doktorpanelipr.AyaktanTedaviRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.AyaktanTedaviResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.AyaktanTedaviResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.AyaktanTedaviResponseGrpc.displayName = 'proto.doktorpanelipr.AyaktanTedaviResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.displayName = 'proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.YatarakTedaviRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.YatarakTedaviRequestGrpc.displayName = 'proto.doktorpanelipr.YatarakTedaviRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.YatarakTedaviResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.YatarakTedaviResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.YatarakTedaviResponseGrpc.displayName = 'proto.doktorpanelipr.YatarakTedaviResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.displayName = 'proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.IstekQORequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.IstekQORequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.IstekQORequestGrpc.displayName = 'proto.doktorpanelipr.IstekQORequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.IstekQOResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.IstekQOResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.IstekQOResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.IstekQOResponseGrpc.displayName = 'proto.doktorpanelipr.IstekQOResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.displayName = 'proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.repeatedFields_, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc.displayName = 'proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    requesttype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paramint1: jspb.Message.getFieldWithDefault(msg, 2, 0),
    paramint2: jspb.Message.getFieldWithDefault(msg, 3, 0),
    paramint3: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paramint4: jspb.Message.getFieldWithDefault(msg, 5, 0),
    paramint5: jspb.Message.getFieldWithDefault(msg, 6, 0),
    paramstr1: jspb.Message.getFieldWithDefault(msg, 7, ""),
    paramstr2: jspb.Message.getFieldWithDefault(msg, 8, ""),
    paramstr3: jspb.Message.getFieldWithDefault(msg, 9, ""),
    paramstr4: jspb.Message.getFieldWithDefault(msg, 10, ""),
    paramstr5: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc;
  return proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequesttype(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParamint1(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParamint2(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParamint3(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParamint4(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParamint5(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamstr1(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamstr2(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamstr3(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamstr4(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamstr5(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequesttype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParamint1();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getParamint2();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getParamint3();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getParamint4();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getParamint5();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getParamstr1();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getParamstr2();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getParamstr3();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getParamstr4();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getParamstr5();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string requestType = 1;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.getRequesttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.setRequesttype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 paramint1 = 2;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.getParamint1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.setParamint1 = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 paramint2 = 3;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.getParamint2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.setParamint2 = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 paramint3 = 4;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.getParamint3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.setParamint3 = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 paramint4 = 5;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.getParamint4 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.setParamint4 = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 paramint5 = 6;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.getParamint5 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.setParamint5 = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string paramstr1 = 7;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.getParamstr1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.setParamstr1 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string paramstr2 = 8;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.getParamstr2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.setParamstr2 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string paramstr3 = 9;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.getParamstr3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.setParamstr3 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string paramstr4 = 10;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.getParamstr4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.setParamstr4 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string paramstr5 = 11;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.getParamstr5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc.prototype.setParamstr5 = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    doctorpanelmerkezitibbigecmisgenericserviceresponseList: jspb.Message.toObjectList(msg.getDoctorpanelmerkezitibbigecmisgenericserviceresponseList(),
    proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc;
  return proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto;
      reader.readMessage(value,proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.deserializeBinaryFromReader);
      msg.addDoctorpanelmerkezitibbigecmisgenericserviceresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoctorpanelmerkezitibbigecmisgenericserviceresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    outparam1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    outparam2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    outparam3: jspb.Message.getFieldWithDefault(msg, 3, ""),
    outparam4: jspb.Message.getFieldWithDefault(msg, 4, ""),
    outparam5: jspb.Message.getFieldWithDefault(msg, 5, ""),
    outparam6: jspb.Message.getFieldWithDefault(msg, 6, ""),
    outparam7: jspb.Message.getFieldWithDefault(msg, 7, ""),
    outparam8: jspb.Message.getFieldWithDefault(msg, 8, ""),
    outparam9: jspb.Message.getFieldWithDefault(msg, 9, ""),
    outparam10: jspb.Message.getFieldWithDefault(msg, 10, ""),
    outparam11: jspb.Message.getFieldWithDefault(msg, 11, ""),
    outparam12: jspb.Message.getFieldWithDefault(msg, 12, ""),
    outparam13: jspb.Message.getFieldWithDefault(msg, 13, ""),
    outparam14: jspb.Message.getFieldWithDefault(msg, 14, ""),
    outparam15: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto;
  return proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam3(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam4(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam5(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam6(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam7(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam8(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam9(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam10(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam11(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam12(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam13(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam14(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam15(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutparam1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOutparam2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOutparam3();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOutparam4();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOutparam5();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOutparam6();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOutparam7();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOutparam8();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOutparam9();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOutparam10();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOutparam11();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOutparam12();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOutparam13();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getOutparam14();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getOutparam15();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string outparam1 = 1;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.getOutparam1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.setOutparam1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string outparam2 = 2;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.getOutparam2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.setOutparam2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string outparam3 = 3;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.getOutparam3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.setOutparam3 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string outparam4 = 4;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.getOutparam4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.setOutparam4 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string outparam5 = 5;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.getOutparam5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.setOutparam5 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string outparam6 = 6;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.getOutparam6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.setOutparam6 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string outparam7 = 7;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.getOutparam7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.setOutparam7 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string outparam8 = 8;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.getOutparam8 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.setOutparam8 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string outparam9 = 9;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.getOutparam9 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.setOutparam9 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string outparam10 = 10;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.getOutparam10 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.setOutparam10 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string outparam11 = 11;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.getOutparam11 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.setOutparam11 = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string outparam12 = 12;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.getOutparam12 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.setOutparam12 = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string outparam13 = 13;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.getOutparam13 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.setOutparam13 = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string outparam14 = 14;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.getOutparam14 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.setOutparam14 = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string outparam15 = 15;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.getOutparam15 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto.prototype.setOutparam15 = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto DoctorPanelMerkeziTibbiGecmisGenericServiceResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto>}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.prototype.getDoctorpanelmerkezitibbigecmisgenericserviceresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto>} value
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc} returns this
*/
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.prototype.setDoctorpanelmerkezitibbigecmisgenericserviceresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto}
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.prototype.addDoctorpanelmerkezitibbigecmisgenericserviceresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMerkeziTibbiGecmisGenericServiceResponseGrpc.prototype.clearDoctorpanelmerkezitibbigecmisgenericserviceresponseList = function() {
  return this.setDoctorpanelmerkezitibbigecmisgenericserviceresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    requesttype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paramint1: jspb.Message.getFieldWithDefault(msg, 2, 0),
    paramint2: jspb.Message.getFieldWithDefault(msg, 3, 0),
    paramint3: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paramint4: jspb.Message.getFieldWithDefault(msg, 5, 0),
    paramint5: jspb.Message.getFieldWithDefault(msg, 6, 0),
    paramstr1: jspb.Message.getFieldWithDefault(msg, 7, ""),
    paramstr2: jspb.Message.getFieldWithDefault(msg, 8, ""),
    paramstr3: jspb.Message.getFieldWithDefault(msg, 9, ""),
    paramstr4: jspb.Message.getFieldWithDefault(msg, 10, ""),
    paramstr5: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc;
  return proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequesttype(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParamint1(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParamint2(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParamint3(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParamint4(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParamint5(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamstr1(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamstr2(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamstr3(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamstr4(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamstr5(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequesttype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParamint1();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getParamint2();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getParamint3();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getParamint4();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getParamint5();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getParamstr1();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getParamstr2();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getParamstr3();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getParamstr4();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getParamstr5();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string requestType = 1;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.getRequesttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.setRequesttype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 paramint1 = 2;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.getParamint1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.setParamint1 = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 paramint2 = 3;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.getParamint2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.setParamint2 = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 paramint3 = 4;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.getParamint3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.setParamint3 = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 paramint4 = 5;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.getParamint4 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.setParamint4 = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 paramint5 = 6;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.getParamint5 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.setParamint5 = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string paramstr1 = 7;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.getParamstr1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.setParamstr1 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string paramstr2 = 8;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.getParamstr2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.setParamstr2 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string paramstr3 = 9;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.getParamstr3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.setParamstr3 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string paramstr4 = 10;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.getParamstr4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.setParamstr4 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string paramstr5 = 11;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.getParamstr5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc.prototype.setParamstr5 = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    doctorpanelfiyatlandirmaistegigenericserviceresponseList: jspb.Message.toObjectList(msg.getDoctorpanelfiyatlandirmaistegigenericserviceresponseList(),
    proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc;
  return proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto;
      reader.readMessage(value,proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.deserializeBinaryFromReader);
      msg.addDoctorpanelfiyatlandirmaistegigenericserviceresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoctorpanelfiyatlandirmaistegigenericserviceresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    outparam1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    outparam2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    outparam3: jspb.Message.getFieldWithDefault(msg, 3, ""),
    outparam4: jspb.Message.getFieldWithDefault(msg, 4, ""),
    outparam5: jspb.Message.getFieldWithDefault(msg, 5, ""),
    outparam6: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto;
  return proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam3(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam4(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam5(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam6(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutparam1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOutparam2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOutparam3();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOutparam4();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOutparam5();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOutparam6();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string outparam1 = 1;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.prototype.getOutparam1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.prototype.setOutparam1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string outparam2 = 2;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.prototype.getOutparam2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.prototype.setOutparam2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string outparam3 = 3;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.prototype.getOutparam3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.prototype.setOutparam3 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string outparam4 = 4;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.prototype.getOutparam4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.prototype.setOutparam4 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string outparam5 = 5;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.prototype.getOutparam5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.prototype.setOutparam5 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string outparam6 = 6;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.prototype.getOutparam6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto.prototype.setOutparam6 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto DoctorPanelFiyatlandirmaIstegiGenericServiceResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto>}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.prototype.getDoctorpanelfiyatlandirmaistegigenericserviceresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto>} value
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc} returns this
*/
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.prototype.setDoctorpanelfiyatlandirmaistegigenericserviceresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto}
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.prototype.addDoctorpanelfiyatlandirmaistegigenericserviceresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelFiyatlandirmaIstegiGenericServiceResponseGrpc.prototype.clearDoctorpanelfiyatlandirmaistegigenericserviceresponseList = function() {
  return this.setDoctorpanelfiyatlandirmaistegigenericserviceresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    requesttype: jspb.Message.getFieldWithDefault(msg, 1, ""),
    paramint1: jspb.Message.getFieldWithDefault(msg, 2, 0),
    paramint2: jspb.Message.getFieldWithDefault(msg, 3, 0),
    paramint3: jspb.Message.getFieldWithDefault(msg, 4, 0),
    paramint4: jspb.Message.getFieldWithDefault(msg, 5, 0),
    paramint5: jspb.Message.getFieldWithDefault(msg, 6, 0),
    paramstr1: jspb.Message.getFieldWithDefault(msg, 7, ""),
    paramstr2: jspb.Message.getFieldWithDefault(msg, 8, ""),
    paramstr3: jspb.Message.getFieldWithDefault(msg, 9, ""),
    paramstr4: jspb.Message.getFieldWithDefault(msg, 10, ""),
    paramstr5: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc;
  return proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequesttype(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParamint1(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParamint2(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParamint3(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParamint4(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParamint5(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamstr1(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamstr2(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamstr3(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamstr4(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setParamstr5(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequesttype();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParamint1();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getParamint2();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getParamint3();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getParamint4();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getParamint5();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getParamstr1();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getParamstr2();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getParamstr3();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getParamstr4();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getParamstr5();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string requestType = 1;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.getRequesttype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.setRequesttype = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 paramint1 = 2;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.getParamint1 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.setParamint1 = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 paramint2 = 3;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.getParamint2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.setParamint2 = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 paramint3 = 4;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.getParamint3 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.setParamint3 = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 paramint4 = 5;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.getParamint4 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.setParamint4 = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 paramint5 = 6;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.getParamint5 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.setParamint5 = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string paramstr1 = 7;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.getParamstr1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.setParamstr1 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string paramstr2 = 8;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.getParamstr2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.setParamstr2 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string paramstr3 = 9;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.getParamstr3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.setParamstr3 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string paramstr4 = 10;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.getParamstr4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.setParamstr4 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string paramstr5 = 11;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.getParamstr5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc.prototype.setParamstr5 = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    doctorpaneldusmeriskidegerlendirmegenericserviceresponseList: jspb.Message.toObjectList(msg.getDoctorpaneldusmeriskidegerlendirmegenericserviceresponseList(),
    proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc;
  return proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto;
      reader.readMessage(value,proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.deserializeBinaryFromReader);
      msg.addDoctorpaneldusmeriskidegerlendirmegenericserviceresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoctorpaneldusmeriskidegerlendirmegenericserviceresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    outparam1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    outparam2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    outparam3: jspb.Message.getFieldWithDefault(msg, 3, ""),
    outparam4: jspb.Message.getFieldWithDefault(msg, 4, ""),
    outparam5: jspb.Message.getFieldWithDefault(msg, 5, ""),
    outparam6: jspb.Message.getFieldWithDefault(msg, 6, ""),
    outparam7: jspb.Message.getFieldWithDefault(msg, 7, ""),
    outparam8: jspb.Message.getFieldWithDefault(msg, 8, ""),
    outparam9: jspb.Message.getFieldWithDefault(msg, 9, ""),
    outparam10: jspb.Message.getFieldWithDefault(msg, 10, ""),
    outparam11: jspb.Message.getFieldWithDefault(msg, 11, ""),
    outparam12: jspb.Message.getFieldWithDefault(msg, 12, ""),
    outparam13: jspb.Message.getFieldWithDefault(msg, 13, ""),
    outparam14: jspb.Message.getFieldWithDefault(msg, 14, ""),
    outparam15: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto;
  return proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam3(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam4(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam5(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam6(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam7(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam8(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam9(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam10(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam11(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam12(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam13(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam14(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutparam15(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutparam1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOutparam2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOutparam3();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOutparam4();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOutparam5();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOutparam6();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOutparam7();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOutparam8();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOutparam9();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOutparam10();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getOutparam11();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOutparam12();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOutparam13();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getOutparam14();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getOutparam15();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string outparam1 = 1;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.getOutparam1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.setOutparam1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string outparam2 = 2;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.getOutparam2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.setOutparam2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string outparam3 = 3;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.getOutparam3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.setOutparam3 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string outparam4 = 4;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.getOutparam4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.setOutparam4 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string outparam5 = 5;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.getOutparam5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.setOutparam5 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string outparam6 = 6;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.getOutparam6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.setOutparam6 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string outparam7 = 7;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.getOutparam7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.setOutparam7 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string outparam8 = 8;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.getOutparam8 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.setOutparam8 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string outparam9 = 9;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.getOutparam9 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.setOutparam9 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string outparam10 = 10;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.getOutparam10 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.setOutparam10 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string outparam11 = 11;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.getOutparam11 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.setOutparam11 = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string outparam12 = 12;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.getOutparam12 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.setOutparam12 = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string outparam13 = 13;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.getOutparam13 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.setOutparam13 = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string outparam14 = 14;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.getOutparam14 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.setOutparam14 = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string outparam15 = 15;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.getOutparam15 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto.prototype.setOutparam15 = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto>}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.prototype.getDoctorpaneldusmeriskidegerlendirmegenericserviceresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto>} value
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc} returns this
*/
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.prototype.setDoctorpaneldusmeriskidegerlendirmegenericserviceresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto}
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.prototype.addDoctorpaneldusmeriskidegerlendirmegenericserviceresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelDusmeRiskiDegerlendirmeGenericServiceResponseGrpc.prototype.clearDoctorpaneldusmeriskidegerlendirmegenericserviceresponseList = function() {
  return this.setDoctorpaneldusmeriskidegerlendirmegenericserviceresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    protokolid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hastaid: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc;
  return proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProtokolid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHastaid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProtokolid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHastaid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 protokolid = 1;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc.prototype.getProtokolid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc.prototype.setProtokolid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 hastaid = 2;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc.prototype.getHastaid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisRequestGrpc.prototype.setHastaid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    doctorpaneltibbigecmisresponseList: jspb.Message.toObjectList(msg.getDoctorpaneltibbigecmisresponseList(),
    proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc;
  return proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto;
      reader.readMessage(value,proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.deserializeBinaryFromReader);
      msg.addDoctorpaneltibbigecmisresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoctorpaneltibbigecmisresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    hikayesi: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gecirdigioperasyonlar: jspb.Message.getFieldWithDefault(msg, 2, ""),
    kullandigiilaclar: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sistemikhastaliklar: jspb.Message.getFieldWithDefault(msg, 4, ""),
    alerji: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tedavi: jspb.Message.getFieldWithDefault(msg, 6, ""),
    recete: jspb.Message.getFieldWithDefault(msg, 7, ""),
    kullanimadedi: jspb.Message.getFieldWithDefault(msg, 8, ""),
    kullanimdozu: jspb.Message.getFieldWithDefault(msg, 9, ""),
    receteaciklama: jspb.Message.getFieldWithDefault(msg, 10, ""),
    id: jspb.Message.getFieldWithDefault(msg, 11, 0),
    hikayesimetin: jspb.Message.getFieldWithDefault(msg, 12, ""),
    icdtanim: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto;
  return proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHikayesi(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGecirdigioperasyonlar(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKullandigiilaclar(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSistemikhastaliklar(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlerji(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTedavi(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecete(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setKullanimadedi(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setKullanimdozu(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setReceteaciklama(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setHikayesimetin(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcdtanim(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHikayesi();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGecirdigioperasyonlar();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKullandigiilaclar();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSistemikhastaliklar();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAlerji();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTedavi();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getRecete();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getKullanimadedi();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getKullanimdozu();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getReceteaciklama();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getHikayesimetin();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIcdtanim();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string hikayesi = 1;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.getHikayesi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto} returns this
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.setHikayesi = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string gecirdigioperasyonlar = 2;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.getGecirdigioperasyonlar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto} returns this
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.setGecirdigioperasyonlar = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string kullandigiIlaclar = 3;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.getKullandigiilaclar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto} returns this
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.setKullandigiilaclar = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sistemikhastaliklar = 4;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.getSistemikhastaliklar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto} returns this
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.setSistemikhastaliklar = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string alerji = 5;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.getAlerji = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto} returns this
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.setAlerji = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string tedavi = 6;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.getTedavi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto} returns this
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.setTedavi = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string recete = 7;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.getRecete = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto} returns this
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.setRecete = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string kullanimadedi = 8;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.getKullanimadedi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto} returns this
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.setKullanimadedi = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string kullanimdozu = 9;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.getKullanimdozu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto} returns this
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.setKullanimdozu = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string receteaciklama = 10;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.getReceteaciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto} returns this
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.setReceteaciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 id = 11;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto} returns this
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string hikayesimetin = 12;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.getHikayesimetin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto} returns this
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.setHikayesimetin = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string icdtanim = 13;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.getIcdtanim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto} returns this
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto.prototype.setIcdtanim = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated DoctorPanelTibbiGecmisDto DoctorPanelTibbiGecmisResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto>}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.prototype.getDoctorpaneltibbigecmisresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto>} value
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc} returns this
*/
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.prototype.setDoctorpaneltibbigecmisresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto}
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.prototype.addDoctorpaneltibbigecmisresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.DoctorPanelTibbiGecmisDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelTibbiGecmisResponseGrpc.prototype.clearDoctorpaneltibbigecmisresponseList = function() {
  return this.setDoctorpaneltibbigecmisresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc;
  return proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 userid = 1;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciRequestGrpc.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    doctorpanelicdagaciresponseList: jspb.Message.toObjectList(msg.getDoctorpanelicdagaciresponseList(),
    proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc;
  return proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto;
      reader.readMessage(value,proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.deserializeBinaryFromReader);
      msg.addDoctorpanelicdagaciresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoctorpanelicdagaciresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    kodu: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    usticd: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto;
  return proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKodu(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsticd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getKodu();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getUsticd();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto} returns this
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string kodu = 2;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.prototype.getKodu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto} returns this
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.prototype.setKodu = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string adi = 3;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto} returns this
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 usticd = 4;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.prototype.getUsticd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto} returns this
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto.prototype.setUsticd = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated DoctorPanelIcdAgaciDto DoctorPanelIcdAgaciResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto>}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.prototype.getDoctorpanelicdagaciresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto>} value
 * @return {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc} returns this
*/
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.prototype.setDoctorpanelicdagaciresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto}
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.prototype.addDoctorpanelicdagaciresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.DoctorPanelIcdAgaciDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelIcdAgaciResponseGrpc.prototype.clearDoctorpanelicdagaciresponseList = function() {
  return this.setDoctorpanelicdagaciresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    userid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc;
  return proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 userid = 1;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListRequestGrpc.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    doctorpaneliislemtanimlistresponseList: jspb.Message.toObjectList(msg.getDoctorpaneliislemtanimlistresponseList(),
    proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc;
  return proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto;
      reader.readMessage(value,proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.deserializeBinaryFromReader);
      msg.addDoctorpaneliislemtanimlistresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoctorpaneliislemtanimlistresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    adi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sutkodu: jspb.Message.getFieldWithDefault(msg, 3, ""),
    huvbirimi: jspb.Message.getFieldWithDefault(msg, 4, ""),
    aciklama: jspb.Message.getFieldWithDefault(msg, 5, ""),
    islemgrubu: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto;
  return proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSutkodu(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHuvbirimi(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAciklama(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIslemgrubu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSutkodu();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHuvbirimi();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAciklama();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIslemgrubu();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto} returns this
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string adi = 2;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto} returns this
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sutkodu = 3;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.prototype.getSutkodu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto} returns this
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.prototype.setSutkodu = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string huvbirimi = 4;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.prototype.getHuvbirimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto} returns this
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.prototype.setHuvbirimi = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Aciklama = 5;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.prototype.getAciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto} returns this
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.prototype.setAciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 IslemGrubu = 6;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.prototype.getIslemgrubu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto} returns this
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto.prototype.setIslemgrubu = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated DoctorPaneliIslemTanimListDto DoctorPaneliIslemTanimListResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto>}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.prototype.getDoctorpaneliislemtanimlistresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto>} value
 * @return {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc} returns this
*/
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.prototype.setDoctorpaneliislemtanimlistresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto}
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.prototype.addDoctorpaneliislemtanimlistresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.DoctorPaneliIslemTanimListDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc} returns this
 */
proto.doktorpanelipr.DoctorPaneliIslemTanimListResponseGrpc.prototype.clearDoctorpaneliislemtanimlistresponseList = function() {
  return this.setDoctorpaneliislemtanimlistresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc;
  return proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciRequestGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    doctorpanelislemgrubuagaciresponseList: jspb.Message.toObjectList(msg.getDoctorpanelislemgrubuagaciresponseList(),
    proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse;
  return proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto;
      reader.readMessage(value,proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.deserializeBinaryFromReader);
      msg.addDoctorpanelislemgrubuagaciresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoctorpanelislemgrubuagaciresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    adi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tipi: jspb.Message.getFieldWithDefault(msg, 3, 0),
    ustislemgrubu: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sirano: jspb.Message.getFieldWithDefault(msg, 5, 0),
    kayitdurumu: jspb.Message.getFieldWithDefault(msg, 6, 0),
    altidleri: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto;
  return proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTipi(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUstislemgrubu(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSirano(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKayitdurumu(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAltidleri(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTipi();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getUstislemgrubu();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSirano();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getKayitdurumu();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getAltidleri();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto} returns this
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string adi = 2;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto} returns this
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 tipi = 3;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.prototype.getTipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto} returns this
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.prototype.setTipi = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 ustislemgrubu = 4;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.prototype.getUstislemgrubu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto} returns this
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.prototype.setUstislemgrubu = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 sirano = 5;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.prototype.getSirano = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto} returns this
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.prototype.setSirano = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 kayitdurumu = 6;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.prototype.getKayitdurumu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto} returns this
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.prototype.setKayitdurumu = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string altidleri = 7;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.prototype.getAltidleri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto} returns this
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto.prototype.setAltidleri = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated DoctorPanelIslemGrubuAgaciDto DoctorPanelIslemGrubuAgaciResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto>}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.prototype.getDoctorpanelislemgrubuagaciresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto>} value
 * @return {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse} returns this
*/
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.prototype.setDoctorpanelislemgrubuagaciresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto}
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.prototype.addDoctorpanelislemgrubuagaciresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.DoctorPanelIslemGrubuAgaciDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse} returns this
 */
proto.doktorpanelipr.DoctorPanelIslemGrubuAgaciResponse.prototype.clearDoctorpanelislemgrubuagaciresponseList = function() {
  return this.setDoctorpanelislemgrubuagaciresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc;
  return proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    dplistekleripopuptestlerigetirresponseList: jspb.Message.toObjectList(msg.getDplistekleripopuptestlerigetirresponseList(),
    proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc;
  return proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto;
      reader.readMessage(value,proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.deserializeBinaryFromReader);
      msg.addDplistekleripopuptestlerigetirresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDplistekleripopuptestlerigetirresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    islemgrubu: jspb.Message.getFieldWithDefault(msg, 2, 0),
    gorunuradi: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto;
  return proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIslemgrubu(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGorunuradi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIslemgrubu();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getGorunuradi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto} returns this
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 islemGrubu = 2;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.prototype.getIslemgrubu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto} returns this
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.prototype.setIslemgrubu = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string gorunurAdi = 3;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.prototype.getGorunuradi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto} returns this
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto.prototype.setGorunuradi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto DPLIstekleriPopupTestleriGetirResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto>}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.prototype.getDplistekleripopuptestlerigetirresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto>} value
 * @return {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc} returns this
*/
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.prototype.setDplistekleripopuptestlerigetirresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto}
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.prototype.addDplistekleripopuptestlerigetirresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.DoctorPanelLaboratuarIstekleriPopupTestleriGetirDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelLaboratuarIstekleriPopupTestleriGetirResponseGrpc.prototype.clearDplistekleripopuptestlerigetirresponseList = function() {
  return this.setDplistekleripopuptestlerigetirresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bolumid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    crud: jspb.Message.getFieldWithDefault(msg, 4, ""),
    baslik: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cevap: jspb.Message.getFieldWithDefault(msg, 6, ""),
    kurumid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc;
  return proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBolumid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrud(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaslik(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCevap(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getBolumid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCrud();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBaslik();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCevap();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 userid = 2;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 bolumid = 3;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.prototype.getBolumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.prototype.setBolumid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string crud = 4;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.prototype.getCrud = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.prototype.setCrud = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string baslik = 5;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.prototype.getBaslik = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.prototype.setBaslik = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string cevap = 6;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.prototype.getCevap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.prototype.setCevap = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 kurumid = 7;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudRequestGrpc.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    returnmessage: jspb.Message.getFieldWithDefault(msg, 1, ""),
    doctorpanelmuayenecevapresponseList: jspb.Message.toObjectList(msg.getDoctorpanelmuayenecevapresponseList(),
    proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc;
  return proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReturnmessage(value);
      break;
    case 2:
      var value = new proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto;
      reader.readMessage(value,proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.deserializeBinaryFromReader);
      msg.addDoctorpanelmuayenecevapresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReturnmessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDoctorpanelmuayenecevapresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    cevap: jspb.Message.getFieldWithDefault(msg, 2, ""),
    baslik: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto;
  return proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCevap(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaslik(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCevap();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getBaslik();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string cevap = 2;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.prototype.getCevap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.prototype.setCevap = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string baslik = 3;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.prototype.getBaslik = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto} returns this
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto.prototype.setBaslik = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string returnmessage = 1;
 * @return {string}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.prototype.getReturnmessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.prototype.setReturnmessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated DoctorPanelMuayeneCevapCrudDto DoctorPanelMuayeneCevapResponse = 2;
 * @return {!Array<!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto>}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.prototype.getDoctorpanelmuayenecevapresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto, 2));
};


/**
 * @param {!Array<!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto>} value
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc} returns this
*/
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.prototype.setDoctorpanelmuayenecevapresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto}
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.prototype.addDoctorpanelmuayenecevapresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.DoctorPanelMuayeneCevapCrudDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc} returns this
 */
proto.doktorpanelipr.DoctorPanelMuayeneCevapCrudResponseGrpc.prototype.clearDoctorpanelmuayenecevapresponseList = function() {
  return this.setDoctorpanelmuayenecevapresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    muayeneid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMuayeneid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMuayeneid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 muayeneid = 1;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc.prototype.getMuayeneid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolRequestGrpc.prototype.setMuayeneid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    genelmuayeneformtedavikontrolresponseList: jspb.Message.toObjectList(msg.getGenelmuayeneformtedavikontrolresponseList(),
    proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc.deserializeBinaryFromReader);
      msg.addGenelmuayeneformtedavikontrolresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGenelmuayeneformtedavikontrolresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    sonuc: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSonuc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSonuc();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string sonuc = 1;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc.prototype.getSonuc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc.prototype.setSonuc = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated GenelMuayeneFormTedaviKontrolDtoGrpc GenelMuayeneFormTedaviKontrolResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc>}
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.prototype.getGenelmuayeneformtedavikontrolresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc>} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc} returns this
*/
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.prototype.setGenelmuayeneformtedavikontrolresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.prototype.addGenelmuayeneformtedavikontrolresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.GenelMuayeneFormTedaviKontrolDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormTedaviKontrolResponseGrpc.prototype.clearGenelmuayeneformtedavikontrolresponseList = function() {
  return this.setGenelmuayeneformtedavikontrolresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    planlihastatakibiid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc;
  return proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanlihastatakibiid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanlihastatakibiid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string planlihastatakibiid = 1;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc.prototype.getPlanlihastatakibiid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayRequestGrpc.prototype.setPlanlihastatakibiid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    genelmuayeneplanlihastadetayresponseList: jspb.Message.toObjectList(msg.getGenelmuayeneplanlihastadetayresponseList(),
    proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc;
  return proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto;
      reader.readMessage(value,proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.deserializeBinaryFromReader);
      msg.addGenelmuayeneplanlihastadetayresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGenelmuayeneplanlihastadetayresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    planlanantarih: jspb.Message.getFieldWithDefault(msg, 1, ""),
    gerceklesentarih: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sonuc: jspb.Message.getFieldWithDefault(msg, 3, ""),
    durum: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto;
  return proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlanlanantarih(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setGerceklesentarih(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSonuc(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDurum(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlanlanantarih();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGerceklesentarih();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSonuc();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDurum();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string planlanantarih = 1;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.prototype.getPlanlanantarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto} returns this
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.prototype.setPlanlanantarih = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string gerceklesentarih = 2;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.prototype.getGerceklesentarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto} returns this
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.prototype.setGerceklesentarih = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sonuc = 3;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.prototype.getSonuc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto} returns this
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.prototype.setSonuc = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 durum = 4;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.prototype.getDurum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto} returns this
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto.prototype.setDurum = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated GenelMuayenePlanliHastaDetayDto GenelMuayenePlanliHastaDetayResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto>}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.prototype.getGenelmuayeneplanlihastadetayresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto>} value
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc} returns this
*/
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.prototype.setGenelmuayeneplanlihastadetayresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.prototype.addGenelmuayeneplanlihastadetayresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.GenelMuayenePlanliHastaDetayDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaDetayResponseGrpc.prototype.clearGenelmuayeneplanlihastadetayresponseList = function() {
  return this.setGenelmuayeneplanlihastadetayresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    protokolid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc;
  return proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtokolid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProtokolid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string protokolid = 1;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc.prototype.getProtokolid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaRequestGrpc.prototype.setProtokolid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    genelmuayeneplanlihastaresponseList: jspb.Message.toObjectList(msg.getGenelmuayeneplanlihastaresponseList(),
    proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc;
  return proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto;
      reader.readMessage(value,proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.deserializeBinaryFromReader);
      msg.addGenelmuayeneplanlihastaresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGenelmuayeneplanlihastaresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    eklemezamani: jspb.Message.getFieldWithDefault(msg, 1, ""),
    baslangictarihi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sorumludoktor: jspb.Message.getFieldWithDefault(msg, 4, 0),
    adisoyadi: jspb.Message.getFieldWithDefault(msg, 5, ""),
    periyot: jspb.Message.getFieldWithDefault(msg, 6, 0),
    tekrar: jspb.Message.getFieldWithDefault(msg, 7, 0),
    id: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto;
  return proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEklemezamani(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaslangictarihi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSorumludoktor(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdisoyadi(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPeriyot(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTekrar(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEklemezamani();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBaslangictarihi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSorumludoktor();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAdisoyadi();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPeriyot();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getTekrar();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional string eklemezamani = 1;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.getEklemezamani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto} returns this
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.setEklemezamani = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string baslangictarihi = 2;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.getBaslangictarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto} returns this
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.setBaslangictarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string adi = 3;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto} returns this
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 sorumludoktor = 4;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.getSorumludoktor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto} returns this
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.setSorumludoktor = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string adisoyadi = 5;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.getAdisoyadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto} returns this
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.setAdisoyadi = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 periyot = 6;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.getPeriyot = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto} returns this
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.setPeriyot = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 tekrar = 7;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.getTekrar = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto} returns this
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.setTekrar = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 id = 8;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto} returns this
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * repeated GenelMuayenePlanliHastaDto GenelMuayenePlanliHastaResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto>}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.prototype.getGenelmuayeneplanlihastaresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto>} value
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc} returns this
*/
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.prototype.setGenelmuayeneplanlihastaresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto}
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.prototype.addGenelmuayeneplanlihastaresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.GenelMuayenePlanliHastaDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayenePlanliHastaResponseGrpc.prototype.clearGenelmuayeneplanlihastaresponseList = function() {
  return this.setGenelmuayeneplanlihastaresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    protokolid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc;
  return proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtokolid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProtokolid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string protokolid = 1;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc.prototype.getProtokolid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestRequestGrpc.prototype.setProtokolid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    genelmuayenetekniktestresponseList: jspb.Message.toObjectList(msg.getGenelmuayenetekniktestresponseList(),
    proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc;
  return proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.deserializeBinaryFromReader);
      msg.addGenelmuayenetekniktestresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGenelmuayenetekniktestresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    referansdegeruyaritipi: jspb.Message.getFieldWithDefault(msg, 2, 0),
    durum: jspb.Message.getFieldWithDefault(msg, 3, 0),
    durumtanim: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sonuctipi: jspb.Message.getFieldWithDefault(msg, 5, 0),
    tipi: jspb.Message.getFieldWithDefault(msg, 6, 0),
    adi: jspb.Message.getFieldWithDefault(msg, 7, ""),
    usttestid: jspb.Message.getFieldWithDefault(msg, 8, 0),
    sonucyazi: jspb.Message.getFieldWithDefault(msg, 9, ""),
    sonucsayisal: jspb.Message.getFieldWithDefault(msg, 10, 0),
    sonucenum: jspb.Message.getFieldWithDefault(msg, 11, 0),
    sonucontanimli: jspb.Message.getFieldWithDefault(msg, 12, 0),
    sonucuzunyazi: jspb.Message.getFieldWithDefault(msg, 13, ""),
    bakterisayisi: jspb.Message.getFieldWithDefault(msg, 14, 0),
    izinlimi: jspb.Message.getFieldWithDefault(msg, 15, 0),
    aciklama: jspb.Message.getFieldWithDefault(msg, 16, ""),
    birimi: jspb.Message.getFieldWithDefault(msg, 17, ""),
    eklemezamani: jspb.Message.getFieldWithDefault(msg, 18, ""),
    tahminisonuctarihi: jspb.Message.getFieldWithDefault(msg, 19, ""),
    testnotu: jspb.Message.getFieldWithDefault(msg, 20, ""),
    rapornotu: jspb.Message.getFieldWithDefault(msg, 21, ""),
    sira: jspb.Message.getFieldWithDefault(msg, 22, 0),
    enumtipi: jspb.Message.getFieldWithDefault(msg, 23, 0),
    raportipi: jspb.Message.getFieldWithDefault(msg, 24, 0),
    klinisyennotu: jspb.Message.getFieldWithDefault(msg, 25, ""),
    secili: jspb.Message.getFieldWithDefault(msg, 26, 0),
    durumimg: jspb.Message.getFieldWithDefault(msg, 27, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc;
  return proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setReferansdegeruyaritipi(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDurum(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDurumtanim(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSonuctipi(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTipi(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUsttestid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSonucyazi(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSonucsayisal(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSonucenum(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSonucontanimli(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSonucuzunyazi(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBakterisayisi(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIzinlimi(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAciklama(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBirimi(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setEklemezamani(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setTahminisonuctarihi(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestnotu(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setRapornotu(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSira(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEnumtipi(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRaportipi(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setKlinisyennotu(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSecili(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setDurumimg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getReferansdegeruyaritipi();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDurum();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getDurumtanim();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSonuctipi();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getTipi();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUsttestid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getSonucyazi();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSonucsayisal();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getSonucenum();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getSonucontanimli();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getSonucuzunyazi();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBakterisayisi();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getIzinlimi();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getAciklama();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getBirimi();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getEklemezamani();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getTahminisonuctarihi();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getTestnotu();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getRapornotu();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getSira();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getEnumtipi();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getRaportipi();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getKlinisyennotu();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getSecili();
  if (f !== 0) {
    writer.writeInt32(
      26,
      f
    );
  }
  f = message.getDurumimg();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 ReferansDegerUyariTipi = 2;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getReferansdegeruyaritipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setReferansdegeruyaritipi = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 Durum = 3;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getDurum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setDurum = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string durumtanim = 4;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getDurumtanim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setDurumtanim = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 SonucTipi = 5;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getSonuctipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setSonuctipi = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 Tipi = 6;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getTipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setTipi = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string Adi = 7;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 UstTestId = 8;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getUsttestid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setUsttestid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string SonucYazi = 9;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getSonucyazi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setSonucyazi = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 SonucSayisal = 10;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getSonucsayisal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setSonucsayisal = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 SonucEnum = 11;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getSonucenum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setSonucenum = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 SonucOnTanimli = 12;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getSonucontanimli = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setSonucontanimli = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string SonucUzunYazi = 13;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getSonucuzunyazi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setSonucuzunyazi = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 BakteriSayisi = 14;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getBakterisayisi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setBakterisayisi = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 IzinliMi = 15;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getIzinlimi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setIzinlimi = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string Aciklama = 16;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getAciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setAciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string Birimi = 17;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getBirimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setBirimi = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string EklemeZamani = 18;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getEklemezamani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setEklemezamani = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string TahminiSonucTarihi = 19;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getTahminisonuctarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setTahminisonuctarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string TestNotu = 20;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getTestnotu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setTestnotu = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string RaporNotu = 21;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getRapornotu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setRapornotu = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional int32 Sira = 22;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getSira = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setSira = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int32 EnumTipi = 23;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getEnumtipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setEnumtipi = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 RaporTipi = 24;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getRaportipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setRaportipi = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional string KlinisyenNotu = 25;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getKlinisyennotu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setKlinisyennotu = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional int32 Secili = 26;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getSecili = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setSecili = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional string durumimg = 27;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.getDurumimg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc.prototype.setDurumimg = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * repeated GenelMuayeneTeknikTestDtoGrpc GenelMuayeneTeknikTestResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc>}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.prototype.getGenelmuayenetekniktestresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc>} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc} returns this
*/
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.prototype.setGenelmuayenetekniktestresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.prototype.addGenelmuayenetekniktestresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.GenelMuayeneTeknikTestDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTeknikTestResponseGrpc.prototype.clearGenelmuayenetekniktestresponseList = function() {
  return this.setGenelmuayenetekniktestresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    protokolid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc;
  return proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtokolid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProtokolid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string protokolid = 1;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc.prototype.getProtokolid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariRequestGrpc.prototype.setProtokolid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    genelmuayenetetkikdiskurumraporlarresponseList: jspb.Message.toObjectList(msg.getGenelmuayenetetkikdiskurumraporlarresponseList(),
    proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc;
  return proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc.deserializeBinaryFromReader);
      msg.addGenelmuayenetetkikdiskurumraporlarresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGenelmuayenetetkikdiskurumraporlarresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    dosya: jspb.Message.getFieldWithDefault(msg, 1, ""),
    aciklama: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc;
  return proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDosya(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAciklama(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDosya();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAciklama();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string dosya = 1;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc.prototype.getDosya = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc.prototype.setDosya = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string aciklama = 2;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc.prototype.getAciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc.prototype.setAciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated GenelMuayeneTetkikDisKurumRaporlariDtoGrpc GenelMuayeneTetkikDisKurumRaporlarResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc>}
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.prototype.getGenelmuayenetetkikdiskurumraporlarresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc>} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc} returns this
*/
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.prototype.setGenelmuayenetetkikdiskurumraporlarresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.prototype.addGenelmuayenetetkikdiskurumraporlarresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.GenelMuayeneTetkikDisKurumRaporlariDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTetkikDisKurumRaporlariResponseGrpc.prototype.clearGenelmuayenetetkikdiskurumraporlarresponseList = function() {
  return this.setGenelmuayenetetkikdiskurumraporlarresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    protokolid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc;
  return proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtokolid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProtokolid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string protokolid = 1;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc.prototype.getProtokolid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerRequestGrpc.prototype.setProtokolid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    genelmuayenetetkiklerresponseList: jspb.Message.toObjectList(msg.getGenelmuayenetetkiklerresponseList(),
    proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc;
  return proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.deserializeBinaryFromReader);
      msg.addGenelmuayenetetkiklerresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGenelmuayenetetkiklerresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    tetkikid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tetkik: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tarih: jspb.Message.getFieldWithDefault(msg, 3, ""),
    onaytarihi: jspb.Message.getFieldWithDefault(msg, 4, ""),
    durum: jspb.Message.getFieldWithDefault(msg, 5, 0),
    isteyeninaciklamasi: jspb.Message.getFieldWithDefault(msg, 6, ""),
    kayitdurumu: jspb.Message.getFieldWithDefault(msg, 7, 0),
    durumtanim: jspb.Message.getFieldWithDefault(msg, 8, ""),
    durumimg: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc;
  return proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTetkikid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTetkik(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnaytarihi(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDurum(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsteyeninaciklamasi(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKayitdurumu(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDurumtanim(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDurumimg(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTetkikid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTetkik();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOnaytarihi();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDurum();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getIsteyeninaciklamasi();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getKayitdurumu();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getDurumtanim();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDurumimg();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 Tetkikid = 1;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.getTetkikid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.setTetkikid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Tetkik = 2;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.getTetkik = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.setTetkik = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Tarih = 3;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string OnayTarihi = 4;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.getOnaytarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.setOnaytarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 Durum = 5;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.getDurum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.setDurum = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string IsteyeninAciklamasi = 6;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.getIsteyeninaciklamasi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.setIsteyeninaciklamasi = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 KayitDurumu = 7;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.getKayitdurumu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.setKayitdurumu = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string durumtanim = 8;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.getDurumtanim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.setDurumtanim = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string durumimg = 9;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.getDurumimg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc.prototype.setDurumimg = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated GenelMuayeneTetkiklerDtoGrpc GenelMuayeneTetkiklerResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc>}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.prototype.getGenelmuayenetetkiklerresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc>} value
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc} returns this
*/
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.prototype.setGenelmuayenetetkiklerresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.prototype.addGenelmuayenetetkiklerresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.GenelMuayeneTetkiklerDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneTetkiklerResponseGrpc.prototype.clearGenelmuayenetetkiklerresponseList = function() {
  return this.setGenelmuayenetetkiklerresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    islemid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIslemid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIslemid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 islemid = 1;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc.prototype.getIslemid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorRequestGrpc.prototype.setIslemid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    genelmuayeneformfordoctorresponseList: jspb.Message.toObjectList(msg.getGenelmuayeneformfordoctorresponseList(),
    proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.deserializeBinaryFromReader);
      msg.addGenelmuayeneformfordoctorresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGenelmuayeneformfordoctorresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    triajbilgisi: jspb.Message.getFieldWithDefault(msg, 1, ""),
    iskazasimi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    trafikkazasimi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    adlivakami: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTriajbilgisi(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIskazasimi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrafikkazasimi(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdlivakami(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTriajbilgisi();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIskazasimi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTrafikkazasimi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAdlivakami();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string triajbilgisi = 1;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.prototype.getTriajbilgisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.prototype.setTriajbilgisi = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string iskazasimi = 2;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.prototype.getIskazasimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.prototype.setIskazasimi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string trafikkazasimi = 3;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.prototype.getTrafikkazasimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.prototype.setTrafikkazasimi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string adlivakami = 4;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.prototype.getAdlivakami = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc.prototype.setAdlivakami = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated GenelMuayeneFormForDoctorDtoGrpc GenelMuayeneFormForDoctorResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc>}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.prototype.getGenelmuayeneformfordoctorresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc>} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc} returns this
*/
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.prototype.setGenelmuayeneformfordoctorresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.prototype.addGenelmuayeneformfordoctorresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.GenelMuayeneFormForDoctorDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormForDoctorResponseGrpc.prototype.clearGenelmuayeneformfordoctorresponseList = function() {
  return this.setGenelmuayeneformfordoctorresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    istekid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIstekid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIstekid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 istekid = 1;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc.prototype.getIstekid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormReceteRequestGrpc.prototype.setIstekid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    genelmuayeneformreceteresponseList: jspb.Message.toObjectList(msg.getGenelmuayeneformreceteresponseList(),
    proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.deserializeBinaryFromReader);
      msg.addGenelmuayeneformreceteresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGenelmuayeneformreceteresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    adi: jspb.Message.getFieldWithDefault(msg, 1, ""),
    kullanimadedi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    kullanimdozu: jspb.Message.getFieldWithDefault(msg, 3, ""),
    kullanimsekli: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tammetin: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKullanimadedi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKullanimdozu(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKullanimsekli(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTammetin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKullanimadedi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKullanimdozu();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKullanimsekli();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTammetin();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string adi = 1;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string kullanimadedi = 2;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.prototype.getKullanimadedi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.prototype.setKullanimadedi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string kullanimdozu = 3;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.prototype.getKullanimdozu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.prototype.setKullanimdozu = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string kullanimsekli = 4;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.prototype.getKullanimsekli = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.prototype.setKullanimsekli = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tammetin = 5;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.prototype.getTammetin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc.prototype.setTammetin = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated GenelMuayeneFormReceteDtoGrpc GenelMuayeneFormReceteResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc>}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.prototype.getGenelmuayeneformreceteresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc>} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc} returns this
*/
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.prototype.setGenelmuayeneformreceteresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.prototype.addGenelmuayeneformreceteresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.GenelMuayeneFormReceteDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormReceteResponseGrpc.prototype.clearGenelmuayeneformreceteresponseList = function() {
  return this.setGenelmuayeneformreceteresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    istekid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIstekid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIstekid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 istekid = 1;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc.prototype.getIstekid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekRequestGrpc.prototype.setIstekid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    genelmuayeneformtumtanilarbyistekresponseList: jspb.Message.toObjectList(msg.getGenelmuayeneformtumtanilarbyistekresponseList(),
    proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc.deserializeBinaryFromReader);
      msg.addGenelmuayeneformtumtanilarbyistekresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGenelmuayeneformtumtanilarbyistekresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    kodu: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adi: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKodu(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKodu();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string kodu = 1;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc.prototype.getKodu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc.prototype.setKodu = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string adi = 2;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated GenelMuayeneFormTumTanilarByIstekDtoGrpc GenelMuayeneFormTumTanilarByIstekResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc>}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.prototype.getGenelmuayeneformtumtanilarbyistekresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc>} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc} returns this
*/
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.prototype.setGenelmuayeneformtumtanilarbyistekresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.prototype.addGenelmuayeneformtumtanilarbyistekresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.GenelMuayeneFormTumTanilarByIstekDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByIstekResponseGrpc.prototype.clearGenelmuayeneformtumtanilarbyistekresponseList = function() {
  return this.setGenelmuayeneformtumtanilarbyistekresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    personelid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPersonelid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPersonelid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 personelid = 1;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc.prototype.getPersonelid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelRequestGrpc.prototype.setPersonelid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    genelmuayeneformtumtanilarbyperonelresponseList: jspb.Message.toObjectList(msg.getGenelmuayeneformtumtanilarbyperonelresponseList(),
    proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc.deserializeBinaryFromReader);
      msg.addGenelmuayeneformtumtanilarbyperonelresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGenelmuayeneformtumtanilarbyperonelresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    kodu: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adi: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKodu(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKodu();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string kodu = 1;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc.prototype.getKodu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc.prototype.setKodu = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string adi = 2;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated GenelMuayeneFormTumTanilarByPeronelDtoGrpc GenelMuayeneFormTumTanilarByPeronelResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc>}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.prototype.getGenelmuayeneformtumtanilarbyperonelresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc>} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc} returns this
*/
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.prototype.setGenelmuayeneformtumtanilarbyperonelresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.prototype.addGenelmuayeneformtumtanilarbyperonelresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.GenelMuayeneFormTumTanilarByPeronelDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormTumTanilarByPersonelResponseGrpc.prototype.clearGenelmuayeneformtumtanilarbyperonelresponseList = function() {
  return this.setGenelmuayeneformtumtanilarbyperonelresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    muayeneid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormRequestGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormRequestGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMuayeneid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMuayeneid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 muayeneid = 1;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneFormRequestGrpc.prototype.getMuayeneid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormRequestGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormRequestGrpc.prototype.setMuayeneid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    genelmuayeneformdtoresponseList: jspb.Message.toObjectList(msg.getGenelmuayeneformdtoresponseList(),
    proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormResponseGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.deserializeBinaryFromReader);
      msg.addGenelmuayeneformdtoresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGenelmuayeneformdtoresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    agriyeri: jspb.Message.getFieldWithDefault(msg, 1, ""),
    yenidendegerlendirme: jspb.Message.getFieldWithDefault(msg, 2, ""),
    yapilanuygulama: jspb.Message.getFieldWithDefault(msg, 3, ""),
    agrisuresi: jspb.Message.getFieldWithDefault(msg, 4, ""),
    agridegerlendirme: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sonadettarihiaciklama: jspb.Message.getFieldWithDefault(msg, 6, ""),
    fonksiyoneldurum: jspb.Message.getFieldWithDefault(msg, 7, 0),
    fonksiyoneldurumtxt: jspb.Message.getFieldWithDefault(msg, 8, ""),
    bilincdurumu: jspb.Message.getFieldWithDefault(msg, 9, 0),
    bilincdurumutxt: jspb.Message.getFieldWithDefault(msg, 10, ""),
    beslenmedurumuaciklama: jspb.Message.getFieldWithDefault(msg, 11, ""),
    bulgularmetin: jspb.Message.getFieldWithDefault(msg, 12, ""),
    aliskanliklar: jspb.Message.getFieldWithDefault(msg, 13, ""),
    alerji: jspb.Message.getFieldWithDefault(msg, 14, ""),
    sistemikhastaliklar: jspb.Message.getFieldWithDefault(msg, 15, ""),
    gecirdigioperasyonlar: jspb.Message.getFieldWithDefault(msg, 16, ""),
    kullandigiilaclar: jspb.Message.getFieldWithDefault(msg, 17, ""),
    sikayetbaslangictarihi: jspb.Message.getFieldWithDefault(msg, 18, ""),
    hikayesimetin: jspb.Message.getFieldWithDefault(msg, 19, ""),
    ozgecmisimetin: jspb.Message.getFieldWithDefault(msg, 20, ""),
    id: jspb.Message.getFieldWithDefault(msg, 21, ""),
    distetkikraporumetin: jspb.Message.getFieldWithDefault(msg, 22, ""),
    ozelnot: jspb.Message.getFieldWithDefault(msg, 23, ""),
    islem: jspb.Message.getFieldWithDefault(msg, 24, ""),
    tedavimetin: jspb.Message.getFieldWithDefault(msg, 25, ""),
    taniaciklama: jspb.Message.getFieldWithDefault(msg, 26, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc;
  return proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgriyeri(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setYenidendegerlendirme(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setYapilanuygulama(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgrisuresi(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAgridegerlendirme(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSonadettarihiaciklama(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFonksiyoneldurum(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFonksiyoneldurumtxt(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBilincdurumu(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setBilincdurumutxt(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeslenmedurumuaciklama(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setBulgularmetin(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAliskanliklar(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlerji(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSistemikhastaliklar(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setGecirdigioperasyonlar(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setKullandigiilaclar(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setSikayetbaslangictarihi(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setHikayesimetin(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setOzgecmisimetin(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistetkikraporumetin(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setOzelnot(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setIslem(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setTedavimetin(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaniaciklama(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAgriyeri();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getYenidendegerlendirme();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getYapilanuygulama();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAgrisuresi();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAgridegerlendirme();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSonadettarihiaciklama();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFonksiyoneldurum();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getFonksiyoneldurumtxt();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getBilincdurumu();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getBilincdurumutxt();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBeslenmedurumuaciklama();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getBulgularmetin();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAliskanliklar();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAlerji();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getSistemikhastaliklar();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getGecirdigioperasyonlar();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getKullandigiilaclar();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSikayetbaslangictarihi();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getHikayesimetin();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getOzgecmisimetin();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getDistetkikraporumetin();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getOzelnot();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getIslem();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getTedavimetin();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getTaniaciklama();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
};


/**
 * optional string agriYeri = 1;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getAgriyeri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setAgriyeri = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string yenidenDegerlendirme = 2;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getYenidendegerlendirme = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setYenidendegerlendirme = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string yapilanUygulama = 3;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getYapilanuygulama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setYapilanuygulama = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string agriSuresi = 4;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getAgrisuresi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setAgrisuresi = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string agriDegerlendirme = 5;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getAgridegerlendirme = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setAgridegerlendirme = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string sonAdetTarihiAciklama = 6;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getSonadettarihiaciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setSonadettarihiaciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 fonksiyonelDurum = 7;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getFonksiyoneldurum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setFonksiyoneldurum = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string fonksiyonelDurumTxt = 8;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getFonksiyoneldurumtxt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setFonksiyoneldurumtxt = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 bilincDurumu = 9;
 * @return {number}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getBilincdurumu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setBilincdurumu = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string bilincDurumuTxt = 10;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getBilincdurumutxt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setBilincdurumutxt = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string beslenmeDurumuAciklama = 11;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getBeslenmedurumuaciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setBeslenmedurumuaciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string bulgularMetin = 12;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getBulgularmetin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setBulgularmetin = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string aliskanliklar = 13;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getAliskanliklar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setAliskanliklar = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string alerji = 14;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getAlerji = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setAlerji = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string sistemikHastaliklar = 15;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getSistemikhastaliklar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setSistemikhastaliklar = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string gecirdigiOperasyonlar = 16;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getGecirdigioperasyonlar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setGecirdigioperasyonlar = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string kullandigiIlaclar = 17;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getKullandigiilaclar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setKullandigiilaclar = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string sikayetBaslangicTarihi = 18;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getSikayetbaslangictarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setSikayetbaslangictarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string hikayesiMetin = 19;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getHikayesimetin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setHikayesimetin = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string ozgecmisiMetin = 20;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getOzgecmisimetin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setOzgecmisimetin = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string id = 21;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string distetkikraporumetin = 22;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getDistetkikraporumetin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setDistetkikraporumetin = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string ozelnot = 23;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getOzelnot = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setOzelnot = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string islem = 24;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getIslem = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setIslem = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string tedavimetin = 25;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getTedavimetin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setTedavimetin = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string taniaciklama = 26;
 * @return {string}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.getTaniaciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc.prototype.setTaniaciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * repeated GenelMuayeneFormDtoGrpc GenelMuayeneFormDtoResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc>}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.prototype.getGenelmuayeneformdtoresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc>} value
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc} returns this
*/
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.prototype.setGenelmuayeneformdtoresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc}
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.prototype.addGenelmuayeneformdtoresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.GenelMuayeneFormDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.GenelMuayeneFormResponseGrpc} returns this
 */
proto.doktorpanelipr.GenelMuayeneFormResponseGrpc.prototype.clearGenelmuayeneformdtoresponseList = function() {
  return this.setGenelmuayeneformdtoresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.AyaktanTedaviRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    datestart: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateend: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    patientno: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tckimlikno: jspb.Message.getFieldWithDefault(msg, 6, ""),
    protocol: jspb.Message.getFieldWithDefault(msg, 7, ""),
    allpatient: jspb.Message.getFieldWithDefault(msg, 8, ""),
    querytype: jspb.Message.getFieldWithDefault(msg, 9, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc}
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.AyaktanTedaviRequestGrpc;
  return proto.doktorpanelipr.AyaktanTedaviRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc}
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatestart(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateend(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientno(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTckimlikno(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtocol(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAllpatient(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuerytype(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.AyaktanTedaviRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatestart();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateend();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPatientno();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTckimlikno();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProtocol();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAllpatient();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getQuerytype();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string datestart = 1;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.getDatestart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.setDatestart = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dateend = 2;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.getDateend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.setDateend = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lastname = 4;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string patientNo = 5;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.getPatientno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.setPatientno = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string tckimlikno = 6;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.getTckimlikno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.setTckimlikno = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string protocol = 7;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.getProtocol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.setProtocol = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string allpatient = 8;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.getAllpatient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.setAllpatient = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string querytype = 9;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.getQuerytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.setQuerytype = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string userid = 10;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviRequestGrpc.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.AyaktanTedaviResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    ayaktantedavilistList: jspb.Message.toObjectList(msg.getAyaktantedavilistList(),
    proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.AyaktanTedaviResponseGrpc;
  return proto.doktorpanelipr.AyaktanTedaviResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.deserializeBinaryFromReader);
      msg.addAyaktantedavilist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.AyaktanTedaviResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAyaktantedavilistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    yapanrol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hastacinsi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    hasta: jspb.Message.getFieldWithDefault(msg, 4, ""),
    protokol: jspb.Message.getFieldWithDefault(msg, 5, ""),
    istektipi: jspb.Message.getFieldWithDefault(msg, 6, ""),
    adi: jspb.Message.getFieldWithDefault(msg, 7, ""),
    tarih: jspb.Message.getFieldWithDefault(msg, 8, ""),
    istekdurumu: jspb.Message.getFieldWithDefault(msg, 9, ""),
    personelcinsi: jspb.Message.getFieldWithDefault(msg, 10, ""),
    isteyenpersonel: jspb.Message.getFieldWithDefault(msg, 11, ""),
    yas: jspb.Message.getFieldWithDefault(msg, 12, ""),
    anaodeyicikurumtipi: jspb.Message.getFieldWithDefault(msg, 13, ""),
    islemyapabilirmi: jspb.Message.getFieldWithDefault(msg, 14, ""),
    babaadi: jspb.Message.getFieldWithDefault(msg, 15, ""),
    bulasicihastatemaslimi: jspb.Message.getFieldWithDefault(msg, 16, ""),
    izolemi: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc;
  return proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYapanrol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastacinsi(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHasta(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtokol(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIstektipi(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIstekdurumu(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonelcinsi(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsteyenpersonel(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setYas(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnaodeyicikurumtipi(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setIslemyapabilirmi(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBabaadi(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setBulasicihastatemaslimi(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setIzolemi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYapanrol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHastacinsi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHasta();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProtokol();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIstektipi();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIstekdurumu();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPersonelcinsi();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIsteyenpersonel();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getYas();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAnaodeyicikurumtipi();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getIslemyapabilirmi();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBabaadi();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getBulasicihastatemaslimi();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getIzolemi();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string yapanrol = 1;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.getYapanrol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.setYapanrol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string hastacinsi = 3;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.getHastacinsi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.setHastacinsi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string hasta = 4;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.getHasta = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.setHasta = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string protokol = 5;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.getProtokol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.setProtokol = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string istektipi = 6;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.getIstektipi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.setIstektipi = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string adi = 7;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string tarih = 8;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string istekdurumu = 9;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.getIstekdurumu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.setIstekdurumu = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string personelcinsi = 10;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.getPersonelcinsi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.setPersonelcinsi = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string isteyenpersonel = 11;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.getIsteyenpersonel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.setIsteyenpersonel = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string yas = 12;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.getYas = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.setYas = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string anaodeyicikurumtipi = 13;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.getAnaodeyicikurumtipi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.setAnaodeyicikurumtipi = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string islemyapabilirmi = 14;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.getIslemyapabilirmi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.setIslemyapabilirmi = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string babaadi = 15;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.getBabaadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.setBabaadi = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string bulasicihastatemaslimi = 16;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.getBulasicihastatemaslimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.setBulasicihastatemaslimi = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string izolemi = 17;
 * @return {string}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.getIzolemi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc.prototype.setIzolemi = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * repeated AyaktanTedaviDtoGrpc AyaktanTedaviList = 1;
 * @return {!Array<!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc>}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.prototype.getAyaktantedavilistList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc>} value
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc} returns this
*/
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.prototype.setAyaktantedavilistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc}
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.prototype.addAyaktantedavilist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.AyaktanTedaviResponseGrpc.AyaktanTedaviDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.AyaktanTedaviResponseGrpc} returns this
 */
proto.doktorpanelipr.AyaktanTedaviResponseGrpc.prototype.clearAyaktantedavilistList = function() {
  return this.setAyaktantedavilistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.YatarakTedaviRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    datestart: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateend: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastname: jspb.Message.getFieldWithDefault(msg, 4, ""),
    patientno: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tckimlikno: jspb.Message.getFieldWithDefault(msg, 6, ""),
    protocol: jspb.Message.getFieldWithDefault(msg, 7, ""),
    allpatient: jspb.Message.getFieldWithDefault(msg, 8, ""),
    querytype: jspb.Message.getFieldWithDefault(msg, 9, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    yatisdurumu: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.YatarakTedaviRequestGrpc}
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.YatarakTedaviRequestGrpc;
  return proto.doktorpanelipr.YatarakTedaviRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.YatarakTedaviRequestGrpc}
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatestart(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateend(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPatientno(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTckimlikno(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtocol(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAllpatient(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuerytype(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserid(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatisdurumu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.YatarakTedaviRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatestart();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateend();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPatientno();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTckimlikno();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getProtocol();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAllpatient();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getQuerytype();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getUserid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getYatisdurumu();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string datestart = 1;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.getDatestart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.setDatestart = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dateend = 2;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.getDateend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.setDateend = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string lastname = 4;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string patientNo = 5;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.getPatientno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.setPatientno = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string tckimlikno = 6;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.getTckimlikno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.setTckimlikno = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string protocol = 7;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.getProtocol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.setProtocol = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string allpatient = 8;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.getAllpatient = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.setAllpatient = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string querytype = 9;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.getQuerytype = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.setQuerytype = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string userid = 10;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.getUserid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.setUserid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string yatisdurumu = 11;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.getYatisdurumu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviRequestGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviRequestGrpc.prototype.setYatisdurumu = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.YatarakTedaviResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.YatarakTedaviResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    yataraktedavilistList: jspb.Message.toObjectList(msg.getYataraktedavilistList(),
    proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.YatarakTedaviResponseGrpc;
  return proto.doktorpanelipr.YatarakTedaviResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.YatarakTedaviResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.deserializeBinaryFromReader);
      msg.addYataraktedavilist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.YatarakTedaviResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.YatarakTedaviResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYataraktedavilistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    yapanrol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    id: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hastacinsi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    hasta: jspb.Message.getFieldWithDefault(msg, 4, ""),
    protokol: jspb.Message.getFieldWithDefault(msg, 5, ""),
    istektipi: jspb.Message.getFieldWithDefault(msg, 6, ""),
    adi: jspb.Message.getFieldWithDefault(msg, 7, ""),
    tarih: jspb.Message.getFieldWithDefault(msg, 8, ""),
    istekdurumu: jspb.Message.getFieldWithDefault(msg, 9, ""),
    personelcinsi: jspb.Message.getFieldWithDefault(msg, 10, ""),
    isteyenpersonel: jspb.Message.getFieldWithDefault(msg, 11, ""),
    yas: jspb.Message.getFieldWithDefault(msg, 12, ""),
    anaodeyicikurumtipi: jspb.Message.getFieldWithDefault(msg, 13, ""),
    islemyapabilirmi: jspb.Message.getFieldWithDefault(msg, 14, ""),
    babaadi: jspb.Message.getFieldWithDefault(msg, 15, ""),
    bulasicihastatemaslimi: jspb.Message.getFieldWithDefault(msg, 16, ""),
    izolemi: jspb.Message.getFieldWithDefault(msg, 17, ""),
    yatisdurumu: jspb.Message.getFieldWithDefault(msg, 18, ""),
    kabultarihi: jspb.Message.getFieldWithDefault(msg, 19, ""),
    cikistarihi: jspb.Message.getFieldWithDefault(msg, 20, ""),
    servis: jspb.Message.getFieldWithDefault(msg, 21, ""),
    yatak: jspb.Message.getFieldWithDefault(msg, 22, ""),
    onaybekleyenvarmi: jspb.Message.getFieldWithDefault(msg, 23, ""),
    apache: jspb.Message.getFieldWithDefault(msg, 24, ""),
    snapsiidegeri: jspb.Message.getFieldWithDefault(msg, 25, ""),
    snappeiidegeri: jspb.Message.getFieldWithDefault(msg, 26, ""),
    anneadi: jspb.Message.getFieldWithDefault(msg, 27, ""),
    cikissekli: jspb.Message.getFieldWithDefault(msg, 28, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc;
  return proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setYapanrol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastacinsi(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHasta(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtokol(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIstektipi(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIstekdurumu(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonelcinsi(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsteyenpersonel(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setYas(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnaodeyicikurumtipi(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setIslemyapabilirmi(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setBabaadi(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setBulasicihastatemaslimi(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setIzolemi(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatisdurumu(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setKabultarihi(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setCikistarihi(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setServis(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatak(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnaybekleyenvarmi(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setApache(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnapsiidegeri(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setSnappeiidegeri(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnneadi(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setCikissekli(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYapanrol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHastacinsi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHasta();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProtokol();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIstektipi();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIstekdurumu();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPersonelcinsi();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIsteyenpersonel();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getYas();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAnaodeyicikurumtipi();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getIslemyapabilirmi();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getBabaadi();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getBulasicihastatemaslimi();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getIzolemi();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getYatisdurumu();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getKabultarihi();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getCikistarihi();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getServis();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getYatak();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getOnaybekleyenvarmi();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getApache();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getSnapsiidegeri();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getSnappeiidegeri();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getAnneadi();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getCikissekli();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
};


/**
 * optional string yapanrol = 1;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getYapanrol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setYapanrol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string hastacinsi = 3;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getHastacinsi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setHastacinsi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string hasta = 4;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getHasta = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setHasta = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string protokol = 5;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getProtokol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setProtokol = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string istektipi = 6;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getIstektipi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setIstektipi = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string adi = 7;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string tarih = 8;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string istekdurumu = 9;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getIstekdurumu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setIstekdurumu = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string personelcinsi = 10;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getPersonelcinsi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setPersonelcinsi = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string isteyenpersonel = 11;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getIsteyenpersonel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setIsteyenpersonel = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string yas = 12;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getYas = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setYas = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string anaodeyicikurumtipi = 13;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getAnaodeyicikurumtipi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setAnaodeyicikurumtipi = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string islemyapabilirmi = 14;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getIslemyapabilirmi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setIslemyapabilirmi = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string babaadi = 15;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getBabaadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setBabaadi = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string bulasicihastatemaslimi = 16;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getBulasicihastatemaslimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setBulasicihastatemaslimi = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string izolemi = 17;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getIzolemi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setIzolemi = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string yatisdurumu = 18;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getYatisdurumu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setYatisdurumu = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string kabultarihi = 19;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getKabultarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setKabultarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string cikistarihi = 20;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getCikistarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setCikistarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string servis = 21;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getServis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setServis = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string yatak = 22;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getYatak = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setYatak = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string onaybekleyenvarmi = 23;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getOnaybekleyenvarmi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setOnaybekleyenvarmi = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string apache = 24;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getApache = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setApache = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string snapsiidegeri = 25;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getSnapsiidegeri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setSnapsiidegeri = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string snappeiidegeri = 26;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getSnappeiidegeri = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setSnappeiidegeri = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string anneadi = 27;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getAnneadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setAnneadi = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string cikissekli = 28;
 * @return {string}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.getCikissekli = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc.prototype.setCikissekli = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * repeated YatarakTedaviDtoGrpc YatarakTedaviList = 1;
 * @return {!Array<!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc>}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.prototype.getYataraktedavilistList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc>} value
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc} returns this
*/
proto.doktorpanelipr.YatarakTedaviResponseGrpc.prototype.setYataraktedavilistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc}
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.prototype.addYataraktedavilist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.YatarakTedaviResponseGrpc.YatarakTedaviDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.YatarakTedaviResponseGrpc} returns this
 */
proto.doktorpanelipr.YatarakTedaviResponseGrpc.prototype.clearYataraktedavilistList = function() {
  return this.setYataraktedavilistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.IstekQORequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.IstekQORequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.IstekQORequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.IstekQORequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hastaid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    vakaid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    kayitdurumu: jspb.Message.getFieldWithDefault(msg, 4, ""),
    databaseid: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.IstekQORequestGrpc}
 */
proto.doktorpanelipr.IstekQORequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.IstekQORequestGrpc;
  return proto.doktorpanelipr.IstekQORequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.IstekQORequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.IstekQORequestGrpc}
 */
proto.doktorpanelipr.IstekQORequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastaid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setVakaid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setKayitdurumu(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatabaseid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.IstekQORequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.IstekQORequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.IstekQORequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.IstekQORequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHastaid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getVakaid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKayitdurumu();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDatabaseid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.doktorpanelipr.IstekQORequestGrpc.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQORequestGrpc} returns this
 */
proto.doktorpanelipr.IstekQORequestGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string hastaid = 2;
 * @return {string}
 */
proto.doktorpanelipr.IstekQORequestGrpc.prototype.getHastaid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQORequestGrpc} returns this
 */
proto.doktorpanelipr.IstekQORequestGrpc.prototype.setHastaid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string vakaid = 3;
 * @return {string}
 */
proto.doktorpanelipr.IstekQORequestGrpc.prototype.getVakaid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQORequestGrpc} returns this
 */
proto.doktorpanelipr.IstekQORequestGrpc.prototype.setVakaid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string kayitdurumu = 4;
 * @return {string}
 */
proto.doktorpanelipr.IstekQORequestGrpc.prototype.getKayitdurumu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQORequestGrpc} returns this
 */
proto.doktorpanelipr.IstekQORequestGrpc.prototype.setKayitdurumu = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string databaseid = 5;
 * @return {string}
 */
proto.doktorpanelipr.IstekQORequestGrpc.prototype.getDatabaseid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQORequestGrpc} returns this
 */
proto.doktorpanelipr.IstekQORequestGrpc.prototype.setDatabaseid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.IstekQOResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.IstekQOResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.IstekQOResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.IstekQOResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    istekqoresponseList: jspb.Message.toObjectList(msg.getIstekqoresponseList(),
    proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.IstekQOResponseGrpc;
  return proto.doktorpanelipr.IstekQOResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.IstekQOResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.deserializeBinaryFromReader);
      msg.addIstekqoresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.IstekQOResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.IstekQOResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.IstekQOResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIstekqoresponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    secili: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0),
    protokolid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    hastaid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    tarih: jspb.Message.getFieldWithDefault(msg, 5, ""),
    isteyenpersoneladisoyadi: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sorumludoktoradisoyadi: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sorumlubolumadi: jspb.Message.getFieldWithDefault(msg, 8, ""),
    kayitdurumu1: jspb.Message.getFieldWithDefault(msg, 9, ""),
    tipi: jspb.Message.getFieldWithDefault(msg, 10, 0),
    hastaadisoyadi: jspb.Message.getFieldWithDefault(msg, 11, ""),
    kayitdurumu2: jspb.Message.getFieldWithDefault(msg, 12, 0),
    yapanpersoneltckimlikno: jspb.Message.getFieldWithDefault(msg, 13, ""),
    yapanpersoneladisoyadi: jspb.Message.getFieldWithDefault(msg, 14, ""),
    adi: jspb.Message.getFieldWithDefault(msg, 15, ""),
    yapanbolumadi: jspb.Message.getFieldWithDefault(msg, 16, ""),
    cevapformtipi: jspb.Message.getFieldWithDefault(msg, 17, 0),
    istektanimadi: jspb.Message.getFieldWithDefault(msg, 18, ""),
    yapanrolid: jspb.Message.getFieldWithDefault(msg, 19, 0),
    anaodeyicikurumtipi: jspb.Message.getFieldWithDefault(msg, 20, 0),
    ikinciodeyicikurumtipi: jspb.Message.getFieldWithDefault(msg, 21, 0),
    hastacinsi: jspb.Message.getFieldWithDefault(msg, 22, 0),
    indirimgrubutipi: jspb.Message.getFieldWithDefault(msg, 23, 0),
    vakaid: jspb.Message.getFieldWithDefault(msg, 24, 0),
    kabultarihi: jspb.Message.getFieldWithDefault(msg, 25, ""),
    protokoltipi: jspb.Message.getFieldWithDefault(msg, 26, ""),
    doktortckimlikno: jspb.Message.getFieldWithDefault(msg, 27, ""),
    hastatckimlikno: jspb.Message.getFieldWithDefault(msg, 28, ""),
    kangrubu: jspb.Message.getFieldWithDefault(msg, 29, ""),
    dogumtarihi: jspb.Message.getFieldWithDefault(msg, 30, ""),
    anakurumadi: jspb.Message.getFieldWithDefault(msg, 31, ""),
    ikincikurumadi: jspb.Message.getFieldWithDefault(msg, 32, ""),
    indirimgrubuadi: jspb.Message.getFieldWithDefault(msg, 33, ""),
    baslangictarihi: jspb.Message.getFieldWithDefault(msg, 34, ""),
    bitistarihi: jspb.Message.getFieldWithDefault(msg, 35, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc;
  return proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSecili(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProtokolid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHastaid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsteyenpersoneladisoyadi(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSorumludoktoradisoyadi(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSorumlubolumadi(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setKayitdurumu1(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTipi(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastaadisoyadi(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKayitdurumu2(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setYapanpersoneltckimlikno(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setYapanpersoneladisoyadi(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setYapanbolumadi(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCevapformtipi(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setIstektanimadi(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYapanrolid(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAnaodeyicikurumtipi(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIkinciodeyicikurumtipi(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHastacinsi(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndirimgrubutipi(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setVakaid(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setKabultarihi(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setProtokoltipi(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoktortckimlikno(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastatckimlikno(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setKangrubu(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setDogumtarihi(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnakurumadi(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setIkincikurumadi(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndirimgrubuadi(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaslangictarihi(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setBitistarihi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSecili();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getProtokolid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHastaid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIsteyenpersoneladisoyadi();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSorumludoktoradisoyadi();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSorumlubolumadi();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getKayitdurumu1();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTipi();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getHastaadisoyadi();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getKayitdurumu2();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getYapanpersoneltckimlikno();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getYapanpersoneladisoyadi();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getYapanbolumadi();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCevapformtipi();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getIstektanimadi();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getYapanrolid();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getAnaodeyicikurumtipi();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getIkinciodeyicikurumtipi();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getHastacinsi();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getIndirimgrubutipi();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getVakaid();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getKabultarihi();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getProtokoltipi();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getDoktortckimlikno();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getHastatckimlikno();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getKangrubu();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getDogumtarihi();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getAnakurumadi();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getIkincikurumadi();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getIndirimgrubuadi();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getBaslangictarihi();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getBitistarihi();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
};


/**
 * optional int32 Secili = 1;
 * @return {number}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getSecili = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setSecili = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 ID = 2;
 * @return {number}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 ProtokolID = 3;
 * @return {number}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getProtokolid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setProtokolid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 HastaID = 4;
 * @return {number}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getHastaid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setHastaid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string Tarih = 5;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string IsteyenPersonelAdiSoyadi = 6;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getIsteyenpersoneladisoyadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setIsteyenpersoneladisoyadi = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string SorumluDoktorAdiSoyadi = 7;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getSorumludoktoradisoyadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setSorumludoktoradisoyadi = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string SorumluBolumAdi = 8;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getSorumlubolumadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setSorumlubolumadi = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string KayitDurumu1 = 9;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getKayitdurumu1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setKayitdurumu1 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 Tipi = 10;
 * @return {number}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getTipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setTipi = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string HastaAdiSoyadi = 11;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getHastaadisoyadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setHastaadisoyadi = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 KayitDurumu2 = 12;
 * @return {number}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getKayitdurumu2 = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setKayitdurumu2 = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string YapanPersonelTcKimlikNo = 13;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getYapanpersoneltckimlikno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setYapanpersoneltckimlikno = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string YapanPersonelAdiSoyadi = 14;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getYapanpersoneladisoyadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setYapanpersoneladisoyadi = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string Adi = 15;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string YapanBolumAdi = 16;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getYapanbolumadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setYapanbolumadi = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional int32 CevapFormTipi = 17;
 * @return {number}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getCevapformtipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setCevapformtipi = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string IstekTanimAdi = 18;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getIstektanimadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setIstektanimadi = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional int32 YapanRolID = 19;
 * @return {number}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getYapanrolid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setYapanrolid = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 AnaOdeyiciKurumTipi = 20;
 * @return {number}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getAnaodeyicikurumtipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setAnaodeyicikurumtipi = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 IkinciOdeyiciKurumTipi = 21;
 * @return {number}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getIkinciodeyicikurumtipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setIkinciodeyicikurumtipi = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional int32 HastaCinsi = 22;
 * @return {number}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getHastacinsi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setHastacinsi = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional int32 IndirimGrubuTipi = 23;
 * @return {number}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getIndirimgrubutipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setIndirimgrubutipi = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 VakaId = 24;
 * @return {number}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getVakaid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setVakaid = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional string KabulTarihi = 25;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getKabultarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setKabultarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string ProtokolTipi = 26;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getProtokoltipi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setProtokoltipi = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string DoktorTcKimlikNo = 27;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getDoktortckimlikno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setDoktortckimlikno = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string HastaTcKimlikNo = 28;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getHastatckimlikno = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setHastatckimlikno = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string KanGrubu = 29;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getKangrubu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setKangrubu = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string DogumTarihi = 30;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getDogumtarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setDogumtarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string AnaKurumAdi = 31;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getAnakurumadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setAnakurumadi = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string IkinciKurumAdi = 32;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getIkincikurumadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setIkincikurumadi = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string IndirimGrubuAdi = 33;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getIndirimgrubuadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setIndirimgrubuadi = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string BaslangicTarihi = 34;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getBaslangictarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setBaslangictarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string BitisTarihi = 35;
 * @return {string}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.getBitistarihi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc.prototype.setBitistarihi = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * repeated IstekQOResponseDtoGrpc IstekQOResponse = 1;
 * @return {!Array<!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc>}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.prototype.getIstekqoresponseList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc>} value
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc} returns this
*/
proto.doktorpanelipr.IstekQOResponseGrpc.prototype.setIstekqoresponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc}
 */
proto.doktorpanelipr.IstekQOResponseGrpc.prototype.addIstekqoresponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.IstekQOResponseGrpc.IstekQOResponseDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.IstekQOResponseGrpc} returns this
 */
proto.doktorpanelipr.IstekQOResponseGrpc.prototype.clearIstekqoresponseList = function() {
  return this.setIstekqoresponseList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    datestart: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateend: jspb.Message.getFieldWithDefault(msg, 2, ""),
    personelid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    withpermission: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc;
  return proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatestart(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateend(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPersonelid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWithpermission(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatestart();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateend();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPersonelid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getWithpermission();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string dateStart = 1;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.prototype.getDatestart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.prototype.setDatestart = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dateEnd = 2;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.prototype.getDateend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.prototype.setDateend = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 personelId = 3;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.prototype.getPersonelid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.prototype.setPersonelid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 withPermission = 4;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.prototype.getWithpermission = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirRequestGrpc.prototype.setWithpermission = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    medikaltanimsablonlarilistList: jspb.Message.toObjectList(msg.getMedikaltanimsablonlarilistList(),
    proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc;
  return proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.deserializeBinaryFromReader);
      msg.addMedikaltanimsablonlarilist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMedikaltanimsablonlarilistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    tipi: jspb.Message.getFieldWithDefault(msg, 1, ""),
    baslik: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tipiimg: jspb.Message.getFieldWithDefault(msg, 3, ""),
    raporbaslik: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tedavi: jspb.Message.getFieldWithDefault(msg, 5, ""),
    bulgular: jspb.Message.getFieldWithDefault(msg, 6, ""),
    ozgecmisi: jspb.Message.getFieldWithDefault(msg, 7, ""),
    hikayesi: jspb.Message.getFieldWithDefault(msg, 8, ""),
    icerik: jspb.Message.getFieldWithDefault(msg, 9, ""),
    id: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc;
  return proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTipi(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaslik(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTipiimg(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRaporbaslik(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTedavi(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBulgular(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOzgecmisi(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setHikayesi(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcerik(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTipi();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBaslik();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTipiimg();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRaporbaslik();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTedavi();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBulgular();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOzgecmisi();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHikayesi();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIcerik();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional string tipi = 1;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.getTipi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.setTipi = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string baslik = 2;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.getBaslik = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.setBaslik = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tipiimg = 3;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.getTipiimg = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.setTipiimg = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string raporBaslik = 4;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.getRaporbaslik = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.setRaporbaslik = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string tedavi = 5;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.getTedavi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.setTedavi = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string bulgular = 6;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.getBulgular = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.setBulgular = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ozgecmisi = 7;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.getOzgecmisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.setOzgecmisi = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string hikayesi = 8;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.getHikayesi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.setHikayesi = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string icerik = 9;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.getIcerik = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.setIcerik = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 id = 10;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated MedikalTanimSablonlariGetirDtoGrpc MedikalTanimSablonlariList = 1;
 * @return {!Array<!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc>}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.prototype.getMedikaltanimsablonlarilistList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc>} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc} returns this
*/
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.prototype.setMedikaltanimsablonlarilistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.prototype.addMedikaltanimsablonlarilist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.MedikalTanimSablonlariGetirDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirResponseGrpc.prototype.clearMedikaltanimsablonlarilistList = function() {
  return this.setMedikaltanimsablonlarilistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    datestart: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateend: jspb.Message.getFieldWithDefault(msg, 2, ""),
    personelid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc;
  return proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatestart(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateend(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPersonelid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatestart();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateend();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPersonelid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string dateStart = 1;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc.prototype.getDatestart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc.prototype.setDatestart = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dateEnd = 2;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc.prototype.getDateend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc.prototype.setDateend = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 personelId = 3;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc.prototype.getPersonelid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirRequestGrpc.prototype.setPersonelid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    medikaltanimmuayenesorularilistList: jspb.Message.toObjectList(msg.getMedikaltanimmuayenesorularilistList(),
    proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc;
  return proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.deserializeBinaryFromReader);
      msg.addMedikaltanimmuayenesorularilist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMedikaltanimmuayenesorularilistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    tipi: jspb.Message.getFieldWithDefault(msg, 1, 0),
    baslik: jspb.Message.getFieldWithDefault(msg, 2, ""),
    zorunlumu: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tipiexp: jspb.Message.getFieldWithDefault(msg, 4, ""),
    aciklama: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc;
  return proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTipi(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaslik(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setZorunlumu(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTipiexp(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAciklama(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTipi();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBaslik();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getZorunlumu();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTipiexp();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAciklama();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 tipi = 1;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.prototype.getTipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.prototype.setTipi = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string baslik = 2;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.prototype.getBaslik = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.prototype.setBaslik = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string zorunlumu = 3;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.prototype.getZorunlumu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.prototype.setZorunlumu = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tipiExp = 4;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.prototype.getTipiexp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.prototype.setTipiexp = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string aciklama = 5;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.prototype.getAciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc.prototype.setAciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated MedikalTanimMuayeneSorulariGetirDtoGrpc MedikalTanimMuayeneSorulariList = 1;
 * @return {!Array<!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc>}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.prototype.getMedikaltanimmuayenesorularilistList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc>} value
 * @return {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc} returns this
*/
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.prototype.setMedikaltanimmuayenesorularilistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.prototype.addMedikaltanimmuayenesorularilist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.MedikalTanimMuayeneSorulariGetirDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimMuayeneSorulariGetirResponseGrpc.prototype.clearMedikaltanimmuayenesorularilistList = function() {
  return this.setMedikaltanimmuayenesorularilistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    datestart: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateend: jspb.Message.getFieldWithDefault(msg, 2, ""),
    personelid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc}
 */
proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc;
  return proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc}
 */
proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatestart(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateend(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPersonelid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatestart();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateend();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPersonelid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string dateStart = 1;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc.prototype.getDatestart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc.prototype.setDatestart = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dateEnd = 2;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc.prototype.getDateend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc.prototype.setDateend = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 personelId = 3;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc.prototype.getPersonelid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimICDGetirRequestGrpc.prototype.setPersonelid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    medikaltanimicdlistList: jspb.Message.toObjectList(msg.getMedikaltanimicdlistList(),
    proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc}
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc;
  return proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc}
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc.deserializeBinaryFromReader);
      msg.addMedikaltanimicdlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMedikaltanimicdlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    kodu: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adi: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc;
  return proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKodu(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKodu();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string kodu = 1;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc.prototype.getKodu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc.prototype.setKodu = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string adi = 2;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MedikalTanimICDGetirDtoGrpc MedikalTanimICDList = 1;
 * @return {!Array<!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc>}
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.prototype.getMedikaltanimicdlistList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc>} value
 * @return {!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc} returns this
*/
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.prototype.setMedikaltanimicdlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.prototype.addMedikaltanimicdlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.MedikalTanimICDGetirDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimICDGetirResponseGrpc.prototype.clearMedikaltanimicdlistList = function() {
  return this.setMedikaltanimicdlistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    datestart: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateend: jspb.Message.getFieldWithDefault(msg, 2, ""),
    personelid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc;
  return proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatestart(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateend(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPersonelid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatestart();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateend();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPersonelid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string dateStart = 1;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc.prototype.getDatestart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc.prototype.setDatestart = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dateEnd = 2;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc.prototype.getDateend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc.prototype.setDateend = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 personelId = 3;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc.prototype.getPersonelid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirRequestGrpc.prototype.setPersonelid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    medikaltanimradyolojikisteklistList: jspb.Message.toObjectList(msg.getMedikaltanimradyolojikisteklistList(),
    proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc;
  return proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.deserializeBinaryFromReader);
      msg.addMedikaltanimradyolojikisteklist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMedikaltanimradyolojikisteklistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    tetkikgrubu: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tetkiktanimid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    id: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sutkodu: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc;
  return proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTetkikgrubu(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTetkiktanimid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSutkodu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTetkikgrubu();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTetkiktanimid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSutkodu();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string tetkikgrubu = 1;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.prototype.getTetkikgrubu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.prototype.setTetkikgrubu = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string adi = 2;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 tetkiktanimid = 3;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.prototype.getTetkiktanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.prototype.setTetkiktanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 id = 4;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string sutkodu = 5;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.prototype.getSutkodu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc.prototype.setSutkodu = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated MedikalTanimRadyolojikIsteklerGetirDtoGrpc MedikalTanimRadyolojikIstekList = 1;
 * @return {!Array<!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc>}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.prototype.getMedikaltanimradyolojikisteklistList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc>} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc} returns this
*/
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.prototype.setMedikaltanimradyolojikisteklistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.prototype.addMedikaltanimradyolojikisteklist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.MedikalTanimRadyolojikIsteklerGetirDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojikIsteklerGetirResponseGrpc.prototype.clearMedikaltanimradyolojikisteklistList = function() {
  return this.setMedikaltanimradyolojikisteklistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    datestart: jspb.Message.getFieldWithDefault(msg, 1, ""),
    dateend: jspb.Message.getFieldWithDefault(msg, 2, ""),
    personelid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc;
  return proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatestart(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateend(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPersonelid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDatestart();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDateend();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPersonelid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string dateStart = 1;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc.prototype.getDatestart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc.prototype.setDatestart = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string dateEnd = 2;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc.prototype.getDateend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc.prototype.setDateend = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 personelId = 3;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc.prototype.getPersonelid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirRequestGrpc.prototype.setPersonelid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    medikaltanimlaboratuaristeklistList: jspb.Message.toObjectList(msg.getMedikaltanimlaboratuaristeklistList(),
    proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc;
  return proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.deserializeBinaryFromReader);
      msg.addMedikaltanimlaboratuaristeklist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMedikaltanimlaboratuaristeklistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    testgrubu: jspb.Message.getFieldWithDefault(msg, 1, ""),
    adi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sirano: jspb.Message.getFieldWithDefault(msg, 3, ""),
    testtanimid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    id: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc;
  return proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestgrubu(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSirano(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTesttanimid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTestgrubu();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSirano();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTesttanimid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string testgrubu = 1;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.prototype.getTestgrubu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.prototype.setTestgrubu = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string adi = 2;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string sirano = 3;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.prototype.getSirano = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.prototype.setSirano = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 testtanimid = 4;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.prototype.getTesttanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.prototype.setTesttanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 id = 5;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated MedikalTanimLaboratuarIsteklerGetirDtoGrpc MedikalTanimLaboratuarIstekList = 1;
 * @return {!Array<!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc>}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.prototype.getMedikaltanimlaboratuaristeklistList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc>} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc} returns this
*/
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.prototype.setMedikaltanimlaboratuaristeklistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.prototype.addMedikaltanimlaboratuaristeklist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.MedikalTanimLaboratuarIsteklerGetirDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerGetirResponseGrpc.prototype.clearMedikaltanimlaboratuaristeklistList = function() {
  return this.setMedikaltanimlaboratuaristeklistList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    medikaltanimlaboratuaristeklercruddtolistList: jspb.Message.toObjectList(msg.getMedikaltanimlaboratuaristeklercruddtolistList(),
    proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc.toObject, includeInstance),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    crud: jspb.Message.getFieldWithDefault(msg, 3, ""),
    kurumid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    kayitdurumu: jspb.Message.getFieldWithDefault(msg, 5, 0),
    eklemezamani: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc;
  return proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc.deserializeBinaryFromReader);
      msg.addMedikaltanimlaboratuaristeklercruddtolist(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrud(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKayitdurumu(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEklemezamani(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMedikaltanimlaboratuaristeklercruddtolistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCrud();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getKayitdurumu();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getEklemezamani();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    testtanimid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc;
  return proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTesttanimid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTesttanimid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 testtanimid = 1;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc.prototype.getTesttanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc.prototype.setTesttanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated MedikalTanimLaboratuarIsteklerCrudDtoGrpc MedikalTanimLaboratuarIsteklerCrudDtoList = 1;
 * @return {!Array<!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc>}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.prototype.getMedikaltanimlaboratuaristeklercruddtolistList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc>} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc} returns this
*/
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.prototype.setMedikaltanimlaboratuaristeklercruddtolistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.prototype.addMedikaltanimlaboratuaristeklercruddtolist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.prototype.clearMedikaltanimlaboratuaristeklercruddtolistList = function() {
  return this.setMedikaltanimlaboratuaristeklercruddtolistList([]);
};


/**
 * optional int32 userid = 2;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string crud = 3;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.prototype.getCrud = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.prototype.setCrud = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 kurumid = 4;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 kayitdurumu = 5;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.prototype.getKayitdurumu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.prototype.setKayitdurumu = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string eklemezamani = 6;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.prototype.getEklemezamani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudRequestGrpc.prototype.setEklemezamani = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    returnmessage: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc;
  return proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReturnmessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReturnmessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string returnmessage = 1;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc.prototype.getReturnmessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimLaboratuarIsteklerCrudResponseGrpc.prototype.setReturnmessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    medikaltanimradyolojiisteklercruddtolistList: jspb.Message.toObjectList(msg.getMedikaltanimradyolojiisteklercruddtolistList(),
    proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc.toObject, includeInstance),
    userid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    crud: jspb.Message.getFieldWithDefault(msg, 3, ""),
    kurumid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    kayitdurumu: jspb.Message.getFieldWithDefault(msg, 5, 0),
    eklemezamani: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc;
  return proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc;
      reader.readMessage(value,proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc.deserializeBinaryFromReader);
      msg.addMedikaltanimradyolojiisteklercruddtolist(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrud(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKayitdurumu(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEklemezamani(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMedikaltanimradyolojiisteklercruddtolistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc.serializeBinaryToWriter
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCrud();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getKayitdurumu();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getEklemezamani();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    tetkiktanimid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    id: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc;
  return proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTetkiktanimid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTetkiktanimid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 tetkiktanimid = 1;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc.prototype.getTetkiktanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc.prototype.setTetkiktanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 id = 2;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated MedikalTanimRadyolojiIsteklerCrudDtoGrpc MedikalTanimRadyolojiIsteklerCrudDtoList = 1;
 * @return {!Array<!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc>}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.prototype.getMedikaltanimradyolojiisteklercruddtolistList = function() {
  return /** @type{!Array<!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc, 1));
};


/**
 * @param {!Array<!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc>} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc} returns this
*/
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.prototype.setMedikaltanimradyolojiisteklercruddtolistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc=} opt_value
 * @param {number=} opt_index
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.prototype.addMedikaltanimradyolojiisteklercruddtolist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.prototype.clearMedikaltanimradyolojiisteklercruddtolistList = function() {
  return this.setMedikaltanimradyolojiisteklercruddtolistList([]);
};


/**
 * optional int32 userid = 2;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string crud = 3;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.prototype.getCrud = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.prototype.setCrud = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 kurumid = 4;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 kayitdurumu = 5;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.prototype.getKayitdurumu = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.prototype.setKayitdurumu = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string eklemezamani = 6;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.prototype.getEklemezamani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudRequestGrpc.prototype.setEklemezamani = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    returnmessage: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc;
  return proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReturnmessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReturnmessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string returnmessage = 1;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc.prototype.getReturnmessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimRadyolojiIsteklerCrudResponseGrpc.prototype.setReturnmessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    titlevalue: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reporttitlevalue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    typevalue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    htmleditorval: jspb.Message.getFieldWithDefault(msg, 4, ""),
    userid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    crud: jspb.Message.getFieldWithDefault(msg, 6, ""),
    kurumid: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc;
  return proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitlevalue(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReporttitlevalue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTypevalue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHtmleditorval(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCrud(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitlevalue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReporttitlevalue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTypevalue();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getHtmleditorval();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getUserid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCrud();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional string titlevalue = 1;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.prototype.getTitlevalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.prototype.setTitlevalue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reporttitlevalue = 2;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.prototype.getReporttitlevalue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.prototype.setReporttitlevalue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 typevalue = 3;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.prototype.getTypevalue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.prototype.setTypevalue = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string htmleditorval = 4;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.prototype.getHtmleditorval = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.prototype.setHtmleditorval = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 userid = 5;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.prototype.getUserid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.prototype.setUserid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string crud = 6;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.prototype.getCrud = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.prototype.setCrud = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 kurumid = 7;
 * @return {number}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudRequestGrpc.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc.prototype.toObject = function(opt_includeInstance) {
  return proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc.toObject = function(includeInstance, msg) {
  var f, obj = {
    returnmessage: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc;
  return proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReturnmessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReturnmessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string returnmessage = 1;
 * @return {string}
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc.prototype.getReturnmessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc} returns this
 */
proto.doktorpanelipr.MedikalTanimSablonlariGetirCrudResponseGrpc.prototype.setReturnmessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto.doktorpanelipr);
