import { SurecTanimlariClient } from "../../../../protos/is-zekasi/rapor-tanimlari/surec-tanimlari/surec_tanimlari_grpc_web_pb";
import {
  GetSurecAgaciRequest,
  SurecTanimEkleRequest,
  SurecTanimGetirRequest,
  SurecTanimGuncelleRequest
} from "../../../../protos/is-zekasi/rapor-tanimlari/surec-tanimlari/surec_tanimlari_pb";

class SurecTanimService {

  getSurecAgaci = (personelID) => {
    return new Promise((resolve, reject) => {

      const client = new SurecTanimlariClient(process.env.REACT_APP_API_BASE_URL);
      const getSurecAgaciRequest = new GetSurecAgaciRequest();
      
      getSurecAgaciRequest.setPersonelid(personelID);
      
      var token = localStorage.getItem('accessToken');
      client.getSurecAgaci(getSurecAgaciRequest,
        { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
          if (response) {
            //console.log('Response->x1->', response);
            resolve(response.toObject());
          } else {
            //console.log('error->x1->', error);
            reject(error);
          }
        });

    });
  }

  setSurecTanimEkle = (KurumID, Adi, Tanimi, KayitDurumu, UstTanimId) => {
    return new Promise((resolve, reject) => {

      const client = new SurecTanimlariClient(process.env.REACT_APP_API_BASE_URL);
      const setSurecTanimEkleRequest = new SurecTanimEkleRequest();
      
      //KayitDurumu enum a göre
      const kayitDurumu = (KayitDurumu == 0) ? 2 : KayitDurumu;

      setSurecTanimEkleRequest.setKurumid(KurumID);
      setSurecTanimEkleRequest.setAdi(Adi);
      setSurecTanimEkleRequest.setTanimi(Tanimi);
      setSurecTanimEkleRequest.setKayitdurumu(kayitDurumu);
      setSurecTanimEkleRequest.setUsttanimid(UstTanimId);
      
      
      var token = localStorage.getItem('accessToken');
      client.surecTanimEkle(setSurecTanimEkleRequest,
        { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
          if (response) {
            //console.log('Response->x1->', response);
            resolve(response.toObject());
          } else {
            //console.log('error->x1->', error);
            reject(error);
          }
        });

    });
  }

  setSurecTanimGuncelle = (Id, Adi, Tanimi, KayitDurumu, UstTanimId) => {
    return new Promise((resolve, reject) => {

      const client = new SurecTanimlariClient(process.env.REACT_APP_API_BASE_URL);
      const setSurecTanimGuncelleRequest = new SurecTanimGuncelleRequest();
      
      //KayitDurumu enum a göre
      const kayitDurumu = (KayitDurumu == 0) ? 2 : KayitDurumu;

      setSurecTanimGuncelleRequest.setId(Id);
      //setSurecTanimGuncelleRequest.setKurumid(KurumID);
      setSurecTanimGuncelleRequest.setAdi(Adi);
      setSurecTanimGuncelleRequest.setTanimi(Tanimi);
      setSurecTanimGuncelleRequest.setKayitdurumu(kayitDurumu);
      setSurecTanimGuncelleRequest.setUsttanimid(UstTanimId);
      
      
      var token = localStorage.getItem('accessToken');
      client.surecTanimGuncelle(setSurecTanimGuncelleRequest,
        { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
          if (response) {
            //console.log('Response->x1->', response);
            resolve(response.toObject());
          } else {
            //console.log('error->x1->', error);
            reject(error);
          }
        });

    });
  }

  getSurecTanimGetir = (ID) => {
    return new Promise((resolve, reject) => {
    
      const client = new SurecTanimlariClient(process.env.REACT_APP_API_BASE_URL);
      const getSurecTanimGetirRequest = new SurecTanimGetirRequest();
    
      getSurecTanimGetirRequest.setId(ID);
      
      var token = localStorage.getItem('accessToken');
      client.getSurecTanimGetir(getSurecTanimGetirRequest,
        { "lsiid": localStorage.getItem('lsiid'), "Authorization": token }, (error, response) => {
          if (response) {
            //console.log('Response->x1->', response);
            resolve(response.toObject());
          } else {
            //console.log('error->x1->', error);
            reject(error);
          }
        });

    });
  }
  
}

const surecTanimService = new SurecTanimService();

export default surecTanimService;
