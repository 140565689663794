/* eslint-disable */
//@ts-nocheck

// source: rapor_tanimlari.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.RaporTanimlari.GetHazirRaporTanimByIdRequest', null, global);
goog.exportSymbol('proto.RaporTanimlari.GetHazirRaporTanimByIdResponse', null, global);
goog.exportSymbol('proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest', null, global);
goog.exportSymbol('proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse', null, global);
goog.exportSymbol('proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO', null, global);
goog.exportSymbol('proto.RaporTanimlari.HazirRaporTanimEkleRequest', null, global);
goog.exportSymbol('proto.RaporTanimlari.HazirRaporTanimEkleResponse', null, global);
goog.exportSymbol('proto.RaporTanimlari.HazirRaporTanimGuncelleRequest', null, global);
goog.exportSymbol('proto.RaporTanimlari.HazirRaporTanimGuncelleResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest.displayName = 'proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.repeatedFields_, null);
};
goog.inherits(proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.displayName = 'proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.repeatedFields_, null);
};
goog.inherits(proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.displayName = 'proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaporTanimlari.GetHazirRaporTanimByIdRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporTanimlari.GetHazirRaporTanimByIdRequest.displayName = 'proto.RaporTanimlari.GetHazirRaporTanimByIdRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaporTanimlari.GetHazirRaporTanimByIdResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.displayName = 'proto.RaporTanimlari.GetHazirRaporTanimByIdResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaporTanimlari.HazirRaporTanimEkleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporTanimlari.HazirRaporTanimEkleRequest.displayName = 'proto.RaporTanimlari.HazirRaporTanimEkleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporTanimlari.HazirRaporTanimEkleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaporTanimlari.HazirRaporTanimEkleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporTanimlari.HazirRaporTanimEkleResponse.displayName = 'proto.RaporTanimlari.HazirRaporTanimEkleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaporTanimlari.HazirRaporTanimGuncelleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.displayName = 'proto.RaporTanimlari.HazirRaporTanimGuncelleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RaporTanimlari.HazirRaporTanimGuncelleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RaporTanimlari.HazirRaporTanimGuncelleResponse.displayName = 'proto.RaporTanimlari.HazirRaporTanimGuncelleResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    surectanimid: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest;
  return proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSurectanimid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSurectanimid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 SurecTanimID = 1;
 * @return {number}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest.prototype.getSurectanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest} returns this
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDRequest.prototype.setSurectanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hazirraportanimlistbysurectanimidList: jspb.Message.toObjectList(msg.getHazirraportanimlistbysurectanimidList(),
    proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse;
  return proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO;
      reader.readMessage(value,proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.deserializeBinaryFromReader);
      msg.addHazirraportanimlistbysurectanimid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHazirraportanimlistbysurectanimidList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    adi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 3, ""),
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO;
  return proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    case 4:
      var value = new proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO;
      reader.readMessage(value,proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO} returns this
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Adi = 2;
 * @return {string}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO} returns this
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string icon = 3;
 * @return {string}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO} returns this
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated GetHazirRaporTanimListBySurecTanimIDDTO items = 4;
 * @return {!Array<!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO>}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO, 4));
};


/**
 * @param {!Array<!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO>} value
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO} returns this
*/
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO} returns this
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * repeated GetHazirRaporTanimListBySurecTanimIDDTO hazirRaporTanimListBySurecTanimID = 1;
 * @return {!Array<!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO>}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.prototype.getHazirraportanimlistbysurectanimidList = function() {
  return /** @type{!Array<!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO, 1));
};


/**
 * @param {!Array<!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO>} value
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse} returns this
*/
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.prototype.setHazirraportanimlistbysurectanimidList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO}
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.prototype.addHazirraportanimlistbysurectanimid = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.GetHazirRaporTanimListBySurecTanimIDDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse} returns this
 */
proto.RaporTanimlari.GetHazirRaporTanimListBySurecTanimIDResponse.prototype.clearHazirraportanimlistbysurectanimidList = function() {
  return this.setHazirraportanimlistbysurectanimidList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporTanimlari.GetHazirRaporTanimByIdRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimByIdRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimByIdRequest}
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporTanimlari.GetHazirRaporTanimByIdRequest;
  return proto.RaporTanimlari.GetHazirRaporTanimByIdRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimByIdRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimByIdRequest}
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporTanimlari.GetHazirRaporTanimByIdRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimByIdRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimByIdRequest} returns this
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimByIdResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    tipi: jspb.Message.getFieldWithDefault(msg, 1, 0),
    webraporlink: jspb.Message.getFieldWithDefault(msg, 2, ""),
    adi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tanimi: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sonokumutipi: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sonokuma: jspb.Message.getFieldWithDefault(msg, 6, 0),
    aktifmi: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    ozelraportanimid: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimByIdResponse}
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporTanimlari.GetHazirRaporTanimByIdResponse;
  return proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimByIdResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimByIdResponse}
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTipi(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setWebraporlink(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTanimi(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSonokumutipi(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSonokuma(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAktifmi(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOzelraportanimid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporTanimlari.GetHazirRaporTanimByIdResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTipi();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getWebraporlink();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTanimi();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSonokumutipi();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSonokuma();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getAktifmi();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getOzelraportanimid();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional int32 tipi = 1;
 * @return {number}
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.getTipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimByIdResponse} returns this
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.setTipi = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string webRaporLink = 2;
 * @return {string}
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.getWebraporlink = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimByIdResponse} returns this
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.setWebraporlink = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string adi = 3;
 * @return {string}
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimByIdResponse} returns this
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tanimi = 4;
 * @return {string}
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.getTanimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimByIdResponse} returns this
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.setTanimi = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 sonOkumuTipi = 5;
 * @return {number}
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.getSonokumutipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimByIdResponse} returns this
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.setSonokumutipi = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 sonOkuma = 6;
 * @return {number}
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.getSonokuma = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimByIdResponse} returns this
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.setSonokuma = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool aktifMi = 7;
 * @return {boolean}
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.getAktifmi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimByIdResponse} returns this
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.setAktifmi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 ozelRaporTanimId = 8;
 * @return {number}
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.getOzelraportanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporTanimlari.GetHazirRaporTanimByIdResponse} returns this
 */
proto.RaporTanimlari.GetHazirRaporTanimByIdResponse.prototype.setOzelraportanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporTanimlari.HazirRaporTanimEkleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporTanimlari.HazirRaporTanimEkleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tipi: jspb.Message.getFieldWithDefault(msg, 1, 0),
    adi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tanimi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sonokumatipi: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sonokuma: jspb.Message.getFieldWithDefault(msg, 5, 0),
    aktifmi: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporTanimlari.HazirRaporTanimEkleRequest}
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporTanimlari.HazirRaporTanimEkleRequest;
  return proto.RaporTanimlari.HazirRaporTanimEkleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporTanimlari.HazirRaporTanimEkleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporTanimlari.HazirRaporTanimEkleRequest}
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTipi(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTanimi(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSonokumatipi(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSonokuma(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAktifmi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporTanimlari.HazirRaporTanimEkleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporTanimlari.HazirRaporTanimEkleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTipi();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTanimi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSonokumatipi();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSonokuma();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAktifmi();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int32 tipi = 1;
 * @return {number}
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.prototype.getTipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporTanimlari.HazirRaporTanimEkleRequest} returns this
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.prototype.setTipi = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string adi = 2;
 * @return {string}
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporTanimlari.HazirRaporTanimEkleRequest} returns this
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string tanimi = 3;
 * @return {string}
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.prototype.getTanimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporTanimlari.HazirRaporTanimEkleRequest} returns this
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.prototype.setTanimi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 sonOkumaTipi = 4;
 * @return {number}
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.prototype.getSonokumatipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporTanimlari.HazirRaporTanimEkleRequest} returns this
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.prototype.setSonokumatipi = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 sonOkuma = 5;
 * @return {number}
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.prototype.getSonokuma = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporTanimlari.HazirRaporTanimEkleRequest} returns this
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.prototype.setSonokuma = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool aktifMi = 6;
 * @return {boolean}
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.prototype.getAktifmi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.RaporTanimlari.HazirRaporTanimEkleRequest} returns this
 */
proto.RaporTanimlari.HazirRaporTanimEkleRequest.prototype.setAktifmi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporTanimlari.HazirRaporTanimEkleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporTanimlari.HazirRaporTanimEkleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporTanimlari.HazirRaporTanimEkleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.HazirRaporTanimEkleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporTanimlari.HazirRaporTanimEkleResponse}
 */
proto.RaporTanimlari.HazirRaporTanimEkleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporTanimlari.HazirRaporTanimEkleResponse;
  return proto.RaporTanimlari.HazirRaporTanimEkleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporTanimlari.HazirRaporTanimEkleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporTanimlari.HazirRaporTanimEkleResponse}
 */
proto.RaporTanimlari.HazirRaporTanimEkleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporTanimlari.HazirRaporTanimEkleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporTanimlari.HazirRaporTanimEkleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporTanimlari.HazirRaporTanimEkleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.HazirRaporTanimEkleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 status = 1;
 * @return {number}
 */
proto.RaporTanimlari.HazirRaporTanimEkleResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporTanimlari.HazirRaporTanimEkleResponse} returns this
 */
proto.RaporTanimlari.HazirRaporTanimEkleResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporTanimlari.HazirRaporTanimGuncelleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tipi: jspb.Message.getFieldWithDefault(msg, 2, 0),
    adi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tanimi: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sonokumatipi: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sonokuma: jspb.Message.getFieldWithDefault(msg, 6, 0),
    aktifmi: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporTanimlari.HazirRaporTanimGuncelleRequest}
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporTanimlari.HazirRaporTanimGuncelleRequest;
  return proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporTanimlari.HazirRaporTanimGuncelleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporTanimlari.HazirRaporTanimGuncelleRequest}
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTipi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdi(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTanimi(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSonokumatipi(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSonokuma(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAktifmi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporTanimlari.HazirRaporTanimGuncelleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTipi();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getAdi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTanimi();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSonokumatipi();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSonokuma();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getAktifmi();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporTanimlari.HazirRaporTanimGuncelleRequest} returns this
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 tipi = 2;
 * @return {number}
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.prototype.getTipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporTanimlari.HazirRaporTanimGuncelleRequest} returns this
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.prototype.setTipi = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string adi = 3;
 * @return {string}
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.prototype.getAdi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporTanimlari.HazirRaporTanimGuncelleRequest} returns this
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.prototype.setAdi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string tanimi = 4;
 * @return {string}
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.prototype.getTanimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.RaporTanimlari.HazirRaporTanimGuncelleRequest} returns this
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.prototype.setTanimi = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 sonOkumaTipi = 5;
 * @return {number}
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.prototype.getSonokumatipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporTanimlari.HazirRaporTanimGuncelleRequest} returns this
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.prototype.setSonokumatipi = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 sonOkuma = 6;
 * @return {number}
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.prototype.getSonokuma = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporTanimlari.HazirRaporTanimGuncelleRequest} returns this
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.prototype.setSonokuma = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool aktifMi = 7;
 * @return {boolean}
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.prototype.getAktifmi = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.RaporTanimlari.HazirRaporTanimGuncelleRequest} returns this
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleRequest.prototype.setAktifmi = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.RaporTanimlari.HazirRaporTanimGuncelleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RaporTanimlari.HazirRaporTanimGuncelleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RaporTanimlari.HazirRaporTanimGuncelleResponse}
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RaporTanimlari.HazirRaporTanimGuncelleResponse;
  return proto.RaporTanimlari.HazirRaporTanimGuncelleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RaporTanimlari.HazirRaporTanimGuncelleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RaporTanimlari.HazirRaporTanimGuncelleResponse}
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RaporTanimlari.HazirRaporTanimGuncelleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RaporTanimlari.HazirRaporTanimGuncelleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 status = 1;
 * @return {number}
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleResponse.prototype.getStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.RaporTanimlari.HazirRaporTanimGuncelleResponse} returns this
 */
proto.RaporTanimlari.HazirRaporTanimGuncelleResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


goog.object.extend(exports, proto.RaporTanimlari);
