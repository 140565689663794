import { createSlice } from '@reduxjs/toolkit';
import permissionService from 'src/services/permissionService';

const initialState = {
    menuPermissions: [],
};

const slice = createSlice({
    name: 'permission',
    initialState,
    reducers: {
        getMenuPermissions(state, action) {
            const menuPermissions = action.payload;
            state.menuPermissions = menuPermissions;
        }
    }
});

export const reducer = slice.reducer;

export const getMenuPermissions = (userId) => async (dispatch) => {
    const response = await permissionService.menuYetkileriniGetir(userId);
    dispatch(slice.actions.getMenuPermissions(response.formtipiList));
};

export default slice;
