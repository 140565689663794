// source: ozel_rapor.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.OzelRapor.GetDinamikRaporRequest', null, global);
goog.exportSymbol('proto.OzelRapor.GetDinamikRaporResponse', null, global);
goog.exportSymbol('proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OzelRapor.GetDinamikRaporRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.OzelRapor.GetDinamikRaporRequest.repeatedFields_, null);
};
goog.inherits(proto.OzelRapor.GetDinamikRaporRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OzelRapor.GetDinamikRaporRequest.displayName = 'proto.OzelRapor.GetDinamikRaporRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OzelRapor.GetDinamikRaporResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.OzelRapor.GetDinamikRaporResponse.repeatedFields_, null);
};
goog.inherits(proto.OzelRapor.GetDinamikRaporResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OzelRapor.GetDinamikRaporResponse.displayName = 'proto.OzelRapor.GetDinamikRaporResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.displayName = 'proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.OzelRapor.GetDinamikRaporRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OzelRapor.GetDinamikRaporRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.OzelRapor.GetDinamikRaporRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OzelRapor.GetDinamikRaporRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzelRapor.GetDinamikRaporRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    myhospitalid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hospitalidsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    datestart: jspb.Message.getFieldWithDefault(msg, 3, ""),
    dateend: jspb.Message.getFieldWithDefault(msg, 4, ""),
    wpc: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OzelRapor.GetDinamikRaporRequest}
 */
proto.OzelRapor.GetDinamikRaporRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OzelRapor.GetDinamikRaporRequest;
  return proto.OzelRapor.GetDinamikRaporRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OzelRapor.GetDinamikRaporRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OzelRapor.GetDinamikRaporRequest}
 */
proto.OzelRapor.GetDinamikRaporRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMyhospitalid(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addHospitalids(values[i]);
      }
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDatestart(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateend(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setWpc(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OzelRapor.GetDinamikRaporRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OzelRapor.GetDinamikRaporRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OzelRapor.GetDinamikRaporRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzelRapor.GetDinamikRaporRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMyhospitalid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHospitalidsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
  f = message.getDatestart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDateend();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getWpc();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 MyHospitalId = 1;
 * @return {number}
 */
proto.OzelRapor.GetDinamikRaporRequest.prototype.getMyhospitalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.OzelRapor.GetDinamikRaporRequest} returns this
 */
proto.OzelRapor.GetDinamikRaporRequest.prototype.setMyhospitalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int32 HospitalIds = 2;
 * @return {!Array<number>}
 */
proto.OzelRapor.GetDinamikRaporRequest.prototype.getHospitalidsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.OzelRapor.GetDinamikRaporRequest} returns this
 */
proto.OzelRapor.GetDinamikRaporRequest.prototype.setHospitalidsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.OzelRapor.GetDinamikRaporRequest} returns this
 */
proto.OzelRapor.GetDinamikRaporRequest.prototype.addHospitalids = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.OzelRapor.GetDinamikRaporRequest} returns this
 */
proto.OzelRapor.GetDinamikRaporRequest.prototype.clearHospitalidsList = function() {
  return this.setHospitalidsList([]);
};


/**
 * optional string DateStart = 3;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporRequest.prototype.getDatestart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporRequest} returns this
 */
proto.OzelRapor.GetDinamikRaporRequest.prototype.setDatestart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string DateEnd = 4;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporRequest.prototype.getDateend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporRequest} returns this
 */
proto.OzelRapor.GetDinamikRaporRequest.prototype.setDateend = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string wpc = 5;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporRequest.prototype.getWpc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporRequest} returns this
 */
proto.OzelRapor.GetDinamikRaporRequest.prototype.setWpc = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.OzelRapor.GetDinamikRaporResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OzelRapor.GetDinamikRaporResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.OzelRapor.GetDinamikRaporResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OzelRapor.GetDinamikRaporResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzelRapor.GetDinamikRaporResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dinamikraporlistList: jspb.Message.toObjectList(msg.getDinamikraporlistList(),
    proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OzelRapor.GetDinamikRaporResponse}
 */
proto.OzelRapor.GetDinamikRaporResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OzelRapor.GetDinamikRaporResponse;
  return proto.OzelRapor.GetDinamikRaporResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OzelRapor.GetDinamikRaporResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OzelRapor.GetDinamikRaporResponse}
 */
proto.OzelRapor.GetDinamikRaporResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto;
      reader.readMessage(value,proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.deserializeBinaryFromReader);
      msg.addDinamikraporlist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OzelRapor.GetDinamikRaporResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OzelRapor.GetDinamikRaporResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OzelRapor.GetDinamikRaporResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzelRapor.GetDinamikRaporResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDinamikraporlistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.toObject = function(opt_includeInstance) {
  return proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    field1: jspb.Message.getFieldWithDefault(msg, 1, ""),
    field2: jspb.Message.getFieldWithDefault(msg, 2, ""),
    field3: jspb.Message.getFieldWithDefault(msg, 3, ""),
    field4: jspb.Message.getFieldWithDefault(msg, 4, ""),
    field5: jspb.Message.getFieldWithDefault(msg, 5, ""),
    field6: jspb.Message.getFieldWithDefault(msg, 6, ""),
    field7: jspb.Message.getFieldWithDefault(msg, 7, ""),
    field8: jspb.Message.getFieldWithDefault(msg, 8, ""),
    field9: jspb.Message.getFieldWithDefault(msg, 9, ""),
    field10: jspb.Message.getFieldWithDefault(msg, 10, ""),
    field11: jspb.Message.getFieldWithDefault(msg, 11, ""),
    field12: jspb.Message.getFieldWithDefault(msg, 12, ""),
    field13: jspb.Message.getFieldWithDefault(msg, 13, ""),
    field14: jspb.Message.getFieldWithDefault(msg, 14, ""),
    field15: jspb.Message.getFieldWithDefault(msg, 15, ""),
    field16: jspb.Message.getFieldWithDefault(msg, 16, ""),
    field17: jspb.Message.getFieldWithDefault(msg, 17, ""),
    field18: jspb.Message.getFieldWithDefault(msg, 18, ""),
    field19: jspb.Message.getFieldWithDefault(msg, 19, ""),
    field20: jspb.Message.getFieldWithDefault(msg, 20, ""),
    fieldmap: jspb.Message.getFieldWithDefault(msg, 21, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto;
  return proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setField1(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setField2(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setField3(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setField4(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setField5(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setField6(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setField7(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setField8(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setField9(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setField10(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setField11(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setField12(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setField13(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setField14(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setField15(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setField16(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setField17(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setField18(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setField19(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setField20(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setFieldmap(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getField1();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getField2();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getField3();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getField4();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getField5();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getField6();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getField7();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getField8();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getField9();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getField10();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getField11();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getField12();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getField13();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getField14();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getField15();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getField16();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getField17();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getField18();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getField19();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getField20();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getFieldmap();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
};


/**
 * optional string field1 = 1;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField1 = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string field2 = 2;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField2 = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string field3 = 3;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField3 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField3 = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string field4 = 4;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField4 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField4 = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string field5 = 5;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField5 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField5 = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string field6 = 6;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField6 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField6 = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string field7 = 7;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField7 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField7 = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string field8 = 8;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField8 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField8 = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string field9 = 9;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField9 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField9 = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string field10 = 10;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField10 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField10 = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string field11 = 11;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField11 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField11 = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string field12 = 12;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField12 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField12 = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string field13 = 13;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField13 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField13 = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string field14 = 14;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField14 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField14 = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string field15 = 15;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField15 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField15 = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string field16 = 16;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField16 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField16 = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string field17 = 17;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField17 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField17 = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string field18 = 18;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField18 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField18 = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string field19 = 19;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField19 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField19 = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string field20 = 20;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getField20 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setField20 = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string fieldMap = 21;
 * @return {string}
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.getFieldmap = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto.prototype.setFieldmap = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * repeated DinamikRaporDto DinamikRaporList = 1;
 * @return {!Array<!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto>}
 */
proto.OzelRapor.GetDinamikRaporResponse.prototype.getDinamikraporlistList = function() {
  return /** @type{!Array<!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto, 1));
};


/**
 * @param {!Array<!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto>} value
 * @return {!proto.OzelRapor.GetDinamikRaporResponse} returns this
*/
proto.OzelRapor.GetDinamikRaporResponse.prototype.setDinamikraporlistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto}
 */
proto.OzelRapor.GetDinamikRaporResponse.prototype.addDinamikraporlist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.OzelRapor.GetDinamikRaporResponse.DinamikRaporDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.OzelRapor.GetDinamikRaporResponse} returns this
 */
proto.OzelRapor.GetDinamikRaporResponse.prototype.clearDinamikraporlistList = function() {
  return this.setDinamikraporlistList([]);
};


goog.object.extend(exports, proto.OzelRapor);
