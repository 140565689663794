/* eslint-disable */
//@ts-nocheck

// source: gunluk_cira_raporu.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetHospitalListResponse', null, global);
goog.exportSymbol('proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetHospitalListResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GunlukCiraRaporu.GetHospitalListResponse.repeatedFields_, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetHospitalListResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetHospitalListResponse.displayName = 'proto.GunlukCiraRaporu.GetHospitalListResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto.displayName = 'proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.repeatedFields_, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.repeatedFields_, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.repeatedFields_, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.repeatedFields_, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.repeatedFields_, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.repeatedFields_, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.repeatedFields_, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto.displayName = 'proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetHospitalListResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetHospitalListResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    hospitallistList: jspb.Message.toObjectList(msg.getHospitallistList(),
    proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetHospitalListResponse}
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetHospitalListResponse;
  return proto.GunlukCiraRaporu.GetHospitalListResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetHospitalListResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetHospitalListResponse}
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto;
      reader.readMessage(value,proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto.deserializeBinaryFromReader);
      msg.addHospitallist(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetHospitalListResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetHospitalListResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHospitallistList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hospitalname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto}
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto;
  return proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto}
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHospitalname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHospitalname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 Id = 1;
 * @return {number}
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto} returns this
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string HospitalName = 2;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto.prototype.getHospitalname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto} returns this
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto.prototype.setHospitalname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated HospitalDto HospitalList = 1;
 * @return {!Array<!proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto>}
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.prototype.getHospitallistList = function() {
  return /** @type{!Array<!proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto, 1));
};


/**
 * @param {!Array<!proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto>} value
 * @return {!proto.GunlukCiraRaporu.GetHospitalListResponse} returns this
*/
proto.GunlukCiraRaporu.GetHospitalListResponse.prototype.setHospitallistList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto}
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.prototype.addHospitallist = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.GunlukCiraRaporu.GetHospitalListResponse.HospitalDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GunlukCiraRaporu.GetHospitalListResponse} returns this
 */
proto.GunlukCiraRaporu.GetHospitalListResponse.prototype.clearHospitallistList = function() {
  return this.setHospitallistList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hospitalid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tarih: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest;
  return proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHospitalid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHospitalid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 HospitalId = 1;
 * @return {number}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest.prototype.getHospitalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest.prototype.setHospitalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Tarih = 2;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsRequest.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gunlukciroraporurowsList: jspb.Message.toObjectList(msg.getGunlukciroraporurowsList(),
    proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse;
  return proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto;
      reader.readMessage(value,proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.deserializeBinaryFromReader);
      msg.addGunlukciroraporurows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGunlukciroraporurowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    sirano: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tarih: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hastane: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tipi: jspb.Message.getFieldWithDefault(msg, 4, ""),
    butce: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ciro: jspb.Message.getFieldWithDefault(msg, 6, ""),
    gidisat: jspb.Message.getFieldWithDefault(msg, 7, ""),
    gerceklesme: jspb.Message.getFieldWithDefault(msg, 8, ""),
    oncekigungidisati: jspb.Message.getFieldWithDefault(msg, 9, ""),
    gunlukdegisim: jspb.Message.getFieldWithDefault(msg, 10, ""),
    hizmetbutcesindekioran: jspb.Message.getFieldWithDefault(msg, 11, ""),
    gidisaticindekioran: jspb.Message.getFieldWithDefault(msg, 12, ""),
    gerceklesmesigereken: jspb.Message.getFieldWithDefault(msg, 13, ""),
    oncekigunciro: jspb.Message.getFieldWithDefault(msg, 14, ""),
    songunciro: jspb.Message.getFieldWithDefault(msg, 15, ""),
    hedefgerceklesmeorani: jspb.Message.getFieldWithDefault(msg, 16, ""),
    gidisathizmetbutcesifarki: jspb.Message.getFieldWithDefault(msg, 17, ""),
    fark: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto;
  return proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSirano(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTipi(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setButce(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCiro(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setGidisat(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setGerceklesme(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setOncekigungidisati(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setGunlukdegisim(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setHizmetbutcesindekioran(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setGidisaticindekioran(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setGerceklesmesigereken(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOncekigunciro(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setSongunciro(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setHedefgerceklesmeorani(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setGidisathizmetbutcesifarki(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setFark(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSirano();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTipi();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getButce();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCiro();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getGidisat();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getGerceklesme();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getOncekigungidisati();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getGunlukdegisim();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getHizmetbutcesindekioran();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getGidisaticindekioran();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getGerceklesmesigereken();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getOncekigunciro();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getSongunciro();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getHedefgerceklesmeorani();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getGidisathizmetbutcesifarki();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getFark();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional int32 SiraNO = 1;
 * @return {number}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getSirano = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setSirano = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Tarih = 2;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Hastane = 3;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Tipi = 4;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getTipi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setTipi = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Butce = 5;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getButce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setButce = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Ciro = 6;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getCiro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setCiro = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Gidisat = 7;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getGidisat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setGidisat = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string Gerceklesme = 8;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getGerceklesme = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setGerceklesme = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string OncekiGunGidisati = 9;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getOncekigungidisati = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setOncekigungidisati = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string GunlukDegisim = 10;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getGunlukdegisim = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setGunlukdegisim = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string HizmetButcesindekiOran = 11;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getHizmetbutcesindekioran = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setHizmetbutcesindekioran = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string GidisatIcindekiOran = 12;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getGidisaticindekioran = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setGidisaticindekioran = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string GerceklesmesiGereken = 13;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getGerceklesmesigereken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setGerceklesmesigereken = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string OncekiGunCiro = 14;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getOncekigunciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setOncekigunciro = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string SonGunCiro = 15;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getSongunciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setSongunciro = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string HedefGerceklesmeOrani = 16;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getHedefgerceklesmeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setHedefgerceklesmeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string GidisatHizmetButcesiFarki = 17;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getGidisathizmetbutcesifarki = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setGidisathizmetbutcesifarki = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string Fark = 18;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.getFark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto.prototype.setFark = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * repeated GunlukCiroRaporuRowDto gunlukCiroRaporuRows = 1;
 * @return {!Array<!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto>}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.prototype.getGunlukciroraporurowsList = function() {
  return /** @type{!Array<!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto, 1));
};


/**
 * @param {!Array<!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto>} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse} returns this
*/
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.prototype.setGunlukciroraporurowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.prototype.addGunlukciroraporurows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.GunlukCiroRaporuRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByHospitalsResponse.prototype.clearGunlukciroraporurowsList = function() {
  return this.setGunlukciroraporurowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tarih: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest;
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string Tarih = 1;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupRequest.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gunlukciroraporurowsList: jspb.Message.toObjectList(msg.getGunlukciroraporurowsList(),
    proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse;
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto;
      reader.readMessage(value,proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.deserializeBinaryFromReader);
      msg.addGunlukciroraporurows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGunlukciroraporurowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    tarih: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tipi: jspb.Message.getFieldWithDefault(msg, 2, 0),
    butce: jspb.Message.getFieldWithDefault(msg, 3, ""),
    ciro: jspb.Message.getFieldWithDefault(msg, 4, ""),
    gidisat: jspb.Message.getFieldWithDefault(msg, 5, ""),
    gerceklesme: jspb.Message.getFieldWithDefault(msg, 6, ""),
    oncekigungidisati: jspb.Message.getFieldWithDefault(msg, 7, ""),
    butcedenkalan: jspb.Message.getFieldWithDefault(msg, 8, ""),
    gerceklesenaktifgun: jspb.Message.getFieldWithDefault(msg, 9, ""),
    oncekigunciro: jspb.Message.getFieldWithDefault(msg, 10, ""),
    hedefgerceklesmeorani: jspb.Message.getFieldWithDefault(msg, 11, ""),
    hizmetbutcesindekioran: jspb.Message.getFieldWithDefault(msg, 12, ""),
    gidisaticindekioran: jspb.Message.getFieldWithDefault(msg, 13, ""),
    tipiaciklama: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto;
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTipi(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setButce(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCiro(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGidisat(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGerceklesme(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOncekigungidisati(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setButcedenkalan(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setGerceklesenaktifgun(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOncekigunciro(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setHedefgerceklesmeorani(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setHizmetbutcesindekioran(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setGidisaticindekioran(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTipiaciklama(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTipi();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getButce();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCiro();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGidisat();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGerceklesme();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOncekigungidisati();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getButcedenkalan();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getGerceklesenaktifgun();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOncekigunciro();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getHedefgerceklesmeorani();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getHizmetbutcesindekioran();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getGidisaticindekioran();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTipiaciklama();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string tarih = 1;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 tipi = 2;
 * @return {number}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.getTipi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.setTipi = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string butce = 3;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.getButce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.setButce = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string ciro = 4;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.getCiro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.setCiro = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string gidisat = 5;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.getGidisat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.setGidisat = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string gerceklesme = 6;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.getGerceklesme = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.setGerceklesme = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string oncekiGunGidisati = 7;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.getOncekigungidisati = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.setOncekigungidisati = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string butcedenKalan = 8;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.getButcedenkalan = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.setButcedenkalan = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string gerceklesenAktifGun = 9;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.getGerceklesenaktifgun = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.setGerceklesenaktifgun = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string oncekiGunCiro = 10;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.getOncekigunciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.setOncekigunciro = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string hedefGerceklesmeOrani = 11;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.getHedefgerceklesmeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.setHedefgerceklesmeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string hizmetButcesindekiOran = 12;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.getHizmetbutcesindekioran = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.setHizmetbutcesindekioran = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string gidisatIcindekiOran = 13;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.getGidisaticindekioran = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.setGidisaticindekioran = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string tipiAciklama = 14;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.getTipiaciklama = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto.prototype.setTipiaciklama = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * repeated GunlukCiroRaporuRowDto gunlukCiroRaporuRows = 1;
 * @return {!Array<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto>}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.prototype.getGunlukciroraporurowsList = function() {
  return /** @type{!Array<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto, 1));
};


/**
 * @param {!Array<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto>} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse} returns this
*/
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.prototype.setGunlukciroraporurowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.prototype.addGunlukciroraporurows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.GunlukCiroRaporuRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByGroupResponse.prototype.clearGunlukciroraporurowsList = function() {
  return this.setGunlukciroraporurowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hospitalid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tarih: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest;
  return proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHospitalid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHospitalid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 HospitalId = 1;
 * @return {number}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest.prototype.getHospitalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest.prototype.setHospitalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Tarih = 2;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayRequest.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gunlukciraraporugelisturudetayrowdtoList: jspb.Message.toObjectList(msg.getGunlukciraraporugelisturudetayrowdtoList(),
    proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse;
  return proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto;
      reader.readMessage(value,proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.deserializeBinaryFromReader);
      msg.addGunlukciraraporugelisturudetayrowdto(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGunlukciraraporugelisturudetayrowdtoList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    tarih: jspb.Message.getFieldWithDefault(msg, 1, ""),
    hastane: jspb.Message.getFieldWithDefault(msg, 2, ""),
    butce: jspb.Message.getFieldWithDefault(msg, 3, ""),
    ciro: jspb.Message.getFieldWithDefault(msg, 4, ""),
    gidisat: jspb.Message.getFieldWithDefault(msg, 5, ""),
    gerceklesme: jspb.Message.getFieldWithDefault(msg, 6, ""),
    endeksdegisimi: jspb.Message.getFieldWithDefault(msg, 7, ""),
    butcegerceklesmeorani: jspb.Message.getFieldWithDefault(msg, 8, ""),
    gelisturu: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto;
  return proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setButce(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCiro(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGidisat(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setGerceklesme(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndeksdegisimi(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setButcegerceklesmeorani(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setGelisturu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getButce();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCiro();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGidisat();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getGerceklesme();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getEndeksdegisimi();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getButcegerceklesmeorani();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getGelisturu();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string Tarih = 1;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Hastane = 2;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Butce = 3;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.getButce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.setButce = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Ciro = 4;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.getCiro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.setCiro = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Gidisat = 5;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.getGidisat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.setGidisat = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Gerceklesme = 6;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.getGerceklesme = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.setGerceklesme = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string EndeksDegisimi = 7;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.getEndeksdegisimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.setEndeksdegisimi = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string ButceGerceklesmeOrani = 8;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.getButcegerceklesmeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.setButcegerceklesmeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string GelisTuru = 9;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.getGelisturu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto.prototype.setGelisturu = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated GunlukCiraRaporuGelisTuruDetayRowDto gunlukCiraRaporuGelisTuruDetayRowDto = 1;
 * @return {!Array<!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto>}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.prototype.getGunlukciraraporugelisturudetayrowdtoList = function() {
  return /** @type{!Array<!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto, 1));
};


/**
 * @param {!Array<!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto>} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse} returns this
*/
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.prototype.setGunlukciraraporugelisturudetayrowdtoList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.prototype.addGunlukciraraporugelisturudetayrowdto = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.GunlukCiraRaporuGelisTuruDetayRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiraRaporuByGelisTuruDetayResponse.prototype.clearGunlukciraraporugelisturudetayrowdtoList = function() {
  return this.setGunlukciraraporugelisturudetayrowdtoList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    kurumid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tarih: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest;
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 KurumId = 1;
 * @return {number}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Tarih = 2;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiRequest.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gunlukciroraporurowsList: jspb.Message.toObjectList(msg.getGunlukciroraporurowsList(),
    proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse;
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto;
      reader.readMessage(value,proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.deserializeBinaryFromReader);
      msg.addGunlukciroraporurows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGunlukciroraporurowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    ustgrup: jspb.Message.getFieldWithDefault(msg, 1, ""),
    altgrup: jspb.Message.getFieldWithDefault(msg, 2, ""),
    oncekiayislemsayisi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    oncekiayciro: jspb.Message.getFieldWithDefault(msg, 4, ""),
    islemsayisi: jspb.Message.getFieldWithDefault(msg, 5, 0),
    ciro: jspb.Message.getFieldWithDefault(msg, 6, ""),
    gidisatislemsayisi: jspb.Message.getFieldWithDefault(msg, 7, 0),
    gidisatciro: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto;
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUstgrup(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAltgrup(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOncekiayislemsayisi(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOncekiayciro(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIslemsayisi(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCiro(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGidisatislemsayisi(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setGidisatciro(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUstgrup();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAltgrup();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOncekiayislemsayisi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOncekiayciro();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIslemsayisi();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getCiro();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getGidisatislemsayisi();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getGidisatciro();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string ustGrup = 1;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.getUstgrup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.setUstgrup = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string altGrup = 2;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.getAltgrup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.setAltgrup = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string oncekiAyIslemSayisi = 3;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.getOncekiayislemsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.setOncekiayislemsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string oncekiAyCiro = 4;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.getOncekiayciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.setOncekiayciro = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 islemSayisi = 5;
 * @return {number}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.getIslemsayisi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.setIslemsayisi = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string ciro = 6;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.getCiro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.setCiro = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 gidisatIslemSayisi = 7;
 * @return {number}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.getGidisatislemsayisi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.setGidisatislemsayisi = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string gidisatCiro = 8;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.getGidisatciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto.prototype.setGidisatciro = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated GunlukCiroRaporuRowDto gunlukCiroRaporuRows = 1;
 * @return {!Array<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto>}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.prototype.getGunlukciroraporurowsList = function() {
  return /** @type{!Array<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto, 1));
};


/**
 * @param {!Array<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto>} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse} returns this
*/
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.prototype.setGunlukciroraporurowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.prototype.addGunlukciroraporurows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.GunlukCiroRaporuRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByHizmetDetayiResponse.prototype.clearGunlukciroraporurowsList = function() {
  return this.setGunlukciroraporurowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hospitalid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tarih: jspb.Message.getFieldWithDefault(msg, 2, ""),
    wquery: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest;
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHospitalid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setWquery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHospitalid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getWquery();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 HospitalId = 1;
 * @return {number}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.prototype.getHospitalid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.prototype.setHospitalid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string Tarih = 2;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string WQuery = 3;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.prototype.getWquery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRequest.prototype.setWquery = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gunlukciroraporubutcetakibiendekshizmetcirosurowsList: jspb.Message.toObjectList(msg.getGunlukciroraporubutcetakibiendekshizmetcirosurowsList(),
    proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse;
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto;
      reader.readMessage(value,proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.deserializeBinaryFromReader);
      msg.addGunlukciroraporubutcetakibiendekshizmetcirosurows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGunlukciroraporubutcetakibiendekshizmetcirosurowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    hastane: jspb.Message.getFieldWithDefault(msg, 1, ""),
    kurumid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tarih: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tutar: jspb.Message.getFieldWithDefault(msg, 4, ""),
    oncekiaytutari: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fark: jspb.Message.getFieldWithDefault(msg, 6, ""),
    oran: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto;
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setKurumid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTutar(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOncekiaytutari(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setFark(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOran(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getKurumid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTutar();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOncekiaytutari();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFark();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOran();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string Hastane = 1;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Kurumid = 2;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.prototype.getKurumid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Tarih = 3;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Tutar = 4;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.prototype.getTutar = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.prototype.setTutar = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string Oncekiaytutari = 5;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.prototype.getOncekiaytutari = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.prototype.setOncekiaytutari = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string Fark = 6;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.prototype.getFark = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.prototype.setFark = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string Oran = 7;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.prototype.getOran = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto.prototype.setOran = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * repeated GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto gunlukCiroRaporuButceTakibiEndeksHizmetCirosuRows = 1;
 * @return {!Array<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto>}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.prototype.getGunlukciroraporubutcetakibiendekshizmetcirosurowsList = function() {
  return /** @type{!Array<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto, 1));
};


/**
 * @param {!Array<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto>} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse} returns this
*/
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.prototype.setGunlukciroraporubutcetakibiendekshizmetcirosurowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.prototype.addGunlukciroraporubutcetakibiendekshizmetcirosurows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuButceTakibiEndeksHizmetCirosuResponse.prototype.clearGunlukciroraporubutcetakibiendekshizmetcirosurowsList = function() {
  return this.setGunlukciroraporubutcetakibiendekshizmetcirosurowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tarih: jspb.Message.getFieldWithDefault(msg, 1, ""),
    odeyicikurumtipi: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest;
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOdeyicikurumtipi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOdeyicikurumtipi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string tarih = 1;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string odeyiciKurumTipi = 2;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest.prototype.getOdeyicikurumtipi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiRequest.prototype.setOdeyicikurumtipi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gunlukciroraporurowsList: jspb.Message.toObjectList(msg.getGunlukciroraporurowsList(),
    proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse;
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto;
      reader.readMessage(value,proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.deserializeBinaryFromReader);
      msg.addGunlukciroraporurows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGunlukciroraporurowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    hastane: jspb.Message.getFieldWithDefault(msg, 1, ""),
    butce: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ciro: jspb.Message.getFieldWithDefault(msg, 3, ""),
    gidisat: jspb.Message.getFieldWithDefault(msg, 4, ""),
    endeksdegisimi: jspb.Message.getFieldWithDefault(msg, 5, ""),
    butcegerceklesmeorani: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto;
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setButce(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCiro(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGidisat(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndeksdegisimi(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setButcegerceklesmeorani(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getButce();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCiro();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGidisat();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEndeksdegisimi();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getButcegerceklesmeorani();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string hastane = 1;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string butce = 2;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.prototype.getButce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.prototype.setButce = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ciro = 3;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.prototype.getCiro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.prototype.setCiro = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string gidisat = 4;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.prototype.getGidisat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.prototype.setGidisat = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string endeksDegisimi = 5;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.prototype.getEndeksdegisimi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.prototype.setEndeksdegisimi = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string butceGerceklesmeOrani = 6;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.prototype.getButcegerceklesmeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto.prototype.setButcegerceklesmeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated GunlukCiroRaporuRowDto gunlukCiroRaporuRows = 1;
 * @return {!Array<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto>}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.prototype.getGunlukciroraporurowsList = function() {
  return /** @type{!Array<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto, 1));
};


/**
 * @param {!Array<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto>} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse} returns this
*/
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.prototype.setGunlukciroraporurowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.prototype.addGunlukciroraporurows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.GunlukCiroRaporuRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiResponse.prototype.clearGunlukciroraporurowsList = function() {
  return this.setGunlukciroraporurowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tarih: jspb.Message.getFieldWithDefault(msg, 1, ""),
    odeyicikurumtipi: jspb.Message.getFieldWithDefault(msg, 2, ""),
    kurumid: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest;
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOdeyicikurumtipi(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOdeyicikurumtipi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional string tarih = 1;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string odeyiciKurumTipi = 2;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.prototype.getOdeyicikurumtipi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.prototype.setOdeyicikurumtipi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 kurumId = 3;
 * @return {number}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikRequest.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    gunlukciroraporurowsList: jspb.Message.toObjectList(msg.getGunlukciroraporurowsList(),
    proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse;
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto;
      reader.readMessage(value,proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto.deserializeBinaryFromReader);
      msg.addGunlukciroraporurows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGunlukciroraporurowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    ciro: jspb.Message.getFieldWithDefault(msg, 1, ""),
    tarih: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto;
  return proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCiro(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCiro();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ciro = 1;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto.prototype.getCiro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto.prototype.setCiro = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string tarih = 2;
 * @return {string}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated GunlukCiroRaporuRowDto gunlukCiroRaporuRows = 1;
 * @return {!Array<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto>}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.prototype.getGunlukciroraporurowsList = function() {
  return /** @type{!Array<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto, 1));
};


/**
 * @param {!Array<!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto>} value
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse} returns this
*/
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.prototype.setGunlukciroraporurowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto}
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.prototype.addGunlukciroraporurows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.GunlukCiroRaporuRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse} returns this
 */
proto.GunlukCiraRaporu.GetGunlukCiroRaporuByOdeyiciDetayiGrafikResponse.prototype.clearGunlukciroraporurowsList = function() {
  return this.setGunlukciroraporurowsList([]);
};


goog.object.extend(exports, proto.GunlukCiraRaporu);
