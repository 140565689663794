/* eslint-disable */
//@ts-nocheck

// source: gc_for_the_ui.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.GCFortheUI.GetDonemTanimlariResponse', null, global);
goog.exportSymbol('proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto', null, global);
goog.exportSymbol('proto.GCFortheUI.GetHospitalListResponseFTUI', null, global);
goog.exportSymbol('proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI', null, global);
goog.exportSymbol('proto.GCFortheUI.GetMerkeziBolumlerResponse', null, global);
goog.exportSymbol('proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GCFortheUI.GetHospitalListResponseFTUI = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GCFortheUI.GetHospitalListResponseFTUI.repeatedFields_, null);
};
goog.inherits(proto.GCFortheUI.GetHospitalListResponseFTUI, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GCFortheUI.GetHospitalListResponseFTUI.displayName = 'proto.GCFortheUI.GetHospitalListResponseFTUI';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI.displayName = 'proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GCFortheUI.GetDonemTanimlariResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GCFortheUI.GetDonemTanimlariResponse.repeatedFields_, null);
};
goog.inherits(proto.GCFortheUI.GetDonemTanimlariResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GCFortheUI.GetDonemTanimlariResponse.displayName = 'proto.GCFortheUI.GetDonemTanimlariResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto.displayName = 'proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.GCFortheUI.GetMerkeziBolumlerResponse.repeatedFields_, null);
};
goog.inherits(proto.GCFortheUI.GetMerkeziBolumlerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GCFortheUI.GetMerkeziBolumlerResponse.displayName = 'proto.GCFortheUI.GetMerkeziBolumlerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto.displayName = 'proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.prototype.toObject = function(opt_includeInstance) {
  return proto.GCFortheUI.GetHospitalListResponseFTUI.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GCFortheUI.GetHospitalListResponseFTUI} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.toObject = function(includeInstance, msg) {
  var f, obj = {
    hospitallistftuiList: jspb.Message.toObjectList(msg.getHospitallistftuiList(),
    proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GCFortheUI.GetHospitalListResponseFTUI}
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GCFortheUI.GetHospitalListResponseFTUI;
  return proto.GCFortheUI.GetHospitalListResponseFTUI.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GCFortheUI.GetHospitalListResponseFTUI} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GCFortheUI.GetHospitalListResponseFTUI}
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI;
      reader.readMessage(value,proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI.deserializeBinaryFromReader);
      msg.addHospitallistftui(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GCFortheUI.GetHospitalListResponseFTUI.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GCFortheUI.GetHospitalListResponseFTUI} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHospitallistftuiList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI.prototype.toObject = function(opt_includeInstance) {
  return proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hospitalname: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI}
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI;
  return proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI}
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHospitalname(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHospitalname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI} returns this
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string hospitalName = 2;
 * @return {string}
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI.prototype.getHospitalname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI} returns this
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI.prototype.setHospitalname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated hospitalDtoFTUI hospitalListFTUI = 1;
 * @return {!Array<!proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI>}
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.prototype.getHospitallistftuiList = function() {
  return /** @type{!Array<!proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI, 1));
};


/**
 * @param {!Array<!proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI>} value
 * @return {!proto.GCFortheUI.GetHospitalListResponseFTUI} returns this
*/
proto.GCFortheUI.GetHospitalListResponseFTUI.prototype.setHospitallistftuiList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI}
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.prototype.addHospitallistftui = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.GCFortheUI.GetHospitalListResponseFTUI.hospitalDtoFTUI, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GCFortheUI.GetHospitalListResponseFTUI} returns this
 */
proto.GCFortheUI.GetHospitalListResponseFTUI.prototype.clearHospitallistftuiList = function() {
  return this.setHospitallistftuiList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GCFortheUI.GetDonemTanimlariResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GCFortheUI.GetDonemTanimlariResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GCFortheUI.GetDonemTanimlariResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GCFortheUI.GetDonemTanimlariResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GCFortheUI.GetDonemTanimlariResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    donemtanimlariList: jspb.Message.toObjectList(msg.getDonemtanimlariList(),
    proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GCFortheUI.GetDonemTanimlariResponse}
 */
proto.GCFortheUI.GetDonemTanimlariResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GCFortheUI.GetDonemTanimlariResponse;
  return proto.GCFortheUI.GetDonemTanimlariResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GCFortheUI.GetDonemTanimlariResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GCFortheUI.GetDonemTanimlariResponse}
 */
proto.GCFortheUI.GetDonemTanimlariResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto;
      reader.readMessage(value,proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto.deserializeBinaryFromReader);
      msg.addDonemtanimlari(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GCFortheUI.GetDonemTanimlariResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GCFortheUI.GetDonemTanimlariResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GCFortheUI.GetDonemTanimlariResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GCFortheUI.GetDonemTanimlariResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDonemtanimlariList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto.prototype.toObject = function(opt_includeInstance) {
  return proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    donemadi: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto}
 */
proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto;
  return proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto}
 */
proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDonemadi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDonemadi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto} returns this
 */
proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string donemAdi = 2;
 * @return {string}
 */
proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto.prototype.getDonemadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto} returns this
 */
proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto.prototype.setDonemadi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated DonemTanimDto donemTanimlari = 1;
 * @return {!Array<!proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto>}
 */
proto.GCFortheUI.GetDonemTanimlariResponse.prototype.getDonemtanimlariList = function() {
  return /** @type{!Array<!proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto, 1));
};


/**
 * @param {!Array<!proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto>} value
 * @return {!proto.GCFortheUI.GetDonemTanimlariResponse} returns this
*/
proto.GCFortheUI.GetDonemTanimlariResponse.prototype.setDonemtanimlariList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto}
 */
proto.GCFortheUI.GetDonemTanimlariResponse.prototype.addDonemtanimlari = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.GCFortheUI.GetDonemTanimlariResponse.DonemTanimDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GCFortheUI.GetDonemTanimlariResponse} returns this
 */
proto.GCFortheUI.GetDonemTanimlariResponse.prototype.clearDonemtanimlariList = function() {
  return this.setDonemtanimlariList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GCFortheUI.GetMerkeziBolumlerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GCFortheUI.GetMerkeziBolumlerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    merkezibolumlerList: jspb.Message.toObjectList(msg.getMerkezibolumlerList(),
    proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GCFortheUI.GetMerkeziBolumlerResponse}
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GCFortheUI.GetMerkeziBolumlerResponse;
  return proto.GCFortheUI.GetMerkeziBolumlerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GCFortheUI.GetMerkeziBolumlerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GCFortheUI.GetMerkeziBolumlerResponse}
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto;
      reader.readMessage(value,proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto.deserializeBinaryFromReader);
      msg.addMerkezibolumler(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GCFortheUI.GetMerkeziBolumlerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GCFortheUI.GetMerkeziBolumlerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMerkezibolumlerList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto.prototype.toObject = function(opt_includeInstance) {
  return proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    bolumadi: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto}
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto;
  return proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto}
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBolumadi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getBolumadi();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto} returns this
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string bolumAdi = 2;
 * @return {string}
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto.prototype.getBolumadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto} returns this
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto.prototype.setBolumadi = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MerkeziBolumDto merkeziBolumler = 1;
 * @return {!Array<!proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto>}
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.prototype.getMerkezibolumlerList = function() {
  return /** @type{!Array<!proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto, 1));
};


/**
 * @param {!Array<!proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto>} value
 * @return {!proto.GCFortheUI.GetMerkeziBolumlerResponse} returns this
*/
proto.GCFortheUI.GetMerkeziBolumlerResponse.prototype.setMerkezibolumlerList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto}
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.prototype.addMerkezibolumler = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.GCFortheUI.GetMerkeziBolumlerResponse.MerkeziBolumDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.GCFortheUI.GetMerkeziBolumlerResponse} returns this
 */
proto.GCFortheUI.GetMerkeziBolumlerResponse.prototype.clearMerkezibolumlerList = function() {
  return this.setMerkezibolumlerList([]);
};


goog.object.extend(exports, proto.GCFortheUI);
