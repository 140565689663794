/* eslint-disable */
//@ts-nocheck

/**
 * @fileoverview gRPC-Web generated client stub for RaporPaneli
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.RaporPaneli = require('./rapor_paneli_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.RaporPaneli.RaporPaneliClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.RaporPaneli.RaporPaneliPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.RaporPaneli.SurecVeRaporAgaciRequest,
 *   !proto.RaporPaneli.SurecVeRaporAgaciResponse>}
 */
const methodDescriptor_RaporPaneli_SurecVeRaporAgaciniGetir = new grpc.web.MethodDescriptor(
  '/RaporPaneli.RaporPaneli/SurecVeRaporAgaciniGetir',
  grpc.web.MethodType.UNARY,
  proto.RaporPaneli.SurecVeRaporAgaciRequest,
  proto.RaporPaneli.SurecVeRaporAgaciResponse,
  /**
   * @param {!proto.RaporPaneli.SurecVeRaporAgaciRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.RaporPaneli.SurecVeRaporAgaciResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.RaporPaneli.SurecVeRaporAgaciRequest,
 *   !proto.RaporPaneli.SurecVeRaporAgaciResponse>}
 */
const methodInfo_RaporPaneli_SurecVeRaporAgaciniGetir = new grpc.web.AbstractClientBase.MethodInfo(
  proto.RaporPaneli.SurecVeRaporAgaciResponse,
  /**
   * @param {!proto.RaporPaneli.SurecVeRaporAgaciRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.RaporPaneli.SurecVeRaporAgaciResponse.deserializeBinary
);


/**
 * @param {!proto.RaporPaneli.SurecVeRaporAgaciRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.RaporPaneli.SurecVeRaporAgaciResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.RaporPaneli.SurecVeRaporAgaciResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.RaporPaneli.RaporPaneliClient.prototype.surecVeRaporAgaciniGetir =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/RaporPaneli.RaporPaneli/SurecVeRaporAgaciniGetir',
      request,
      metadata || {},
      methodDescriptor_RaporPaneli_SurecVeRaporAgaciniGetir,
      callback);
};


/**
 * @param {!proto.RaporPaneli.SurecVeRaporAgaciRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.RaporPaneli.SurecVeRaporAgaciResponse>}
 *     Promise that resolves to the response
 */
proto.RaporPaneli.RaporPaneliPromiseClient.prototype.surecVeRaporAgaciniGetir =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/RaporPaneli.RaporPaneli/SurecVeRaporAgaciniGetir',
      request,
      metadata || {},
      methodDescriptor_RaporPaneli_SurecVeRaporAgaciniGetir);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest,
 *   !proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse>}
 */
const methodDescriptor_RaporPaneli_RaporOkumaGecmisiniGetir = new grpc.web.MethodDescriptor(
  '/RaporPaneli.RaporPaneli/RaporOkumaGecmisiniGetir',
  grpc.web.MethodType.UNARY,
  proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest,
  proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse,
  /**
   * @param {!proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest,
 *   !proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse>}
 */
const methodInfo_RaporPaneli_RaporOkumaGecmisiniGetir = new grpc.web.AbstractClientBase.MethodInfo(
  proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse,
  /**
   * @param {!proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse.deserializeBinary
);


/**
 * @param {!proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.RaporPaneli.RaporPaneliClient.prototype.raporOkumaGecmisiniGetir =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/RaporPaneli.RaporPaneli/RaporOkumaGecmisiniGetir',
      request,
      metadata || {},
      methodDescriptor_RaporPaneli_RaporOkumaGecmisiniGetir,
      callback);
};


/**
 * @param {!proto.RaporPaneli.RaporOkumaGecmisiniGetirRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.RaporPaneli.RaporOkumaGecmisiniGetirResponse>}
 *     Promise that resolves to the response
 */
proto.RaporPaneli.RaporPaneliPromiseClient.prototype.raporOkumaGecmisiniGetir =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/RaporPaneli.RaporPaneli/RaporOkumaGecmisiniGetir',
      request,
      metadata || {},
      methodDescriptor_RaporPaneli_RaporOkumaGecmisiniGetir);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.RaporPaneli.RaporBilgisiniGetirRequest,
 *   !proto.RaporPaneli.RaporBilgisiniGetirResponse>}
 */
const methodDescriptor_RaporPaneli_RaporBilgisiniGetir = new grpc.web.MethodDescriptor(
  '/RaporPaneli.RaporPaneli/RaporBilgisiniGetir',
  grpc.web.MethodType.UNARY,
  proto.RaporPaneli.RaporBilgisiniGetirRequest,
  proto.RaporPaneli.RaporBilgisiniGetirResponse,
  /**
   * @param {!proto.RaporPaneli.RaporBilgisiniGetirRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.RaporPaneli.RaporBilgisiniGetirResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.RaporPaneli.RaporBilgisiniGetirRequest,
 *   !proto.RaporPaneli.RaporBilgisiniGetirResponse>}
 */
const methodInfo_RaporPaneli_RaporBilgisiniGetir = new grpc.web.AbstractClientBase.MethodInfo(
  proto.RaporPaneli.RaporBilgisiniGetirResponse,
  /**
   * @param {!proto.RaporPaneli.RaporBilgisiniGetirRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.RaporPaneli.RaporBilgisiniGetirResponse.deserializeBinary
);


/**
 * @param {!proto.RaporPaneli.RaporBilgisiniGetirRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.RaporPaneli.RaporBilgisiniGetirResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.RaporPaneli.RaporBilgisiniGetirResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.RaporPaneli.RaporPaneliClient.prototype.raporBilgisiniGetir =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/RaporPaneli.RaporPaneli/RaporBilgisiniGetir',
      request,
      metadata || {},
      methodDescriptor_RaporPaneli_RaporBilgisiniGetir,
      callback);
};


/**
 * @param {!proto.RaporPaneli.RaporBilgisiniGetirRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.RaporPaneli.RaporBilgisiniGetirResponse>}
 *     Promise that resolves to the response
 */
proto.RaporPaneli.RaporPaneliPromiseClient.prototype.raporBilgisiniGetir =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/RaporPaneli.RaporPaneli/RaporBilgisiniGetir',
      request,
      metadata || {},
      methodDescriptor_RaporPaneli_RaporBilgisiniGetir);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.RaporPaneli.SaveReportReadingHistoryRequest,
 *   !proto.RaporPaneli.SaveReportReadingHistoryResponse>}
 */
const methodDescriptor_RaporPaneli_SaveReportReadingHistory = new grpc.web.MethodDescriptor(
  '/RaporPaneli.RaporPaneli/SaveReportReadingHistory',
  grpc.web.MethodType.UNARY,
  proto.RaporPaneli.SaveReportReadingHistoryRequest,
  proto.RaporPaneli.SaveReportReadingHistoryResponse,
  /**
   * @param {!proto.RaporPaneli.SaveReportReadingHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.RaporPaneli.SaveReportReadingHistoryResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.RaporPaneli.SaveReportReadingHistoryRequest,
 *   !proto.RaporPaneli.SaveReportReadingHistoryResponse>}
 */
const methodInfo_RaporPaneli_SaveReportReadingHistory = new grpc.web.AbstractClientBase.MethodInfo(
  proto.RaporPaneli.SaveReportReadingHistoryResponse,
  /**
   * @param {!proto.RaporPaneli.SaveReportReadingHistoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.RaporPaneli.SaveReportReadingHistoryResponse.deserializeBinary
);


/**
 * @param {!proto.RaporPaneli.SaveReportReadingHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.RaporPaneli.SaveReportReadingHistoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.RaporPaneli.SaveReportReadingHistoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.RaporPaneli.RaporPaneliClient.prototype.saveReportReadingHistory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/RaporPaneli.RaporPaneli/SaveReportReadingHistory',
      request,
      metadata || {},
      methodDescriptor_RaporPaneli_SaveReportReadingHistory,
      callback);
};


/**
 * @param {!proto.RaporPaneli.SaveReportReadingHistoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.RaporPaneli.SaveReportReadingHistoryResponse>}
 *     Promise that resolves to the response
 */
proto.RaporPaneli.RaporPaneliPromiseClient.prototype.saveReportReadingHistory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/RaporPaneli.RaporPaneli/SaveReportReadingHistory',
      request,
      metadata || {},
      methodDescriptor_RaporPaneli_SaveReportReadingHistory);
};


module.exports = proto.RaporPaneli;

