import { createSlice } from '@reduxjs/toolkit';
import raporPaneliService from 'src/services/raporPaneliService';
import { trackPromise } from 'react-promise-tracker';

const initialState = {
    isSidebarOpen: false,
    surecVeRaporAgaci: [],
    raporBilgisi: null,
    raporOkumaGecmisi: [],
    selectedTab: 'hazirRapor',
    raporKodu: ''
};

const slice = createSlice({
    name: 'raporPaneli',
    initialState,
    reducers: {
        openSidebar(state) {
            state.isSidebarOpen = true;
        },
        closeSidebar(state) {
            state.isSidebarOpen = false;
        },
        getSurecVeRaporAgaci(state, action) {
            const surecVeRaporAgaci = action.payload;
            state.surecVeRaporAgaci = surecVeRaporAgaci;
        },
        getRaporBilgisi(state, action) {
            const raporBilgisi = action.payload;
            state.raporBilgisi = raporBilgisi;
        },
        getRaporOkumaGecmisi(state, action) {
            const raporOkumaGecmisi = action.payload;
            state.raporOkumaGecmisi = raporOkumaGecmisi;
        },
        getSelectedTab(state, action) {
            const selectedTab = action.payload;
            state.selectedTab = selectedTab;
        },
        getRaporKodu(state, action) {
            const raporKodu = action.payload;
            state.raporKodu = raporKodu;
        }
    }
});

export const reducer = slice.reducer;

export const openSidebar = () => async (dispatch) => {
    dispatch(slice.actions.openSidebar());
};

export const closeSidebar = () => async (dispatch) => {
    dispatch(slice.actions.closeSidebar());
};

export const getSurecVeRaporAgaci = (userId) => async (dispatch) => {
    const response = await  trackPromise(raporPaneliService.getSurecVeRaporAgaci(userId));

    dispatch(slice.actions.getSurecVeRaporAgaci(response.surecveraporagaciList));
};

export const getRaporBilgisi = (userId, hazirRaporTanimId) => async (dispatch) => {
    const response = await trackPromise(raporPaneliService.getRaporBilgisi(userId, hazirRaporTanimId));

    dispatch(slice.actions.getRaporBilgisi(response.raporbilgisi));
};

export const getRaporOkumaGecmisi = (hazirRaporTanimId) => async (dispatch) => {
    const response = await raporPaneliService.getRaporOkumaGecmisi(hazirRaporTanimId);

    dispatch(slice.actions.getRaporOkumaGecmisi(response.raporokumagecmisiList));
};

export const getSelectedTab = (hazirRaporTipi) => async (dispatch) => {
    switch (hazirRaporTipi) {
        case 1: {
            dispatch(slice.actions.getSelectedTab('hazirRapor'));
            break;
        }
        case 2: {
            dispatch(slice.actions.getSelectedTab('ozelRapor'));

            break;
        }
        case 3: {
            dispatch(slice.actions.getSelectedTab('webRapor'));
            break;
        }
        default:
            break;
    }
}

export const getRaporKodu = (raporKodu) => async (dispatch) => {
    dispatch(slice.actions.getRaporKodu(raporKodu));
}

export default slice;
