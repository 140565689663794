import {DoktorPaneliPRClient} from '../../protos/doktorpaneli/doktorpanelipr_grpc_web_pb'
import {AyaktanTedaviRequestGrpc,YatarakTedaviRequestGrpc,IstekQORequestGrpc,GenelMuayeneFormRequestGrpc, GenelMuayeneFormTumTanilarByPersonelRequestGrpc,
	GenelMuayeneFormTumTanilarByIstekRequestGrpc,GenelMuayeneFormReceteRequestGrpc,GenelMuayeneFormForDoctorRequestGrpc,GenelMuayeneFormTedaviKontrolRequestGrpc,
	GenelMuayeneTeknikTestRequestGrpc,
	MedikalTanimSablonlariGetirRequestGrpc,
	MedikalTanimMuayeneSorulariGetirRequestGrpc,
	MedikalTanimICDGetirRequestGrpc,
	MedikalTanimRadyolojikIsteklerGetirRequestGrpc,
	MedikalTanimLaboratuarIsteklerGetirRequestGrpc,
	GenelMuayeneTetkiklerRequestGrpc,
	GenelMuayeneTetkikDisKurumRaporlariRequestGrpc,
	GenelMuayenePlanliHastaRequestGrpc,
	GenelMuayenePlanliHastaDetayRequestGrpc,
	MedikalTanimLaboratuarIsteklerCrudRequestGrpc,
	MedikalTanimRadyolojiIsteklerCrudRequestGrpc,
	MedikalTanimSablonlariGetirCrudRequestGrpc,
	DoctorPanelMuayeneCevapCrudRequestGrpc,
	DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc,
	DoctorPanelIslemGrubuAgaciRequestGrpc,
	DoctorPaneliIslemTanimListRequestGrpc,
	DoctorPanelIcdAgaciRequestGrpc,
	DoctorPanelTibbiGecmisRequestGrpc,
	DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc,
	DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc,
	DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc

} from '../../protos/doktorpaneli/doktorpanelipr_pb'

export function DoctorPanelMerkeziTibbiGecmisGenericService(requestType, paramint1, paramint2, paramint3, paramint4, paramint5
	,paramstr1, paramstr2, paramstr3, paramstr4, paramstr5){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new DoctorPanelMerkeziTibbiGecmisGenericServiceRequestGrpc();

	nRequest.setRequesttype(requestType===undefined?-1:requestType);
	nRequest.setParamint1(paramint1===undefined?-1:paramint1);
	nRequest.setParamint2(paramint2===undefined?-1:paramint2);
	nRequest.setParamint3(paramint3===undefined?-1:paramint3);
	nRequest.setParamint4(paramint4===undefined?-1:paramint4);
	nRequest.setParamint5(paramint5===undefined?-1:paramint5);
	nRequest.setParamstr1(paramstr1===undefined?'-1':paramstr1);
	nRequest.setParamstr2(paramstr2===undefined?'-1':paramstr2);
	nRequest.setParamstr3(paramstr3===undefined?'-1':paramstr3);
	nRequest.setParamstr4(paramstr4===undefined?'-1':paramstr4);
	nRequest.setParamstr5(paramstr5===undefined?'-1':paramstr5);

	
	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.doctorPanelMerkeziTibbiGecmisGenericService(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			console.log('DoctorPanelMerkeziTibbiGecmisGenericService içerisindeyiz 2');
			resolve(response.toObject());
		  } else {
			console.log('DoctorPanelMerkeziTibbiGecmisGenericService içerisindeyiz 3', error);
			reject(error);
		  }
	}));	

}

export function DoctorPanelDusmeRiskiDegerlendirmeGenericService(requestType, paramint1, paramint2, paramint3, paramint4, paramint5
	,paramstr1, paramstr2, paramstr3, paramstr4, paramstr5){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new DoctorPanelDusmeRiskiDegerlendirmeGenericServiceRequestGrpc();

	nRequest.setRequesttype(requestType===undefined?-1:requestType);
	nRequest.setParamint1(paramint1===undefined?-1:paramint1);
	nRequest.setParamint3(paramint2===undefined?-1:paramint2);
	nRequest.setParamint4(paramint3===undefined?-1:paramint3);
	nRequest.setParamint5(paramint4===undefined?-1:paramint4);
	nRequest.setParamstr1(paramstr1===undefined?'-1':paramstr1);
	nRequest.setParamstr2(paramstr2===undefined?'-1':paramstr2);
	nRequest.setParamstr3(paramstr3===undefined?'-1':paramstr3);
	nRequest.setParamstr4(paramstr4===undefined?'-1':paramstr4);
	nRequest.setParamstr5(paramstr5===undefined?'-1':paramstr5);

	
	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.doctorPanelDusmeRiskiDegerlendirmeGenericService(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			console.log('DoctorPanelDusmeRiskiDegerlendirmeGenericService içerisindeyiz 2');
			resolve(response.toObject());
		  } else {
			console.log('DoctorPanelDusmeRiskiDegerlendirmeGenericService içerisindeyiz 3', error);
			reject(error);
		  }
	}));	

}


export function DoctorPanelFiyatlandirmaIstegiGenericService(requestType, paramint1, paramint2, paramint3, paramint4, paramint5
	,paramstr1, paramstr2, paramstr3, paramstr4, paramstr5){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new DoctorPanelFiyatlandirmaIstegiGenericServiceRequestGrpc();

	nRequest.setRequesttype(requestType===undefined?-1:requestType);
	nRequest.setParamint1(paramint1===undefined?-1:paramint1);
	nRequest.setParamint3(paramint2===undefined?-1:paramint2);
	nRequest.setParamint4(paramint3===undefined?-1:paramint3);
	nRequest.setParamint5(paramint4===undefined?-1:paramint4);
	nRequest.setParamstr1(paramstr1===undefined?'-1':paramstr1);
	nRequest.setParamstr2(paramstr2===undefined?'-1':paramstr2);
	nRequest.setParamstr3(paramstr3===undefined?'-1':paramstr3);
	nRequest.setParamstr4(paramstr4===undefined?'-1':paramstr4);
	nRequest.setParamstr5(paramstr5===undefined?'-1':paramstr5);

	
	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.doctorPanelFiyatlandirmaIstegiGenericService(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			console.log('DoctorPanelFiyatlandirmaIstegiGenericService içerisindeyiz 2');
			resolve(response.toObject());
		  } else {
			console.log('DoctorPanelFiyatlandirmaIstegiGenericService içerisindeyiz 3', error);
			reject(error);
		  }
	}));	

}

export function DoctorPanelTibbiGecmis(protokolid, hastaid){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new DoctorPanelTibbiGecmisRequestGrpc();

	nRequest.setProtokolid(protokolid===undefined?-1:protokolid);
	nRequest.setHastaid(hastaid===undefined?-1:hastaid);
	
	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.doctorPanelTibbiGecmis(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			console.log('DoctorPanelTibbiGecmis içerisindeyiz 2');
			resolve(response.toObject());
		  } else {
			console.log('DoctorPanelTibbiGecmis içerisindeyiz 3', error);
			reject(error);
		  }
	}));	

}


export function DoctorPanelIcdAgaci(userid){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new DoctorPanelIcdAgaciRequestGrpc();

	nRequest.setUserid(userid===undefined?-1:userid);
	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.doctorPanelIcdAgaci(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			console.log('DoctorPanelIcdAgaci içerisindeyiz 2');
			resolve(response.toObject());
		  } else {
			console.log('DoctorPanelIcdAgaci içerisindeyiz 3', error);
			reject(error);
		  }
	}));	

}

export function DoctorPaneliIslemTanimList(userid){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new DoctorPaneliIslemTanimListRequestGrpc();

	nRequest.setUserid(userid===undefined?'':userid+'');
	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.doctorPaneliIslemTanimList(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			console.log('DoctorPaneliIslemTanimList içerisindeyiz 2');
			resolve(response.toObject());
		  } else {
			console.log('DoctorPaneliIslemTanimList içerisindeyiz 3', error);
			reject(error);
		  }
	}));	

}

export function DoctorPanelIslemGrubuAgaci(id){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new DoctorPanelIslemGrubuAgaciRequestGrpc();

	nRequest.setId(id===undefined?'':id+'');
	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.doctorPanelIslemGrubuAgaci(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			console.log('DoctorPanelIslemGrubuAgaci içerisindeyiz 2');
			resolve(response.toObject());
		  } else {
			console.log('DoctorPanelIslemGrubuAgaci içerisindeyiz 3', error);
			reject(error);
		  }
	}));
}

export function DoctorPanelLaboratuarIstekleriPopupTestleriGetir(id){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new DoctorPanelLaboratuarIstekleriPopupTestleriGetirRequestGrpc();

	nRequest.setId(id===undefined?'':id+'');
	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.doctorPanelLaboratuarIstekleriPopupTestleriGetir(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			console.log('doctorPanelLaboratuarIstekleriPopupTestleriGetir crud içerisindeyiz 2');
			resolve(response.toObject());
		  } else {
			console.log('doctorPanelLaboratuarIstekleriPopupTestleriGetir crud içerisindeyiz 3', error);
			reject(error);
		  }
		}));
}


export function DoctorPanelMuayeneCevapCrud(crud, userID, id, bolumid, baslik, cevap ){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new DoctorPanelMuayeneCevapCrudRequestGrpc();

	nRequest.setId(id===undefined?'':id+'');
	nRequest.setUserid(userID===undefined?'':userID+'');
	nRequest.setBolumid(bolumid===undefined?'':bolumid+'');
	nRequest.setCrud(crud===undefined?'':crud+'');
	nRequest.setBaslik(baslik===undefined?'':baslik+'');
	nRequest.setCevap(cevap===undefined?'':cevap+'');
	nRequest.setKurumid(localStorage.getItem('lsiid'));	

	var token = localStorage.getItem('accessToken');

	console.log('doctorPanelMuayeneCevapCrudResponse crud içerisindeyiz 1');

	return new Promise((resolve, reject) => client.doctorPanelMuayeneCevapCrud(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			console.log('doctorPanelMuayeneCevapCrudResponse crud içerisindeyiz 2');
			resolve(response.toObject());
		  } else {
			console.log('doctorPanelMuayeneCevapCrudResponse crud içerisindeyiz 3', error);
			reject(error);
		  }
		}));
}

export function MedikalTanimSablonlariGetirCrud(crud, userID, titlevalue, reporttitlevalue, typevalue, htmleditorval ){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new MedikalTanimSablonlariGetirCrudRequestGrpc();

	nRequest.setTitlevalue(titlevalue===undefined?'':titlevalue+'')
	nRequest.setReporttitlevalue(reporttitlevalue===undefined?'':reporttitlevalue+'')
	nRequest.setTypevalue(typevalue===undefined?'':typevalue+'')
	nRequest.setHtmleditorval(htmleditorval===undefined?'':htmleditorval+'')
	nRequest.setUserid(userID===undefined?'':userID+'');
	nRequest.setCrud(crud===undefined?'':crud+'')
	nRequest.setKurumid(localStorage.getItem('lsiid'));	

	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.medikalTanimSablonlariGetirCrud(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));
}

export function MedikalTanimRadyolojiIsteklerCrud(crud, userid, eklemezamani, doctorPanelMedikalTanimRadyolojiSelectedList){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new MedikalTanimRadyolojiIsteklerCrudRequestGrpc();
	
	nRequest.setUserid(userid);
	nRequest.setCrud(crud);
	nRequest.setKurumid(localStorage.getItem('lsiid'));	
	nRequest.setKayitdurumu(1);
	nRequest.setEklemezamani(eklemezamani);

	for(let indxsl in doctorPanelMedikalTanimRadyolojiSelectedList){
		let tetkiktanimid = doctorPanelMedikalTanimRadyolojiSelectedList[indxsl].tetkiktanimid;
		let id = doctorPanelMedikalTanimRadyolojiSelectedList[indxsl].id;

		const mtlicdg = new MedikalTanimRadyolojiIsteklerCrudRequestGrpc.MedikalTanimRadyolojiIsteklerCrudDtoGrpc();
		mtlicdg.setTetkiktanimid(tetkiktanimid);
		mtlicdg.setId(id);

		nRequest.addMedikaltanimradyolojiisteklercruddtolist(mtlicdg);
	}

	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.medikalTanimRadyolojikIstekleriGetirCrud(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			//console.log('DoktorPaneliIstekQO service', response.toObject());
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));
} 

export function MedikalTanimLaboratuarIsteklerCrud(crud, userid, eklemezamani, doctorPanelMedikalTanimLabaratuarSelectedList){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new MedikalTanimLaboratuarIsteklerCrudRequestGrpc();
	
	nRequest.setUserid(userid);
	nRequest.setCrud(crud);
	nRequest.setKurumid(localStorage.getItem('lsiid'));	
	nRequest.setKayitdurumu(1);
	nRequest.setEklemezamani(eklemezamani);

	for(let indxsl in doctorPanelMedikalTanimLabaratuarSelectedList){
		let testtanimid = doctorPanelMedikalTanimLabaratuarSelectedList[indxsl].testtanimid;
		let id = doctorPanelMedikalTanimLabaratuarSelectedList[indxsl].id;

		const mtlicdg = new MedikalTanimLaboratuarIsteklerCrudRequestGrpc.MedikalTanimLaboratuarIsteklerCrudDtoGrpc();
		mtlicdg.setTesttanimid(testtanimid);
		mtlicdg.setId(id);

		nRequest.addMedikaltanimlaboratuaristeklercruddtolist(mtlicdg);
	}

	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.medikalTanimLaboratuarkIstekleriGetirCrud(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			//console.log('DoktorPaneliIstekQO service', response.toObject());
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));
} 

export function DoktorPaneliService(dateStart, dateEnd, name, lastname, patientno, tckimlikno, protocol, allpatient, queryType, userID){
	//const { user } = useAuth();
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new AyaktanTedaviRequestGrpc();
	nRequest.setDatestart(dateStart===undefined?'':dateStart+'');
	nRequest.setDateend(dateEnd===undefined?'':dateEnd+'');
	nRequest.setName(name===undefined?'':name+'');
	nRequest.setLastname(lastname===undefined?'':lastname+'');
	nRequest.setPatientno(patientno===undefined?'':patientno+'');
	nRequest.setTckimlikno(tckimlikno===undefined?'':tckimlikno+'');
	nRequest.setProtocol(protocol===undefined?'':protocol+'');
	nRequest.setAllpatient(allpatient===undefined?'':allpatient+'');
	nRequest.setQuerytype(queryType===undefined?'':queryType+'');
	nRequest.setUserid(userID===undefined?'':userID+'');
	//console.log('DoctorPanelAyaktaAction service nRequest', nRequest);
	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.ayaktanTedavi(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			//console.log('DoctorPanelAyaktaAction service', response);
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));

}

export function DoktorPaneliServiceYatarak(dateStart, dateEnd, name, lastname, patientno, tckimlikno, protocol, allpatient, queryType, userID, ydID){
	//const { user } = useAuth();
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new YatarakTedaviRequestGrpc();
	nRequest.setDatestart(dateStart===undefined?'':dateStart+'');
	nRequest.setDateend(dateEnd===undefined?'':dateEnd+'');
	nRequest.setName(name===undefined?'':name+'');
	nRequest.setLastname(lastname===undefined?'':lastname+'');
	nRequest.setPatientno(patientno===undefined?'':patientno+'');
	nRequest.setTckimlikno(tckimlikno===undefined?'':tckimlikno+'');
	nRequest.setProtocol(protocol===undefined?'':protocol+'');
	nRequest.setAllpatient(allpatient===undefined?'':allpatient+'');
	nRequest.setQuerytype(queryType===undefined?'':queryType+'');
	nRequest.setUserid(userID===undefined?'':userID+'');
	nRequest.setYatisdurumu(ydID===undefined?'':ydID+'');
	//console.log('DoctorPanelYatarakAction service nRequest', nRequest);
	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.yatarakTedavi(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			//console.log('DoctorPanelYatarakAction service', response.toObject());
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));

}

export function DoktorPaneliIstekQO(id, hastaid, vakaid, kayitdurumu){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new IstekQORequestGrpc();

	nRequest.setId(id);
	nRequest.setHastaid(hastaid);
	nRequest.setVakaid(vakaid);
	nRequest.setKayitdurumu(kayitdurumu);

	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.istekQO(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			//console.log('DoktorPaneliIstekQO service', response.toObject());
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));
}

export function DoktorPaneliGenelFormTumTanilarByPersonel(personelId){

	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new GenelMuayeneFormTumTanilarByPersonelRequestGrpc();

	nRequest.setPersonelid(personelId);

	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.genelMuayeneFormTumTanilarByPersonel(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			//console.log('DoktorPaneliGenelFormTumTanilarByPersonel service', response.toObject());
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));
}

export function DoktorPaneliGenelFormTumTanilarByIstek(istekid){

	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new GenelMuayeneFormTumTanilarByIstekRequestGrpc();

	nRequest.setIstekid(istekid);

	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.genelMuayeneFormTumTanilarByIstek(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			//console.log('DoktorPaneliGenelFormTumTanilarByIstek service', response.toObject());
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));
}

export function DoktorPaneliGenelFormRecete(istekid){

	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new GenelMuayeneFormReceteRequestGrpc();

	nRequest.setIstekid(istekid);

	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.genelMuayeneFormRecete(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			//console.log('DoktorPaneliGenelFormRecete service', response.toObject());
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));
}

export function DoktorPaneliGenelFormForDoctor(islemid){

	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new GenelMuayeneFormForDoctorRequestGrpc();

	nRequest.setIslemid(islemid);

	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.genelMuayeneFormForDoctor(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			//console.log('DoktorPaneliGenelFormForDoctor service', response.toObject());
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));
}


export function DoktorPaneliGenelFormTedaviKontrol(muayeneid){

	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new GenelMuayeneFormTedaviKontrolRequestGrpc();

	nRequest.setMuayeneid(muayeneid);

	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.genelMuayeneFormTedaviKontrol(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			//console.log('DoktorPaneliGenelFormTedaviKontrol service', response.toObject());
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));
}

export function DoktorPaneliGenelMuayeneTeknikTest(protokolid){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new GenelMuayeneTeknikTestRequestGrpc();

	nRequest.setProtokolid(protokolid);
	//console.log('DoktorPaneliGenelMuayeneTeknikTest protokolid', protokolid);

	var token = localStorage.getItem('accessToken');

	
	return new Promise((resolve, reject) => client.genelMuayeneTeknikTest(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			console.log('DoktorPaneliGenelMuayeneTeknikTest service', response.toObject());
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));
		

}

export function DoktorPaneliGenelMuayenePlanliHasta(protokolid){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new GenelMuayenePlanliHastaRequestGrpc();

	nRequest.setProtokolid(protokolid);

	var token = localStorage.getItem('accessToken');

	
	return new Promise((resolve, reject) => client.genelMuayenePlanliHasta(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			console.log('DoktorPaneliGenelMuayenePlanliHasta service', response.toObject());
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));	
}

export function DoktorPaneliGenelMuayenePlanliHastaDetay(planlihastatakibiid){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new GenelMuayenePlanliHastaDetayRequestGrpc();

	nRequest.setPlanlihastatakibiid(planlihastatakibiid);

	var token = localStorage.getItem('accessToken');

	
	return new Promise((resolve, reject) => client.genelMuayenePlanliHastaDetay(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			console.log('DoktorPaneliGenelMuayenePlanliHastaDetay service', response.toObject());
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));	
}

export function DoktorPaneliGenelMuayeneTetkikler(protokolid){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new GenelMuayeneTetkiklerRequestGrpc();

	nRequest.setProtokolid(protokolid);

	var token = localStorage.getItem('accessToken');

	
	return new Promise((resolve, reject) => client.genelMuayeneTetkikler(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			console.log('DoktorPaneliGenelMuayeneTetkikler service', response.toObject());
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));	
}

export function DoktorPaneliGenelMuayeneTetkikDisKurumRaporlari(protokolid){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new GenelMuayeneTetkikDisKurumRaporlariRequestGrpc();

	nRequest.setProtokolid(protokolid);

	var token = localStorage.getItem('accessToken');

	
	return new Promise((resolve, reject) => client.genelMuayeneTetkikDisKurumRaporlari(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			console.log('DoktorPaneliGenelMuayeneTetkikDisKurumRaporlari service', response.toObject());
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));	
}


export function DoktorPaneliGenelForm(muayeneid){
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const nRequest = new GenelMuayeneFormRequestGrpc();

	nRequest.setMuayeneid(muayeneid);

	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.genelMuayeneForm(nRequest, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			//console.log('DoktorPaneliGenelForm service', response.toObject());
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));
}

export function MedikalTanimlarSablonBilgileri(dateStart, dateEnd, personelId){

	//console.log("service personelId", personelId)
	
	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const request = new MedikalTanimSablonlariGetirRequestGrpc();
	request.setDatestart(dateStart);
	request.setDateend(dateEnd);
	request.setPersonelid(personelId);
	//request.setPersonelid(1153);


	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.medikalTanimSablonlariGetir(request, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			//console.log('MedikalTanimlarSablonBilgileri service', response);
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));
}

export function MedikalTanimlarMuayeneSoruBilgileri(dateStart, dateEnd, personelId){

	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const request = new MedikalTanimMuayeneSorulariGetirRequestGrpc();
	request.setDatestart(dateStart);
	request.setDateend(dateEnd);
	request.setPersonelid(personelId);

	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.medikalTanimMuayeneSorulariGetir(request, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			//console.log('MedikalTanimlarMuayeneSoruBilgileri service', response);
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));
}

export function MedikalTanimlarICDBilgileri(dateStart, dateEnd, personelId){

	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const request = new MedikalTanimICDGetirRequestGrpc();
	request.setDatestart(dateStart);
	request.setDateend(dateEnd);
	request.setPersonelid(personelId);

	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.medikalTanimICDGetir(request, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			//console.log('MedikalTanimlarICDBilgileri service', response);
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));
}

export function MedikalTanimlarRadyolojikIstekBilgileri(dateStart, dateEnd, personelId){

	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const request = new MedikalTanimRadyolojikIsteklerGetirRequestGrpc();
	request.setDatestart(dateStart);
	request.setDateend(dateEnd);
	request.setPersonelid(personelId);

	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.medikalTanimRadyolojikIstekleriGetir(request, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			//console.log('MedikalTanimlarRadyolojikIstekBilgileri service', response);
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));
}

export function MedikalTanimlarLaboratuarIstekBilgileri(dateStart, dateEnd, personelId){

	const client = new DoktorPaneliPRClient(process.env.REACT_APP_API_BASE_URL);
	const request = new MedikalTanimLaboratuarIsteklerGetirRequestGrpc();
	request.setDatestart(dateStart);
	request.setDateend(dateEnd);
	request.setPersonelid(personelId);


	var token = localStorage.getItem('accessToken');

	return new Promise((resolve, reject) => client.medikalTanimLaboratuarkIstekleriGetir(request, { "Authorization": token,"lsiid": localStorage.getItem('lsiid') }, (error, response) => {
		if (response) {
			//console.log('MedikalTanimlarLaboratuarIstekBilgileri service', response);
			resolve(response.toObject());
		  } else {
			reject(error);
		  }
		}));
}