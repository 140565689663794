/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

/* eslint-disable */
//@ts-nocheck

// source: doktor_karnesi.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest', null, global);
goog.exportSymbol('proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse', null, global);
goog.exportSymbol('proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto', null, global);
goog.exportSymbol('proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest', null, global);
goog.exportSymbol('proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse', null, global);
goog.exportSymbol('proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto', null, global);
goog.exportSymbol('proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest', null, global);
goog.exportSymbol('proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse', null, global);
goog.exportSymbol('proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto', null, global);
goog.exportSymbol('proto.DoktorKarnesi.GetDoktorKarnesiRequest', null, global);
goog.exportSymbol('proto.DoktorKarnesi.GetDoktorKarnesiResponse', null, global);
goog.exportSymbol('proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DoktorKarnesi.GetDoktorKarnesiRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DoktorKarnesi.GetDoktorKarnesiRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DoktorKarnesi.GetDoktorKarnesiRequest.displayName = 'proto.DoktorKarnesi.GetDoktorKarnesiRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DoktorKarnesi.GetDoktorKarnesiResponse.repeatedFields_, null);
};
goog.inherits(proto.DoktorKarnesi.GetDoktorKarnesiResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DoktorKarnesi.GetDoktorKarnesiResponse.displayName = 'proto.DoktorKarnesi.GetDoktorKarnesiResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.displayName = 'proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.displayName = 'proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.repeatedFields_, null);
};
goog.inherits(proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.displayName = 'proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.displayName = 'proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.displayName = 'proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.repeatedFields_, null);
};
goog.inherits(proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.displayName = 'proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.displayName = 'proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest.displayName = 'proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.repeatedFields_, null);
};
goog.inherits(proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.displayName = 'proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.displayName = 'proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DoktorKarnesi.GetDoktorKarnesiRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DoktorKarnesi.GetDoktorKarnesiRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    donemtanimid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    doktorturuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    kurumid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    merkezibolumid: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiRequest}
 */
proto.DoktorKarnesi.GetDoktorKarnesiRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DoktorKarnesi.GetDoktorKarnesiRequest;
  return proto.DoktorKarnesi.GetDoktorKarnesiRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiRequest}
 */
proto.DoktorKarnesi.GetDoktorKarnesiRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDonemtanimid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoktorturuid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMerkezibolumid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DoktorKarnesi.GetDoktorKarnesiRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DoktorKarnesi.GetDoktorKarnesiRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDonemtanimid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDoktorturuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMerkezibolumid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 donemTanimId = 1;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiRequest.prototype.getDonemtanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiRequest} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiRequest.prototype.setDonemtanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string doktorTuruId = 2;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiRequest.prototype.getDoktorturuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiRequest} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiRequest.prototype.setDoktorturuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 kurumId = 3;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiRequest.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiRequest} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiRequest.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 merkeziBolumId = 4;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiRequest.prototype.getMerkezibolumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiRequest} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiRequest.prototype.setMerkezibolumid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DoktorKarnesi.GetDoktorKarnesiResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    doktorkarnesirowsList: jspb.Message.toObjectList(msg.getDoktorkarnesirowsList(),
    proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DoktorKarnesi.GetDoktorKarnesiResponse;
  return proto.DoktorKarnesi.GetDoktorKarnesiResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto;
      reader.readMessage(value,proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.deserializeBinaryFromReader);
      msg.addDoktorkarnesirows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DoktorKarnesi.GetDoktorKarnesiResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoktorkarnesirowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    kurumid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hastane: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sorumlubolumid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sorumlubolum: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sorumludoktorid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sorumludoktor: jspb.Message.getFieldWithDefault(msg, 6, ""),
    personelcinsi: jspb.Message.getFieldWithDefault(msg, 7, ""),
    ayaktanciro: jspb.Message.getFieldWithDefault(msg, 8, ""),
    ayaktanprotokolsayisi: jspb.Message.getFieldWithDefault(msg, 9, ""),
    ayaktanortalamaciro: jspb.Message.getFieldWithDefault(msg, 10, ""),
    yatisciro: jspb.Message.getFieldWithDefault(msg, 11, ""),
    yatisprotokolsayisi: jspb.Message.getFieldWithDefault(msg, 12, ""),
    yatistumprotokolsayisi: jspb.Message.getFieldWithDefault(msg, 13, ""),
    yatisortalamaciro: jspb.Message.getFieldWithDefault(msg, 14, ""),
    toplamciro: jspb.Message.getFieldWithDefault(msg, 15, ""),
    kurumcirosunaorani: jspb.Message.getFieldWithDefault(msg, 16, ""),
    netciro: jspb.Message.getFieldWithDefault(msg, 17, ""),
    ozelliklimalzemecirosu: jspb.Message.getFieldWithDefault(msg, 18, ""),
    ortalamapolikliniksayisi: jspb.Message.getFieldWithDefault(msg, 19, ""),
    ortalamayatissayisi: jspb.Message.getFieldWithDefault(msg, 20, ""),
    yatisadonusmeorani: jspb.Message.getFieldWithDefault(msg, 21, ""),
    yatisozellikliharicmalzememaliyeti: jspb.Message.getFieldWithDefault(msg, 22, ""),
    sgknetyatiscirosundakimalzemeorani: jspb.Message.getFieldWithDefault(msg, 23, ""),
    netyatiscirosundakimalzemeorani: jspb.Message.getFieldWithDefault(msg, 24, ""),
    ortalamaayaktanlabtestsayisi: jspb.Message.getFieldWithDefault(msg, 25, ""),
    testislemealinmaorani: jspb.Message.getFieldWithDefault(msg, 26, ""),
    ortalamaayaktanradtetkiksayisi: jspb.Message.getFieldWithDefault(msg, 27, ""),
    ortalamamuayenesayisi: jspb.Message.getFieldWithDefault(msg, 28, ""),
    testcirosu: jspb.Message.getFieldWithDefault(msg, 29, ""),
    tetkikcirosu: jspb.Message.getFieldWithDefault(msg, 30, ""),
    btsayisi: jspb.Message.getFieldWithDefault(msg, 31, ""),
    mrsayisi: jspb.Message.getFieldWithDefault(msg, 32, ""),
    ekoeforholtersayisi: jspb.Message.getFieldWithDefault(msg, 33, ""),
    gunkatsayisi: jspb.Message.getFieldWithDefault(msg, 34, ""),
    testsayisi: jspb.Message.getFieldWithDefault(msg, 35, ""),
    tetkiksayisi: jspb.Message.getFieldWithDefault(msg, 36, ""),
    digergoruntulemesayisi: jspb.Message.getFieldWithDefault(msg, 37, ""),
    gruportalamaciro: jspb.Message.getFieldWithDefault(msg, 38, ""),
    gruportalamapolikliniksayisi: jspb.Message.getFieldWithDefault(msg, 39, ""),
    gruportalamayatissayisi: jspb.Message.getFieldWithDefault(msg, 40, ""),
    gruportalamabtsayisi: jspb.Message.getFieldWithDefault(msg, 41, ""),
    gruportalamamrsayisi: jspb.Message.getFieldWithDefault(msg, 42, ""),
    gruportalamaekoeforholtersayisi: jspb.Message.getFieldWithDefault(msg, 43, ""),
    gruportalamaayaktanlabtestsayisi: jspb.Message.getFieldWithDefault(msg, 44, ""),
    gruportalamaayaktanradtetkiksayisi: jspb.Message.getFieldWithDefault(msg, 45, ""),
    grupnetyatiscirosundakimalzemeorani: jspb.Message.getFieldWithDefault(msg, 46, ""),
    grupsgknetyatiscirosundakimalzemeorani: jspb.Message.getFieldWithDefault(msg, 47, ""),
    asinifiameliyatorani: jspb.Message.getFieldWithDefault(msg, 48, ""),
    bsinifiameliyatorani: jspb.Message.getFieldWithDefault(msg, 49, ""),
    csinifiameliyatorani: jspb.Message.getFieldWithDefault(msg, 50, ""),
    dsinifiameliyatorani: jspb.Message.getFieldWithDefault(msg, 51, ""),
    esinifiameliyatorani: jspb.Message.getFieldWithDefault(msg, 52, ""),
    cokluameliyatorani: jspb.Message.getFieldWithDefault(msg, 53, ""),
    doktorkapasitekullanimorani: jspb.Message.getFieldWithDefault(msg, 54, ""),
    toplamameliyatsayisi: jspb.Message.getFieldWithDefault(msg, 55, ""),
    doktorgidisat: jspb.Message.getFieldWithDefault(msg, 56, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto;
  return proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSorumlubolumid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSorumlubolum(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSorumludoktorid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSorumludoktor(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPersonelcinsi(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAyaktanciro(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAyaktanprotokolsayisi(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAyaktanortalamaciro(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatisciro(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatisprotokolsayisi(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatistumprotokolsayisi(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatisortalamaciro(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setToplamciro(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setKurumcirosunaorani(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetciro(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setOzelliklimalzemecirosu(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrtalamapolikliniksayisi(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrtalamayatissayisi(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatisadonusmeorani(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatisozellikliharicmalzememaliyeti(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setSgknetyatiscirosundakimalzemeorani(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetyatiscirosundakimalzemeorani(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrtalamaayaktanlabtestsayisi(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestislemealinmaorani(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrtalamaayaktanradtetkiksayisi(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrtalamamuayenesayisi(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestcirosu(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setTetkikcirosu(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setBtsayisi(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setMrsayisi(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setEkoeforholtersayisi(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setGunkatsayisi(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestsayisi(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setTetkiksayisi(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setDigergoruntulemesayisi(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamaciro(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamapolikliniksayisi(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamayatissayisi(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamabtsayisi(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamamrsayisi(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamaekoeforholtersayisi(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamaayaktanlabtestsayisi(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamaayaktanradtetkiksayisi(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrupnetyatiscirosundakimalzemeorani(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrupsgknetyatiscirosundakimalzemeorani(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setAsinifiameliyatorani(value);
      break;
    case 49:
      var value = /** @type {string} */ (reader.readString());
      msg.setBsinifiameliyatorani(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setCsinifiameliyatorani(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setDsinifiameliyatorani(value);
      break;
    case 52:
      var value = /** @type {string} */ (reader.readString());
      msg.setEsinifiameliyatorani(value);
      break;
    case 53:
      var value = /** @type {string} */ (reader.readString());
      msg.setCokluameliyatorani(value);
      break;
    case 54:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoktorkapasitekullanimorani(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setToplamameliyatsayisi(value);
      break;
    case 56:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoktorgidisat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSorumlubolumid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSorumlubolum();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSorumludoktorid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSorumludoktor();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPersonelcinsi();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAyaktanciro();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAyaktanprotokolsayisi();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAyaktanortalamaciro();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getYatisciro();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getYatisprotokolsayisi();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getYatistumprotokolsayisi();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getYatisortalamaciro();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getToplamciro();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getKurumcirosunaorani();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getNetciro();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getOzelliklimalzemecirosu();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getOrtalamapolikliniksayisi();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getOrtalamayatissayisi();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getYatisadonusmeorani();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getYatisozellikliharicmalzememaliyeti();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getSgknetyatiscirosundakimalzemeorani();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getNetyatiscirosundakimalzemeorani();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getOrtalamaayaktanlabtestsayisi();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getTestislemealinmaorani();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getOrtalamaayaktanradtetkiksayisi();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getOrtalamamuayenesayisi();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getTestcirosu();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getTetkikcirosu();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getBtsayisi();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getMrsayisi();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getEkoeforholtersayisi();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getGunkatsayisi();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getTestsayisi();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getTetkiksayisi();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getDigergoruntulemesayisi();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getGruportalamaciro();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getGruportalamapolikliniksayisi();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getGruportalamayatissayisi();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getGruportalamabtsayisi();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getGruportalamamrsayisi();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getGruportalamaekoeforholtersayisi();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getGruportalamaayaktanlabtestsayisi();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getGruportalamaayaktanradtetkiksayisi();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getGrupnetyatiscirosundakimalzemeorani();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getGrupsgknetyatiscirosundakimalzemeorani();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
  f = message.getAsinifiameliyatorani();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getBsinifiameliyatorani();
  if (f.length > 0) {
    writer.writeString(
      49,
      f
    );
  }
  f = message.getCsinifiameliyatorani();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getDsinifiameliyatorani();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getEsinifiameliyatorani();
  if (f.length > 0) {
    writer.writeString(
      52,
      f
    );
  }
  f = message.getCokluameliyatorani();
  if (f.length > 0) {
    writer.writeString(
      53,
      f
    );
  }
  f = message.getDoktorkapasitekullanimorani();
  if (f.length > 0) {
    writer.writeString(
      54,
      f
    );
  }
  f = message.getToplamameliyatsayisi();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getDoktorgidisat();
  if (f.length > 0) {
    writer.writeString(
      56,
      f
    );
  }
};


/**
 * optional int32 kurumID = 1;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string hastane = 2;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 sorumluBolumID = 3;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getSorumlubolumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setSorumlubolumid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string sorumluBolum = 4;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getSorumlubolum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setSorumlubolum = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 sorumluDoktorID = 5;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getSorumludoktorid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setSorumludoktorid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string sorumluDoktor = 6;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getSorumludoktor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setSorumludoktor = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string personelCinsi = 7;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getPersonelcinsi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setPersonelcinsi = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string ayaktanCiro = 8;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getAyaktanciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setAyaktanciro = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string ayaktanProtokolSayisi = 9;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getAyaktanprotokolsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setAyaktanprotokolsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string ayaktanOrtalamaCiro = 10;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getAyaktanortalamaciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setAyaktanortalamaciro = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string yatisCiro = 11;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getYatisciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setYatisciro = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string yatisProtokolSayisi = 12;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getYatisprotokolsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setYatisprotokolsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string yatisTumProtokolSayisi = 13;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getYatistumprotokolsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setYatistumprotokolsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string yatisOrtalamaCiro = 14;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getYatisortalamaciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setYatisortalamaciro = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string toplamCiro = 15;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getToplamciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setToplamciro = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string kurumCirosunaOrani = 16;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getKurumcirosunaorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setKurumcirosunaorani = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string netCiro = 17;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getNetciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setNetciro = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string ozellikliMalzemeCirosu = 18;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getOzelliklimalzemecirosu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setOzelliklimalzemecirosu = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string ortalamaPoliklinikSayisi = 19;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getOrtalamapolikliniksayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setOrtalamapolikliniksayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string ortalamaYatisSayisi = 20;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getOrtalamayatissayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setOrtalamayatissayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string yatisaDonusmeOrani = 21;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getYatisadonusmeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setYatisadonusmeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string yatisOzellikliHaricMalzemeMaliyeti = 22;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getYatisozellikliharicmalzememaliyeti = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setYatisozellikliharicmalzememaliyeti = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string sgkNetYatisCirosundakiMalzemeOrani = 23;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getSgknetyatiscirosundakimalzemeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setSgknetyatiscirosundakimalzemeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string netYatisCirosundakiMalzemeOrani = 24;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getNetyatiscirosundakimalzemeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setNetyatiscirosundakimalzemeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string ortalamaAyaktanLabTestSayisi = 25;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getOrtalamaayaktanlabtestsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setOrtalamaayaktanlabtestsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string testIslemeAlinmaOrani = 26;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getTestislemealinmaorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setTestislemealinmaorani = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string ortalamaAyaktanRadTetkikSayisi = 27;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getOrtalamaayaktanradtetkiksayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setOrtalamaayaktanradtetkiksayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string ortalamaMuayeneSayisi = 28;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getOrtalamamuayenesayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setOrtalamamuayenesayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string testCirosu = 29;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getTestcirosu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setTestcirosu = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string tetkikCirosu = 30;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getTetkikcirosu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setTetkikcirosu = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string btSayisi = 31;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getBtsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setBtsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string mrSayisi = 32;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getMrsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setMrsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string ekoEforHolterSayisi = 33;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getEkoeforholtersayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setEkoeforholtersayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string gunKatsayisi = 34;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getGunkatsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setGunkatsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string testSayisi = 35;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getTestsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setTestsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string tetkikSayisi = 36;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getTetkiksayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setTetkiksayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string digerGoruntulemeSayisi = 37;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getDigergoruntulemesayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setDigergoruntulemesayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string grupOrtalamaCiro = 38;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getGruportalamaciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setGruportalamaciro = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string grupOrtalamaPoliklinikSayisi = 39;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getGruportalamapolikliniksayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setGruportalamapolikliniksayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string grupOrtalamaYatisSayisi = 40;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getGruportalamayatissayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setGruportalamayatissayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string grupOrtalamaBtSayisi = 41;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getGruportalamabtsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setGruportalamabtsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string grupOrtalamaMrSayisi = 42;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getGruportalamamrsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setGruportalamamrsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string grupOrtalamaEkoEforHolterSayisi = 43;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getGruportalamaekoeforholtersayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setGruportalamaekoeforholtersayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string grupOrtalamaAyaktanLabTestSayisi = 44;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getGruportalamaayaktanlabtestsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setGruportalamaayaktanlabtestsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional string grupOrtalamaAyaktanRadTetkikSayisi = 45;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getGruportalamaayaktanradtetkiksayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setGruportalamaayaktanradtetkiksayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional string grupNetYatisCirosundakiMalzemeOrani = 46;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getGrupnetyatiscirosundakimalzemeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setGrupnetyatiscirosundakimalzemeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional string grupSgkNetYatisCirosundakiMalzemeOrani = 47;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getGrupsgknetyatiscirosundakimalzemeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setGrupsgknetyatiscirosundakimalzemeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * optional string aSinifiAmeliyatOrani = 48;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getAsinifiameliyatorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setAsinifiameliyatorani = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional string bSinifiAmeliyatOrani = 49;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getBsinifiameliyatorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 49, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setBsinifiameliyatorani = function(value) {
  return jspb.Message.setProto3StringField(this, 49, value);
};


/**
 * optional string cSinifiAmeliyatOrani = 50;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getCsinifiameliyatorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setCsinifiameliyatorani = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string dSinifiAmeliyatOrani = 51;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getDsinifiameliyatorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setDsinifiameliyatorani = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional string eSinifiAmeliyatOrani = 52;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getEsinifiameliyatorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 52, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setEsinifiameliyatorani = function(value) {
  return jspb.Message.setProto3StringField(this, 52, value);
};


/**
 * optional string cokluAmeliyatOrani = 53;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getCokluameliyatorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 53, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setCokluameliyatorani = function(value) {
  return jspb.Message.setProto3StringField(this, 53, value);
};


/**
 * optional string doktorKapasiteKullanimOrani = 54;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getDoktorkapasitekullanimorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 54, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setDoktorkapasitekullanimorani = function(value) {
  return jspb.Message.setProto3StringField(this, 54, value);
};


/**
 * optional string toplamAmeliyatSayisi = 55;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getToplamameliyatsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setToplamameliyatsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional string doktorGidisat = 56;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.getDoktorgidisat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 56, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto.prototype.setDoktorgidisat = function(value) {
  return jspb.Message.setProto3StringField(this, 56, value);
};


/**
 * repeated DoktorKarnesiRowDto doktorKarnesiRows = 1;
 * @return {!Array<!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto>}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.prototype.getDoktorkarnesirowsList = function() {
  return /** @type{!Array<!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto, 1));
};


/**
 * @param {!Array<!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto>} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse} returns this
*/
proto.DoktorKarnesi.GetDoktorKarnesiResponse.prototype.setDoktorkarnesirowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto}
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.prototype.addDoktorkarnesirows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DoktorKarnesi.GetDoktorKarnesiResponse.DoktorKarnesiRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiResponse} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiResponse.prototype.clearDoktorkarnesirowsList = function() {
  return this.setDoktorkarnesirowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    donemtanimid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    kurumid: jspb.Message.getFieldWithDefault(msg, 2, 0),
    doktoradi: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bolumid: jspb.Message.getFieldWithDefault(msg, 4, 0),
    alanadi: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest;
  return proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDonemtanimid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoktoradi(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBolumid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlanadi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDonemtanimid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getDoktoradi();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBolumid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getAlanadi();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional int32 donemTanimId = 1;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.prototype.getDonemtanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.prototype.setDonemtanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 kurumId = 2;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string doktorAdi = 3;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.prototype.getDoktoradi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.prototype.setDoktoradi = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 bolumId = 4;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.prototype.getBolumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.prototype.setBolumid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string alanAdi = 5;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.prototype.getAlanadi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiRequest.prototype.setAlanadi = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    doktorkarnesidetayirowsList: jspb.Message.toObjectList(msg.getDoktorkarnesidetayirowsList(),
    proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse;
  return proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto;
      reader.readMessage(value,proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.deserializeBinaryFromReader);
      msg.addDoktorkarnesidetayirows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoktorkarnesidetayirowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    kurumid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hastane: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sorumlubolumid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sorumlubolum: jspb.Message.getFieldWithDefault(msg, 4, ""),
    sorumludoktorid: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sorumludoktor: jspb.Message.getFieldWithDefault(msg, 6, ""),
    personelcinsi: jspb.Message.getFieldWithDefault(msg, 7, 0),
    tarih: jspb.Message.getFieldWithDefault(msg, 8, ""),
    deger: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto;
  return proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSorumlubolumid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSorumlubolum(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSorumludoktorid(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSorumludoktor(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPersonelcinsi(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTarih(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSorumlubolumid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSorumlubolum();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSorumludoktorid();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSorumludoktor();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPersonelcinsi();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getTarih();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDeger();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional int32 kurumId = 1;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string hastane = 2;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 sorumluBolumID = 3;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.getSorumlubolumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.setSorumlubolumid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string sorumluBolum = 4;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.getSorumlubolum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.setSorumlubolum = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 sorumluDoktorID = 5;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.getSorumludoktorid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.setSorumludoktorid = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string sorumluDoktor = 6;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.getSorumludoktor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.setSorumludoktor = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 personelCinsi = 7;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.getPersonelcinsi = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.setPersonelcinsi = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string tarih = 8;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.getTarih = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.setTarih = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string deger = 9;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.getDeger = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto.prototype.setDeger = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated DoktorKarnesiDetayiRowDto doktorKarnesiDetayiRows = 1;
 * @return {!Array<!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto>}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.prototype.getDoktorkarnesidetayirowsList = function() {
  return /** @type{!Array<!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto, 1));
};


/**
 * @param {!Array<!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto>} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse} returns this
*/
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.prototype.setDoktorkarnesidetayirowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto}
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.prototype.addDoktorkarnesidetayirows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.DoktorKarnesiDetayiRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiDetayiResponse.prototype.clearDoktorkarnesidetayirowsList = function() {
  return this.setDoktorkarnesidetayirowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    donemtanimid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    doktorturuid: jspb.Message.getFieldWithDefault(msg, 2, ""),
    kurumid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    merkezibolumid: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest;
  return proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDonemtanimid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoktorturuid(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMerkezibolumid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDonemtanimid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDoktorturuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMerkezibolumid();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional int32 donemTanimId = 1;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.prototype.getDonemtanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.prototype.setDonemtanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string doktorTuruId = 2;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.prototype.getDoktorturuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.prototype.setDoktorturuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 kurumId = 3;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 merkeziBolumId = 4;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.prototype.getMerkezibolumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumRequest.prototype.setMerkezibolumid = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    doktorkarnesibybolumrowsList: jspb.Message.toObjectList(msg.getDoktorkarnesibybolumrowsList(),
    proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse;
  return proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto;
      reader.readMessage(value,proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.deserializeBinaryFromReader);
      msg.addDoktorkarnesibybolumrows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoktorkarnesibybolumrowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    kurumid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hastane: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sorumlubolumid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sorumlubolum: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ayaktanciro: jspb.Message.getFieldWithDefault(msg, 5, ""),
    ayaktanprotokolsayisi: jspb.Message.getFieldWithDefault(msg, 6, ""),
    ayaktanortalamaciro: jspb.Message.getFieldWithDefault(msg, 7, ""),
    yatisciro: jspb.Message.getFieldWithDefault(msg, 8, ""),
    yatisprotokolsayisi: jspb.Message.getFieldWithDefault(msg, 9, ""),
    yatistumprotokolsayisi: jspb.Message.getFieldWithDefault(msg, 10, ""),
    yatisortalamaciro: jspb.Message.getFieldWithDefault(msg, 11, ""),
    toplamciro: jspb.Message.getFieldWithDefault(msg, 12, ""),
    kurumcirosunaorani: jspb.Message.getFieldWithDefault(msg, 13, ""),
    netciro: jspb.Message.getFieldWithDefault(msg, 14, ""),
    ozelliklimalzemecirosu: jspb.Message.getFieldWithDefault(msg, 15, ""),
    ortalamapolikliniksayisi: jspb.Message.getFieldWithDefault(msg, 16, ""),
    ortalamayatissayisi: jspb.Message.getFieldWithDefault(msg, 17, ""),
    yatisadonusmeorani: jspb.Message.getFieldWithDefault(msg, 18, ""),
    yatisozellikliharicmalzememaliyeti: jspb.Message.getFieldWithDefault(msg, 19, ""),
    sgknetyatiscirosundakimalzemeorani: jspb.Message.getFieldWithDefault(msg, 20, ""),
    netyatiscirosundakimalzemeorani: jspb.Message.getFieldWithDefault(msg, 21, ""),
    ortalamaayaktanlabtestsayisi: jspb.Message.getFieldWithDefault(msg, 22, ""),
    testislemealinmaorani: jspb.Message.getFieldWithDefault(msg, 23, ""),
    ortalamaayaktanradtetkiksayisi: jspb.Message.getFieldWithDefault(msg, 24, ""),
    ortalamamuayenesayisi: jspb.Message.getFieldWithDefault(msg, 25, ""),
    testcirosu: jspb.Message.getFieldWithDefault(msg, 26, ""),
    tetkikcirosu: jspb.Message.getFieldWithDefault(msg, 27, ""),
    btsayisi: jspb.Message.getFieldWithDefault(msg, 28, ""),
    mrsayisi: jspb.Message.getFieldWithDefault(msg, 29, ""),
    ekoeforholtersayisi: jspb.Message.getFieldWithDefault(msg, 30, ""),
    gunkatsayisi: jspb.Message.getFieldWithDefault(msg, 31, ""),
    testsayisi: jspb.Message.getFieldWithDefault(msg, 32, ""),
    tetkiksayisi: jspb.Message.getFieldWithDefault(msg, 33, ""),
    digergoruntulemesayisi: jspb.Message.getFieldWithDefault(msg, 34, ""),
    gruportalamaciro: jspb.Message.getFieldWithDefault(msg, 35, ""),
    gruportalamapolikliniksayisi: jspb.Message.getFieldWithDefault(msg, 36, ""),
    gruportalamayatissayisi: jspb.Message.getFieldWithDefault(msg, 37, ""),
    gruportalamabtsayisi: jspb.Message.getFieldWithDefault(msg, 38, ""),
    gruportalamamrsayisi: jspb.Message.getFieldWithDefault(msg, 39, ""),
    gruportalamaekoeforholtersayisi: jspb.Message.getFieldWithDefault(msg, 40, ""),
    gruportalamaayaktanlabtestsayisi: jspb.Message.getFieldWithDefault(msg, 41, ""),
    gruportalamaayaktanradtetkiksayisi: jspb.Message.getFieldWithDefault(msg, 42, ""),
    grupnetyatiscirosundakimalzemeorani: jspb.Message.getFieldWithDefault(msg, 43, ""),
    grupsgknetyatiscirosundakimalzemeorani: jspb.Message.getFieldWithDefault(msg, 44, ""),
    butce: jspb.Message.getFieldWithDefault(msg, 45, ""),
    ciro: jspb.Message.getFieldWithDefault(msg, 46, ""),
    gidisat: jspb.Message.getFieldWithDefault(msg, 47, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto;
  return proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSorumlubolumid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSorumlubolum(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAyaktanciro(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAyaktanprotokolsayisi(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAyaktanortalamaciro(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatisciro(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatisprotokolsayisi(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatistumprotokolsayisi(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatisortalamaciro(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setToplamciro(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setKurumcirosunaorani(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetciro(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setOzelliklimalzemecirosu(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrtalamapolikliniksayisi(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrtalamayatissayisi(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatisadonusmeorani(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatisozellikliharicmalzememaliyeti(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setSgknetyatiscirosundakimalzemeorani(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetyatiscirosundakimalzemeorani(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrtalamaayaktanlabtestsayisi(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestislemealinmaorani(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrtalamaayaktanradtetkiksayisi(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrtalamamuayenesayisi(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestcirosu(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setTetkikcirosu(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setBtsayisi(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setMrsayisi(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setEkoeforholtersayisi(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setGunkatsayisi(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestsayisi(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setTetkiksayisi(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setDigergoruntulemesayisi(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamaciro(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamapolikliniksayisi(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamayatissayisi(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamabtsayisi(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamamrsayisi(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamaekoeforholtersayisi(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamaayaktanlabtestsayisi(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamaayaktanradtetkiksayisi(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrupnetyatiscirosundakimalzemeorani(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrupsgknetyatiscirosundakimalzemeorani(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setButce(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setCiro(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setGidisat(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSorumlubolumid();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSorumlubolum();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAyaktanciro();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAyaktanprotokolsayisi();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAyaktanortalamaciro();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getYatisciro();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getYatisprotokolsayisi();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getYatistumprotokolsayisi();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getYatisortalamaciro();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getToplamciro();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getKurumcirosunaorani();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getNetciro();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getOzelliklimalzemecirosu();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getOrtalamapolikliniksayisi();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getOrtalamayatissayisi();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getYatisadonusmeorani();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getYatisozellikliharicmalzememaliyeti();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSgknetyatiscirosundakimalzemeorani();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getNetyatiscirosundakimalzemeorani();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getOrtalamaayaktanlabtestsayisi();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getTestislemealinmaorani();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getOrtalamaayaktanradtetkiksayisi();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getOrtalamamuayenesayisi();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getTestcirosu();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getTetkikcirosu();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getBtsayisi();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getMrsayisi();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getEkoeforholtersayisi();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getGunkatsayisi();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getTestsayisi();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getTetkiksayisi();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getDigergoruntulemesayisi();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getGruportalamaciro();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getGruportalamapolikliniksayisi();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getGruportalamayatissayisi();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getGruportalamabtsayisi();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getGruportalamamrsayisi();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getGruportalamaekoeforholtersayisi();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getGruportalamaayaktanlabtestsayisi();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getGruportalamaayaktanradtetkiksayisi();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getGrupnetyatiscirosundakimalzemeorani();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getGrupsgknetyatiscirosundakimalzemeorani();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getButce();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getCiro();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getGidisat();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
};


/**
 * optional int32 kurumID = 1;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string hastane = 2;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 sorumluBolumID = 3;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getSorumlubolumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setSorumlubolumid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string sorumluBolum = 4;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getSorumlubolum = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setSorumlubolum = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ayaktanCiro = 5;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getAyaktanciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setAyaktanciro = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string ayaktanProtokolSayisi = 6;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getAyaktanprotokolsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setAyaktanprotokolsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string ayaktanOrtalamaCiro = 7;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getAyaktanortalamaciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setAyaktanortalamaciro = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string yatisCiro = 8;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getYatisciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setYatisciro = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string yatisProtokolSayisi = 9;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getYatisprotokolsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setYatisprotokolsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string yatisTumProtokolSayisi = 10;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getYatistumprotokolsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setYatistumprotokolsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string yatisOrtalamaCiro = 11;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getYatisortalamaciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setYatisortalamaciro = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string toplamCiro = 12;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getToplamciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setToplamciro = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string kurumCirosunaOrani = 13;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getKurumcirosunaorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setKurumcirosunaorani = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string netCiro = 14;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getNetciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setNetciro = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string ozellikliMalzemeCirosu = 15;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getOzelliklimalzemecirosu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setOzelliklimalzemecirosu = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string ortalamaPoliklinikSayisi = 16;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getOrtalamapolikliniksayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setOrtalamapolikliniksayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string ortalamaYatisSayisi = 17;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getOrtalamayatissayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setOrtalamayatissayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string yatisaDonusmeOrani = 18;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getYatisadonusmeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setYatisadonusmeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string yatisOzellikliHaricMalzemeMaliyeti = 19;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getYatisozellikliharicmalzememaliyeti = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setYatisozellikliharicmalzememaliyeti = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string sgkNetYatisCirosundakiMalzemeOrani = 20;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getSgknetyatiscirosundakimalzemeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setSgknetyatiscirosundakimalzemeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string netYatisCirosundakiMalzemeOrani = 21;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getNetyatiscirosundakimalzemeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setNetyatiscirosundakimalzemeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string ortalamaAyaktanLabTestSayisi = 22;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getOrtalamaayaktanlabtestsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setOrtalamaayaktanlabtestsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string testIslemeAlinmaOrani = 23;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getTestislemealinmaorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setTestislemealinmaorani = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string ortalamaAyaktanRadTetkikSayisi = 24;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getOrtalamaayaktanradtetkiksayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setOrtalamaayaktanradtetkiksayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string ortalamaMuayeneSayisi = 25;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getOrtalamamuayenesayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setOrtalamamuayenesayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string testCirosu = 26;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getTestcirosu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setTestcirosu = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string tetkikCirosu = 27;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getTetkikcirosu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setTetkikcirosu = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string btSayisi = 28;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getBtsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setBtsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string mrSayisi = 29;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getMrsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setMrsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string ekoEforHolterSayisi = 30;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getEkoeforholtersayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setEkoeforholtersayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string gunKatsayisi = 31;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getGunkatsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setGunkatsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string testSayisi = 32;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getTestsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setTestsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string tetkikSayisi = 33;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getTetkiksayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setTetkiksayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string digerGoruntulemeSayisi = 34;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getDigergoruntulemesayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setDigergoruntulemesayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string grupOrtalamaCiro = 35;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getGruportalamaciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setGruportalamaciro = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string grupOrtalamaPoliklinikSayisi = 36;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getGruportalamapolikliniksayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setGruportalamapolikliniksayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string GrupOrtalamaYatisSayisi = 37;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getGruportalamayatissayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setGruportalamayatissayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string GrupOrtalamaBtSayisi = 38;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getGruportalamabtsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setGruportalamabtsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string GrupOrtalamaMrSayisi = 39;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getGruportalamamrsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setGruportalamamrsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string GrupOrtalamaEkoEforHolterSayisi = 40;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getGruportalamaekoeforholtersayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setGruportalamaekoeforholtersayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string GrupOrtalamaAyaktanLabTestSayisi = 41;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getGruportalamaayaktanlabtestsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setGruportalamaayaktanlabtestsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string GrupOrtalamaAyaktanRadTetkikSayisi = 42;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getGruportalamaayaktanradtetkiksayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setGruportalamaayaktanradtetkiksayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string GrupNetYatisCirosundakiMalzemeOrani = 43;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getGrupnetyatiscirosundakimalzemeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setGrupnetyatiscirosundakimalzemeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string GrupSgkNetYatisCirosundakiMalzemeOrani = 44;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getGrupsgknetyatiscirosundakimalzemeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setGrupsgknetyatiscirosundakimalzemeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional string Butce = 45;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getButce = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setButce = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional string Ciro = 46;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getCiro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setCiro = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional string Gidisat = 47;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.getGidisat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto.prototype.setGidisat = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * repeated DoktorKarnesiByBolumRowDto doktorKarnesiByBolumRows = 1;
 * @return {!Array<!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto>}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.prototype.getDoktorkarnesibybolumrowsList = function() {
  return /** @type{!Array<!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto, 1));
};


/**
 * @param {!Array<!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto>} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse} returns this
*/
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.prototype.setDoktorkarnesibybolumrowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.prototype.addDoktorkarnesibybolumrows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.DoktorKarnesiByBolumRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByBolumResponse.prototype.clearDoktorkarnesibybolumrowsList = function() {
  return this.setDoktorkarnesibybolumrowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    donemtanimid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    doktorturuid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest;
  return proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDonemtanimid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDoktorturuid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDonemtanimid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getDoktorturuid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 donemTanimId = 1;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest.prototype.getDonemtanimid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest.prototype.setDonemtanimid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string doktorTuruId = 2;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest.prototype.getDoktorturuid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneRequest.prototype.setDoktorturuid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    doktorkarnesibyhastanerowsList: jspb.Message.toObjectList(msg.getDoktorkarnesibyhastanerowsList(),
    proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse;
  return proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto;
      reader.readMessage(value,proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.deserializeBinaryFromReader);
      msg.addDoktorkarnesibyhastanerows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDoktorkarnesibyhastanerowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.toObject = function(opt_includeInstance) {
  return proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    kurumid: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hastane: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ayaktanciro: jspb.Message.getFieldWithDefault(msg, 3, ""),
    ayaktanprotokolsayisi: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ayaktanortalamaciro: jspb.Message.getFieldWithDefault(msg, 5, ""),
    yatisciro: jspb.Message.getFieldWithDefault(msg, 6, ""),
    yatisprotokolsayisi: jspb.Message.getFieldWithDefault(msg, 7, ""),
    yatistumprotokolsayisi: jspb.Message.getFieldWithDefault(msg, 8, ""),
    yatisortalamaciro: jspb.Message.getFieldWithDefault(msg, 9, ""),
    toplamciro: jspb.Message.getFieldWithDefault(msg, 10, ""),
    kurumcirosunaorani: jspb.Message.getFieldWithDefault(msg, 11, ""),
    netciro: jspb.Message.getFieldWithDefault(msg, 12, ""),
    ozelliklimalzemecirosu: jspb.Message.getFieldWithDefault(msg, 13, ""),
    ortalamapolikliniksayisi: jspb.Message.getFieldWithDefault(msg, 14, ""),
    ortalamayatissayisi: jspb.Message.getFieldWithDefault(msg, 15, ""),
    yatisadonusmeorani: jspb.Message.getFieldWithDefault(msg, 16, ""),
    yatisozellikliharicmalzememaliyeti: jspb.Message.getFieldWithDefault(msg, 17, ""),
    sgknetyatiscirosundakimalzemeorani: jspb.Message.getFieldWithDefault(msg, 18, ""),
    netyatiscirosundakimalzemeorani: jspb.Message.getFieldWithDefault(msg, 19, ""),
    ortalamaayaktanlabtestsayisi: jspb.Message.getFieldWithDefault(msg, 20, ""),
    testislemealinmaorani: jspb.Message.getFieldWithDefault(msg, 21, ""),
    ortalamaayaktanradtetkiksayisi: jspb.Message.getFieldWithDefault(msg, 22, ""),
    ortalamamuayenesayisi: jspb.Message.getFieldWithDefault(msg, 23, ""),
    testcirosu: jspb.Message.getFieldWithDefault(msg, 24, ""),
    tetkikcirosu: jspb.Message.getFieldWithDefault(msg, 25, ""),
    btsayisi: jspb.Message.getFieldWithDefault(msg, 26, ""),
    mrsayisi: jspb.Message.getFieldWithDefault(msg, 27, ""),
    ekoeforholtersayisi: jspb.Message.getFieldWithDefault(msg, 28, ""),
    gunkatsayisi: jspb.Message.getFieldWithDefault(msg, 29, ""),
    testsayisi: jspb.Message.getFieldWithDefault(msg, 30, ""),
    tetkiksayisi: jspb.Message.getFieldWithDefault(msg, 31, ""),
    digergoruntulemesayisi: jspb.Message.getFieldWithDefault(msg, 32, ""),
    gruportalamaciro: jspb.Message.getFieldWithDefault(msg, 33, ""),
    gruportalamapolikliniksayisi: jspb.Message.getFieldWithDefault(msg, 34, ""),
    gruportalamayatissayisi: jspb.Message.getFieldWithDefault(msg, 35, ""),
    gruportalamabtsayisi: jspb.Message.getFieldWithDefault(msg, 36, ""),
    gruportalamamrsayisi: jspb.Message.getFieldWithDefault(msg, 37, ""),
    gruportalamaekoeforholtersayisi: jspb.Message.getFieldWithDefault(msg, 38, ""),
    gruportalamaayaktanlabtestsayisi: jspb.Message.getFieldWithDefault(msg, 39, ""),
    gruportalamaayaktanradtetkiksayisi: jspb.Message.getFieldWithDefault(msg, 40, ""),
    grupnetyatiscirosundakimalzemeorani: jspb.Message.getFieldWithDefault(msg, 41, ""),
    grupsgknetyatiscirosundakimalzemeorani: jspb.Message.getFieldWithDefault(msg, 42, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto;
  return proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setKurumid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHastane(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAyaktanciro(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAyaktanprotokolsayisi(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAyaktanortalamaciro(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatisciro(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatisprotokolsayisi(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatistumprotokolsayisi(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatisortalamaciro(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setToplamciro(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setKurumcirosunaorani(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetciro(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOzelliklimalzemecirosu(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrtalamapolikliniksayisi(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrtalamayatissayisi(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatisadonusmeorani(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setYatisozellikliharicmalzememaliyeti(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setSgknetyatiscirosundakimalzemeorani(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetyatiscirosundakimalzemeorani(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrtalamaayaktanlabtestsayisi(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestislemealinmaorani(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrtalamaayaktanradtetkiksayisi(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrtalamamuayenesayisi(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestcirosu(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setTetkikcirosu(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setBtsayisi(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setMrsayisi(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setEkoeforholtersayisi(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setGunkatsayisi(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setTestsayisi(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setTetkiksayisi(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setDigergoruntulemesayisi(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamaciro(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamapolikliniksayisi(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamayatissayisi(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamabtsayisi(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamamrsayisi(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamaekoeforholtersayisi(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamaayaktanlabtestsayisi(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setGruportalamaayaktanradtetkiksayisi(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrupnetyatiscirosundakimalzemeorani(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrupsgknetyatiscirosundakimalzemeorani(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKurumid();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getHastane();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAyaktanciro();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAyaktanprotokolsayisi();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAyaktanortalamaciro();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getYatisciro();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getYatisprotokolsayisi();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getYatistumprotokolsayisi();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getYatisortalamaciro();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getToplamciro();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getKurumcirosunaorani();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getNetciro();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOzelliklimalzemecirosu();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getOrtalamapolikliniksayisi();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getOrtalamayatissayisi();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getYatisadonusmeorani();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getYatisozellikliharicmalzememaliyeti();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSgknetyatiscirosundakimalzemeorani();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getNetyatiscirosundakimalzemeorani();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getOrtalamaayaktanlabtestsayisi();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getTestislemealinmaorani();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getOrtalamaayaktanradtetkiksayisi();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getOrtalamamuayenesayisi();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getTestcirosu();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getTetkikcirosu();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getBtsayisi();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getMrsayisi();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getEkoeforholtersayisi();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getGunkatsayisi();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getTestsayisi();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getTetkiksayisi();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getDigergoruntulemesayisi();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getGruportalamaciro();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getGruportalamapolikliniksayisi();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getGruportalamayatissayisi();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getGruportalamabtsayisi();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getGruportalamamrsayisi();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getGruportalamaekoeforholtersayisi();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getGruportalamaayaktanlabtestsayisi();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getGruportalamaayaktanradtetkiksayisi();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getGrupnetyatiscirosundakimalzemeorani();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getGrupsgknetyatiscirosundakimalzemeorani();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
};


/**
 * optional int32 kurumID = 1;
 * @return {number}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getKurumid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setKurumid = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string hastane = 2;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getHastane = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setHastane = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ayaktanCiro = 3;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getAyaktanciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setAyaktanciro = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string ayaktanProtokolSayisi = 4;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getAyaktanprotokolsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setAyaktanprotokolsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ayaktanOrtalamaCiro = 5;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getAyaktanortalamaciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setAyaktanortalamaciro = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string yatisCiro = 6;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getYatisciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setYatisciro = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string yatisProtokolSayisi = 7;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getYatisprotokolsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setYatisprotokolsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string yatisTumProtokolSayisi = 8;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getYatistumprotokolsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setYatistumprotokolsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string yatisOrtalamaCiro = 9;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getYatisortalamaciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setYatisortalamaciro = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string toplamCiro = 10;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getToplamciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setToplamciro = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string kurumCirosunaOrani = 11;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getKurumcirosunaorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setKurumcirosunaorani = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string netCiro = 12;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getNetciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setNetciro = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string ozellikliMalzemeCirosu = 13;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getOzelliklimalzemecirosu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setOzelliklimalzemecirosu = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string ortalamaPoliklinikSayisi = 14;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getOrtalamapolikliniksayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setOrtalamapolikliniksayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string ortalamaYatisSayisi = 15;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getOrtalamayatissayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setOrtalamayatissayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string yatisaDonusmeOrani = 16;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getYatisadonusmeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setYatisadonusmeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string yatisOzellikliHaricMalzemeMaliyeti = 17;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getYatisozellikliharicmalzememaliyeti = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setYatisozellikliharicmalzememaliyeti = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string sgkNetYatisCirosundakiMalzemeOrani = 18;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getSgknetyatiscirosundakimalzemeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setSgknetyatiscirosundakimalzemeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string netYatisCirosundakiMalzemeOrani = 19;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getNetyatiscirosundakimalzemeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setNetyatiscirosundakimalzemeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string ortalamaAyaktanLabTestSayisi = 20;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getOrtalamaayaktanlabtestsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setOrtalamaayaktanlabtestsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string testIslemeAlinmaOrani = 21;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getTestislemealinmaorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setTestislemealinmaorani = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string ortalamaAyaktanRadTetkikSayisi = 22;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getOrtalamaayaktanradtetkiksayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setOrtalamaayaktanradtetkiksayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string ortalamaMuayeneSayisi = 23;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getOrtalamamuayenesayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setOrtalamamuayenesayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string testCirosu = 24;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getTestcirosu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setTestcirosu = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string tetkikCirosu = 25;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getTetkikcirosu = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setTetkikcirosu = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string btSayisi = 26;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getBtsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setBtsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string mrSayisi = 27;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getMrsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setMrsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string ekoEforHolterSayisi = 28;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getEkoeforholtersayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setEkoeforholtersayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string gunKatsayisi = 29;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getGunkatsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setGunkatsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string testSayisi = 30;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getTestsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setTestsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string tetkikSayisi = 31;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getTetkiksayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setTetkiksayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string digerGoruntulemeSayisi = 32;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getDigergoruntulemesayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setDigergoruntulemesayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string grupOrtalamaCiro = 33;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getGruportalamaciro = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setGruportalamaciro = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string grupOrtalamaPoliklinikSayisi = 34;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getGruportalamapolikliniksayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setGruportalamapolikliniksayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string grupOrtalamaYatisSayisi = 35;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getGruportalamayatissayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setGruportalamayatissayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string grupOrtalamaBtSayisi = 36;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getGruportalamabtsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setGruportalamabtsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string grupOrtalamaMrSayisi = 37;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getGruportalamamrsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setGruportalamamrsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string grupOrtalamaEkoEforHolterSayisi = 38;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getGruportalamaekoeforholtersayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setGruportalamaekoeforholtersayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string grupOrtalamaAyaktanLabTestSayisi = 39;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getGruportalamaayaktanlabtestsayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setGruportalamaayaktanlabtestsayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string grupOrtalamaAyaktanRadTetkikSayisi = 40;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getGruportalamaayaktanradtetkiksayisi = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setGruportalamaayaktanradtetkiksayisi = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string grupNetYatisCirosundakiMalzemeOrani = 41;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getGrupnetyatiscirosundakimalzemeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setGrupnetyatiscirosundakimalzemeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string grupSgkNetYatisCirosundakiMalzemeOrani = 42;
 * @return {string}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.getGrupsgknetyatiscirosundakimalzemeorani = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto.prototype.setGrupsgknetyatiscirosundakimalzemeorani = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * repeated DoktorKarnesiByHastaneRowDto doktorKarnesiByHastaneRows = 1;
 * @return {!Array<!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto>}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.prototype.getDoktorkarnesibyhastanerowsList = function() {
  return /** @type{!Array<!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto, 1));
};


/**
 * @param {!Array<!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto>} value
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse} returns this
*/
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.prototype.setDoktorkarnesibyhastanerowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto}
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.prototype.addDoktorkarnesibyhastanerows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.DoktorKarnesiByHastaneRowDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse} returns this
 */
proto.DoktorKarnesi.GetDoktorKarnesiByHastaneResponse.prototype.clearDoktorkarnesibyhastanerowsList = function() {
  return this.setDoktorkarnesibyhastanerowsList([]);
};


goog.object.extend(exports, proto.DoktorKarnesi);
